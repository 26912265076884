import client from "./client";

const endpoint = "flight";

const addRemarks = ({ body }) => client.post(`${endpoint}/remarks`, body);

const getFlightDetailsByrecordLocator = ({ referenceId }) =>
  client.get(`${endpoint}/booking/by-reference/${referenceId}`);

export { addRemarks, getFlightDetailsByrecordLocator };
