import React, { useState, useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation } from "react-router-dom";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import IconButton from "@mui/material/IconButton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@mui/material/Tooltip";

import { CurrencyContext } from "../useContext";
import confetti from "canvas-confetti";
import { getPaymentOptions } from "api/Payment";
import { LabelAndValue } from "components/ui";

import { checkISAdminOrEmployee } from "utils/Utils";
import useApi from "hooks/useApi";

import Badge from "components/badge";
import RoomCard from "components/hotels/room-card";

const HotelsConfirmation = () => {
  const SECONDS = 5;
  var end = Date.now() + SECONDS * 1000;

  var colors = ["#f15e22", "#124786"];
  const [copy, setCopy] = useState(false);

  const { state } = useLocation();

  useEffect(() => {
    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors,
      });
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  }, []);

  return (
    <div className="reservation">
      <div className="booking-info reservation-content">
        <div>
          <div className="booking-info-wrapper card-card">
            <span className="circle">
              <CheckIcon className="check-icon" />
            </span>
            <div>
              <p className="medium-font-size  mb-12 gray">
                Congratulations! your hotel booking is confirmed.
              </p>
              <div className="d-flex align-items-center">
                <p className="medium-font-size gray">
                  Control Number:{" "}
                  <strong>
                    {state?.hotelSellResponse?.reservation?.controlNumber}
                  </strong>
                </p>
                <CopyToClipboard
                  text={state?.hotelSellResponse?.reservation?.controlNumber}
                  onCopy={() => setCopy(true)}
                >
                  <Tooltip title={copy ? "Copied!" : "Copy"}>
                    <IconButton>
                      <CopyAllIcon />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </div>
          </div>

          <p className="medium-font-size mb-16">Hotel Details</p>
          <div className="card-card">
            <LabelAndValue
              label="Hotel Name: "
              value={state?.hotelDetailsAndRoom?.basicProperty?.hotelName}
              justifyContent="flex-start"
            />

            <LabelAndValue
              label="Address: "
              value={
                state?.hotelDetailsAndRoom?.address?.hotelCityName +
                "/" +
                state?.hotelDetailsAndRoom?.address?.hotelAddressLine
              }
              justifyContent="flex-start"
            />
          </div>

          <p className="medium-font-size mb-16">Travelers Details</p>
          <div className="card-card">
            <LabelAndValue
              label="First Name:"
              value={state?.hotelSellResponse?.traveller?.passengerFirstName}
              justifyContent="flex-start"
            />
            <LabelAndValue
              label="Last Name:"
              value={state?.hotelSellResponse?.traveller?.passengerLastName}
              justifyContent="flex-start"
            />
            <LabelAndValue
              label="Traveler number:"
              value={state?.hotelSellResponse?.traveller?.quantity}
              justifyContent="flex-start"
            />
          </div>
        </div>
        <aside className="reversation-sideBar-wrapper">
          <div className="reversation-bill">
            <div className="reversation-bill-box">
              <div className="card-card">
                <p className="medium-font-size pb-2">Price details</p>
                <hr />

                <LabelAndValue
                  label="Price"
                  value={
                    state?.hotelDetailsAndRoom?.pricing?.currency +
                    (state?.hotelDetailsAndRoom?.pricing?.priceAfterTax
                      ? " " + state?.hotelDetailsAndRoom?.pricing?.priceAfterTax
                      : " " +
                        state?.hotelDetailsAndRoom?.pricing?.priceAfterTax)
                  }
                  mb={2}
                />
                {state?.hotelDetailsAndRoom?.pricing?.taxes &&
                  state?.hotelDetailsAndRoom?.pricing?.taxes !== "NaN" && (
                    <LabelAndValue
                      label="Taxes and fees"
                      value={
                        state?.hotelDetailsAndRoom?.pricing?.currency +
                        " " +
                        state?.hotelDetailsAndRoom?.pricing?.taxes
                      }
                    />
                  )}
                <hr />

                <LabelAndValue
                  mt={2}
                  sx={{ "& p": { fontSize: "1rem", fontWeight: "bold" } }}
                  label="Total"
                  value={
                    state?.hotelDetailsAndRoom?.pricing?.currency +
                    " " +
                    state?.hotelDetailsAndRoom?.pricing?.priceAfterTax
                  }
                />
              </div>
              <RoomCard
                room={state?.hotelDetailsAndRoom?.room}
                hotelDetails={state?.hotelDetailsAndRoom}
                hasReserveBtn
              />
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default HotelsConfirmation;
