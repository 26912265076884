import { createTheme } from "@mui/material/styles";

export const AppTheme = createTheme({
  palette: {
    primary: {
      main: "#f15e22",
      light: "#F8F8FA",
    },
    secondary: {
      main: "#124786",
      light: "#919294",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#fff",
    },

    error: {
      main: "#CB001F",
    },
    success: {
      main: "#4CAF50",
    },
  },
});
