import React from "react";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Typography } from "@mui/material";

import logo from "assets/images/satyamTours.svg";

import ItineraryComponent from "components/itinerary";

import Badge from "./badge";

function ShareQuatePrice({
  flight,
  dictionaries,
  destinationLocationCode,
  originLocationCode,
  totalPrice,
  getSellingForSurinamePrice,
  currency,
  checked,
  daysNumber,
  fareAdjustment,
  adjustedPrice,
}) {
  const handleGetTravelerPricing = (type) => {
    let info = {};

    // Get FareAdjustment
    const getFareAdjustment =
      fareAdjustment && fareAdjustment[flight?.id]
        ? fareAdjustment[flight?.id]
        : 0;

    // Price of the passenger fare
    const price = flight?.travelerPricings?.find(
      (travelerPricing) => travelerPricing?.travelerType === type
    )?.price?.total;

    // Number of passengers based on the type (ADULT/CHILD/INFANT)
    info.number = flight?.travelerPricings?.filter(
      (travelerPricing) => travelerPricing?.travelerType === type
    )?.length;

    // Edit the price of the passenger based on the FareAdjustment
    if (getFareAdjustment) {
      info.price = (
        price * 1 +
        getFareAdjustment / flight?.travelerPricings?.length
      ).toFixed(2);
    } else {
      info.price = price;
    }

    // Totalprice of the passengers based on age
    info.totalPrice = (+info.price * info.number)?.toFixed(2);

    return info;
  };

  return (
    <Box
      sx={{
        borderRadius: "10px",
        background: "#fff",
        border: "10px solid #fff",
      }}
    >
      <Box
        px={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">
          {flight?.source === "NDC" && (
            <FiberManualRecordIcon color="secondary" />
          )}
          Price Details
        </Typography>
        <img src={logo} alt="logo" width="170px" />
      </Box>
      <Box px={2}>
        {flight?.itineraries?.map((itinerary, index) =>
          itinerary?.segments?.map((item, index) => (
            <ItineraryComponent
              key={index}
              index={index}
              itinerary={itinerary}
              segment={item}
              dictionaries={dictionaries}
              flight={flight}
              destinationLocationCode={destinationLocationCode}
              originLocationCode={originLocationCode}
              hideClass
              isScreenShot
            />
          ))
        )}
      </Box>
      <Box display="flex" gap={1} mt={1} ml={4.8}>
        {daysNumber && <Badge title={`${daysNumber} Days`} className="teal" />}
        {flight?.numberOfBookableSeats && (
          <Badge
            className="teal"
            title={`${flight?.numberOfBookableSeats} Seats Left`}
          />
        )}
      </Box>
      <Box p={2} mt={1.5} sx={{ background: "rgb(245, 247, 249)" }}>
        {!adjustedPrice && (
          <Box mb={2}>
            <Typography>Travelers Price:</Typography>
            {handleGetTravelerPricing("SENIOR")?.number > 0 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={1}
              >
                <Typography
                  fontSize="14px"
                  fontWeight="bold"
                  color="text.disabled"
                >
                  {handleGetTravelerPricing("SENIOR")?.number}x{" "}
                  {handleGetTravelerPricing("SENIOR")?.number > 1 &&
                    currency +
                      handleGetTravelerPricing("SENIOR")?.price +
                      "  "}{" "}
                  (SENIOR)
                </Typography>

                <Typography fontWeight="bold" fontSize="14px">
                  {currency + " "}

                  {handleGetTravelerPricing("SENIOR")?.totalPrice}
                </Typography>
              </Box>
            )}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
            >
              <Typography
                fontSize="14px"
                fontWeight="bold"
                color="text.disabled"
              >
                {handleGetTravelerPricing("ADULT")?.number}x{" "}
                {handleGetTravelerPricing("ADULT")?.number > 1 &&
                  currency +
                    handleGetTravelerPricing("ADULT")?.price +
                    "  "}{" "}
                (Adult)
              </Typography>

              <Typography fontWeight="bold" fontSize="14px">
                {currency + " "}

                {handleGetTravelerPricing("ADULT")?.totalPrice}
              </Typography>
            </Box>

            {handleGetTravelerPricing("CHILD")?.number > 0 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={1}
              >
                <Typography
                  fontSize="14px"
                  fontWeight="bold"
                  color="text.disabled"
                >
                  {handleGetTravelerPricing("CHILD")?.number}x{" "}
                  {handleGetTravelerPricing("CHILD")?.number > 1 &&
                    currency +
                      handleGetTravelerPricing("CHILD")?.price +
                      "  "}{" "}
                  (Child)
                </Typography>

                <Typography fontWeight="bold" fontSize="14px">
                  {currency + " "}

                  {handleGetTravelerPricing("CHILD")?.totalPrice}
                </Typography>
              </Box>
            )}
            {handleGetTravelerPricing("HELD_INFANT")?.number > 0 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={1}
              >
                <Typography
                  fontSize="14px"
                  fontWeight="bold"
                  color="text.disabled"
                >
                  {handleGetTravelerPricing("HELD_INFANT")?.number}x{" "}
                  {handleGetTravelerPricing("HELD_INFANT")?.number > 1 &&
                    currency +
                      handleGetTravelerPricing("HELD_INFANT")?.price +
                      "  "}{" "}
                  (Infant)
                </Typography>

                <Typography fontWeight="bold" fontSize="14px">
                  {currency + " "}

                  {handleGetTravelerPricing("HELD_INFANT")?.totalPrice}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        <hr />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontWeight="bold" fontSize="18px">
            {checked ? "Total Bank Price" : "Total Price"}
          </Typography>

          <Typography fontWeight="bold" fontSize="18px">
            {currency + " "}

            {adjustedPrice || totalPrice(flight)}
          </Typography>
        </Box>
        {getSellingForSurinamePrice() && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={1}
          >
            <Typography fontWeight="bold" fontSize="18px">
              Total Cash Price
            </Typography>

            <Typography fontWeight="bold" fontSize="18px">
              {currency + " "}

              {getSellingForSurinamePrice()}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ShareQuatePrice;
