import client from "./client";

const endpoint = "payments/";

const getPaymentIntent = ({ body }) =>
  client.post(`${endpoint}payment-intent`, body);

const PaymentWebHook = ({ body }) => client.post(`${endpoint}webhook`, body);

const getPaymentOptions = ({ currency }) =>
  client.get(`${endpoint}payment-options/?currency=${currency}`);

const createTikkie = ({ body }) => client.post(`${endpoint}tikkie`, body);

const getAllTikkieRequests = () => client.get(`${endpoint}tikkie-history`);

const createStripURL = (body) => client.post(`${endpoint}stripe-url`, body);

export {
  getPaymentIntent,
  PaymentWebHook,
  getPaymentOptions,
  createTikkie,
  getAllTikkieRequests,
  createStripURL,
};
