import { useState } from "react";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import CurrencyProvider from "pages/useContext";

import CheckoutForm from "components/checkouForm";

import Footer from "components/footer";
import FeedBack from "components/Feedback";
import HelpDesk from "components/help-desk";
import Dialog from "components/dialog";

import { AppTheme } from "./theme";

import "assets/css/global.css";

import { AppRouter } from "router";

function App() {
  const [open, setOpen] = useState(false);

  const currency = JSON.parse(
    localStorage.getItem("satyam-currency")
  )?.currency;

  const stripe_key =
    currency === "EUR"
      ? process.env.REACT_APP_STRIPE_EUR
      : process.env.REACT_APP_STRIPE_USD;

  const stripePromise = loadStripe(stripe_key);
  const [clientSecret, setClientSecret] = useState("");
  const [openCheckout, setOpenCheckout] = useState("");

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  const handleCloseModalFeedBack = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={AppTheme}>
      <CssBaseline />
      <CurrencyProvider>
        <div className="main-view">
          <div className="feedback-stick" onClick={() => setOpen(true)}>
            <TextsmsIcon sx={{ width: "14px" }} />
            <p>Feedback</p>
          </div>
          <AppRouter
            setClientSecret={setClientSecret}
            setOpenCheckout={setOpenCheckout}
          />

          {location.pathname !== "/booking" && <Footer />}

          {/***** FeedBack Modal *****/}
          <Dialog open={open} onClose={handleCloseModalFeedBack}>
            <FeedBack closeModal={handleCloseModalFeedBack} />
          </Dialog>

          {/***** HelpDesk Modal *****/}
          <HelpDesk />
        </div>
      </CurrencyProvider>

      {/***** Stripe Payment Modal *****/}
      {clientSecret && openCheckout && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            clientSecret={clientSecret}
            setClientSecret={setClientSecret}
          />
        </Elements>
      )}
    </ThemeProvider>
  );
}

export default App;
