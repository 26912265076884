import * as Yup from "yup";

/// common Country Info
const commontIntialValues = [
  {
    mandatory: true,
    value: "firstName",
    placeholder: "John",
    label: "First Name",
  },
  {
    mandatory: true,
    value: "lastName",
    placeholder: "Dou",
    label: "Last Name",
  },

  {
    mandatory: true,
    value: "gender",
    placeholder: "gender",
    label: "gender",
    type: "select",
  },
  {
    mandatory: true,
    value: "maritalName",
    placeholder: "Select",
    label: "Marital Status",
    type: "select",
  },
  {
    mandatory: true,
    value: "dateOfBirth",
    placeholder: "DD/MM/YYYY",
    label: "Date Of Birth",
  },
  {
    mandatory: true,
    value: "email",
    placeholder: "Dou@gmail.com",
    label: "Email",
  },
  {
    mandatory: true,
    value: "phone",
    placeholder: "33244",
    label: "Phone Number",
  },
];

const commonValidationSchema = {
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  dateOfBirth: Yup.string().required().nullable().label("Date Of Birth"),
  gender: Yup.string().required().label("Gender"),
  maritalName: Yup.string().required().label("Marital Status"),
  email: Yup.string().required().email().label("Email"),
  phone: Yup.string().required().label("Phone Number"),
};

/// India Country Info
const IndiaIntialValues = {
  intialValues: [
    ...commontIntialValues,
    {
      mandatory: true,
      value: "nationality",
      placeholder: "Indian",
      label: "Nationality",
    },
    {
      mandatory: true,
      value: "countryOfBirth",
      placeholder: "Indian",
      label: "Country Of Birth",
    },
    {
      mandatory: true,
      value: "idNumber",
      placeholder: "I98765678",
      label: "ID Number",
    },
    {
      mandatory: true,
      value: "religion",
      placeholder: "Test",
      label: "Religion",
    },
    {
      mandatory: true,
      value: "school",
      placeholder: "Test",
      label: "School/Graduated",
    },
    {
      mandatory: true,
      title: "Passport Info",
      value: "passNum",
      placeholder: "098778",
      label: "Passport Number",
    },
    {
      mandatory: true,
      value: "placeOfSub",
      placeholder: "India",
      label: "Place of application",
    },
    {
      mandatory: true,
      value: "dateSub",
      placeholder: "India",
      label: "Submission date",
    },
    {
      mandatory: true,
      value: "dateEnd",
      placeholder: "India",
      label: "Valid until",
    },
    {
      mandatory: true,
      value: "country",
      placeholder: "tes",
      label: "Country",
    },
  ],
  validationSchema: Yup.object().shape({
    ...commonValidationSchema,
    nationality: Yup.string().required().label("Nationality"),
    countryOfBirth: Yup.string()
      .required()
      .nullable()
      .label("Country Of Birth"),
    idNumber: Yup.string().required().nullable().label("ID Number"),
    religion: Yup.string().required().label("Religion"),
    school: Yup.string().required().label("School/Graduated"),
    dateSub: Yup.string().required().nullable().label("Submission date"),
    dateEnd: Yup.string().required().nullable().label("Valid until"),
    passNum: Yup.string().required().label("Passport Number"),
    placeOfSub: Yup.string().required().label("Place of application"),
    country: Yup.string().required().label("Country"),
    fFirstName: Yup.string().required().label("First Name"),
    fNationality: Yup.string().required().label("Nationality"),
    fCountryOfBirth: Yup.string()
      .required()
      .nullable()
      .label("Country Of Birth"),
  }),
};

const CandaInfoANDNetherland = {
  intialValues: [
    ...commontIntialValues,
    {
      mandatory: true,
      value: "district",
      placeholder: "tes",
      label: "District",
      title: "Address",
    },
    {
      mandatory: true,
      value: "homeNumber",
      placeholder: "tes",
      label: "Home Number",
    },
    {
      mandatory: true,
      value: "street",
      placeholder: "tes",
      label: "Street",
    },
  ],
  validationSchema: Yup.object().shape({
    ...commonValidationSchema,
    street: Yup.string().required().label("Street"),
    homeNumber: Yup.string().required().label("Home Number"),
    district: Yup.string().required().label("district"),
  }),
};

const TurkeyInfo = {
  intialValues: [
    ...commontIntialValues,
    {
      mandatory: true,
      value: "district",
      placeholder: "tes",
      label: "District",
      title: "Address",
    },

    {
      mandatory: true,
      value: "homeNumber",
      placeholder: "tes",
      label: "Home Number",
    },
    {
      mandatory: true,
      value: "street",
      placeholder: "tes",
      label: "Street",
    },
    {
      mandatory: true,
      value: "dateArrival",
      placeholder: "MM-DD-YYYY",
      label: "Date of Arrival",
      title: "Travel Information",
    },
    {
      mandatory: true,
      value: "datedeparture",
      placeholder: "MM-DD-YYYY",
      label: "Date of departure",
    },
  ],
  validationSchema: Yup.object().shape({
    ...commonValidationSchema,
    street: Yup.string().required().label("Street"),
    district: Yup.string().required().label("district"),
    homeNumber: Yup.string().required().label("Home Number"),
    dateArrival: Yup.string().required().nullable().label("Date of Arrival"),
    datedeparture: Yup.string()
      .required()
      .nullable()
      .label("Date of departure"),
  }),
};

const USInfo = {
  intialValues: [
    ...commontIntialValues,
    {
      mandatory: true,
      value: "nationality",
      placeholder: "Indian",
      label: "Nationality",
    },
    {
      mandatory: true,
      value: "otherNationalities",
      placeholder: "Indian",
      label: "Other nationalities",
    },
    {
      mandatory: true,
      value: "idNumber",
      placeholder: "I98765678",
      label: "ID Number",
    },
    {
      mandatory: true,
      value: "district",
      placeholder: "tes",
      label: "District",
      title: "Address",
    },
    {
      mandatory: true,
      value: "homeNumber",
      placeholder: "tes",
      label: "Home Number",
    },
    {
      mandatory: true,
      value: "street",
      placeholder: "tes",
      label: "Street",
    },
    {
      mandatory: true,
      title: "Passport Info",
      value: "passNum",
      placeholder: "098778",
      label: "Passport Number",
    },
    {
      mandatory: true,
      value: "placeOfSub",
      placeholder: "India",
      label: "Place of application",
    },
    {
      mandatory: true,
      value: "dateSub",
      placeholder: "India",
      label: "Submission date",
    },
    {
      mandatory: true,
      value: "dateEnd",
      placeholder: "India",
      label: "Valid until",
    },
    {
      mandatory: true,
      value: "stoleOrNo",
      placeholder: "Select",
      label: "Has your passport ever been lost/stolen?",
      type: "select",
    },
    {
      mandatory: true,
      value: "purposeTrip",
      placeholder: "Study",
      label: "Purpose of your trip to USA?",
      title: "Travel Information",
    },
    {
      mandatory: true,
      value: "plannedTrip",
      placeholder: "Study",
      label: "Planned day of arrival?",
    },
    {
      mandatory: true,
      value: "lengthTrip",
      placeholder: "Study",
      label: "Planned length of stay in USA",
    },
    {
      mandatory: true,
      value: "addressTrip",
      placeholder: "2 days",
      label: "Address where you are staying in USA",
    },
    {
      mandatory: true,
      value: "namePersonTrip",
      placeholder: "2 days",
      label: "Name of the person paying for your travel",
    },
    {
      mandatory: true,
      value: "emailPersonTrip",
      placeholder: "2 days",
      label: "Email of the person paying for your travel",
    },
    {
      mandatory: true,
      value: "relaionPersonTrip",
      placeholder: "Brother",
      label: "The relationship with you",
    },
    {
      mandatory: true,
      value: "addressPersonTrip",
      placeholder: "Street,home",
      label: "Is the address of the person paying for you the same as your?",
      type: "select",
    },
    {
      mandatory: true,
      value: "beInUsa",
      placeholder: "Street,home",
      label: "Have you ever been to usa",
      type: "select",
    },
    {
      mandatory: true,
      value: "appliedInUsa",
      placeholder: "Street,home",
      label: "Have you applied for a USA visa before?",
      type: "select",
    },
    {
      mandatory: true,
      value: "appliedCountry",
      placeholder: "Street,home",
      label:
        "Are you applying for the visa in the country where you are staying?",
      type: "select",
    },
    {
      mandatory: true,
      value: "appliedCountryOriginal",
      placeholder: "Street,home",
      label: "Is the above country your original place of residence?",
      type: "select",
    },
    {
      mandatory: true,
      value: "contactPerson",
      placeholder: "Street,home",
      label: "Contact person in USA",
      title: "USA contact information",
    },
    {
      mandatory: true,
      value: "organizationName",
      placeholder: "Street,home",
      label: "Organization name in USA",
    },
    {
      mandatory: true,
      value: "residenceAddress",
      placeholder: "Street,home",
      label: "The residence address in USA",
    },
    {
      mandatory: true,
      value: "residenceEmail",
      placeholder: "Street,home",
      label: "The residence Email",
    },
    {
      mandatory: true,
      value: "residencePhone",
      placeholder: "Street,home",
      label: "The residence Phone",
    },
    {
      mandatory: true,
      value: "fatherFN",
      placeholder: "Johne",
      label: "Father's first name",
      title: "Family Information",
    },
    {
      mandatory: true,
      value: "fatherLN",
      placeholder: "Dou",
      label: "Father's last name",
    },
    {
      mandatory: true,
      value: "motherFN",
      placeholder: "Johne",
      label: "Mother's first name",
    },
    {
      mandatory: true,
      value: "motherLN",
      placeholder: "Dou",
      label: "Mother's last name",
    },
    {
      mandatory: true,
      value: "workPro",
      placeholder: "Engeneer",
      label: "Profession",
      title: "Work/education information from the traveler",
    },
    {
      mandatory: true,
      value: "workTask",
      placeholder: "Engeneer",
      label: "Task",
    },
    {
      mandatory: true,
      value: "workName",
      placeholder: "Engeneer",
      label: "Company/School name",
    },
    {
      mandatory: true,
      value: "workAddress",
      placeholder: "Engeneer",
      label: "Address of the company/school ",
    },
    {
      mandatory: true,
      value: "workPhone",
      placeholder: "Engeneer",
      label: "Company/school telephone number",
    },
    {
      mandatory: true,
      value: "educationSchool",
      placeholder: "Street,home",
      label: "Name of school where you last attended",
      title: "Education",
    },
    {
      mandatory: true,
      value: "educationField",
      placeholder: "Street,home",
      label: "Field of Study",
    },
    {
      mandatory: true,
      value: "educationReg",
      placeholder: "Street,home",
      label: "Registration date ",
      type: "date",
    },
    {
      mandatory: true,
      value: "educationDep",
      placeholder: "Street,home",
      label: "Depreciation date ",
      type: "date",
    },
  ],
  validationSchema: Yup.object().shape({
    ...commonValidationSchema,
    nationality: Yup.string().required().label("Nationality"),
    otherNationalities: Yup.string().required().label("Other Nationalities"),
    idNumber: Yup.string().required().nullable().label("ID Number"),
    district: Yup.string().required().label("District"),
    dateSub: Yup.string().required().nullable().label("Submission date"),
    dateEnd: Yup.string().required().nullable().label("Valid until"),
    passNum: Yup.string().required().label("Passport Number"),
    placeOfSub: Yup.string().required().label("Place of application"),
    street: Yup.string().required().label("Street"),
    homeNumber: Yup.string().required().label("Home Number"),
    stoleOrNo: Yup.string().required().label("Select"),
    purposeTrip: Yup.string().required().label("Purpose of Trip"),
    plannedTrip: Yup.string().required().label("Planned day of arrival"),
    lengthTrip: Yup.string().required().label("Planned length of stay in USA"),
    addressTrip: Yup.string()
      .required()
      .label("Address where you are staying in USA"),
    namePersonTrip: Yup.string()
      .required()
      .label("Name of the person paying for your travel"),
    emailPersonTrip: Yup.string()
      .required()
      .email()
      .label("Email of the person paying for your travel"),
    relaionPersonTrip: Yup.string()
      .required()
      .label("The relationship with you"),
    addressPersonTrip: Yup.string().required().label("This"),
    beInUsa: Yup.string().required().label("This"),
    appliedInUsa: Yup.string().required().label("This"),
    appliedCountry: Yup.string().required().label("This"),
    appliedCountryOriginal: Yup.string().required().label("This"),
    motherLN: Yup.string().required().label("This"),
    motherFN: Yup.string().required().label("This"),
    fatherFN: Yup.string().required().label("This"),
    fatherLN: Yup.string().required().label("This"),
    workPro: Yup.string().required().label("This"),
    workTask: Yup.string().required().label("This"),
    workAddress: Yup.string().required().label("This"),
    workName: Yup.string().required().label("This"),
    workPhone: Yup.string().required().label("This"),
    contactPerson: Yup.string().required().label("contactPerson"),
    organizationName: Yup.string().required().label("Organization name in USA"),
    residenceAddress: Yup.string()
      .required()
      .label("The residence address in USA"),
    residenceEmail: Yup.string()
      .required()
      .email()
      .label("The residence Email"),
    residencePhone: Yup.string().required().label("The residence Phone"),
    educationSchool: Yup.string().required().label("Education School"),
    educationField: Yup.string().required().label("Education Field"),
    educationReg: Yup.string().required().label("Registration date "),
    educationDep: Yup.string().required().label("Depreciation date "),
  }),
};
///Choose the right country Info
export const ChooseCountrySchema = (country) => {
  if (country) {
    const allCountries = {
      india: IndiaIntialValues,
      canada: CandaInfoANDNetherland,
      netherlands: CandaInfoANDNetherland,
      turkey: TurkeyInfo,
      usa: USInfo,
      dubai: CandaInfoANDNetherland,
    };
    return allCountries[country] ?? CandaInfoANDNetherland;
  }
};
