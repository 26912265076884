import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import "./style.css";

function BannerInput({
  placeholder,
  isMobile,
  value,
  onChange,
  toggleDrawer,
  label,
  mandatory,
  name,
  error,
  onBlur,
  hideLeftText,
  onClick,
  type = "text",
  containerStyle,
  placeholderInput,
  ...otherProps
}) {
  const [show, setShow] = useState("password");

  const handelShowPass = () => {
    if (show === "password") {
      setShow("text");
    } else {
      setShow("password");
    }
  };

  return (
    <div className="input-group-container" style={containerStyle}>
      {label && (
        <label>
          {mandatory && <span className="mandatory-sign">*</span>}
          {label}
        </label>
      )}
      <div className={`input-wapper ${error && "error"}`}>
        {!label && !hideLeftText && placeholder && (
          <span onClick={onClick} className="search-label">
            {placeholder}
          </span>
        )}

        {isMobile ? (
          <div
            onClick={() => toggleDrawer()}
            className="search-field-fake-input"
          >
            {value ? value : placeholder}
          </div>
        ) : (
          <input
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            value={value}
            type={type === "password" ? show : type}
            placeholder={placeholderInput}
            {...otherProps}
          />
        )}
        {type === "password" && (
          <IconButton onClick={handelShowPass} className="password-icon">
            {show === "password" ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        )}
      </div>
      {error && <span className="error-msg">{error}</span>}
    </div>
  );
}

export default BannerInput;
