import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import { ReactComponent as Cabinbaggage } from "assets/images/cabinbaggage.svg";

import Badge from "../badge";

import { ReactComponent as Children } from "assets/images/children.svg";
import { ReactComponent as Infants } from "assets/images/infants.svg";

import "./style.css";
import { duration, timeDiffCalc } from "utils/Utils";
import AddRemark from "components/remark";
import { Typography } from "@mui/material";

const ReservedFlightDetails = ({
  reservation,
  loading,
  price,
  showRemarks,
}) => {
  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const getCargeableSeatNumber = (segmentId, travelerId) => {
    let number;

    reservation?.flightOffers[0]?.travelerPricings?.map((traveler) => {
      if (traveler?.travelerId === travelerId) {
        number = traveler?.fareDetailsBySegment?.find(
          (segment) => segment?.segmentId === segmentId
        )?.additionalServices?.chargeableSeatNumber;
      }
    });

    if (number) return number;
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          variant="scrollable"
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Flight Details" />
          <Tab label="Travelers" />
          <Tab label="Payment Info" />
          {showRemarks && <Tab label="Remarks" />}
          {showRemarks && <Tab label="Contacts" />}
          {showRemarks && <Tab label="More Info" />}
        </Tabs>
      </Box>
      {loading ? (
        <div className="skeleton featured-job">
          <div className="job-title">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </div>

          <div className="job-department">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </div>
          <div className="job-location">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </div>
        </div>
      ) : (
        <>
          <div className="tabs-header">
            <h1 className="medium-font-size">
              {
                reservation?.flightOffers[0]?.itineraries[0]?.segments[0]
                  ?.departure?.iataCode
              }{" "}
              to{" "}
              {reservation?.flightOffers[0]?.itineraries[0]?.length > 1
                ? reservation?.flightOffers[0]?.itineraries[
                    reservation?.flightOffers[0]?.itineraries?.length - 1
                  ]?.segments[0]?.departure?.iataCode
                : reservation?.flightOffers[0]?.itineraries[0]?.segments[0]
                    ?.arrival?.iataCode}
            </h1>
            <p>
              {moment(reservation?.createdAt).format("DD MMM YYYY, hh:m A")}
            </p>
          </div>
          {value === 0 && (
            <Box py={2}>
              {reservation?.recordLocator && (
                <Box p={2} mb={2} sx={{ border: "1px solid #eee" }}>
                  <Box>
                    <p className="medium-font-size bolder">
                      Flight ID :{" "}
                      <span className="gray">{reservation?.recordLocator}</span>
                    </p>
                    <p className="medium-font-size bolder">
                      Sold For :{" "}
                      <span className="gray">
                        {reservation?.currency} {reservation?.soldFor}
                      </span>
                    </p>
                  </Box>
                </Box>
              )}

              {reservation?.flightOffers[0]?.itineraries?.map(
                (itinerary, index) => (
                  <Box
                    key={index}
                    mb={3}
                    p={2}
                    sx={{ border: "1px solid #eee" }}
                  >
                    {itinerary?.segments?.map((flight, indexFlight) => (
                      <Box position="relative">
                        <Box
                          display="flex"
                          mb={2}
                          alignItems="center"
                          key={indexFlight}
                        >
                          <Box>
                            <p className="medium-font-size bolder">
                              {" "}
                              {flight?.departure?.iataCode}
                            </p>
                            <p>
                              {" "}
                              {moment(flight?.departure?.at).format(
                                "DD MMM YYYY, hh:m A"
                              )}
                            </p>
                          </Box>
                          <Box
                            mx={4}
                            textAlign="center"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <span>
                              <svg
                                className="Icon__StyledIcon-sc-1det6wr-0 fJfkpT"
                                viewBox="0 0 24 24"
                                preserveAspectRatio="xMidYMid meet"
                              >
                                <path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path>
                              </svg>
                            </span>
                            {flight?.duration && (
                              <Badge
                                title={duration(flight?.duration)}
                                className="teal"
                              />
                            )}

                            {showRemarks && (
                              <>
                                <Badge
                                  title={`${
                                    reservation?.flightOffers[0]?.travelerPricings[0]?.fareDetailsBySegment?.find(
                                      (fare) => fare.segmentId === flight?.id
                                    )?.includedCheckedBags?.quantity ?? 0
                                  }x `}
                                  Icon={Cabinbaggage}
                                />
                              </>
                            )}
                          </Box>
                          <Box>
                            <p className="medium-font-size bolder">
                              {" "}
                              {flight?.arrival?.iataCode}
                            </p>
                            <p>
                              {moment(flight?.arrival?.at).format(
                                "DD MMM YYYY, hh:m A"
                              )}
                            </p>
                          </Box>
                        </Box>
                        {showRemarks && (
                          <>
                            <p className="medium-font-size bolder mb-2">
                              Flight Details:
                            </p>
                            <Box className="badge-float" display="flex">
                              <Badge
                                title={
                                  flight?.isFlown
                                    ? "Flight status: Flown"
                                    : "Flight status: Not flown"
                                }
                                className={flight?.isFlown ? "green" : "teal"}
                              />
                              <Badge
                                title={`Source: ${reservation?.flightOffers[0]?.source}`}
                                className="blue"
                              />
                            </Box>
                            <Box display="flex" gap={3} flexWrap="wrap">
                              <Box>
                                {reservation?.flightOffers[0]?.travelerPricings[0]?.fareDetailsBySegment?.find(
                                  (sege) => sege?.segmentId == flight?.id
                                )?.cabin && (
                                  <Box display="flex" alignItems="center">
                                    <p className="me-2">Cabin : </p>
                                    <Badge
                                      title={
                                        reservation?.flightOffers[0]?.travelerPricings[0]?.fareDetailsBySegment?.find(
                                          (sege) =>
                                            sege?.segmentId == flight?.id
                                        )?.cabin
                                      }
                                    />
                                  </Box>
                                )}
                                {reservation?.flightOffers[0]?.travelerPricings[0]?.fareDetailsBySegment?.find(
                                  (sege) => sege?.segmentId == flight?.id
                                )?.class && (
                                  <Box display="flex" alignItems="center">
                                    <p className="me-2">Class : </p>
                                    <Badge
                                      title={
                                        reservation?.flightOffers[0]?.travelerPricings[0]?.fareDetailsBySegment?.find(
                                          (sege) =>
                                            sege?.segmentId == flight?.id
                                        )?.class
                                      }
                                      className="teal"
                                    />
                                  </Box>
                                )}
                              </Box>
                              <Box>
                                <p>Aircraft : {flight?.aircraft?.code}</p>
                                <p>Carier code : {flight?.carrierCode}</p>
                                <p>Booking status : {flight?.bookingStatus}</p>
                              </Box>
                              <Box>
                                <p>Number : {flight?.number}</p>
                                <p>Segment Type : {flight?.segmentType}</p>
                                <p>Number Of stops : {flight?.numberOfStops}</p>
                              </Box>
                            </Box>
                            <Box>
                              {reservation?.travelers?.map((traveler, index) =>
                                getCargeableSeatNumber(
                                  flight?.id,
                                  traveler?.id
                                ) ? (
                                  <>
                                    {index === 0 && (
                                      <p className="medium-font-size bolder mb-2">
                                        Seating
                                      </p>
                                    )}
                                    <Box display="flex" flexWrap="wrap">
                                      <Box>
                                        <p className="gray">
                                          {traveler?.name?.firstName}.
                                          {traveler?.name?.lastName?.charAt(0)}—
                                          {getCargeableSeatNumber(
                                            flight?.id,
                                            traveler?.id
                                          )}
                                        </p>
                                      </Box>
                                    </Box>
                                  </>
                                ) : null
                              )}
                            </Box>
                          </>
                        )}

                        {itinerary?.segments[
                          itinerary?.segments?.length - 1
                        ] !== flight &&
                          itinerary?.segments?.length > 1 &&
                          flight?.arrival?.iataCode !==
                            flight?.departure?.iataCode &&
                          flight?.arrival?.iataCode !==
                            flight?.departure?.iataCode && (
                            <div className="sector-layover my-4">
                              <hr />

                              <div className="layover-text ">
                                Layover in {flight?.arrival?.iataCode}{" "}
                                {timeDiffCalc(
                                  new Date(flight?.arrival?.at),
                                  new Date(
                                    itinerary?.segments[1]?.departure?.at
                                  )
                                )}
                              </div>
                            </div>
                          )}
                      </Box>
                    ))}
                  </Box>
                )
              )}
            </Box>
          )}
          {value === 1 &&
            reservation?.travelers?.map((traveler, index) => (
              <Box py={2} key={index}>
                <Box display="flex" flexWrap="wrap">
                  <Box width="40%">
                    <Box display="flex">
                      <Box ml={1} width="24px">
                        {reservation?.flightOffers[0]?.travelerPricings?.find(
                          (trav) => trav.travelerId == traveler.id
                        )?.travelerType === "ADULT" ? (
                          <svg
                            className="small-svg"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9 11.041v-0.825c1.102-0.621 2-2.168 2-3.716 0-2.485 0-4.5-3-4.5s-3 2.015-3 4.5c0 1.548 0.898 3.095 2 3.716v0.825c-3.392 0.277-6 1.944-6 3.959h14c0-2.015-2.608-3.682-6-3.959z"></path>
                          </svg>
                        ) : reservation?.flightOffers[0]?.travelerPricings?.find(
                            (trav) => trav.travelerId == traveler.id
                          )?.travelerType === "CHILD" ? (
                          <Children className="small-svg" />
                        ) : (
                          <Infants className="small-svg" />
                        )}
                      </Box>
                      <p>
                        {traveler?.name?.firstName} {traveler?.name?.lastName}
                      </p>
                    </Box>
                  </Box>
                  <Box width="30%" minWidth={155} display="flex" ml={2}>
                    <p>
                      {traveler?.gender}/{traveler?.dateOfBirth}
                    </p>
                  </Box>
                </Box>
                {traveler?.contact?.emailAddress && (
                  <Box display="flex">
                    <Box mr={1} width="24px" textAlign="right">
                      <svg
                        className="small-svg"
                        viewBox="0 0 24 24"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path d="M11.262 14.127l-8.07-8.072a.42.42 0 01-.119-.235.418.418 0 01.353-.473c.08-.011.16-.017.24-.017h16.667c.08 0 .161.006.24.017a.426.426 0 01.235.119.417.417 0 010 .589l-8.073 8.072a1.044 1.044 0 01-1.473 0zM21.74 6.889a.15.15 0 01.166-.032.15.15 0 01.093.14v10c0 .92-.746 1.667-1.667 1.667H3.667c-.92 0-1.667-.747-1.667-1.667v-10a.152.152 0 01.093-.138.15.15 0 01.167.032l5.167 5.167a.21.21 0 010 .295l-3.202 3.202a.624.624 0 000 .869c.24.249.634.255.883.015l3.202-3.202a.21.21 0 01.295 0l1.776 1.772a2.293 2.293 0 003.24 0l1.775-1.775c.08-.08.212-.08.294.001l3.203 3.203.015.016c.248.239.644.231.884-.017a.625.625 0 00-.015-.883l-3.203-3.203a.208.208 0 010-.295l5.167-5.167z"></path>
                      </svg>
                    </Box>
                    <p>{traveler?.contact?.emailAddress}</p>
                  </Box>
                )}
                {traveler?.contact?.phones[0]?.number && (
                  <Box display="flex" mb={2}>
                    <Box mr={1} width="24px" textAlign="right">
                      <svg
                        className="small-svg"
                        viewBox="0 0 24 24"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path d="M17.965 13.806a2.083 2.083 0 00-2.947 0l-1.321 1.32A39.095 39.095 0 018.872 10.3l1.322-1.322a2.085 2.085 0 000-2.948l-1.77-1.768a2.13 2.13 0 00-2.945 0l-.97.97a2.926 2.926 0 00-.367 3.67 39.133 39.133 0 0010.95 10.96 2.956 2.956 0 003.667-.37l.97-.97a2.085 2.085 0 000-2.948l-1.764-1.768z"></path>
                      </svg>
                    </Box>
                    <p> {traveler?.contact?.phones[0]?.number}</p>
                  </Box>
                )}
                {showRemarks && (
                  <>
                    <hr />
                    <Typography mr={1} fontWeight="bold">
                      Price details:
                    </Typography>
                    <Box display="flex">
                      <p className="bold me-2">Neth Price: </p>

                      <p>
                        {
                          reservation?.flightOffers[0]?.travelerPricings?.find(
                            (trav) => trav.travelerId == traveler.id
                          )?.price?.currency
                        }{" "}
                        {
                          reservation?.flightOffers[0]?.travelerPricings?.find(
                            (trav) => trav.travelerId == traveler.id
                          )?.price?.base
                        }
                      </p>
                    </Box>
                    <Box display="flex">
                      <p className="bold me-2">Total Price: </p>

                      <p>
                        {
                          reservation?.flightOffers[0]?.travelerPricings?.find(
                            (trav) => trav.travelerId == traveler.id
                          )?.price?.currency
                        }{" "}
                        {
                          reservation?.flightOffers[0]?.travelerPricings?.find(
                            (trav) => trav.travelerId == traveler.id
                          )?.price?.total
                        }
                      </p>
                    </Box>
                  </>
                )}
              </Box>
            ))}
          {value === 2 && (
            <Box py={2}>
              <Box p={2} mb={2} sx={{ border: "1px solid #eee" }}>
                <Box>
                  {price?.base && (
                    <p className="medium-font-size bolder">
                      Neth Price :{" "}
                      <span className="gray">
                        {reservation?.currency ?? price?.currency} {price?.base}
                      </span>
                    </p>
                  )}

                  <p className="medium-font-size bolder">
                    Grand Total :{" "}
                    <span className="gray">
                      {reservation?.currency ?? price?.currency}{" "}
                      {price?.grandTotal}
                    </span>
                  </p>
                </Box>
              </Box>
            </Box>
          )}
          {value === 3 && showRemarks && (
            <Box py={2}>
              {reservation?.flightOffers[0]?.source === "GDS" && (
                <>
                  <p className="medium-font-size mb-16">Remarks</p>

                  <AddRemark
                    flightOrderId={reservation?.id}
                    officeId={reservation?.queuingOfficeId}
                  />
                </>
              )}

              <p className="medium-font-size bolder mb-1 secondary-color">
                General Remarks
              </p>
              {reservation?.remarks?.general?.map((remark, index) => (
                <Box key={index} p={2} mb={2} sx={{ border: "1px solid #eee" }}>
                  <Box>
                    <p className="medium-font-size bolder">
                      Text: <span className="gray">{remark?.text}</span>
                    </p>
                    <p className="medium-font-size bolder">
                      SubType: <span className="gray">{remark?.subType}</span>
                    </p>
                  </Box>
                </Box>
              ))}

              <p className="medium-font-size bolder mb-1 secondary-color">
                Airlines Remarks
              </p>
              {reservation?.remarks?.airline?.map((remark, index) => (
                <Box key={index} p={2} mb={2} sx={{ border: "1px solid #eee" }}>
                  <Box>
                    <p className="medium-font-size bolder">
                      Text: <span className="gray">{remark?.text}</span>
                    </p>
                    <p className="medium-font-size bolder">
                      SubType: <span className="gray">{remark?.subType}</span>
                    </p>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          {value === 4 && showRemarks && (
            <Box py={2}>
              <p className="medium-font-size bolder mb-1 secondary-color">
                Contacts
              </p>
              {reservation?.contacts?.map((contact, index) => (
                <Box key={index} p={2} mb={2} sx={{ border: "1px solid #eee" }}>
                  <Box>
                    <p className="medium-font-size bolder">
                      City Name:
                      <span className="gray  ms-2 ms-2">
                        {contact?.address?.cityName}
                      </span>
                    </p>
                    <p className="medium-font-size bolder">
                      Country Code:
                      <span className="gray  ms-2">
                        {contact?.address?.countryCode}
                      </span>
                    </p>
                    <p className="medium-font-size bolder">
                      Lines:
                      <span className="gray  ms-2">
                        {contact?.address?.lines?.map((line) => line)}
                      </span>
                    </p>
                    <p className="medium-font-size bolder">
                      Postal Code:
                      <span className="gray  ms-2">
                        {contact?.address?.postalCode ?? "-"}
                      </span>
                    </p>
                    <p className="medium-font-size bolder">
                      Company Name:
                      <span className="gray  ms-2">
                        {contact?.addresseeName?.firstName}
                      </span>
                    </p>
                    <p className="medium-font-size bolder">
                      Name:
                      <span className="gray  ms-2">{contact?.companyName}</span>
                    </p>
                    <p className="medium-font-size bolder">
                      Email:
                      <span className="gray  ms-2">
                        {contact?.emailAddress ?? "-"}
                      </span>
                    </p>
                    <p className="medium-font-size bolder">
                      Phone:
                      {!contact?.phones
                        ? " - "
                        : contact?.phones?.map((phone) => (
                            <span className="gray  ms-2">
                              {phone?.deviceType} :({phone?.countryCallingCode}{" "}
                              {phone?.number} )
                            </span>
                          ))}
                    </p>
                    <p className="medium-font-size bolder">
                      Purpose:
                      <span className="gray  ms-2">{contact?.purpose}</span>
                    </p>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          {value === 5 && showRemarks && (
            <Box py={2}>
              <p className="medium-font-size bolder mb-1 secondary-color">
                More Info
              </p>
              <Box p={2} mb={2} sx={{ border: "1px solid #eee" }}>
                <Box>
                  <p className="medium-font-size bolder">
                    Queuing Office Id:
                    <span className="gray  ms-2 ms-2">
                      {reservation?.queuingOfficeId}
                    </span>
                  </p>
                  <p className="medium-font-size bolder">
                    Id:
                    <span className="gray  ms-2 ms-2">{reservation?.id}</span>
                  </p>
                  <p className="medium-font-size bolder">
                    Source:
                    <span className="gray  ms-2 ms-2">
                      {reservation?.flightOffers[0]?.source}
                    </span>
                  </p>
                  <p className="medium-font-size bolder">
                    Last ticketing date:
                    <span className="gray  ms-2 ms-2">
                      {reservation?.flightOffers[0]?.lastTicketingDate}
                    </span>
                  </p>

                  <p className="medium-font-size bolder">
                    Validating Airline Codes:
                    <span className="gray  ms-2 ms-2">
                      {reservation?.flightOffers[0]?.validatingAirlineCodes?.map(
                        (i) => i
                      )}
                    </span>
                  </p>
                </Box>
              </Box>
              <hr />{" "}
              <p className="medium-font-size bolder secondary-color mb-1 mt-3">
                Ticketing Agreement:
              </p>
              <Box p={2} mb={2} sx={{ border: "1px solid #eee" }}>
                <Box>
                  <p className="medium-font-size bolder">
                    Date Time:
                    <span className="gray  ms-2 ms-2">
                      {reservation?.ticketingAgreement?.dateTime}
                    </span>
                  </p>
                  <p className="medium-font-size bolder">
                    Option:
                    <span className="gray  ms-2 ms-2">
                      {reservation?.ticketingAgreement?.option}
                    </span>
                  </p>
                </Box>
              </Box>
              <hr />
              <p className="medium-font-size bolder mb-1 secondary-color  mt-3">
                Associated Records
              </p>
              {reservation?.associatedRecords?.map(
                (associatedRecord, index) => (
                  <Box
                    key={index}
                    p={2}
                    mb={2}
                    sx={{ border: "1px solid #eee" }}
                  >
                    <Box>
                      <p className="medium-font-size bolder">
                        Creation Date:
                        <span className="gray  ms-2 ms-2">
                          {associatedRecord?.creationDate?.replace("T", ", ") ??
                            "-"}
                        </span>
                      </p>
                      <p className="medium-font-size bolder">
                        Reference:
                        <span className="gray  ms-2 ms-2">
                          {associatedRecord?.reference}
                        </span>
                      </p>
                      <p className="medium-font-size bolder">
                        Flight Offer Id:
                        <span className="gray  ms-2">
                          {associatedRecord?.flightOfferId}
                        </span>
                      </p>
                      <p className="medium-font-size bolder">
                        Origin System Code:
                        <span className="gray  ms-2">
                          {associatedRecord?.originSystemCode}
                        </span>
                      </p>
                    </Box>
                  </Box>
                )
              )}
              <hr />
              {reservation?.tickets && (
                <>
                  <p className="medium-font-size bolder mb-1 secondary-color  mt-3">
                    Tickets
                  </p>
                  {reservation?.tickets?.map((ticket, index) => (
                    <Box
                      key={index}
                      p={2}
                      mb={2}
                      sx={{ border: "1px solid #eee" }}
                    >
                      <Box>
                        <p className="medium-font-size bolder">
                          Document Type:
                          <span className="gray  ms-2 ms-2">
                            {ticket?.documentType}
                          </span>
                        </p>
                        <p className="medium-font-size bolder">
                          Document Number:
                          <span className="gray  ms-2 ms-2">
                            {ticket?.documentNumber}
                          </span>
                        </p>
                        <p className="medium-font-size bolder">
                          Document Status:
                          <span className="gray  ms-2">
                            {ticket?.documentStatus}
                          </span>
                        </p>
                        <p className="medium-font-size bolder">
                          Traveler Id:
                          <span className="gray  ms-2">
                            {ticket?.travelerId}
                          </span>
                        </p>

                        <p className="medium-font-size bolder">
                          SegmentIds:
                          <span className="gray  ms-2">
                            {ticket?.segmentIds?.map((i) => i)}
                          </span>
                        </p>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
              {reservation?.commissions && (
                <>
                  <p className="medium-font-size bolder mb-1 secondary-color  mt-3">
                    Commissions
                  </p>
                  {reservation?.commissions?.map((commission, index) => (
                    <Box
                      key={index}
                      p={2}
                      mb={2}
                      sx={{ border: "1px solid #eee" }}
                    >
                      <Box>
                        <p className="medium-font-size bolder">
                          Values
                          <span className="gray  ms-2 ms-2">
                            {commission?.values?.map((i) => (
                              <div>
                                <p>Commission Type :{i.commissionType}</p>
                                <p>Percentage :{i.percentage}</p>
                              </div>
                            ))}
                          </span>
                        </p>
                        <p className="medium-font-size bolder">
                          TravelerIds:
                          <span className="gray  ms-2 ms-2">
                            {commission?.travelerIds?.map((i) => i)}
                          </span>
                        </p>

                        <p className="medium-font-size bolder">
                          SegmentIds:
                          <span className="gray  ms-2">
                            {commission?.segmentIds?.map((i) => i)}
                          </span>
                        </p>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ReservedFlightDetails;
