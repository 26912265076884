import React, { useState } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";

import Button from "components/button";

import DragAndDrop from "../../jobDetails/components/drag&drop-file";

const UploadPassport = ({ applyFVS, loading, error }) => {
  const [img, setImg] = useState("");
  const [traveler, setTraveler] = useState({});

  const handlePassportResponse = (data) => {
    setTraveler(data);
  };

  return (
    <Box minHeight="300px" className="card-card" width={{ md: "700px" }}>
      <Box mb={2}>
        <p className="mb-2">Upload Your passport! </p>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <DragAndDrop
          file={img}
          label="Please upload your passport."
          getFile={setImg}
        />
      </Box>

      <hr />
      <Box mt={5}>
        <Button
          className="w-100 bigBtn primary-color"
          disabled={!img}
          loading={loading}
          onClick={() => applyFVS(img)}
        >
          Apply Now
        </Button>
      </Box>
    </Box>
  );
};

export default UploadPassport;
