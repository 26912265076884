import React, { useState, useEffect, useContext } from "react";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../bannerInput";
import Button from "../button";
import ForgotPassword from "../forgot-Password";
import VerifyCode from "../verifyCode";
import "./style.css";
import Dialog from "components/dialog";

import { Formik } from "formik";
import * as Yup from "yup";

import { login } from "api/Auth";
import {
  storeUserInLocalStorage,
  changeCurrencyInLocalStorage,
  getUserFromLocalStorage,
} from "hooks/LocalStorage";
import { getCurrentCountry } from "utils/Utils";
import { CurrencyContext } from "pages/useContext";

const Login = ({ handelOpenSignUp }) => {
  const [openLogin, setOpenLogin] = useState(false);
  const navigate = useNavigate();

  const { hash } = useLocation();

  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);
  const [currecny, setCurrency] = useContext(CurrencyContext);

  const emailValidator = Yup.string().email().required().label("Email");

  const validationSchema = Yup.object().shape({
    email: emailValidator,
    password: Yup.string().min(3).required().label("Password"),
  });

  const handleCloseLoginModal = () => {
    window.location.hash = "";

    setForgotPass(false);
  };

  const handelSubmit = async (values) => {
    setLoading(true);
    setErr("");
    const res = await login({ body: values });

    if (!res.ok) {
      setErr(res?.data?.errorDetails);
      if (res?.status === 403) {
        setShowOtp(true);
        setEmail(values.email);
      }
    } else {
      handleCloseLoginModal();

      const token = {
        authenticationToken: res?.data?.authenticationToken,
        refreshToken: res?.data?.refreshToken,
      };

      storeUserInLocalStorage(token);

      const getTokenData = getUserFromLocalStorage();
      const checkCountry = getCurrentCountry(getTokenData?.registeredFrom);
      window.dispatchEvent(new Event("storage"));

      if (checkCountry) {
        setCurrency(checkCountry);
        changeCurrencyInLocalStorage(checkCountry);
      }
      // navigate(0);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (hash === "#auth-required") {
      setOpenLogin(true);
    } else {
      setOpenLogin(false);
    }
  }, [hash]);

  return (
    <Dialog open={openLogin} onClose={handleCloseLoginModal}>
      {forgotPass ? (
        <ForgotPassword setForgotPass={setForgotPass} />
      ) : !showOtp ? (
        <div className="login-modal">
          <h1 className="main-title">Log in to Satyam Holidays</h1>
          {err && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {err}
            </Alert>
          )}
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handelSubmit}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              setFieldTouched,
              touched,
              values,
              setFieldValue,
            }) => (
              <>
                <Input
                  onChange={(e) => {
                    const value = e.target.value || "";
                    setFieldValue("email", value.toLowerCase());
                  }}
                  error={touched.email && errors.email}
                  onBlur={() => setFieldTouched("email")}
                  name={"email"}
                  mandatory={true}
                  type="email"
                  label="Enter your Email"
                />
                <Input
                  onChange={handleChange(`password`)}
                  error={touched.password && errors.password}
                  onBlur={() => setFieldTouched("password")}
                  name={"password"}
                  mandatory={true}
                  type="password"
                  label="Enter your password"
                />
                <u
                  onClick={() => {
                    setForgotPass(true);
                    setEmail("");
                  }}
                >
                  Forget Password?
                </u>

                <Button
                  type="submit"
                  onClick={handleSubmit}
                  className="bigBtn w-100 primary-color"
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#fff" }} size={22} />
                  ) : (
                    "Login"
                  )}
                </Button>
              </>
            )}
          </Formik>
          <p className="gray medium-font-size text-center">
            Do not have an account? <u onClick={handelOpenSignUp}>Sign up</u>
          </p>
        </div>
      ) : (
        <VerifyCode email={email} handleClose={handleCloseLoginModal} />
      )}
    </Dialog>
  );
};

export default Login;
