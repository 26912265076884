import client from "./client";

const endpoint = "flight-target";

const postFlightTarget = ({ body }) => {
  return client.post(endpoint, body);
};
const triggerFlightTarget = ({ body }) => {
  return client.post(endpoint + "/trigger", body);
};
export { postFlightTarget, triggerFlightTarget };
