import { useState } from "react";

const useFilter = (
  refundableFare,
  checkedbaggage,
  satyamSpecial,
  stops,
  cabinType,
  flight,
  officeId
) => {
  const [allFilters, setAllFilters] = useState([
    {
      label: "Office ID",
      key: "officeId",
      permission: "Administrator",
      defaultOption: "",

      options: [
        {
          id: 1,
          value: "PBM1S23CL",
          key: "PBM1S23CL",
          checked: officeId === "PBM1S23CL",
        },
        {
          id: 2,
          value: "AMSN222SH",
          key: "AMSN222SH",
          checked: officeId === "AMSN222SH",
        },
      ],
    },
    {
      label: "Refundable Fare",
      key: "refundableFare",
      defaultOption: false,
      options: [
        {
          value: "Refund Fare",
          checked: refundableFare ?? false,
          key: refundableFare ?? false,
        },
      ],
    },
    {
      label: "Baggage",
      multiple: true,
      options: [
        {
          value: "Checked baggage",
          checked: checkedbaggage ?? false,
          key: checkedbaggage ?? false,
          defaultOption: false,
        },
        {
          value: "Satyam Special",
          checked: satyamSpecial === "NEGOTIATED",
          key: "PUBLISHED",
          defaultOption: "PUBLISHED",
        },
      ],
    },

    {
      label: "Cabin Type",
      key: "cabinType",
      defaultOption: "",
      options: [
        { id: 1, value: "Economy", key: "ECONOMY", checked: false },
        {
          id: 2,
          value: "Premium Economy",
          key: "PREMIUM_ECONOMY",
          checked: cabinType === "PREMIUM_ECONOMY",
        },
        {
          id: 3,
          value: "Business",
          key: "BUSINESS",
          checked: cabinType === "BUSINESS",
        },
        {
          id: 4,
          value: "First Class",
          key: "FIRST",
          checked: cabinType === "FIRST",
        },
      ],
    },
    {
      label: "Stops",
      key: "stops",
      defaultOption: 1,

      options: [
        { id: 1, value: "Non Stop(direct)", key: 0, checked: stops === 0 },
        { id: 2, value: "Up to 1 stop", key: 1, checked: false },
        { id: 3, value: "Up to 2 stop", key: 2, checked: stops === 2 },
      ],
    },
  ]);

  return { allFilters, setAllFilters };
};

export default useFilter;
