import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

import { LabelAndValue } from "components/ui";
import Button from "components/button";
import AppDialog from "components/dialog";
import useApi from "hooks/useApi";

import { enhancedPricing, multiElements, hotelSell } from "api/Hotels";

import TravelersInfo from "./steps/traveler-info";
import PaymentBlock from "./steps/payment";

const steps = ["Travellers Information", " Payment information"];

const Payment = () => {
  const fetchEnhancedPricing = useApi(enhancedPricing);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [multiElementsData, setMultiElementsData] = useState();
  const [hotelSellData, setHotelSellData] = useState();
  const [isModalOpen, setModalOpen] = useState(false);

  const { state } = useLocation();

  const [error, setError] = useState(false);

  useEffect(() => {
    handleFetchEnhancedPricing();
  }, []);

  const handleFetchEnhancedPricing = async () => {
    const room = state?.hotelDetailsAndRoom;
    const body = {
      currencyCode: room?.pricing?.currency,
      sessionId: room?.session?.sessionId,
      sequenceNumber: room?.session?.sequenceNumber,
      securityToken: room?.session?.securityToken,
      infoSource: state?.hotelDetailsAndRoom?.infoSource,
      hotelCode: room?.basicProperty?.hotelCode,
      startDate: room?.startDate,
      endDate: room?.endDate,
      ratePlanCode: room?.room?.roomRates?.ratePlanCode,
      bookingCode: room?.room?.roomRates?.bookingCode,
      roomTypeCode: room?.room?.roomRates?.roomTypeCode,
      roomStayCandidate: room?.roomStayCandidate,
      guestCount: room?.guestCount,
      children: room?.children,
    };
    fetchEnhancedPricing.request({
      body,
    });
  };

  const handleMultiElements = async (values) => {
    setError(false);

    setLoading(true);
    const session = fetchEnhancedPricing?.data?.pricing?.session;

    const body = {
      sessionId: session?.sessionId,
      sequenceNumber: session?.sequenceNumber,
      securityToken: session?.securityToken,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    };

    const response = await multiElements({
      body,
    });
    if (!response.ok) {
      setLoading(false);
    } else {
      setMultiElementsData(response.data.multiElementsResponse);
      setActiveStep(1);
      setLoading(false);
    }
  };

  const handleHotelSell = async (values) => {
    setError(false);

    setLoading(true);
    const room = state?.hotelDetailsAndRoom;

    const body = {
      sessionId: multiElementsData?.session?.sessionId,
      sequenceNumber: multiElementsData?.session?.sequenceNumber,
      securityToken: multiElementsData?.session?.securityToken,
      passengerTattoo: multiElementsData?.travellerInfo?.passengerTattoo,
      guestCount: room?.guestCount,
      roomStayData: room?.roomStayCandidate,
      title: "MR",
      firstName: multiElementsData?.travellerInfo?.passengerFirstName,
      lastName: multiElementsData?.travellerInfo?.passengerLastName,
      cardNumber: values.cardNumber,
      securityId: values.securityId,
      expiryDate: values.expiryDate.replace("/", ""),
      hotelChainCode: room?.basicProperty?.hotelChainCode,
      hotelCityCode: room?.basicProperty?.hotelCityCode,
      hotelCode: room?.basicProperty?.hotelCode,
      bookingCode: room?.room?.roomRates?.bookingCode,
      paymentType: "1",
    };

    const response = await hotelSell({
      body,
    });
    if (!response.ok) {
      setModalOpen(true);
      setTimeout(() => navigate("/"), 1200);
    } else {
      if (!response.data?.hotelSellResponse) {
        setModalOpen(true);
        setTimeout(() => navigate("/"), 1200);
      } else {
        navigate("/hotel-confirmation", {
          state: {
            hotelDetailsAndRoom: state?.hotelDetailsAndRoom,
            hotelSellResponse: response.data?.hotelSellResponse,
          },
        });
      }
    }
    setLoading(false);
  };

  const TextSkeleton = () => (
    <Skeleton
      variant="text"
      width={60}
      height={20}
      sx={{
        bgcolor: "#F1F3F6",
        borderRadius: "4px",
      }}
      animation="wave"
    />
  );

  const onClose = () => {
    setModalOpen(false);
  };
  const pricingDetails = fetchEnhancedPricing?.data?.pricing?.roomRates;

  return (
    <div className="reservation">
      <Box className="reservation-content">
        <Box sx={{ width: "100%" }} className="reservation-column-stepper">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step
                onClick={() => {
                  if (index) {
                    setActiveStep(index);
                  }
                }}
                key={label}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {activeStep === 0 ? (
          <TravelersInfo
            loading={loading}
            handleMultiElements={handleMultiElements}
          />
        ) : (
          <PaymentBlock loading={loading} handleHotelSell={handleHotelSell} />
        )}
        <aside>
          <Box className="card-card" mt={10}>
            <p className="medium-font-size">
              Hotel :{state?.hotelDetailsAndRoom?.basicProperty?.hotelName}
            </p>
            <Box mt={2}>
              <p>
                {" "}
                <strong>Room:</strong> Room, 1 King Bed
              </p>
            </Box>
            <Box mt={2}>
              <p>
                {" "}
                <strong>Check-in:</strong>{" "}
                {state?.hotelDetailsAndRoom?.startDate}
              </p>
              <p>
                {" "}
                <strong>Check-Out:</strong>{" "}
                {state?.hotelDetailsAndRoom?.endDate}
              </p>
            </Box>
          </Box>
          <div className="card-card">
            <p className="medium-font-size pb-2">Price details</p>
            <hr />

            <LabelAndValue
              label="Price"
              value={
                fetchEnhancedPricing?.loading ? (
                  <TextSkeleton />
                ) : (
                  <>
                    {pricingDetails?.currencyCode}
                    {pricingDetails?.amountAfterTax
                      ? " " + pricingDetails?.amountAfterTax
                      : " " + pricingDetails?.amountBeforeTax}
                  </>
                )
              }
              mb={2}
            />
            {pricingDetails?.taxes !== "NaN" && (
              <LabelAndValue
                label="Taxes and fees"
                value={
                  fetchEnhancedPricing?.loading ? (
                    <TextSkeleton />
                  ) : (
                    <>
                      {pricingDetails?.currencyCode}
                      {" " + pricingDetails?.taxes}
                    </>
                  )
                }
              />
            )}
            <hr />

            <LabelAndValue
              mt={2}
              sx={{ "& p": { fontSize: "1rem", fontWeight: "bold" } }}
              label="Total"
              value={
                fetchEnhancedPricing?.loading ? (
                  <TextSkeleton />
                ) : (
                  <>
                    {pricingDetails?.currencyCode}
                    {" " + pricingDetails?.amountAfterTax}
                  </>
                )
              }
            />
          </div>
        </aside>
      </Box>
      <AppDialog open={isModalOpen} onClose={onClose}>
        <Typography variant="h6" color="error">
          Failed Reservation
        </Typography>
        <Typography mb={3}>
          Failed to make reservation please contact Satyam Tours B.V
        </Typography>
        <Button className="w-100" onClick={onClose}>
          Close
        </Button>
      </AppDialog>
    </div>
  );
};

export default Payment;
