import { useState } from "react";

const useApi = (apiFunc, source, setSearchWithNDC) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const request = async (...args) => {
    setLoading(true);
    const response = await apiFunc(...args);
    setLoading(false);

    if (!response.ok) return setError(true);

    setError(false);
    if (source === "NDC" && response?.data?.response?.data?.length === 0)
      return setSearchWithNDC();
    else if (source === "NDC" && response?.data?.response?.data?.length > 0) {
      setSearchWithNDC("NDC");
      setData(response.data);
    } else {
      setData(response.data);
      if (setSearchWithNDC) {
        setSearchWithNDC("GDS");
      }
    }
  };

  return { data, error, loading, request, setData };
};
export default useApi;
