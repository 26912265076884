import client from "./client";

const endpoint = "authentication/";

const register = ({ body }) =>
  client.post(`${endpoint}register`, body, {
    headers: { "device-type": "satyam-tours-web" },
  });

const login = ({ body }) =>
  client.post(`${endpoint}login`, body, {
    headers: { "device-type": "satyam-tours-web" },
  });

const refreshToken = ({ body }) =>
  client.post(`${endpoint}refresh-token`, body, {
    headers: { "device-type": "satyam-tours-web" },
  });

const verifyCode = ({ email, verificationToken }) => {
  return client.post(`${endpoint}verify/${email}/${verificationToken}`);
};

const triggerEmailForgotPassword = ({ email }) => {
  return client.post(`${endpoint}trigger-email-forgot-password/${email}`);
};

const changePassword = ({ body, token }) => {
  return client.post(`${endpoint}change-password`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export {
  register,
  login,
  verifyCode,
  changePassword,
  triggerEmailForgotPassword,
  refreshToken,
};
