import client from "./client";

const endpoint = "career/";

const getJobs = ({ body }) =>
  client.post(`${endpoint}filter/?language=en`, body);

const getJobByID = ({ jobId }) => client.get(`${endpoint}/${jobId}`);

const applyForJob = ({ body, resume, motivationLetterFile }) => {
  let data = new FormData();
  data.append(`data`, JSON.stringify(body));
  data.append(`documents`, resume);
  data.append(`documents`, motivationLetterFile);

  return client.post(`${endpoint}apply`, data);
};

export { getJobs, getJobByID, applyForJob };
