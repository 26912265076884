import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OutlinedInput from "@mui/material/OutlinedInput";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@mui/material/Tooltip";

const CopyField = ({ value, label, ...rest }) => {
  const [copy, setCopy] = useState(false);

  return (
    <Box display="grid" className="input-group-container" {...rest}>
      {label && <label>{label}</label>}
      <OutlinedInput
        value={value}
        readOnly
        className="input-wapper"
        endAdornment={
          <InputAdornment position="end" disablePointerEvents={!value}>
            <CopyToClipboard text={value} onCopy={() => setCopy(true)}>
              <Tooltip title={copy ? "Copied!" : "Copy To clipboard"}>
                <IconButton aria-label="toggle password visibility" edge="end">
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </InputAdornment>
        }
      />
    </Box>
  );
};

export default CopyField;
