import React, { useState } from "react";
import Chart from "chart.js/auto";
import { Box, Alert } from "@mui/material";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import BannerInput from "components/bannerInput";
import Button from "components/button";

import { reservationReport } from "api/Admin";

function Reports() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState();
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("2023");
  const [data2, setData2] = useState([]);

  const data = {
    labels: data2?.map((label) => dayjs(label?.createdAt).format("MMM DD")),
    datasets: [
      {
        // label: "Reservation",
        fill: false,
        lineTension: 0.5,
        backgroundColor: "#f15e22",
        borderColor: "#f15e22",
        borderWidth: 2,
        data: data2?.map((label) => label?.totalIssuedReservations),
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const createHandleMenuClick = (menuItem) => {
    setMonth(menuItem);
  };

  const createHandleMenuClickYear = (menuItem) => {
    setYear(menuItem);
  };

  const monthes = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = ["2023", "2024", "2025"];

  const handleReservationReport = async () => {
    if (!month || !year) {
      setError("Please provide a month and year before submitting!");
    } else {
      setLoading(true);
      setError("");

      const body = {
        month,
        year,
      };
      const response = await reservationReport({
        body,
      });

      if (!response.ok) {
        setLoading(false);
        setError("There is something wrong!");
      } else {
        setSuccess("The reservation was successfully issued");
        setData2(response?.data?.report);
        setLoading(false);
      }
    }
  };

  return (
    <Box maxWidth="800px">
      <p className="bold main-title">Reports</p>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <Box display="flex" alignItems="start" columnGap={1} mb={1}>
        <Menu
          className="main-dropdown"
          menuButton={
            <MenuButton
              style={{ background: "red" }}
              className={`dropdown-btn d-block bg-primary-light`}
            >
              {monthes[month - 1] ?? "Select Month"}
            </MenuButton>
          }
          transition
        >
          {monthes.map((i, idx) => (
            <MenuItem key={i} onClick={() => createHandleMenuClick(idx + 1)}>
              {i}
            </MenuItem>
          ))}
        </Menu>
        <Menu
          className="main-dropdown"
          menuButton={
            <MenuButton
              style={{ background: "red" }}
              className={`dropdown-btn d-block bg-primary-light`}
            >
              {year || "Select Year"}
            </MenuButton>
          }
          transition
        >
          {years.map((i) => (
            <MenuItem key={i} onClick={() => createHandleMenuClickYear(i)}>
              {i}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Box mb={4}>
        <Button
          className="primary-color"
          loading={loading}
          onClick={handleReservationReport}
        >
          Plot Graph{" "}
        </Button>
      </Box>
      {data2?.length > 0 && <Line data={data} options={options} />}
    </Box>
  );
}

export default Reports;
