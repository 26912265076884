import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { getUserFromLocalStorage } from "hooks/LocalStorage";

import { allAppCurrencies, defaultCurrency } from "./Currency&Language";

const generateAmaRefId = (_) => {
  const uuid = uuidv4();
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth();
  const hours = today.getHours();
  const minutes = today.getMinutes();
  const key = day.toString() + month + hours + minutes;
  let splittedUuid = uuid.split("-");
  splittedUuid[splittedUuid.length - 1] = key;
  const amaRefId = splittedUuid.join("-");
  return amaRefId;
};

const getHeaders = (amaRefId) => {
  return {
    headers: {
      "ama-client-ref": amaRefId,
    },
  };
};

function capitalizeFirstLetter(string) {
  const newStr = string
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.substring(1).toLowerCase())
    .join(" ");
  return newStr;
}

function getCurrentCountry(country) {
  const getCurrency = allAppCurrencies?.filter((currency) =>
    currency.country.includes(country)
  )[0];

  return getCurrency || defaultCurrency;
}

const duration = (dur, itineraryDur) => {
  const newStr = dur
    ? dur.indexOf("M") > -1
      ? dur.replace("PT", "").replace("H", "h, ").replace("M", "m")
      : dur.replace("PT", "").replace("H", "h")
    : itineraryDur.indexOf("M") > -1
    ? itineraryDur.replace("PT", "").replace("H", "h, ").replace("M", "m")
    : itineraryDur.replace("PT", "").replace("H", "h");

  return newStr;
};

const timeDiffCalc = (dateFuture, dateNow) => {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";

  difference += hours === 0 ? "" : `${hours}h, `;

  difference += minutes === 0 || hours === 1 ? `${minutes}m` : `${minutes}m`;

  return difference;
};

const checkISAdminOrEmployee = () => {
  const IsAdminOrEmployee =
    getUserFromLocalStorage()?.type === "ADMIN" ||
    getUserFromLocalStorage()?.type === "EMPLOYEE";
  return IsAdminOrEmployee;
};

const daysDifference = (departureDate, returnDate) => {
  const date = moment(returnDate).diff(moment(departureDate), "days") + 1;
  if (!date) return null;
  return date > 0
    ? date + " Days "
    : moment(departureDate).diff(moment(returnDate), "days") + 1 + " Days";
};

const monthsLong = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};

export {
  generateAmaRefId,
  getHeaders,
  capitalizeFirstLetter,
  getCurrentCountry,
  timeDiffCalc,
  duration,
  checkISAdminOrEmployee,
  daysDifference,
  monthsLong,
};
