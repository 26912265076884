import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/react";

/**
 * Sentry
 */
const sentryOptions = {
  dsn: "https://8f43e922b4993ff6820e13c78bd10fef@o4506191964864512.ingest.sentry.io/4506191966896128",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

if (process.env.REACT_APP_ENV === "PROD") {
  Sentry.init(sentryOptions);
}

ReactDOM.render(<App />, document.getElementById("root"));
