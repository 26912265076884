import React, { useState } from "react";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Skeleton from "@mui/material/Skeleton";

import { getUserFromLocalStorage } from "hooks/LocalStorage";

import Button from "components/button";

const ActivitiesTab = ({ getAllReservedFlights, handelClickOpen }) => {
  const [value, setValue] = useState(0);
  const [count, setCount] = useState(5);

  const handleChangeTab = (newValue) => {
    setValue(newValue);
  };

  const LoadMore = () => {
    if (getAllReservedFlights?.data?.allReservations?.length >= count) {
      setCount(count + 5);
    } else {
      setCount(getAllReservedFlights?.data?.allReservations?.length);
    }
  };

  return (
    <div className="login-modal">
      <p className="bold main-title"> My Activities</p>

      <Box mb={3}>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label={"My Flights"} />
          {getUserFromLocalStorage()?.type === "MEMBER" && (
            <>
              <Tab label={"Mangage Trips"} />
              <Tab label={"My Applied Jobs"} />
              <Tab label={"Visa Processes"} />
            </>
          )}
        </Tabs>
      </Box>

      {value === 0 ? (
        <div>
          <hr />
          <Box my={2}>
            <p className="sub-main-title mb-16 gray">Reservations</p>
            {getAllReservedFlights?.data?.allReservations?.length > 0 && (
              <div className="job-list-heading">
                <p className="job-department"> Flight ID</p>
                <p className="job-location">Sold For</p>
              </div>
            )}
            {getAllReservedFlights?.loading ? (
              <div className="skeleton featured-job">
                <div className="job-title">
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </div>

                <div className="job-department">
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </div>
                <div className="job-location">
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </div>
              </div>
            ) : (
              <>
                {getAllReservedFlights?.data?.allReservations
                  ?.slice(0, count)
                  ?.map((flight, index) => (
                    <div
                      onClick={() => handelClickOpen(flight?.recordLocator)}
                      key={index}
                      className="featured-job"
                    >
                      <p className="medium-font-size job-department">
                        {flight?.recordLocator}
                      </p>
                      <p className="medium-font-size job-location">
                        {flight?.currency} {flight?.soldFor}
                      </p>
                    </div>
                  ))}

                {getAllReservedFlights?.data?.allReservations?.length >
                  count && (
                  <Box sx={{ textAlign: "center" }} my={2}>
                    <Button
                      onClick={LoadMore}
                      className="main-booking-btn bigBtn m-auto primary-color"
                    >
                      Load More
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
        </div>
      ) : (
        <div className="empty-data-msg">
          <h1 className="medium-font-size title-header">No Results Found!</h1>
        </div>
      )}
    </div>
  );
};

export default ActivitiesTab;
