import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import { Avatar, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {
  getUserFromLocalStorage,
  removeUserFromLocalStorage,
} from "hooks/LocalStorage";
import { getFlag } from "utils/Currency&Language";

import Package from "../../../package.json";

import { CurrencyContext } from "pages/useContext";

export default function HeaderDrawer({
  open,
  onCloseDrawer,
  handleOpenModalLangs,
}) {
  const navigate = useNavigate();
  const [currency, setCurrency] = useContext(CurrencyContext);

  const handleNavigate = (href) => {
    onCloseDrawer();
    navigate(href);
  };

  const logOut = () => {
    removeUserFromLocalStorage();
    handleNavigate("/");
  };

  const DrawerList = (
    <Box
      sx={{
        width: { xs: "100%", md: 350 },
        px: 2,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
      role="presentation"
    >
      <IconButton
        onClick={onCloseDrawer}
        size="small"
        sx={{ position: "absolute", right: "14px", top: "10px" }}
      >
        <CloseIcon sx={{ width: "18px" }} />
      </IconButton>
      <Box p={3} py={8} display="flex" gap={2}>
        <Avatar
          sx={{
            background: "var(--primary-color)",
          }}
        >
          {getUserFromLocalStorage()?.firstName?.charAt()}
          {getUserFromLocalStorage()?.lastName?.charAt()}
        </Avatar>

        <Box>
          <Typography fontWeight="800">
            {getUserFromLocalStorage()?.firstName +
              " " +
              getUserFromLocalStorage()?.lastName}
          </Typography>
          <Typography>{getUserFromLocalStorage()?.memberId}</Typography>
          <Typography>{getUserFromLocalStorage()?.email}</Typography>
        </Box>
      </Box>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleNavigate("/my-account/profile")}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary={"My account"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={logOut}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Log Out"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />

      <Box
        onClick={() => {
          onCloseDrawer();
          handleOpenModalLangs();
        }}
        display="flex"
        m={1}
        my={4}
        sx={{ cursor: "pointer" }}
      >
        <Box mx={1}> {getFlag(currency?.currency)}</Box>
        <Typography variant="subtitle2" fontWeight="500">
          {currency?.currency}
        </Typography>
      </Box>

      <Box
        display="flex"
        gap={2}
        mt={"auto"}
        justifyContent="center"
        alignItems="center"
      >
        <IconButton
          size="small"
          component="a"
          href="https://www.facebook.com/SatyamHolidays/"
        >
          <FacebookIcon sx={{ width: "22px" }} />
        </IconButton>
        <IconButton
          size="small"
          component="a"
          href="https://www.instagram.com/satyamholidays_/"
        >
          <InstagramIcon sx={{ width: "22px" }} />
        </IconButton>

        <IconButton
          size="small"
          component="a"
          href="https://www.linkedin.com/company/satyam-holidays-b-v/"
        >
          <LinkedInIcon sx={{ width: "22px" }} />
        </IconButton>
      </Box>
      <Typography textAlign="center" my={2} color="text.disabled">
        version: {Package?.version}
      </Typography>
    </Box>
  );

  return (
    <Drawer anchor="right" open={open} onClose={onCloseDrawer}>
      {DrawerList}
    </Drawer>
  );
}
