import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useFormikContext } from "formik";

import "./style.css";
const Number = ({
  error,
  onChange,
  onBlur,
  setCode,
  mandatory,
  value,
  label,
  countryCode,
}) => {
  const { setFieldValue, values } = useFormikContext();

  const handleOnChange = (value, data, event, formattedValue) => {
    onChange(value);
    if (countryCode) {
      setFieldValue(countryCode, data.dialCode);
    }
  };

  return (
    <div className="input-group-container">
      <label>
        {mandatory && <span className="mandatory-sign">*</span>}
        {label}
      </label>

      <PhoneInput
        onBlur={onBlur}
        containerClass={`phone-input input-wapper ${error && "error"}`}
        international
        inputclassName="input-class"
        placeholder="Enter phone number"
        withCountryCallingCode="true"
        onChange={handleOnChange}
        country="us"
        value={value}
        style={{ paddingLeft: "12px" }}
      />
      {error && <span className="error-msg">{error}</span>}
    </div>
  );
};

export default Number;
