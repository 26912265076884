import { useEffect, useState } from "react";
import "./style.css";
import { ReactComponent as SeatSvg } from "assets/images/seat.svg";

const Seat = ({ seatNmber, price, style, onClick, activeSeats, currency }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {}, [count]);

  return (
    <>
      {seatNmber ? (
        <div
          onClick={() => {
            setCount(count + 1);
            onClick(
              seatNmber,
              price || "FREE",
              activeSeats?.includes(seatNmber)
            );
          }}
          className={`${
            activeSeats?.includes(seatNmber)
              ? "active seat-container"
              : "seat-container"
          }`}
          style={style}
        >
          <div className="seat-wapper">
            <SeatSvg />
          </div>
          {/* <Adult /> */}
          <p>{seatNmber}</p>
          {price && (
            <span className="seat-price">
              {currency} {price}{" "}
            </span>
          )}
        </div>
      ) : (
        <div className="seat-container">
          <div className="seat-wapper"></div>
        </div>
      )}
    </>
  );
};
export default Seat;
