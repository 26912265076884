import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";

const AppDialog = ({ open, onClose, children, ...rest }) => {
  const style = {
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    width: { xs: "100%", lg: "600px" },

    p: { xs: 2, md: 4 },
    "&:focus": {
      outline: "none",
    },
  };

  return (
    <Dialog
      open={open}
      sx={{ ".MuiPaper-root": { width: { xs: "90%", lg: "auto" } } }}
      onClose={onClose}
    >
      <Box sx={style} {...rest}>
        {children}
      </Box>
    </Dialog>
  );
};

export default AppDialog;
