import PropTypes from "prop-types";
import { useFormikContext, getIn } from "formik";

import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";

import { countries } from "assets/data";

import Iconify from "components/iconify";

// ----------------------------------------------------------------------

export default function RHFAutocomplete({
  name,
  label,
  type,
  helperText,
  placeholder,
  sendCode,
  mandatory,
  ...other
}) {
  const { values, errors, setFieldValue } = useFormikContext();

  const error = getIn(errors, name);
  const value = getIn(values, name);

  const { multiple } = other;
  if (type === "country") {
    return (
      <div className="input-group-container">
        <label>
          {mandatory && <span className="mandatory-sign">*</span>}
          {label}
        </label>
        <Autocomplete
          name={name}
          id={`autocomplete-${name}`}
          disableClearable
          autoHighlight={!multiple}
          disableCloseOnSelect={multiple}
          size="small"
          onChange={(event, newValue) =>
            setFieldValue(name, getCountry(newValue)?.code, {
              shouldValidate: true,
            })
          }
          sx={{
            pt: 0.4,
            pb: 0.4,
            "& .MuiInputBase-root": {
              fontSize: "14px",
              boxShadow: "rgb(186 199 213) 0px 0px 0px 1px",
              borderRadius: "5px",
            },
          }}
          renderOption={(props, option) => {
            const country = getCountry(option, sendCode);

            if (!country.label) {
              return null;
            }

            return (
              <li {...props} key={country.label}>
                <Iconify
                  key={country.label}
                  icon={`circle-flags:${country.code?.toLowerCase()}`}
                  sx={{ mr: 1 }}
                />
                {country.label} ({country.code}) +{country.phone}
              </li>
            );
          }}
          renderInput={(params) => {
            const country = getCountry(params.inputProps.value, sendCode);

            const baseField = {
              ...params,

              placeholder,
              error: !!error,
              helperText: error,
              inputProps: {
                ...params.inputProps,
                autoComplete: "new-password",
              },
            };

            if (multiple) {
              return <TextField {...baseField} />;
            }

            return (
              <TextField
                {...baseField}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon={`circle-flags:${country.code?.toLowerCase()}`}
                        sx={{ mr: -0.5, ml: 0.5 }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
          renderTags={(selected, getTagProps) =>
            selected.map((option, index) => {
              const country = getCountry(option, sendCode);

              return (
                <Chip
                  {...getTagProps({ index })}
                  key={country.label}
                  label={country.label}
                  icon={
                    <Iconify
                      icon={`circle-flags:${country.code?.toLowerCase()}`}
                    />
                  }
                  size="small"
                  variant="soft"
                />
              );
            })
          }
          value={countries.find((i) => i.code === value)?.label}
          {...other}
        />
      </div>
    );
  }
  return (
    <Autocomplete
      name={name}
      id={`autocomplete-${name}`}
      onChange={(event, newValue) =>
        setValue(name, newValue, { shouldValidate: true })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={!!error}
          helperText={error}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
        />
      )}
      {...other}
    />
  );
}

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
  placeholder: PropTypes.string,
};

// ----------------------------------------------------------------------

export function getCountry(inputValue, sendCode) {
  const option = countries.filter((country) => country.label === inputValue)[0];
  return {
    ...option,
  };
}
