import React, { useState } from "react";
import dayjs from "dayjs";
import { Formik } from "formik";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CopyField from "./components/copy-field";
import PassportField from "components/upload-passport/input-field";
import CalendarField from "components/calendarField";

const PassportManager = () => {
  const [errorMsg, setErrImg] = useState("");
  const [traveler, setTraveler] = useState({});

  const handlePassportResponse = (data) => {
    setTraveler(data);
  };

  return (
    <>
      <Box minHeight="400px">
        <Typography mb={2}>
          Upload users's passport so you can get info fast!
        </Typography>
        <PassportField
          setErrImg={setErrImg}
          handlePassportResponse={handlePassportResponse}
        />

        <Box mt={3} className="form">
          <Formik
            initialValues={{
              email: "",
            }}
          >
            {() => (
              <>
                <CopyField value={traveler?.firstName} label="First Name" />
                <CopyField value={traveler?.lastName} label="Last Name" />
                {/* <CopyField value={traveler?.birthDate} label="Date Of Birth" /> */}

                <CalendarField
                  valueDay={
                    traveler?.birthDate
                      ? dayjs(traveler?.birthDate).format("DD")
                      : ""
                  }
                  valueMonth={
                    traveler?.birthDate
                      ? dayjs(traveler?.birthDate).format("MM")
                      : ""
                  }
                  valueYear={
                    traveler?.birthDate
                      ? dayjs(traveler?.birthDate).format("YYYY")
                      : ""
                  }
                  label="Date of birth"
                  readOnly
                />
                <CopyField value={traveler?.sex} label="Gender" />
                <CopyField
                  value={traveler?.documentNumber}
                  label="Passport Number"
                />
              </>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default PassportManager;
