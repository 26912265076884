import "./style.css";
import Button from "../button";

const Sidebar = () => {
  return (
    <div className="search-sideBar-wrapper">
      <div className="sidebar-img-wrapper">
        <img src="https://suriwebwinkel-bucket.s3.amazonaws.com/websiteImage.png" />
      </div>
      <div className="sidebar-content">
        <div>
          <h1 className="main-title">
            <strong>Want to fly for even less?</strong>
          </h1>
          <p>Search our best deals, price drops, and travel hacks.</p>
        </div>
        <Button className="bigBtn">Explore Virtual Reality</Button>
      </div>
    </div>
  );
};

export default Sidebar;
