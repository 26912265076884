import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";

import Button from "components/button";
import Seat from "components/seat";
import Badge from "components/badge";

import { ReactComponent as Block } from "assets/images/blockS.svg";
import { ReactComponent as ArrowDown } from "assets/images/arrowDown.svg";
import Seating from "assets/images/seating.png";

function SeatMap({
  loading,
  errMsg,
  value,
  travelers,
  seats,
  reverseSeats,
  handelRemoveSeat,
  handelTraverlsSeats,
  getAllReversedSeats,
  handelSubmit,
  setTravelerIndex,
  handleChangeTab,
  state,
  travelerIndex,
  currency,
}) {
  const isMobile = window.innerWidth <= 768;

  return (
    <div>
      <Box className="card-card">
        <Box display="flex">
          <Box>
            <img className="seat-img" src={Seating} alt="seating" />{" "}
          </Box>
          <Box ml={2}>
            <p className="medium-font-size bolder">Select your seats</p>
            <Box display="flex" mt={1}>
              <CheckIcon
                sx={{ fontSize: 20, color: "rgb(40, 161, 56)", mr: 2 }}
              />
              <p>Find the most comfortable seats for your group.</p>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="card-card seats-container" overflow="hidden">
        {loading ? (
          [...Array(3)]?.map((_, skeleton) => (
            <Box display="flex" justifyContent="space-between" key={skeleton}>
              <Box display="flex">
                {[...Array(3)]?.map((_, skeleton) => (
                  <Box mx={1}>
                    <Skeleton
                      key={skeleton}
                      variant="text"
                      width={24}
                      height={34}
                      animation="wave"
                      sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                    />
                  </Box>
                ))}
              </Box>
              <Box display="flex">
                {[...Array(4)]?.map((_, skeleton) => (
                  <Box display="flex" mx={1} key={skeleton}>
                    <Skeleton
                      variant="text"
                      width={24}
                      height={34}
                      animation="wave"
                      sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                    />
                  </Box>
                ))}
              </Box>
              <Box display="flex">
                {[...Array(3)]?.map((_, skeleton) => (
                  <Box display="flex" mx={1} key={skeleton}>
                    <Skeleton
                      variant="text"
                      width={24}
                      height={34}
                      animation="wave"
                      sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          ))
        ) : errMsg ? (
          <div className="empty-data-msg">
            <h1 className="medium-font-size title-header">{errMsg}</h1>
          </div>
        ) : (
          <>
            <Box mb={4} sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                {seats?.map((flight, tab) => (
                  <Tab
                    key={tab}
                    label={
                      flight?.departure?.iataCode +
                      " → " +
                      flight?.arrival?.iataCode
                    }
                  />
                ))}
              </Tabs>
            </Box>
            <Box mb={6}>
              {travelers?.length > 0 && (
                <Box display="flex" mb={2} className="sticky-badges">
                  {travelers?.map(
                    (traveler, index) =>
                      state?.flight?.travelerPricings?.[index]?.travelerType !==
                        "HELD_INFANT" && (
                        <>
                          <Badge
                            key={index}
                            className={`teal clickable ${
                              travelerIndex === index
                                ? "activeChosen"
                                : "inactiveChosen"
                            }`}
                            title={
                              traveler?.name?.firstName +
                              "." +
                              traveler?.name?.lastName?.charAt(0)
                            }
                            selectedSeat={
                              reverseSeats[index][traveler.id]?.seats?.find(
                                (i) => i.segmentId === seats[value]?.segmentId
                              )?.selectedSeat
                            }
                            iconClose
                            onClick={() => {
                              setTravelerIndex(index);
                            }}
                            onCloseClick={() =>
                              handelRemoveSeat(
                                reverseSeats[index][
                                  travelers[index].id
                                ]?.seats?.find(
                                  (i) => i.segmentId === seats[value]?.segmentId
                                )?.selectedSeat,
                                index
                              )
                            }
                          />
                        </>
                      )
                  )}
                </Box>
              )}

              <Box textAlign="center" mb={2}>
                <p className="medium-font-size bolder">
                  Select a seat on the map
                </p>
                <Box
                  mt={1}
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  gap={{ xs: 0, sm: 1, md: 2 }}
                  flexWrap={{ xs: "wrap", sm: "nowrap", md: "nowrap" }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    className="seat-booking-wrapper"
                  >
                    <Block />
                    <p className="gray ms-2 align-self-end">Available</p>
                  </Box>

                  <Box
                    className="light seat-booking-wrapper"
                    display="flex"
                    alignItems="center"
                  >
                    <Block style={{ opacity: 0.4 }} />
                    <p className="gray ms-2 align-self-end">
                      Blocked or Occupied
                    </p>
                  </Box>
                </Box>
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                className="test"
                position="relative"
              >
                {[
                  ...Array(seats[value]?.decks[0]?.deckConfiguration?.width),
                ]?.map((col, y) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={`column-${y}`}
                    key={y}
                  >
                    {[
                      ...Array(
                        seats[value]?.decks[0]?.deckConfiguration?.length
                      ),
                    ]?.map((row, x) => (
                      <>
                        {seats[value]?.decks[0]?.deckConfiguration
                          ?.startWingsRow === x &&
                          y === 0 && (
                            <div
                              key={x}
                              className="left-wing"
                              style={{
                                height:
                                  (isMobile ? 32 : 70) *
                                    (seats[value]?.decks[0]?.deckConfiguration
                                      ?.endWingsX +
                                      1) +
                                  "px",
                                top:
                                  (isMobile ? 32 : 70) *
                                    seats[value]?.decks[0]?.deckConfiguration
                                      ?.startWingsRow +
                                  "px",
                              }}
                            />
                          )}
                        {seats[value]?.decks[0]?.deckConfiguration
                          ?.startWingsRow === x &&
                          y === 0 && (
                            <div
                              className="right-wing"
                              style={{
                                height:
                                  (isMobile ? 32 : 70) *
                                    (seats[value]?.decks[0]?.deckConfiguration
                                      ?.endWingsX +
                                      1) +
                                  "px",
                                top:
                                  (isMobile ? 32 : 70) *
                                    seats[value]?.decks[0]?.deckConfiguration
                                      ?.startWingsRow +
                                  "px",
                              }}
                            />
                          )}

                        <Seat
                          onClick={handelTraverlsSeats}
                          activeSeats={
                            reverseSeats?.length > 0 && travelers?.length > 0
                              ? getAllReversedSeats()
                              : []
                          }
                          style={{
                            pointerEvents:
                              seats[value]?.decks[0]?.seats?.find(
                                (i) =>
                                  i?.coordinates?.x === x &&
                                  i?.coordinates?.y === y
                              )?.travelerPricing?.[0]
                                ?.seatAvailabilityStatus === "AVAILABLE"
                                ? "all"
                                : "none",
                            opacity:
                              seats[value]?.decks[0]?.seats?.find(
                                (i) =>
                                  i?.coordinates?.x === x &&
                                  i?.coordinates?.y === y
                              )?.travelerPricing?.[0]
                                ?.seatAvailabilityStatus === "AVAILABLE"
                                ? 1
                                : 0.4,
                          }}
                          price={
                            seats[value]?.decks[0]?.seats?.find(
                              (i) =>
                                i?.coordinates?.x === x &&
                                i?.coordinates?.y === y
                            )?.travelerPricing?.[0]?.price?.total
                          }
                          seatNmber={
                            seats[value]?.decks[0]?.seats?.find(
                              (i) =>
                                i?.coordinates?.x === x &&
                                i?.coordinates?.y === y
                            )?.number
                          }
                          currency={currency}
                        />
                      </>
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Button
        type="button"
        // onClick={() => {
        //   setActiveStep(3);
        //   window.scrollTo(0, 0);
        // }}
        onClick={handelSubmit}
        className="bigBtn primary-color booking"
      >
        {loading ? "Skip seats and continue" : "Continue"}{" "}
        {state?.flightDetails?.return !== "One-way" && (
          <>
            - {state?.flightDetails?.totalDays + "   "}
            Days
          </>
        )}{" "}
        <ArrowDown />
      </Button>
    </div>
  );
}

export default SeatMap;
