import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Banner from "components/banner";
import Feature from "components/feature";
import PopularFlight from "components/popluar-flight";
import SuggestPlaces from "components/suggestPlaces";
import "video-react/dist/video-react.css";
import AppQRCode from "assets/images/AppQRCode.webp";
import HomeVideo from "assets/images/video.mp4";

import Partner1 from "assets/images/Partner1.svg";
import Partner2 from "assets/images/anvrLogo.svg";
import Partner3 from "assets/images/sgrLogo.svg";
import Partner4 from "assets/images/iataLogo.svg";

import { getUserFromLocalStorage } from "hooks/LocalStorage";
import useFilter from "components/filterConfiguration";

import "./style.css";
import packageJson from "../../../package.json";

function Home({ setRedirectLink }) {
  const [departureDate, setdepartureDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const { allFilters, setAllFilters } = useFilter();

  return (
    <div>
      <div className="home-page">
        <div className="banner banner-bg row m-0">
          <div className="home-image px-0">
            {/* <img src={HomeImage} alt="Home" /> */}
            <Box
              maxHeight="460px"
              overflow="hidden"
              position="relative"
              height="100%"
            >
              {/* <Player
                ref={videoRef}
                autoPlay
                fluid
                src={HomeVideo}
                preload="auto"
                muted
                loop
                controls={false}
              >
                <source width="300px" />
              </Player> */}

              <div
                dangerouslySetInnerHTML={{
                  __html: `<div className="video-container">
                <video style="width:100%;height:100%;" className="video-react" preload fluid autoplay loop muted playsinline>
                  <source src=${HomeVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>`,
                }}
              />
            </Box>
          </div>
          <div className="container">
            {getUserFromLocalStorage() ? (
              <h1>
                Welkom,{" "}
                {getUserFromLocalStorage()?.type === "B_AGENT"
                  ? getUserFromLocalStorage()?.travelAgencyName
                  : getUserFromLocalStorage()?.firstName +
                    " " +
                    getUserFromLocalStorage()?.lastName}
              </h1>
            ) : (
              <h1>Welkom</h1>
            )}
            <p className="banner-sub-title mb-16">
              You can't buy happiness, but you can buy plane tickets, which is
              kind of the same thing.
            </p>
            <Banner
              departureDate={departureDate}
              setdepartureDate={setdepartureDate}
              returnDate={returnDate}
              setReturnDate={setReturnDate}
              allFilters={allFilters}
              setAllFilters={setAllFilters}
              setRedirectLink={setRedirectLink}
            />
          </div>
        </div>

        {/**** Features ****/}
        <div className="container features">
          <h1>Satyam Tours B.V.</h1>
          <div className="row">
            <div className="col-md-4">
              <Feature
                header={"Refunds & Cancellations"}
                body={"Contact us for more details"}
                buttonValue={"Contact us"}
              />
            </div>
            <div className="col-md-4">
              <Feature
                header={"Issue & Modification"}
                body={"Contact us for more details"}
                buttonValue={"Contact us"}
              />
            </div>
            <div className="col-md-4">
              <Feature
                header={"Rebooking"}
                body={"Contact us for more details"}
                buttonValue={"Contact us"}
              />
            </div>
          </div>
        </div>

        {/**** Popular Flight ****/}
        <div style={{ backgroundColor: "#fffcfb" }}>
          <div className="container my-5 py-5">
            <h1 className="main-title">Package Deals Coming soon!</h1>
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-3">
                <SuggestPlaces
                  header={"Coming Soon"}
                  inbound={"Paramaribo"}
                  outbound={"Amsterdam"}
                  footerText={"5 nights from $950"}
                  imageSource={
                    "https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/Airballoon.png"
                  }
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <SuggestPlaces
                  header={"Coming Soon"}
                  inbound={"Paramaribo"}
                  outbound={"Curacao"}
                  footerText={"5 nights from $450"}
                  imageSource={
                    "https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/Lady.png"
                  }
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <SuggestPlaces
                  header={"Coming Soon"}
                  inbound={"Paramaribo"}
                  outbound={"Miami"}
                  footerText={"10 nights from $850"}
                  imageSource={
                    "https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/Lady2.png"
                  }
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <SuggestPlaces
                  header={"Coming Soon"}
                  inbound={"Paramaribo"}
                  outbound={"Aruba"}
                  footerText={"5 nights from $650"}
                  imageSource={
                    "https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/man.png"
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="banner-desktop">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1 className="main-title">
                  Satyam Tours mobile app coming soon!
                </h1>
                <p>
                  Satyam Tours mobile app expected release date: 25th of
                  November 2024
                </p>
                <div className="d-flex flex-wrap">
                  <div className="d-flex app-feature">
                    <svg
                      className="Icon__StyledIcon-sc-1det6wr-0 kbAetT"
                      viewBox="0 0 24 24"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path d="M8.445 12.668a.9.9 0 00-1.303 1.242l2.573 2.745a.9.9 0 001.335-.036l5.591-7.037a.9.9 0 00-1.367-1.17l-4.655 5.913a.4.4 0 01-.607.025l-1.567-1.683zM1.999 12C2 6.5 6.5 2 12 2s10 4.5 10 10-4.5 10-10 10S2 17.5 2 12z"></path>
                    </svg>
                    <p>Search Cheapest Flights</p>
                  </div>
                  <div className="d-flex app-feature">
                    <svg
                      className="Icon__StyledIcon-sc-1det6wr-0 kbAetT"
                      viewBox="0 0 24 24"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path d="M8.445 12.668a.9.9 0 00-1.303 1.242l2.573 2.745a.9.9 0 001.335-.036l5.591-7.037a.9.9 0 00-1.367-1.17l-4.655 5.913a.4.4 0 01-.607.025l-1.567-1.683zM1.999 12C2 6.5 6.5 2 12 2s10 4.5 10 10-4.5 10-10 10S2 17.5 2 12z"></path>
                    </svg>
                    <p>Seat Selections</p>
                  </div>
                  <div className="d-flex app-feature">
                    <svg
                      className="Icon__StyledIcon-sc-1det6wr-0 kbAetT"
                      viewBox="0 0 24 24"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path d="M8.445 12.668a.9.9 0 00-1.303 1.242l2.573 2.745a.9.9 0 001.335-.036l5.591-7.037a.9.9 0 00-1.367-1.17l-4.655 5.913a.4.4 0 01-.607.025l-1.567-1.683zM1.999 12C2 6.5 6.5 2 12 2s10 4.5 10 10-4.5 10-10 10S2 17.5 2 12z"></path>
                    </svg>
                    <p>Free Online Reservations</p>
                  </div>
                  <div className="d-flex app-feature">
                    <svg
                      className="Icon__StyledIcon-sc-1det6wr-0 kbAetT"
                      viewBox="0 0 24 24"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path d="M8.445 12.668a.9.9 0 00-1.303 1.242l2.573 2.745a.9.9 0 001.335-.036l5.591-7.037a.9.9 0 00-1.367-1.17l-4.655 5.913a.4.4 0 01-.607.025l-1.567-1.683zM1.999 12C2 6.5 6.5 2 12 2s10 4.5 10 10-4.5 10-10 10S2 17.5 2 12z"></path>
                    </svg>
                    <p>View Live Flight Schedules</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="image-wrapper">
                  {/* <div className="mobile-hide qr-con">
                     <img src={Homepage_qr} /> 
                    <p>Scan to download</p>
                  </div> */}
                  <img src={AppQRCode} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/**** Popular Flight ****/}
        <div style={{ backgroundColor: "#fffcfb" }}>
          <div className="container my-5 py-5">
            <h1 className="main-title">Popular destinations</h1>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <PopularFlight
                  inbound={"Amsterdam"}
                  outbound={"Paramaribo"}
                  source={
                    "https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/Suriname.png"
                  }
                />
              </div>
              <div className="col-md-4">
                <PopularFlight
                  inbound={"Amsterdam"}
                  outbound={"Dubai"}
                  source={
                    "https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/Dubai.png"
                  }
                />
              </div>
              <div className="col-md-4">
                <PopularFlight
                  inbound={"Paramaribo"}
                  outbound={"Amsterdam"}
                  source={
                    "https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/Asmterdam.png"
                  }
                />
              </div>
              <div className="col-md-4">
                <PopularFlight
                  inbound={"Paramaribo"}
                  outbound={"Miami"}
                  source={
                    "https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/Miami.png"
                  }
                />
              </div>
              <div className="col-md-4">
                <PopularFlight
                  inbound={"Paramaribo"}
                  outbound={"Curacao"}
                  source={
                    "https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/Curacao.png"
                  }
                />
              </div>
              <div className="col-md-4">
                <PopularFlight
                  inbound={"Paramaribo"}
                  outbound={"Aruba"}
                  source={
                    "https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/Aruba.png"
                  }
                />
              </div>
              <button className="btn global-btn">Show More</button>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="row partners-logo">
              {/* <Partner2 /> */}
              <img src={Partner4} className="partners-logo" />
              <img src={Partner2} className="partners-logo" />
              <img src={Partner1} className="partners-logo" />
              <img src={Partner3} className="partners-logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
