import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Input from "./bannerInput";
import Button from "./button";

import { addRemarks } from "api/Remarks";

const AddRemark = ({ flightOrderId, officeId }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [msg, setMsg] = useState("");
  const [fields, setFields] = useState([
    {
      subType: "GENERAL_MISCELLANEOUS",
      text: "",
    },
  ]);

  const handleOnChange = (index, value) => {
    const allFields = [...fields];
    allFields[index].text = value;
    setFields(allFields);
  };

  const handleAddNewField = () => {
    const checkHasEmptyField = fields.find((field) => field.text === "");
    if (checkHasEmptyField) {
      return;
    }
    const obj = {
      subType: "GENERAL_MISCELLANEOUS",
      text: "",
    };
    return setFields([...fields, obj]);
  };

  const handleRemoveField = (index) => {
    const filterFields = [...fields];
    filterFields.splice(index, 1);
    setFields(filterFields);
  };

  const handleSubmitRemarks = async () => {
    setMsg("");
    setLoading(true);
    const response = await addRemarks({
      body: {
        flightId: flightOrderId,
        remarks: fields,
        ...(officeId && { officeId }),
      },
    });
    if (!response.ok) {
      setStatus("error");
      setMsg(
        "Your remark was not added, please add this remark manually in Sell Connect"
      );
    } else {
      setStatus("success");
      setMsg("Your remark was added successfully");
    }
    setLoading(false);
  };
  return (
    <Box>
      {msg && (
        <Alert severity={status} sx={{ mb: 2 }}>
          {msg}
        </Alert>
      )}
      {fields.map((field, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="flex-end"
          mb={1}
          sx={{
            "& .input-group-container": {
              width: "70%",
            },
          }}
        >
          <Input
            placeholder="Type your remark"
            label="Add your remark"
            onChange={(e) => handleOnChange(index, e.target.value)}
            value={field.text}
          />
          {index !== 0 && (
            <IconButton
              sx={{ mx: 1, mb: 1.5 }}
              onClick={() => handleRemoveField(index)}
            >
              <CloseIcon fontSize="small" color="error" />
            </IconButton>
          )}
        </Box>
      ))}

      <Button onClick={handleAddNewField} className="mt-2">
        Add New Remark
      </Button>

      <Box sx={{ textAlign: "right", mt: 2 }}>
        <Button
          loading={loading}
          onClick={handleSubmitRemarks}
          className="bigBtn primary-color ms-auto me-0"
        >
          Sumbit Remark
        </Button>
      </Box>
    </Box>
  );
};

export default AddRemark;
