import "./style.css";

const Badge = ({
  className,
  title,
  Icon,
  onClick,
  selectedSeat,
  iconClose,
  onCloseClick,
}) => {
  return (
    <div className={`badge ${className}`}>
      {title && Icon ? (
        <>
          <Icon /> {title}
        </>
      ) : Icon ? (
        <Icon />
      ) : title && selectedSeat ? (
        <span onClick={onClick}>{title + " " + selectedSeat}</span>
      ) : (
        <span onClick={onClick}> {title}</span>
      )}
      {iconClose && (
        <span onClick={onCloseClick} className="remove-btn">
          x
        </span>
      )}
    </div>
  );
};
export default Badge;
