import { useRef, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Skeleton from "@mui/material/Skeleton";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupsIcon from "@mui/icons-material/Groups";
import WorkIcon from "@mui/icons-material/Work";
import ArticleIcon from "@mui/icons-material/Article";
import { Alert, Typography } from "@mui/material";

import animationData from "lotties/success";

import Button from "components/button";

import DragAndDrop from "./components/drag&drop-file";
import { getJobByID, applyForJob } from "api/Jobs";
import useApi from "hooks/useApi";

import "./style.css";

function JobDetails() {
  const myRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const executeScroll = () => scrollToRef(myRef);
  const navigate = useNavigate();
  const [motivationLetterFile, setMotivationLetterFile] = useState(null);
  const [resume, setResume] = useState(null);
  const [loading, setLoading] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { jobId } = useParams();

  const getJobDetails = useApi(getJobByID);

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const language = "en";

  useEffect(() => {
    getJobDetails.request({
      jobId,
    });
  }, []);

  const handleApplyForJob = async () => {
    setError(false);

    // Validate the submit button
    if (!resume || !motivationLetterFile) {
      return setError(
        "Please upload the resume and motivation letter for submitting the job!"
      );
    }
    setLoading(true);
    const body = {
      title: getJobDetails?.data[language]?.title,
      department: getJobDetails?.data[language]?.department,
      location: getJobDetails?.data[language]?.location,
    };

    const response = await applyForJob({
      body,
      resume,
      motivationLetterFile,
    });
    if (!response.ok) {
      setLoading(false);
    } else {
      setSuccess(true);
      setLoading(false);
      navigate("/how-we-hire");
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    // Clean error state
    if (error && resume && motivationLetterFile) {
      setError("");
    }
  }, [resume, motivationLetterFile]);

  return (
    <div className="search-form-wrapper job">
      {/* <div className="search-form job">
        <h1>Search for jobs</h1>
        <div className="card">
          <div className="d-flex flex-wrap">
            <div className="d-flex banner-content">
              <div className="block-group">
                <div className="block-group-item">
                  <BannerInput placeholder="Search" hideLeftText />
                  {false && <ContainerSearch />}
                </div>
                <div className="block-group-item">
                  <BannerInput placeholder="Select Team" hideLeftText />
                  {false && <ContainerSearch />}
                </div>
                <div className="block-group-item">
                  <BannerInput placeholder="Select Location" hideLeftText />
                  {false && <ContainerSearch />}
                </div>
              </div>
              <button className="search-btn btn">Search</button>
            </div>
          </div>
        </div>
      </div> */}

      <div className="search-results-view-jobs">
        {getJobDetails?.loading ? (
          <div className="card-card">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </div>
        ) : getJobDetails?.error ? (
          <div className="empty-data-msg">
            <h1 className="medium-font-size title-header">
              There is something wrong!
            </h1>
          </div>
        ) : (
          <>
            <div className="job-social-media card-card">
              <p className="medium-font-size">Share this listing:</p>
              <div>
                <IconButton>
                  <FacebookIcon />
                </IconButton>
                <IconButton>
                  <EmailIcon />
                </IconButton>
                <IconButton>
                  <LinkedInIcon />
                </IconButton>
              </div>
              <hr />
              <Button onClick={executeScroll} className="primary-color m-auto">
                Apply Now
              </Button>
            </div>
            <p className="heading-title">
              {getJobDetails?.data[language]?.title}
            </p>
            <div className="mobile-content">
              <Button onClick={executeScroll} className="primary-color">
                Apply Now
              </Button>
              <div>
                <IconButton>
                  <FacebookIcon />
                </IconButton>
                <IconButton>
                  <EmailIcon />
                </IconButton>
                <IconButton>
                  <LinkedInIcon />
                </IconButton>
              </div>
            </div>

            <div className="card-card">
              <div className="d-flex">
                <WorkIcon />
                <p className="mx-2">{getJobDetails?.data[language]?.type}</p>
              </div>
              <div className="d-flex">
                <LocationOnIcon />
                <p className="mx-2">
                  {getJobDetails?.data[language]?.location}
                </p>
              </div>

              <div className="d-flex">
                <GroupsIcon />
                <p className="mx-2">
                  {getJobDetails?.data[language]?.department}
                </p>
              </div>
              <div className="d-flex">
                <ArticleIcon />
                <p className="mx-2">
                  {getJobDetails?.data[language]?.jobDescription}
                </p>
              </div>
            </div>
            {getJobDetails?.data[language]?.contents?.map((item, index) => (
              <div className="card-card" key={index}>
                <p className="medium-font-size mb-12">{item?.title}</p>
                <p>
                  {item?.content?.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </p>
              </div>
            ))}

            <p ref={myRef} className="heading-title">
              Apply Now
            </p>
            <div className="card-card w-100">
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}
              {!success ? (
                <>
                  <DragAndDrop
                    file={resume}
                    label="Please upload your resume."
                    getFile={setResume}
                  />
                  <DragAndDrop
                    file={motivationLetterFile}
                    label="Please upload your motivation letter."
                    getFile={setMotivationLetterFile}
                  />

                  <Button
                    className="primary-color bigBtn w-100"
                    onClick={handleApplyForJob}
                    loading={loading}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <Box my={2}>
                  <Typography textAlign="center" fontWeight="bold">
                    Congratulations! Your job application was successfully
                    submitted!
                  </Typography>
                  <Lottie options={defaultOptions} height={150} width={150} />
                </Box>
              )}
            </div>
            <div className="card-card w-100">
              <p className="bold note-font">
                <span className="orange-color"> ~</span> At our company,
                diversity is our strength. We welcome and value everyone,
                regardless of gender, race, or disability, and we believe in the
                potential of every applicant. Join us and let's build an
                inclusive future together.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default JobDetails;
