import React, { useEffect, useState } from "react";
import moment from "moment";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";

import { ReactComponent as Airplane } from "assets/images/airplate.svg";
import { ReactComponent as NetherLands } from "assets/images/nl.svg";
import { ReactComponent as Suriname } from "assets/images/sr.svg";
import { ReactComponent as Curacao } from "assets/images/cw.svg";

import useApi from "hooks/useApi";
import flightSchedules from "api/FlightSchedules";

import Badge from "components/badge";
import FlightDetails from "components/flightDetails";

import "./style.css";

function FlightSchedules({}) {
  const getFlightSchedules = useApi(flightSchedules);
  const airports = ["Suriname", "Curacao", "Netherlands"];

  const [selected, setSelected] = useState({
    currentAirport: "Suriname",
    flightType: "Arrival",
  });

  useEffect(() => {
    getFlightSchedules.request();
  }, []);

  const selectAnAirport = (currentAirport) => {
    setSelected({ ...selected, currentAirport });
  };
  const selectFlightType = (flightType) => {
    setSelected({ ...selected, flightType });
  };

  return (
    <div className="airports job-page">
      <div className="home-page">
        <div className="banner banner-bg row m-0">
          <div className="home-image">
            <img
              src="https://images.pexels.com/photos/396437/pexels-photo-396437.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
              alt="Home"
            />
          </div>
          <div className="container">
            <h1>Available Airports</h1>
            <div className="card">
              <div className="d-flex flex-wrap">
                <div className="d-flex banner-content">
                  <div className="block-group">
                    {airports.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => selectAnAirport(item)}
                        className={`block-group-item tab ${
                          selected.currentAirport === item
                            ? "active"
                            : "inactive"
                        }`}
                      >
                        <p>
                          {index === 0 ? (
                            <Suriname />
                          ) : index === 1 ? (
                            <Curacao />
                          ) : (
                            <NetherLands />
                          )}{" "}
                          {item}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Container sx={{ px: "0px" }}>
          <Box my={4} mt={{ xs: 13, sm: 4 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
            >
              <div className="airports-tabs">
                <p
                  onClick={() => selectFlightType("Arrival")}
                  className={`job-title medium-font-size ${
                    selected.flightType === "Arrival" ? "active" : "inactive"
                  }`}
                >
                  <Airplane className="landed" /> Arrivals
                </p>
                <p
                  onClick={() => selectFlightType("Departure")}
                  className={`job-title medium-font-size ${
                    selected.flightType === "Departure" ? "active" : "inactive"
                  }`}
                >
                  <Airplane className="plane-up" /> Departures
                </p>
              </div>
              <Tooltip title="The data will update every 4h !">
                <div className="info-msg">
                  <span>
                    <CircleIcon />
                  </span>
                  <p>
                    <strong>Last updated: </strong>
                    {getFlightSchedules?.loading ? (
                      <Skeleton variant="text" width="100px" />
                    ) : (
                      <>
                        {
                          getFlightSchedules?.data?.flightSchedules
                            ?.lastUpdatedCalculated
                        }
                      </>
                    )}
                  </p>
                </div>
              </Tooltip>
            </Box>
            <div className="featured-job-section">
              {getFlightSchedules?.loading ? (
                <div className="skeleton">
                  <div>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </div>
                  <div>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </div>
                  <div>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </div>
                  <div>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </div>
                </div>
              ) : (
                <>
                  {getFlightSchedules?.error ? (
                    <div className="empty-data-msg">
                      <h1 className="medium-font-size title-header">
                        There is something wrong! Please try again
                      </h1>
                    </div>
                  ) : getFlightSchedules?.data?.length === 0 ? (
                    <div className="empty-data-msg">
                      <h1 className="medium-font-size title-header">
                        There No flights at this moment please check again in 4
                        hours !
                      </h1>
                    </div>
                  ) : (
                    <>
                      {getFlightSchedules?.data?.flightSchedules[
                        selected.currentAirport.toLowerCase() +
                          selected.flightType
                      ]?.length === 0 ? (
                        <div className="empty-data-msg">
                          <h1 className="medium-font-size title-header">
                            There No {selected.flightType} at this moment please
                            check again in 4 hours !
                          </h1>
                        </div>
                      ) : (
                        getFlightSchedules?.data?.flightSchedules[
                          selected.currentAirport.toLowerCase() +
                            selected.flightType
                        ]?.map((item, index) => (
                          <>
                            <div className="flight-details-mobile">
                              <FlightDetails item={item} />
                            </div>
                            <div className="card-flight" key={index}>
                              <div style={{ width: "10%" }}>
                                <p className="medium-font-size title-header">
                                  Flight
                                </p>
                                <div className="card-flight-name">
                                  <img src={item?.airlineLogos?.squareImage} />

                                  <p className="job-title">
                                    {item?.airlineIata}
                                  </p>
                                </div>
                              </div>{" "}
                              <div style={{ width: "20%" }}>
                                <p className="medium-font-size title-header">
                                  <Airplane className="plane-up" />
                                  Departure from
                                </p>
                                <div className="card-flight-name">
                                  <p className="job-title">
                                    {item?.departureAirport},{" "}
                                    {item?.departureCity}
                                  </p>
                                </div>
                              </div>
                              <div style={{ width: "17%" }}>
                                <p className="medium-font-size title-header">
                                  Departure Time
                                </p>
                                <div className="card-flight-name">
                                  <p className="job-title">
                                    {moment(item?.depTime).format(
                                      "ddd, Do MMMM, h:mmA"
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div style={{ width: "20%" }}>
                                <p className="medium-font-size title-header">
                                  <Airplane className="landed" /> Arrival
                                  Airport
                                </p>
                                <div className="card-flight-name">
                                  <p className="job-title">
                                    {item?.arrivalAirport}, {item?.arrivalCity}
                                  </p>
                                </div>
                              </div>{" "}
                              <div style={{ width: "17%" }}>
                                <p className="medium-font-size title-header">
                                  Arrival Time
                                </p>
                                <div className="card-flight-name">
                                  <p className="job-title">
                                    {moment(item?.arrTime).format(
                                      "ddd, Do MMMM, h:mmA"
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div style={{ width: "10%" }}>
                                <p className="medium-font-size title-header">
                                  Status
                                </p>
                                <div className="card-flight-name">
                                  <p className="job-title">
                                    <Badge
                                      title={item?.status}
                                      className={item?.status}
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      )}
                    </>
                  )}{" "}
                </>
              )}
            </div>
          </Box>
        </Container>
      </div>
    </div>
  );
}

export default FlightSchedules;
