import { useState } from "react";

import Box from "@mui/material/Box";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import IconButton from "@mui/material/IconButton";

import QrCodeModal from "./qrCode-modal";

const Branch = ({ branch }) => {
  const [visible, setVisiable] = useState(false);

  const styleWhatsAppIcon = {
    position: "absolute",
    bottom: "16px",
    right: "16px",
  };

  const handleModal = () => {
    setVisiable(true);
  };
  const handleClose = () => {
    setVisiable(false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          position: "relative",
          minHeight: "320px",
          bgcolor: "#fff",
          cursor: "pointer",
          "&:hover": {
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          },
        }}
        mb={2}
        p={1}
        borderRadius="10px"
        overflow="hidden"
      >
        <Box>
          <Box p={1} flex={1} display="flex" flexDirection="column">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box>
                <h2 className="bolder sub-main-title ">{branch.name}</h2>
                <p className="mb-2">{branch.address}</p>
              </Box>
            </Box>
            <Box
              mt={"auto"}
              display="flex"
              justifyContent="space-between"
              alignItems="end"
              flexWrap="wrap"
            >
              <Box pb={3}>
                <p className="d-flex  gray mt-2">
                  <p className="bolder me-1 medium-font-size">Tel: </p>
                  {branch.tel}
                </p>
                <p className=" gray mt-2">
                  <p className="bolder me-1 medium-font-size d-inline">
                    WhatsApp :{" "}
                  </p>{" "}
                  {branch.whatsapp}
                </p>
                <p className="bolder mt-2 medium-font-size">Opening Hours :</p>{" "}
                <p className="d-flex  gray mt-1">{branch.openeingHours}</p>
              </Box>
            </Box>
          </Box>
        </Box>
        {branch.whatsappIamgeQrCode && (
          <IconButton sx={styleWhatsAppIcon} onClick={handleModal}>
            <WhatsAppIcon color="success" />
          </IconButton>
        )}
      </Box>
      <QrCodeModal
        visible={visible}
        handleClose={handleClose}
        image={branch.whatsappIamgeQrCode}
      />
    </>
  );
};

export default Branch;
