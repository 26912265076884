import React, { useState } from "react";
import dayjs from "dayjs";
import { Formik } from "formik";

import Alert from "@mui/material/Alert";

import CalendarField from "components/calendarField";
import Input from "components/bannerInput";
import Button from "components/button";
import PhoneNumber from "components/phoneInput";

const ActivitiesTab = ({
  getMemberInfo,
  validationSchema,
  handelSubmit,
  error,
  success,
  handleShowPassword,
  loading,
}) => {
  const [code, setCode] = useState();

  return (
    <Formik
      initialValues={{
        firstName: getMemberInfo?.firstName,
        lastName: getMemberInfo?.lastName,

        dateOfBirthDay: getMemberInfo?.dateOfBirth
          ? dayjs.utc(getMemberInfo?.dateOfBirth).format("DD")
          : "",
        dateOfBirthMonth: getMemberInfo?.dateOfBirth
          ? dayjs(getMemberInfo?.dateOfBirth).format("MM")
          : "",
        dateOfBirthYear: getMemberInfo?.dateOfBirth
          ? dayjs(getMemberInfo?.dateOfBirth).format("YYYY")
          : "",
        phoneNumber: getMemberInfo?.phoneNumber,
        email: getMemberInfo?.email,
        passportNumber: getMemberInfo?.passportNumber,
      }}
      validationSchema={validationSchema}
      onSubmit={handelSubmit}
      enableReinitialize={true}
      validateOnBlur={true}
    >
      {({
        handleChange,
        handleSubmit,
        errors,
        setFieldTouched,
        touched,
        values,
        setFieldValue,
      }) => (
        <>
          <p className="bold main-title"> My Account</p>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {success}
            </Alert>
          )}
          <div className="form">
            <Input
              onChange={handleChange(`firstName`)}
              error={touched.firstName && errors.firstName}
              onBlur={() => setFieldTouched("firstName")}
              name={"firstName"}
              mandatory={true}
              type="firstName"
              label="First name"
              value={values?.firstName}
            />
            <Input
              onChange={handleChange(`lastName`)}
              error={touched.lastName && errors.lastName}
              onBlur={() => setFieldTouched("lastName")}
              name={"lastName"}
              mandatory={true}
              label="Last name"
              value={values?.lastName}
            />
            <Input
              onChange={handleChange(`email`)}
              error={touched.email && errors.email}
              onBlur={() => setFieldTouched("email")}
              name={"email"}
              mandatory={true}
              type="email"
              label="Email"
              value={values?.email}
              disabled="true"
            />

            <CalendarField
              valueDay={values["dateOfBirthDay"]}
              valueMonth={values["dateOfBirthMonth"]}
              valueYear={values["dateOfBirthYear"]}
              error1={touched.dateOfBirthDay && errors.dateOfBirthDay}
              error2={errors.dateOfBirthMonth && touched.dateOfBirthMonth}
              error3={errors.dateOfBirthYear && touched.dateOfBirthYear}
              nameDay={`dateOfBirthDay`}
              nameMonth={`dateOfBirthMonth`}
              nameYear={`dateOfBirthYear`}
              onChangeDay={handleChange(`dateOfBirthDay`)}
              onChangeMonth={handleChange("dateOfBirthMonth")}
              onChangeYear={handleChange("dateOfBirthYear")}
              onBlurDay={() => setFieldTouched("dateOfBirthYear")}
              onBlurMonth={() => setFieldTouched("onBlurMonth")}
              onBlurYear={() => setFieldTouched("onBlurYear")}
              label="Date of birth"
            />

            <PhoneNumber
              onChange={handleChange(`phoneNumber`)}
              error={touched.phoneNumber && errors.phoneNumber}
              onBlur={() => setFieldTouched("phoneNumber")}
              name={"phoneNumber"}
              label="Phone number"
              value={values?.phoneNumber}
              setCode={setCode}
            />
            <Input
              onChange={handleChange(`passportNumber`)}
              error={touched.passportNumber && errors.passportNumber}
              onBlur={() => setFieldTouched("passportNumber")}
              name={"passportNumber"}
              value={values?.passportNumber}
              label="Passport number"
            />
            <p className="app-link bold" onClick={handleShowPassword}>
              <u>Change Password ?</u>
            </p>
            <Button
              type="submit"
              onClick={handleSubmit}
              className="bigBtn  primary-color"
              loading={loading}
            >
              Save changes
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};

export default ActivitiesTab;
