import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IconButton from "@mui/material/IconButton";

import Slide from "assets/images/slide.webp";

import Badge from "../badge";

const HotelCard = ({
  hotel,
  infoSource,
  startDate,
  endDate,
  destination,
  guestCount,
  children,
  roomStayCandidate,
}) => {
  const navigate = useNavigate();
  const hotelDetails = {
    destination,
    startDate,
    endDate,
    children,
    guestCount,
    roomStayCandidate,
    infoSource,
    ...hotel,
  };

  const navigateToHotelDetails = () => {
    window.scrollTo(0, 0);
    navigate("/hotel-details", { state: { hotelDetails } });
  };

  return (
    <Link
      target="_blank"
      to={{
        pathname: `/hotel-details`,
        search: `destination=${destination}&startDate=${startDate}&endDate=${endDate}&guestCount=${guestCount}&children=${children}&roomStayCandidate=${roomStayCandidate}&infoSource=${infoSource}&hotelCode=${
          hotelDetails?.basicProperty?.hotelCode
        }&hotel=${JSON.stringify(hotelDetails)}`,
      }}
    >
      <Box
        // onClick={navigateToHotelDetails}
        sx={{
          bgcolor: "#fff",
          cursor: "pointer",
          "&:hover": { boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" },
        }}
        mb={2}
        display="flex"
        borderRadius="10px"
        overflow="hidden"
        flexDirection={{ xs: "column	", md: "row" }}
      >
        <Box
          position="relative"
          sx={{ "& img": { width: { xs: "100%", md: "200px" } } }}
        >
          <IconButton sx={{ position: "absolute" }}>
            <FavoriteBorderIcon sx={{ color: "#fff" }} />
          </IconButton>

          <img src={hotel?.basicProperty?.hotelImage ?? Slide} />
        </Box>
        <Box p={1} flex={1} display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box>
              <h2 className="bolder sub-main-title ">
                {hotel?.basicProperty?.hotelName}
              </h2>
              <p>{hotel?.address?.hotelCityName} </p>
              <p>{hotel?.address?.hotelAddressLine} </p>
            </Box>
          </Box>
          <Box
            mt={"auto"}
            display="flex"
            justifyContent="space-between"
            alignItems="end"
            flexWrap="wrap"
          >
            <Box>
              {hotel?.basicProperty?.hotelAmenities?.map((amenity, index) => (
                <p key={index}>{amenity}</p>
              ))}

              <p className="d-flex medium-font-size gray mt-2">
                <p className="bolder me-1 medium-font-size">9\10</p> Very Good
                (2232) reviews{" "}
              </p>
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "flex-start", md: "flex-end" }}
              flexDirection="column"
            >
              <Badge title="We have 3 left at" className="m-0 teal" />

              <h2 className="bolder sub-main-title mt-1 ">
                {hotel?.pricing?.currency}{" "}
                {hotel?.pricing?.priceBeforeTax !== "NaN" &&
                hotel?.pricing?.priceBeforeTax
                  ? hotel?.pricing?.priceBeforeTax
                  : hotel?.pricing?.priceAfterTax}
              </h2>

              {hotel?.pricing?.priceBeforeTax && (
                <>
                  <p className="small-font-size">
                    {hotel?.pricing?.currency} {hotel?.pricing?.priceAfterTax}{" "}
                    total
                  </p>
                  <p className="small-font-size">includes tax and fees</p>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default HotelCard;
