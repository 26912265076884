import { useState } from "react";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { IconButton, Typography, Box } from "@mui/material";
import { ReactComponent as NetherLands } from "assets/images/nl.svg";
import { ReactComponent as Suriname } from "assets/images/sr.svg";
import { ReactComponent as Curacao } from "assets/images/cw.svg";
import AppDialog from "./dialog";
import { PHONE_NUMBERS } from "constants/phoneNumbers";

const HelpDesk = () => {
  const [open, setOpen] = useState(false);

  const style = {
    position: "fixed",
    bottom: "32px",
    right: "32px",
    border: "0.5px rgb(229, 229, 229)",
    borderRadius: "10px",
    flexDirection: "column",
    bgcolor: "#fff",
    display: "flex",
    zIndex: 1000,
    boxShadow:
      "rgba(37, 42, 49, 0.16) 0px 0px 2px 0px, rgba(37, 42, 49, 0.12) 0px 1px 4px 0px",
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleOpenWhatsApp = (country) => {
    const isMobile = window.innerWidth <= 768;
    const phoneNumber = PHONE_NUMBERS[country];
    window.open(
      isMobile
        ? `https://api.whatsapp.com/send?phone=${phoneNumber}`
        : `https://web.whatsapp.com/send?phone=${phoneNumber}`
    );
  };

  const btnStyle = {
    border: "1px solid #f2f2f2",
    "& svg": { width: "30px", height: "30px" },
  };
  return (
    <>
      <IconButton sx={style} onClick={handleOpenModal}>
        <WhatsAppIcon sx={{ color: "#25D366" }} />

        <Typography fontSize="11px">Help Desk</Typography>
      </IconButton>

      <AppDialog open={open} onClose={handleCloseModal}>
        <Box>
          <Typography variant="h6">
            Feel free to contact us on WhatsApp:
          </Typography>
          <Box
            display="flex"
            gap={2}
            alignItems="center"
            justifyContent="center"
            p={2}
          >
            <IconButton
              size="large"
              sx={btnStyle}
              onClick={() => handleOpenWhatsApp("SURINAME")}
            >
              <Suriname />
            </IconButton>
            <IconButton
              size="large"
              sx={btnStyle}
              onClick={() => handleOpenWhatsApp("NETHERLANDS")}
            >
              <NetherLands />
            </IconButton>

            <IconButton
              size="large"
              sx={btnStyle}
              onClick={() => handleOpenWhatsApp("CURACAO")}
            >
              <Curacao />
            </IconButton>
          </Box>
        </Box>
      </AppDialog>
    </>
  );
};

export default HelpDesk;
