import React from "react";
import { ReactComponent as ArrowDown } from "assets/images/arrowDown.svg";
import { MenuItem } from "@mui/material";
import "./style.css";

export default function SelectLabels({
  options,
  label,
  mandatory,
  error,
  onChange,
  placeholder,
  value,
  name,
  disabled = false,
}) {
  return (
    <div className="select-labels-container">
      {label && (
        <label>
          {mandatory && <span className="mandatory-sign">*</span>}
          {label}
        </label>
      )}
      <select
        onChange={onChange}
        className={`select-ele ${error && "error"}`}
        name={name}
        disabled={disabled}
      >
        <option value="" disabled="">
          {value ?? placeholder ?? "Select"}
        </option>
        {options?.map((item, index) => (
          <option value={item} key={index}>
            {item}
          </option>
        ))}
      </select>
      <div className="select-ele-icon">
        <ArrowDown />
      </div>
      {error && <span className="error-msg">{error}</span>}
    </div>
  );
}
