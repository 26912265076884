import React from "react";

const PassportLoading = ({ image, fileType }) => {
  console.log("image", image);

  const isImage = fileType.startsWith("image/");
  const isPdf = fileType === "application/pdf";

  return (
    <div className="modal-scanner">
      <div className="ocrloader">
        <div>
          {isPdf ? (
            <iframe
              src={image}
              frameborder="0"
              seamless="seamless"
              style={{
                width: "370px",
                height: "250px",
                border: "none",
                overflow: "hidden",
                scroll: "none",
              }}
            />
          ) : (
            <img className="passport" src={image} width="350" height="250" />
          )}
        </div>
        <p>Scanning</p>
        <em></em>
        <span></span>
      </div>
    </div>
  );
};

export default PassportLoading;
