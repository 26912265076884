import React from "react";
import "./style.css";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import BannerInput from "../bannerInput";

const ContainerSearch = ({
  onChange,
  value,
  onClick,
  placeholder,
  options,
  loading,
  name,
}) => {
  return (
    <div className="container-search">
      <BannerInput
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        autoFocus
      />
      {/* <p className="container-search-title">Your Locations</p>
      <ListItem button className="primary-text">
        <ListItemText primary={"Test"} secondary="Jan 9, 2014" />
        <div className="box-plus">+</div>
      </ListItem>
      <p className="container-search-title">Near You</p> */}

      {loading ? (
        <Stack spacing={1} px={2}>
          {[...Array(2)].map((item, index) => (
            <Skeleton key={index} height="30px" variant="text" width="100%" />
          ))}
        </Stack>
      ) : options?.length === 0 ? (
        <ListItem className="primary-text">
          <ListItemText primary={"Example ‘Amsterdam’ "} />
        </ListItem>
      ) : (
        <div className="result-search-wrapper">
          {options?.map((item, index) => (
            <ListItem
              key={index}
              className="primary-text"
              onClick={() => onClick(item)}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  background: "#f1f1f1",
                },
              }}
            >
              <ListItemText
                primary={item?.cityName}
                secondary={item?.airportName}
              />
              <div className="box-plus">+</div>
            </ListItem>
          ))}
        </div>
      )}
    </div>
  );
};

export default ContainerSearch;
