import { useState } from "react";

import Box from "@mui/material/Box";
import {
  IconButton,
  Typography,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "../dialog";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getUserFromLocalStorage,
  getCurrencyInLocalStorage,
} from "hooks/LocalStorage";

import { getCurrentCountry } from "utils/Utils";

import Iconify from "components/iconify";

import {
  allAppCurrencies,
  defaultLanguage,
  defaultCurrency,
  appLanguages,
} from "utils/Currency&Language";

import { changeCurrencyInLocalStorage } from "hooks/LocalStorage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CurrencyModal({
  open,
  onCloseModal,
  onHandleChangeCurrentCurrency = { setCurrency },
}) {
  const [lang, setLang] = useState("English");
  const [currency, setCurrency] = useState(
    JSON.parse(getCurrencyInLocalStorage())?.currency ||
      defaultCurrency?.currency
  );

  let navigate = useNavigate();
  let location = useLocation();

  const handleUpdateCurrency = (selectedCurrency) => {
    // setCurrency(selectedCurrency);
    changeCurrencyInLocalStorage(selectedCurrency);

    // Redirect to search page when change currency
    const getCurrentPathname = window.location.pathname.split("/")[1];
    if (
      getCurrentPathname === "booking" ||
      getCurrentPathname === "confirmation"
    ) {
      navigate(location.state.prevPath);
    }
  };

  const handleChangeLang = (e) => {
    setLang(e.target.value);
  };
  const handleChangeCurrency = (e) => {
    setCurrency(e.target.value);
  };

  const handleSubmit = () => {
    const getCurrency = allAppCurrencies.find(
      (option) => option.currency === currency
    );
    handleUpdateCurrency(getCurrency);
    onHandleChangeCurrentCurrency(getCurrency);
    onCloseModal();
  };

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <IconButton
          onClick={onCloseModal}
          size="small"
          sx={{ position: "absolute", right: "14px", top: "10px" }}
        >
          <CloseIcon sx={{ width: "18px" }} />
        </IconButton>
        <Typography id="modal-modal-title" variant="h5" fontWeight="800">
          Language and currency
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          Choose your preferred language and currency
        </Typography>

        <Box>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            mt={2}
          >
            <Typography fontWeight="bold">Language</Typography>
            <TextField
              select
              onChange={handleChangeLang}
              value={lang}
              variant="outlined"
              size="small"
              sx={{
                boxShadow: "rgb(186 199 213) 0px 0px 0px 1px",
                borderRadius: "5px",
                fontSize: "12px",
                "& input::placeholder": {
                  fontSize: "14px",
                },
              }}
            >
              {appLanguages.map((option) => (
                <MenuItem key={option.language} value={option.language}>
                  <Iconify
                    icon={`circle-flags:${option.code?.toLowerCase()}`}
                    sx={{ mr: 1 }}
                  />

                  {option.language}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            mt={2}
          >
            <Typography fontWeight="bold">Currency</Typography>
            <TextField
              select
              onChange={handleChangeCurrency}
              value={currency}
              variant="outlined"
              size="small"
              sx={{
                boxShadow: "rgb(186 199 213) 0px 0px 0px 1px",
                borderRadius: "5px",
                fontSize: "12px",
                "& input::placeholder": {
                  fontSize: "14px",
                },
              }}
            >
              {allAppCurrencies.map((option) => (
                <MenuItem key={option.currency} value={option.currency}>
                  {option.currency}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: 3 }}
            fullWidth
          >
            Save & continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
