import { useState } from "react";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Radio from "@mui/material/Radio";
import { ReactComponent as Cabinbaggage } from "assets/images/cabinbaggage.svg";
import { ReactComponent as Checkedbaggage } from "assets/images/checkedbaggage.svg";
import { capitalizeFirstLetter } from "utils/Utils";

const FilterCollapse = ({
  collapseChildren,
  headerTitle,
  onChange,
  hasImg,
  airlineCarrier,
  includedCarrierCodes,
}) => {
  const [open, setOpen] = useState(true);
  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        my: 1,
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton onClick={() => setOpen((prev) => !prev)}>
        <ListItemText className="primary-text" primary={headerTitle} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {collapseChildren.map((item, index) => (
            <div className="item-flex mb-8" key={index}>
              <Radio
                onClick={() => onChange(item)}
                checked={
                  hasImg ? includedCarrierCodes.includes(item) : item.checked
                }
              />
              <div className="pass-users flex-row">
                {hasImg && item && (
                  <img
                    className="results-card-logo logos-collapse"
                    src={airlineCarrier[item]?.carrierLogo[0]?.squareImage}
                  />
                )}

                <p>
                  {hasImg && item
                    ? capitalizeFirstLetter(airlineCarrier[item]?.carrierName)
                    : item.value}
                </p>
              </div>
            </div>
          ))}
        </List>
      </Collapse>
    </List>
  );
};

export default FilterCollapse;
