import { createContext, useState, useEffect } from "react";

export const CurrencyContext = createContext();

import { allAppCurrencies, defaultCurrency } from "utils/Currency&Language";

const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState();

  useEffect(() => {
    // localStorage.clear();
    getCurrencyInLocalStorage();
  }, []);

  const getCurrencyInLocalStorage = async () => {
    const currencyLocalStorage = await JSON.parse(
      localStorage.getItem("satyam-currency")
    );

    if (currencyLocalStorage === null) {
      await localStorage.setItem(
        "satyam-currency",
        JSON.stringify(defaultCurrency)
      );
      setCurrency(defaultCurrency);
    } else {
      const getCurrentCurrency = allAppCurrencies?.find(
        (item) =>
          item.currency ===
          JSON.parse(localStorage.getItem("satyam-currency"))?.currency
      );

      setCurrency(getCurrentCurrency);
    }
  };

  return (
    <CurrencyContext.Provider value={[currency, setCurrency]}>
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
