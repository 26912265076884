import Box from "@mui/material/Box";

export const FlexBetween = ({ children, ...rest }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      {children}
    </Box>
  );
};
