import { ReactComponent as US } from "assets/images/us.svg";
import { ReactComponent as EU } from "assets/images/eu.svg";
import { ReactComponent as SR } from "assets/images/sr.svg";
import { ReactComponent as CW } from "assets/images/cw.svg";

import { AsYouType } from "libphonenumber-js";

export const defaultCurrency = {
  country: ["NL"],
  currency: "EUR",
  sign: " € ",
};

export const defaultLanguage = { language: "English" };

export const allAppCurrencies = [
  { code: "US", country: ["SR", "US"], currency: "USD", sign: " $ " },
  { code: "NL", country: ["NL"], currency: "EUR", sign: " € " },
  // { currency: "SRD", sign: " SRD " },
  // { currency: "ANG", sign: " ANG " },
];

export const appLanguages = [
  { language: "English", code: "US" },
  // { language: "Dutch", code: "NL" },
];

export const getFlag = (currency) => {
  const flags = {
    USD: <US />,
    EUR: <EU />,
    // SRD: <SR />,
    // ANG: <CW />,
  };

  return flags[currency];
};

export const getCountryCode = (input) => {
  // Set default country code to US if no real country code is specified
  const defaultCountryCode = input.substr(0, 1) !== "+" ? "US" : null;
  let formatted = new AsYouType(defaultCountryCode).input(input);
  let countryCode = "";
  let withoutCountryCode = formatted;

  if (defaultCountryCode === "US") {
    countryCode = "+1";
    formatted = "+1 " + formatted;
  } else {
    const parts = formatted.split(" ");
    countryCode = parts.length > 1 ? parts.shift() : "";
    withoutCountryCode = parts.join(" ");
  }

  return {
    formatted,
    withoutCountryCode,
    countryCode: countryCode?.replace("+", ""),
  };
};
