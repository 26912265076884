import React from "react";
import { ReactComponent as AirPlane } from "assets/images/airplate.svg";
import { ReactComponent as Dot } from "assets/images/dot.svg";
import { ReactComponent as Arrow } from "assets/images/arrow.svg";
import moment from "moment";

import Badge from "../badge";

function FlightDetails({ item }) {
  return (
    <div className="result-card-content">
      <div className="flex-box mb-12">
        <p>
          <strong>Airline</strong> :{" "}
          <img
            alt="airline"
            className="airline-logo"
            src={item?.airlineLogos?.squareImage}
          />{" "}
          {item?.airlineIata}
        </p>
        <Badge className={item.status} title={item.status} />
      </div>
      <p className="result-card-time">
        {moment(item?.depTime).format("ddd DD MMM")}
      </p>
      <div>
        <div className="result-card-slice-title">
          <div className="arrow-wrapper">
            <div className="arrow"></div>
            <Dot />
          </div>

          <div className="result-card-place">
            <strong>
              <time>{moment(item?.depTime).format("H:mm A")}</time>{" "}
              <span>{item?.departureCity}</span>
            </strong>
            <div className="gray-color"> {item?.departureAirport}</div>
          </div>
        </div>
        <div className="result-card-slice-title">
          <div className="arrow-wrapper">
            <div className="arrow"></div>
            <div className="trip-icon">
              <AirPlane className="icon-180" />
            </div>

            <div className="arrow bottom"></div>
          </div>
          {/* <div className="badges">
            <Badge Icon={AirPlane} className="icon" />
          </div> */}
        </div>
        <div className="result-card-slice-title">
          <div className="arrow-wrapper">
            <div className="arrow bottom"></div>
            <Dot />
          </div>

          <div className="result-card-place">
            <strong>
              <time>{moment(item?.arrTime).format("H:mm A")}</time>{" "}
              <span>{item?.arrivalCity}</span>
            </strong>
            <div className="gray-color"> {item?.arrivalAirport} </div>
          </div>
        </div>
        <p className="result-card-time arrival-date">
          {" "}
          {moment(item?.arrTime).format("ddd DD MMM")}
        </p>
      </div>
    </div>
  );
}

export default FlightDetails;
