import React, { useState, useContext } from "react";
import OtpInput from "react-otp-input";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

import Button from "../button";

import "./style.css";

import { verifyCode } from "api/Auth";
import {
  storeUserInLocalStorage,
  changeCurrencyInLocalStorage,
  getUserFromLocalStorage,
} from "hooks/LocalStorage";

import { getCurrentCountry } from "utils/Utils";
import { CurrencyContext } from "pages/useContext";

function VerifyCode({ email, handleClose }) {
  const [Otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const [sucess, setSuccess] = useState("");
  const [currecny, setCurrency] = useContext(CurrencyContext);

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const handelSubmit = async () => {
    setErr("");
    setSuccess("");
    if (!Otp) {
      setErr(
        "Please enter the verification code you received over email or SMS"
      );
      return;
    }
    setLoading(true);
    const res = await verifyCode({ email, verificationToken: Otp });
    if (!res.ok) {
      setErr(res?.data?.errorDetails);
    } else {
      setSuccess(res?.data?.message);

      const getTokens = res?.data?.authenticationToken;

      const token = {
        authenticationToken: getTokens?.authenticationToken,
        refreshToken: getTokens.refreshToken,
      };

      storeUserInLocalStorage(token);
      const getTokenData = getUserFromLocalStorage();

      window.dispatchEvent(new Event("storage"));

      const checkCountry = getCurrentCountry(getTokenData.registeredFrom);

      if (checkCountry) {
        setCurrency(checkCountry);
        changeCurrencyInLocalStorage(checkCountry);
      }
      setTimeout(() => handleClose(), 1500);
    }
    setLoading(false);
  };

  return (
    <div>
      <h1 className="main-title">Verify Code</h1>
      {err && (
        <Alert severity="error" sx={{ mb: 1 }}>
          {err}
        </Alert>
      )}
      {sucess && (
        <Alert severity="success" sx={{ mb: 1 }}>
          {sucess}
        </Alert>
      )}
      <OtpInput
        value={Otp}
        onChange={handleChange}
        numInputs={4}
        shouldAutoFocus={true}
        inputStyle="input-otp"
        containerStyle="input-otp-container"
      />

      <p className="gray medium-font-size text-center mb-16">
        Have not recieved any email? <u onClick={handelSubmit}>Resend</u>
      </p>

      <Button
        onClick={handelSubmit}
        type="submit"
        className="bigBtn w-100 primary-color"
      >
        {loading ? (
          <CircularProgress sx={{ color: "#fff" }} size={22} />
        ) : (
          "Submit"
        )}
      </Button>
    </div>
  );
}

export default VerifyCode;
