import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Button from "components/button";

import { getJobs } from "api/Jobs";
import useApi from "hooks/useApi";

import "./style.css";

const Jobs = () => {
  const getJobsList = useApi(getJobs);
  let navigate = useNavigate();

  const [locations, setLocations] = useState({
    selected: null,
    options: [],
  });

  const [jobsTitle, setJobsTitle] = useState({
    selected: null,
    options: [],
  });

  const [departments, setDepartments] = useState({
    selected: null,
    options: [],
  });

  const language = "en";

  useEffect(() => {
    handleFetchJobs();
  }, []);

  const handleFetchJobs = async () => {
    const body = {
      title: jobsTitle.selected,
      department: departments.selected,
      location: locations.selected,
    };
    getJobsList.request({
      language,
      body,
    });
  };

  const handelNavigate = (item) => {
    navigate(`/job/${item?.careerId}`, {
      state: {
        careerDetails: item,
      },
    });
  };

  useEffect(() => {
    setLocations({
      ...locations,
      options: getJobsList?.data?.filterKeys?.location,
    });
    setJobsTitle({
      ...jobsTitle,
      options: getJobsList?.data?.filterKeys?.title,
    });
    setDepartments({
      ...departments,
      options: getJobsList?.data?.filterKeys?.department,
    });
  }, [getJobsList?.data]);

  const handleReset = () => {
    setLocations({
      ...locations,
      selected: null,
    });
    setJobsTitle({
      ...jobsTitle,
      selected: null,
    });
    setDepartments({
      ...departments,
      selected: null,
    });
    const body = {
      title: null,
      department: null,
      location: null,
    };
    getJobsList.request({
      language,
      body,
    });
  };

  return (
    <div className="job-page job-details">
      <div className="home-page">
        <div className="banner banner-bg row m-0">
          <div className="home-image" style={{ minHeight: "340px" }}>
            <img src={require("assets/images/job-banner.png")} alt="Home" />
          </div>
          <div className="container">
            {/* <h1>Jobs and Career</h1>
            <p className="banner-sub-title mb-16">Find your dream job now.</p> */}
            <div className="card">
              <div className="d-flex flex-wrap">
                <div className="d-flex banner-content">
                  <div className="block-group">
                    <div className="block-group-item position-relative">
                      <Select
                        value={"job"}
                        defaultValue=""
                        renderValue={() => jobsTitle?.selected ?? "Select Job"}
                        onChange={(e) =>
                          setJobsTitle({
                            ...jobsTitle,
                            selected: e.target.value,
                          })
                        }
                        sx={{
                          boxShadow: "rgb(186 199 213) 0px 0px 0px 1px",
                          borderRadius: "3px",
                          height: "45px",
                          width: "100%",
                          fontSize: "14px",
                          mb: 2,
                          "& .MuiSelect-outlined": {
                            padding: "11.5px 14px",
                            fontSize: "14px",
                          },
                        }}
                      >
                        {jobsTitle?.options?.length === 0 && (
                          <MenuItem
                            sx={{ fontStyle: "italic", color: "text.disabled" }}
                          >
                            No Results found
                          </MenuItem>
                        )}
                        {jobsTitle?.options?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="block-group-item position-relative">
                      <Select
                        value={"job"}
                        renderValue={() =>
                          departments?.selected ?? "Select Department"
                        }
                        onChange={(e) =>
                          setDepartments({
                            ...departments,
                            selected: e.target.value,
                          })
                        }
                        sx={{
                          boxShadow: "rgb(186 199 213) 0px 0px 0px 1px",
                          borderRadius: "3px",
                          height: "45px",
                          width: "100%",
                          fontSize: "14px",
                          mb: 2,
                          "& .MuiSelect-outlined": {
                            padding: "11.5px 14px",
                            fontSize: "14px",
                          },
                        }}
                      >
                        {departments?.options?.length === 0 && (
                          <MenuItem
                            sx={{ fontStyle: "italic", color: "text.disabled" }}
                          >
                            No Results found
                          </MenuItem>
                        )}
                        {departments?.options?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="block-group-item position-relative">
                      <Select
                        value={"job"}
                        renderValue={() =>
                          locations?.selected ?? "Select Location"
                        }
                        onChange={(e) =>
                          setLocations({
                            ...locations,
                            selected: e.target.value,
                          })
                        }
                        sx={{
                          boxShadow: "rgb(186 199 213) 0px 0px 0px 1px",
                          borderRadius: "3px",
                          height: "45px",
                          width: "100%",
                          mb: 2,
                          fontSize: "14px",
                          "& .MuiSelect-outlined": {
                            padding: "11.5px 14px",
                            fontSize: "14px",
                          },
                        }}
                      >
                        {locations?.options?.length === 0 && (
                          <MenuItem
                            sx={{ fontStyle: "italic", color: "text.disabled" }}
                          >
                            No Results found
                          </MenuItem>
                        )}
                        {locations?.options?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <button
                    disabled={jobsTitle?.options?.length === 0}
                    className="search-btn btn"
                    onClick={handleFetchJobs}
                  >
                    Search
                  </button>
                  {(jobsTitle.selected ||
                    departments.selected ||
                    locations.selected) && (
                    <Button
                      className="ms-2 mt-0 mb-0 bigBtn"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Container>
          <Box my={4} mt={{ xs: 7, md: 4 }}>
            <div className="featured-job-section">
              {getJobsList?.data?.jobs?.length > 0 && (
                <div className="job-list-heading">
                  <p className="job-title">Job</p>
                  <p className="job-department">Department</p>
                  <p className="job-location">Location</p>
                </div>
              )}

              {getJobsList?.loading ? (
                <div className="skeleton featured-job">
                  <div className="job-title">
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </div>

                  <div className="job-department">
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </div>
                  <div className="job-location">
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </div>
                </div>
              ) : getJobsList?.data?.jobs?.length === 0 ? (
                <div className="empty-data-msg">
                  <h1 className="medium-font-size title-header">
                    At the moment we do not have any open vacancies, please
                    visit us later again!
                  </h1>
                </div>
              ) : (
                getJobsList?.data?.jobs?.map((career, index) => (
                  <div
                    className="featured-job"
                    key={index}
                    onClick={() => handelNavigate(career)}
                  >
                    <p className="job-title">{career?.title}</p>
                    <p className="job-department">{career?.department}</p>
                    <p className="job-location">{career?.location}</p>
                  </div>
                ))
              )}
              {/* <Button className="bigBtn primary-color">Show More</Button> */}
            </div>
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default Jobs;
