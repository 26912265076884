import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import * as Yup from "yup";

import Input from "./bannerInput";
import Button from "./button";

import { changePassword } from "api/Auth";

const ChangePassword = ({ setChangePass }) => {
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [succeed, setSucceeded] = useState(false);

  const validationSchemaEmail = Yup.object().shape({
    oldPassword: Yup.string().min(6).required().label("Old Password"),
    newPassword: Yup.string().min(6).required().label("New Password"),
    confirmPassword: Yup.string()
      .min(6)
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required()
      .label("Confirm Password"),
  });

  const handelSubmitChangePass = async (values) => {
    setLoading(true);
    setErr("");
    setSucceeded(false);
    const body = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };

    const res = await changePassword({
      body,
    });
    if (!res.ok) {
      setErr(res?.data?.errorDetails);
    } else {
      setSucceeded("Password has changed successfully");
    }
    setLoading(false);
  };

  return (
    <>
      {err && (
        <Alert severity="error" sx={{ mb: 1 }}>
          {err}
        </Alert>
      )}

      {succeed && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {succeed}
        </Alert>
      )}
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchemaEmail}
        onSubmit={handelSubmitChangePass}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          values,
          setFieldValue,
        }) => (
          <>
            <Input
              onChange={(e) => {
                const value = e.target.value || "";
                setFieldValue("oldPassword", value);
              }}
              error={touched.oldPassword && errors.oldPassword}
              onBlur={() => setFieldTouched("oldPassword")}
              name={"oldPassword"}
              mandatory={true}
              type="password"
              label="Enter your old Password"
            />

            <hr />

            <Input
              onChange={(e) => {
                const value = e.target.value || "";
                setFieldValue("newPassword", value);
              }}
              error={touched.newPassword && errors.newPassword}
              onBlur={() => setFieldTouched("newPassword")}
              name={"newPassword"}
              mandatory={true}
              type="password"
              label="Enter your new Password"
            />

            <Input
              onChange={(e) => {
                const value = e.target.value || "";
                setFieldValue("confirmPassword", value);
              }}
              error={touched.confirmPassword && errors.confirmPassword}
              onBlur={() => setFieldTouched("confirmPassword")}
              name={"confirmPassword"}
              mandatory={true}
              type="password"
              label="Enter your confirm Password"
            />
            <Box display="inline-flex" gab={2} mt={2}>
              <Button
                type="submit"
                onClick={handleSubmit}
                className="bigBtn primary-color"
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#fff" }} size={22} />
                ) : (
                  "Change Password"
                )}
              </Button>
              <Button onClick={() => setChangePass(false)} className="bigBtn">
                Cancel
              </Button>
            </Box>
          </>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;
