import { useEffect, useState } from "react";

import Stepper from "@mui/material/Stepper";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import ApplyForm from "./steps/apply";
import UploadPassport from "./steps/upload-passport";
import PaymentStep from "./steps/payment";

import { vfsApply } from "api/VFS";

import { isUserLoggedIn } from "hooks/LocalStorage";

//----------------------------------------------------------------

const EntryFee = ({ setClientSecret, setOpenCheckout }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setBody] = useState({});

  const [response, setResponse] = useState();

  const [tikkieUrl, setTikkieUrl] = useState("");

  const steps = ["Apply", "Upload Passport", "Payment"];

  const applyFVS = async (passport) => {
    if (!isUserLoggedIn()) {
      window.location.hash = "auth-required";
    } else {
      setLoading(true);
      setError("");
      setSuccess("");

      const body = new FormData();
      Object.keys(data).forEach((key) => body.append(key, data[key]));
      body.append("image", passport);

      const response = await vfsApply({
        body,
      });

      if (!response.ok) {
        setError("There is something Wrong. Please try again!");
      } else {
        setTikkieUrl(response.data?.savedEntryFee?.tikkieUrl);
        setResponse(response.data?.savedEntryFee);
        setActiveStep(2);
      }
      setLoading(false);
    }
  };

  const handleStep = () => {
    setActiveStep(1);
  };

  const handleOnSuccessPaymentRequest = (client_secret) => {
    setClientSecret(client_secret);
    setOpenCheckout(true);
  };

  // useEffect(() => {
  //   if (!isUserLoggedIn()) {
  //     window.location.hash = "auth-required";
  //   }
  // }, []);

  return (
    <Box bgcolor="#f8f8f8" pb={4}>
      <Box maxWidth="lg" m="auto" pt={5}>
        <Box className="reservation-column-stepper">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box width="fit-content" m="auto" mt={4}>
          {activeStep === 0 ? (
            <ApplyForm setBody={setBody} handleStep={handleStep} />
          ) : activeStep === 1 ? (
            <UploadPassport
              applyFVS={applyFVS}
              loading={loading}
              error={error}
            />
          ) : (
            <PaymentStep
              tikkieUrl={response?.tikkieUrl}
              id={response?.entryFeeId}
              handleOnSuccessPaymentRequest={handleOnSuccessPaymentRequest}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EntryFee;
