import { useState, useRef } from "react";

import Button from "../button";

import { scanPassport } from "api/Utilities";
import ScannerSound from "assets/scanner.mpeg";
import PassportLoading from "./loading";

const PassportField = ({ setErrImg, handlePassportResponse }) => {
  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [fileType, setFileType] = useState("");

  const scanApi = async (result) => {
    setErrImg("");
    setLoading(true);
    var audio = new Audio(ScannerSound);
    audio.play();
    const response = await scanPassport({
      image: result,
    });
    if (response.ok) {
      const travelerData = response?.data?.data;
      handlePassportResponse(travelerData);

      setLoading(false);
      audio.pause();
      audio.currentTime = 0;
    } else {
      setErrImg(response?.data?.errorDetails);
      setLoading(false);
      audio.pause();
      audio.currentTime = 0;
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setFileType(img.type);

      setImage(URL.createObjectURL(img));
      scanApi(img);
    }
  };

  return (
    <>
      <Button onClick={handleClick} className="primary-color">
        Upload your passport
      </Button>
      <input
        style={{ display: "none" }}
        type="file"
        name="myImage"
        ref={hiddenFileInput}
        onChange={onImageChange}
      />
      {loading && <PassportLoading image={image} fileType={fileType} />}
    </>
  );
};

export default PassportField;
