import client from "./client";

const endpoint = "intelligence/";

const TravelersList = ({ searchQuery }) => {
  return client.get(
    `${endpoint}travelers-table-data?searchQuery=${searchQuery}`,
    null
  );
};
export { TravelersList };
