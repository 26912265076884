import React, { useEffect, useState } from "react";

import { Box, IconButton, Typography } from "@mui/material";

import ContainerSearch from "../containerSearch";

import { getAirportByIataCode } from "api/Airport";
import useApi from "hooks/useApi";

import CloseIcon from "@mui/icons-material/Close";

///////////////////////////////////////////////////////

function BannerInputSection({
  placeholder,
  onSelect,
  selected,
  toggleDrawer,
  isMobile,
  show,
  onClick,
  onClose,
  isDestination,
}) {
  const [keyword, setKeyword] = useState("");

  const getAirportByIataCodeApi = useApi(getAirportByIataCode);

  useEffect(() => {
    if (keyword.length >= 2) {
      getAirportByIataCodeApi.request({ keyword });
    }
  }, [keyword]);

  return (
    <>
      <Box position="relative">
        <div
          className="input-group-container"
          onClick={!isMobile ? () => onClick() : () => toggleDrawer()}
        >
          <div className="input-wapper">
            <span className="search-label">{placeholder}</span>
            {selected && (
              <Box
                display="flex"
                alignItems="center"
                bgcolor={isDestination ? "secondary.main" : "primary.main"}
                p={0.5}
                justifyContent="space-between"
                borderRadius={"3px"}
                px={0.8}
              >
                <Typography
                  whiteSpace="nowrap"
                  variant="subtitle2"
                  color="#fff"
                  sx={{
                    overflow: "hidden",
                    maxWidth: "125px",
                    textOverflow: "ellipsis",
                  }}
                >
                  {selected}
                </Typography>

                <IconButton
                  sx={{
                    width: "14px",
                    height: "14px",
                    bgcolor: "rgba(255,255,255,0.2)",
                    ml: 1,
                  }}
                  onClick={onClose}
                >
                  <CloseIcon sx={{ color: "#fff", width: "12px" }} />
                </IconButton>
              </Box>
            )}
          </div>
        </div>

        {show && (
          <ContainerSearch
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder={placeholder}
            options={getAirportByIataCodeApi?.data}
            loading={getAirportByIataCodeApi?.loading}
            onClick={(item) => {
              setKeyword("");
              onSelect(item);
            }}
            readOnly
          />
        )}
      </Box>
    </>
  );
}

export default BannerInputSection;
