import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik } from "formik";
import * as Yup from "yup";

import Input from "./bannerInput";
import Button from "./button";

import { triggerEmailForgotPassword } from "api/Auth";

const ForgotPassword = ({ setForgotPass }) => {
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);

  const validationSchemaEmail = Yup.object().shape({
    email: Yup.string().email().required().label("Email"),
  });

  const handelSubmitForgotPass = async (values) => {
    setLoading(true);
    setErr("");
    const res = await triggerEmailForgotPassword({ email: values.email });
    if (!res.ok) {
      setErr(res?.data?.message);
    } else {
      setSucceeded(true);
    }
    setLoading(false);
  };

  const [succeed, setSucceeded] = useState(false);

  return (
    <div className="login-modal">
      <h1 className="main-title">Forget Password</h1>
      {err && (
        <Alert severity="error" sx={{ mb: 1 }}>
          {err}
        </Alert>
      )}
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchemaEmail}
        onSubmit={handelSubmitForgotPass}
      >
        {({
          handleSubmit,
          errors,
          setFieldTouched,
          touched,

          setFieldValue,
        }) => (
          <>
            {succeed ? (
              <p className="bold">
                Please check your inbox for instructions on how to change your
                password. If you don't see it there, remember to also check your
                spam folder
              </p>
            ) : (
              <>
                <Input
                  onChange={(e) => {
                    const value = e.target.value || "";
                    setFieldValue("email", value.toLowerCase());
                  }}
                  error={touched.email && errors.email}
                  onBlur={() => setFieldTouched("email")}
                  name={"email"}
                  mandatory={true}
                  type="email"
                  label="Enter your Email"
                />

                <Button
                  type="submit"
                  onClick={handleSubmit}
                  className="bigBtn w-100 primary-color"
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#fff" }} size={22} />
                  ) : (
                    "Submit Email"
                  )}
                </Button>

                <p className="gray medium-font-size text-center">
                  <u onClick={() => setForgotPass(false)}>Login</u>
                </p>
              </>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
