import jwt_decode from "jwt-decode";

import { defaultCurrency } from "utils/Currency&Language";

const LocalStorage_Key = "satyam-token";

const storedTokens = localStorage.getItem(LocalStorage_Key);

const storeUserInLocalStorage = (token) => {
  if (token) {
    localStorage.setItem(LocalStorage_Key, JSON.stringify(token));
  }
};

const removeUserFromLocalStorage = () => {
  if (localStorage.getItem(LocalStorage_Key) !== null) {
    localStorage.removeItem(LocalStorage_Key);

    window.dispatchEvent(new Event("storage"));
  }
};

const getUserFromLocalStorage = () => {
  let user;

  // If parsing succeeds, it's an object; otherwise, it's a plain string
  if (localStorage.getItem(LocalStorage_Key) !== null) {
    const parsedValue = JSON.parse(localStorage.getItem(LocalStorage_Key));

    user = jwt_decode(parsedValue?.authenticationToken);
  }

  return user;
};

const isUserLoggedIn = () => {
  if (localStorage.getItem(LocalStorage_Key) !== null) {
    return true;
  }
  return false;
};

const getToken = () => {
  let tokenFromLS = {};

  if (localStorage.getItem(LocalStorage_Key) !== null) {
    const parsedValue = JSON.parse(localStorage.getItem(LocalStorage_Key));

    tokenFromLS = parsedValue;
  }

  return tokenFromLS;
};

// Store Currency in LocalStorage
const storeCurrencyInLocalStorage = () => {
  const currencyLocalStorage = localStorage.getItem("satyam-currency");
  if (!currencyLocalStorage) {
    localStorage.setItem("satyam-currency", JSON.stringify(defaultCurrency));
  }
};

// Update Currency in LocalStorage
const changeCurrencyInLocalStorage = (currency) => {
  localStorage.setItem("satyam-currency", JSON.stringify(currency));
};

// Get Currency in LocalStorage
const getCurrencyInLocalStorage = () => {
  return localStorage.getItem("satyam-currency");
};

export {
  storeCurrencyInLocalStorage,
  storeUserInLocalStorage,
  removeUserFromLocalStorage,
  getUserFromLocalStorage,
  changeCurrencyInLocalStorage,
  getCurrencyInLocalStorage,
  getToken,
  isUserLoggedIn,
};
