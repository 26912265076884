import React from "react";
import Logo from "assets/images/satyamTours.svg";

import "./style.css";
import { Box, Divider, Typography } from "@mui/material";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div>
              <div style={{ textAlign: "center" }}>
                <img src={Logo} />
              </div>
              <div className="social-media">
                <a href="https://www.facebook.com/SatyamHolidays/">
                  <svg
                    className="Icon__StyledIcon-sc-1pnzn3g-0 kEmlNM"
                    viewBox="0 0 24 24"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path d="M5 3h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2zm13 2h-2.5A3.5 3.5 0 0012 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 011-1h2V5z"></path>
                  </svg>
                </a>

                <a href="https://www.instagram.com/satyamholidays_/">
                  <svg
                    className="Icon__StyledIcon-sc-1pnzn3g-0 kEmlNM"
                    viewBox="0 0 24 24"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 01-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 017.8 2zm-.2 2A3.6 3.6 0 004 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 003.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6zm9.65 1.5a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zM12 7a5 5 0 110 10 5 5 0 010-10zm0 2a3 3 0 100 6 3 3 0 000-6z"></path>
                  </svg>
                </a>

                <a href="https://www.linkedin.com/company/satyam-holidays-b-v/">
                  <svg
                    className="Icon__StyledIcon-sc-1pnzn3g-0 kEmlNM"
                    viewBox="0 0 24 24"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path d="M7.746 9.185v10.567H4.21V9.185h3.537zM5.978 4c1.233 0 2 .811 2 1.826-.022 1.036-.767 1.825-1.977 1.825S4 6.862 4 5.826C4 4.812 4.768 4 5.955 4h.023zm3.725 16s.047-9.576 0-10.567h3.537v1.532h-.023c.465-.721 1.303-1.78 3.21-1.78 2.328 0 4.073 1.51 4.073 4.756V20h-3.537v-5.653c0-1.42-.512-2.39-1.792-2.39-.977 0-1.559.653-1.814 1.285-.094.225-.117.54-.117.857V20H9.703z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <ul>
              <h5>Reservations</h5>
              <li>
                <a>Use our online tool</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul>
              <h5>Become a member</h5>
              <li>
                <a>Register now to earn points</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul>
              <h5>Contact us</h5>
              <li>
                <a>Call us on 1611 in Suriname</a>
              </li>
              <li>
                <a>Call us on +31-070-7113625 in Europe</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
