import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";

const QrCodeModal = ({ visible, image, handleClose }) => {
  return (
    <Modal open={visible} onClose={handleClose}>
      <Box className="pop-up-modal" textAlign="center" pb={6}>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 16, top: 14 }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          mb={3}
          fontSize={"18px"}
          textAlign="left"
          mt={4}
          fontWeight="bold"
        >
          Point your phone to this screen to capture the code{" "}
        </Typography>
        <img src={image} width={220} />
      </Box>
    </Modal>
  );
};

export default QrCodeModal;
