import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import PageNotFoundIllustration from "assets/illustrations";

// ----------------------------------------------------------------------

export default function NotFoundPage() {
  return (
    <Container component="main">
      <Stack
        sx={{
          pb: 3,
          m: "auto",
          maxWidth: 400,
          textAlign: "center",
        }}
      >
        <Box my={10}>
          <Box>
            <Typography variant="h3" sx={{ mb: 2 }}>
              Sorry, page not found!
            </Typography>
          </Box>

          <Box>
            <Typography sx={{ color: "text.secondary" }}>
              Sorry, the page you were looking for was not found. Maybe I
              misspelled the URL? Make sure to check the crossbreeding.
            </Typography>
          </Box>

          <Box>
            <PageNotFoundIllustration
              sx={{
                height: 260,
                my: { xs: 5 },
              }}
            />
          </Box>

          <Button href="/" size="large" variant="contained">
            Main Page{" "}
          </Button>
        </Box>
      </Stack>
    </Container>
  );
}
