import client from "./client";
import { getHeaders } from "utils/Utils";

const endpoint = "flight/";

const flightSearch = ({
  originDestinations,
  travelers,
  cabin,
  amaRefId,
  refundableFare,
  maxNumberOfConnections,
  currencyCode,
  includedCheckedBagsOnly,
  includedCarrierCodes,
  source,
  fareType,
  officeId,
}) => {
  const body = {
    currencyCode,
    cabin,
    includedCarrierCodes,
    refundableFare,
    maxNumberOfConnections,
    includedCheckedBagsOnly,
    originDestinations,
    source,
    travelers,
    fareType,
    officeId,
  };

  return client.post(`${endpoint}search`, body, getHeaders(amaRefId));
};

const getCalendar = ({
  originDestinations,
  travelers,
  cabin,
  amaRefId,
  currencyCode,
  refundableFare,
  maxNumberOfConnections,
  includedCheckedBagsOnly,
  includedCarrierCodes,
  fareType,
  officeId,
}) => {
  const body = {
    currencyCode,
    cabin,
    includedCarrierCodes,
    refundableFare,
    includedCheckedBagsOnly,
    maxNumberOfConnections,
    originDestinations,
    travelers,
    fareType,
    officeId,
  };
  return client.post(`${endpoint}calendar`, body, getHeaders(amaRefId));
};

const getValidateFlight = ({ body, amaRefId }) => {
  return client.post(`${endpoint}price`, body, getHeaders(amaRefId));
};

const flightReverse = ({ body, amaRefId }) => {
  return client.post(`${endpoint}reserve`, body, getHeaders(amaRefId));
};

const seatMap = ({ body, amaRefId }) => {
  return client.post(`${endpoint}seat-map`, body, getHeaders(amaRefId));
};

const fareRules = ({ body }) => {
  return client.post(`${endpoint}fare-rules`, body);
};

const sharingLink = ({ url }) => {
  return client.get(`util/shorten-url?url=${url}`);
};

const issueTicket = ({ body }) => {
  return client.post(`${endpoint}issue`, body);
};

export {
  flightSearch,
  getCalendar,
  getValidateFlight,
  flightReverse,
  seatMap,
  sharingLink,
  fareRules,
  issueTicket,
};
