import React from "react";

import { Typography, Box, Container } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Box sx={{ backgroundColor: "#f8f8f8" }} py={8}>
      <Container>
        <Typography variant="h6" fontWeight="bold" mb={3}>
          ALGEMENE VOORWAARDEN SATYAM TOURS B.V.
        </Typography>
        <Box className="card" p={2}>
          <Typography mb={2} variant="subtitle2" ml={2}>
            1. Deze algemene voorwaarden zijn van toepassing op alle (online)
            boekingen die worden gedaan via Satyam Tours B.V.
          </Typography>
          <Typography mb={2} variant="subtitle2" ml={2}>
            2. Satyam Tours B.V. is aangesloten bij de Algemene Nederlandse
            Vereniging van Reisondernemingen (ANVR). Zij hanteert derhalve de
            ANVR-reisvoorwaarden voor zover er in deze algemene voorwaarden geen
            aanvullingen zijn opgenomen. De ANVR-reisvoorwaarden zijn te
            raadplegen via de volgende link:
            <a href="https://www.anvr.nl/reizigersvoorwaarden.pdf">
              https://www.anvr.nl/reizigersvoorwaarden.pdf.
            </a>
          </Typography>
          <Typography mb={2} variant="subtitle2" ml={2}>
            3. Op boekingen van enkelvoudige reisdiensten, zoals de aankoop van
            vliegtickets, zijn de ANVR-boekingsvoorwaarden voor losse
            reisdiensten conform paragraaf 3 en deze algemene voorwaarden van
            toepassing.
          </Typography>
          <Typography mb={2} variant="subtitle2" ml={2}>
            4. Bij het boeken van vliegtickets zijn tevens de algemene
            voorwaarden van de dienstverleners (luchtvaartmaatschappijen) van
            toepassing.{" "}
          </Typography>
          <Typography mb={2} variant="subtitle2" ml={2}>
            5. Indien er wordt gekozen voor een non-refundable vliegticket
            vervalt het recht op restitutie bij een annulering van de boeking.
            Satyam Tours B.V. zal zich in dat geval, desgewenst, inspannen om,
            binnen de marges van de algemene voorwaarden van de dienstverlener,
            het geboekte vliegticket te wijzigen. De (eventuele)
            wijzigingskosten die hieraan verbonden zijn komen volledig voor
            rekening en risico van de reiziger.
          </Typography>
          <Typography mb={2} variant="subtitle2" ml={2}>
            6. Op boekingen die kwalificeren als een gekoppeld reisarrangement
            zijn de ANVR-voorwaarden conform paragraaf 2 en deze algemene
            voorwaarden van toepassing.{" "}
          </Typography>
          <Typography mb={2} variant="subtitle2" ml={2}>
            7. Op boekingen van pakketreizen zijn de ANVR-boekingsvoorwaarden
            voor pakketreizen conform paragraaf 1 en deze algemene voorwaarden
            van toepassing
          </Typography>
          <Typography mb={2} variant="subtitle2" ml={2}>
            8. Satyam Tours B.V. behoudt zich het recht voor om prijsverhogingen
            door te voeren die het gevolg zijn van een verhoging van
            wisselkoersen die voor de pakketreis van belang zijn.
          </Typography>
          <Typography mb={2} variant="subtitle2" ml={2}>
            9. Op boekingen van pakketreizen zijn de voorwaarden van de
            garantieregeling van de Stichting Garantiefonds Reisgelden (SGR)
            eveneens van toepassing. Binnen de grenzen van deze regeling wordt
            aan consumenten aanvullende bescherming geboden in het geval dat
            Satyam Tours B.V. in financiële problemen komt te verkeren. Indien
            dit onverhoopt het geval is kunnen consumenten aanspraak maken op de
            uitvoering of terugbetaling van hun boeking. De SGR-voorwaarden zijn
            te raadplegen via de volgende link:
            <a href="https://www.sgr.nl/wp/reisorganisaties/garantieregeling/">
              https://www.sgr.nl/wp/reisorganisaties/garantieregeling/{" "}
            </a>
          </Typography>
          <Typography mb={2} variant="subtitle2" ml={2}>
            10. Op boekingen van pakketreizen zijn ook de voorwaarden van
            Stichting Calamiteitenfonds van toepassing. Op basis hiervan hebben
            consumenten binnen de grenzen van de geldende voorwaarden recht op
            een compensatie bij calamiteiten. Voor een succesvol beroep op deze
            compensatie moet er sprake zijn van een door een molest of een
            natuurramp veroorzaakte abnormale gebeurtenis. De voorwaarden van de
            Stichting Calamiteitenfonds zijn te raadplegen via de volgende link:
            <a href="https://www.calamiteitenfonds.nl/garantieregeling/">
              https://www.calamiteitenfonds.nl/garantieregeling/.
            </a>
          </Typography>
          <Typography>
            Satyam Tours B.V. is gevestigd te Den Haag en staat ingeschreven in
            het Handelsregister van de Kamer van Koophandel onder het nummer
            57235341.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
