import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

function HowWeHire() {
  const jobProccess = [
    {
      title: "CV evaluation",
      description:
        "First, we'll carefully evaluate your resume and cover letter to see if we might be a good fit for each other.",

      img: require("assets/images/resume.jpeg"),
    },
    {
      title: "Video call",
      description:
        "If your experience meets the requirements, we'll schedule a video call or a regular call to find out more about each other.",
      img: require("assets/images/video.jpeg"),
    },
    {
      title: "Practical task",
      description:
        "For technical roles, we've prepared various practical tasks. It can be homework or a live coding session. For customer support, we'll test your language skills via an online platform right after the video call.",
      img: require("assets/images/task.webp"),
    },
    {
      title: "Interview",
      description:
        "After you pass the practical task, we'll invite you for 1 or 2 rounds of interviews where you can meet your future manager and find out more about your desired role within our company.",
      img: require("assets/images/offer.webp"),
    },
    {
      title: "Job offer",
      description:
        "At this point, you’ll know a lot about the job and what to expect. It's time for the formal last step, and then we can welcome you on board.",
      img: require("assets/images/interview.webp"),
    },
  ];
  const [goingUp, setGoingUp] = useState(0);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const svg = window.document.querySelector("#svgPath");
    const length = svg?.getTotalLength();
    // start positioning of svg drawing
    svg.style.strokeDasharray = length;

    // hide svg before scrolling starts
    svg.style.strokeDashoffset = length;
  }, []);

  window.addEventListener("scroll", () => {
    const svg = window.document.querySelector("#svgPath");
    const length = svg?.getTotalLength();
    // start positioning of svg drawing

    svg.style.strokeDasharray = length;

    // hide svg before scrolling starts
    svg.style.strokeDashoffset = length;

    const scrollpercent =
      (window.document.body.scrollTop +
        window.document.documentElement.scrollTop) /
      (window.document.documentElement.scrollHeight -
        window.document.documentElement.clientHeight);

    const draw = length * scrollpercent;

    svg.style.strokeDashoffset = length - draw;
  });
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          "& svg": { width: "auto", height: "auto" },
          position: "absolute",
          zIndex: 9,
          left: "50%",
          top: "114px",
        }}
      >
        <svg width="357" height="1900">
          <path
            id="svgPath"
            stroke="#f15e22"
            stroke-width="4px"
            stroke-dasharray="20, 10"
            fill="none"
            d="M268,106C293.6990093311958,156.98197064989517,319.39801866239156,207.96394129979035,282,250C244.6019813376084,292.03605870020965,144.10693468162947,325.1262054507337,89,388C33.89306531837054,450.8737945492663,24.174242611090563,543.5312368972745,18,666C11.82575738890944,788.4687631027255,9.196094874008299,940.7488469601676,13,1056C16.8039051259917,1171.2511530398324,27.04137789287624,1249.4733752620546,71,1351C114.95862210712376,1452.5266247379454,192.6383935544868,1577.3576519916141,242,1659C291.3616064455132,1740.6423480083859,312.40504788917667,1779.0960167714886,268,1879C223.59495211082336,1978.9039832285114,113.74141488880667,2140.258280922432,88,2337C62.25858511119333,2533.741719077568,120.62929255559666,2765.8708595387843,179,2998"
          ></path>

          <path
            id=""
            className="narmol"
            stroke="#fff"
            stroke-width="6px"
            stroke-dasharray="20"
            fill="none"
            d="M268,106C293.6990093311958,156.98197064989517,319.39801866239156,207.96394129979035,282,250C244.6019813376084,292.03605870020965,144.10693468162947,325.1262054507337,89,388C33.89306531837054,450.8737945492663,24.174242611090563,543.5312368972745,18,666C11.82575738890944,788.4687631027255,9.196094874008299,940.7488469601676,13,1056C16.8039051259917,1171.2511530398324,27.04137789287624,1249.4733752620546,71,1351C114.95862210712376,1452.5266247379454,192.6383935544868,1577.3576519916141,242,1659C291.3616064455132,1740.6423480083859,312.40504788917667,1779.0960167714886,268,1879C223.59495211082336,1978.9039832285114,113.74141488880667,2140.258280922432,88,2337C62.25858511119333,2533.741719077568,120.62929255559666,2765.8708595387843,179,2998"
          ></path>
        </svg>
      </Box>

      {/* <Box
        display="flex"
        py={5}
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Box maxWidth="450px">
          <Typography sx={{ fontSize: "80px", lineHeight: "100px" }}>
            Find out where the journey will take you
          </Typography>
        </Box>
        <Box maxWidth="600px">
          <img src={require("assets/images/hire.jpg")} width="100%" />
        </Box>
      </Box> */}
      <Box textAlign="center" my={4}>
        <Typography
          textAlign="center"
          sx={{ color: "#5f738c", fontSize: "20px" }}
          maxWidth="500px"
          lineHeight="1.8em"
          mx="auto"
        >
          We’re on the lookout for someone who not only has the skills but also
          shares our vision. We want you to be just as enthusiastic about
          working here as we are. To fit our culture, you should value
          cooperation over competition, and love a challenge. Every interview is
          a little different, but so are our recruiters, managers, and
          candidates. You won’t ruin your chances by being honest or being
          yourself. So what does the process look like?
        </Typography>
      </Box>
      <Box>
        {jobProccess.map((job, index) => (
          <Box
            key={index}
            display="flex"
            py={5}
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            flexDirection={index % 2 !== 0 ? "row" : "row-reverse"}
          >
            <Box maxWidth="450px">
              <Typography sx={{ fontSize: "25px", position: "relative" }}>
                <Typography
                  component="span"
                  fontWeight="bold"
                  sx={{
                    color: "#f15e22",
                    fontSize: "25px",
                    position: "absolute",
                    left: "-45px",
                  }}
                >
                  0{index + 1}
                </Typography>{" "}
                {job.title}
              </Typography>
              <Typography
                sx={{ color: "#5f738c", fontSize: "16px" }}
                lineHeight="1.8em"
                mt={2}
                maxWidth="400px"
              >
                {job.description}
              </Typography>
            </Box>
            <Box maxWidth="450px">
              <img
                src={job.img}
                width="100%"
                style={{ zIndex: 999, position: "relative" }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
}

export default HowWeHire;
