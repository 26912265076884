import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StarRatings from "react-star-ratings";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

import Button from "./button";

import { submitFeedback } from "api/Feedback";

import { ReactComponent as FeebackSvg } from "assets/images/undraw_feedback_re_urmj.svg";

const Feedback = ({ closeModal }) => {
  const [stars, setStars] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState("success");

  const changeRating = (newRating) => {
    setStars(newRating);
  };

  const handleFeedback = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmitFeedback = async () => {
    setLoading(true);
    setMsg("");
    const body = {
      feedback,
      stars,
    };
    submitFeedback({ body })
      .then(() => {
        setMsg("Thanks for Your Feedback");
        setStatus("success");
        setTimeout(() => closeModal(), 1500);
      })
      .catch((error) => {
        setStatus("error");
        setMsg(
          "There is something wrong with your feedback, please try again!"
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box>
      <Box sx={{ "& svg": { width: "200px", height: "auto" } }}>
        <FeebackSvg />
      </Box>
      <IconButton
        sx={{ position: "absolute", top: 10, right: 10 }}
        onClick={closeModal}
      >
        <CloseIcon />
      </IconButton>

      {msg && (
        <Alert severity={status} sx={{ my: 2 }}>
          {msg}
        </Alert>
      )}
      <Typography textAlign="center" mt={2} variant="h5" fontWeight="bold">
        How would you rate your experience with Satyamtours so far?
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={4}
      >
        <Typography color="rgb(79, 94, 113)">1 – Fully negative</Typography>
        <StarRatings
          rating={stars}
          starRatedColor="rgb(230, 67, 47)"
          numberOfStars={5}
          changeRating={changeRating}
          name="rating"
          starDimension="40px"
          starSpacing="2px"
        />
        <Typography color="rgb(79, 94, 113)">5 – Fully positive</Typography>
      </Box>
      <Box>
        <Typography fontWeight="bold" variant="body2">
          Could you please share the main reasons for your rating? (optional)
        </Typography>
        <div className="input-group-container my-2">
          <div className="input-wapper textarea" onChange={handleFeedback}>
            {" "}
            <textarea className="p-2" placeholder="Type Your Feedback" />
          </div>
        </div>

        <Button
          className="primary-color btn-large bigBtn w-100 ml-auto mt-5 me-0"
          onClick={handleSubmitFeedback}
        >
          {loading ? (
            <CircularProgress sx={{ color: "#fff" }} size={22} />
          ) : (
            "Submit"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default Feedback;
