import { Typography } from "@mui/material";
import { FlexBetween } from "./flex-between";

export const LabelAndValue = ({ label, value, ...rest }) => {
  return (
    <FlexBetween {...rest}>
      <Typography mr={1} variant="subtitle2" fontWeight="normal" component="p">
        {label}
      </Typography>
      <Typography variant="subtitle2" component="p">
        {value}
      </Typography>
    </FlexBetween>
  );
};
