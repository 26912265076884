import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Alert from "@mui/material/Alert";

import Input from "components/bannerInput";
import Button from "components/button";
import AppDialog from "components/dialog";
import ReservedFlightDetails from "components/reservedFlightDetails";

import useApi from "hooks/useApi";
import { getUserFromLocalStorage } from "hooks/LocalStorage";

import { getFlightDetailsByrecordLocator } from "api/Remarks";
import { issueTicket } from "api/Flights";

const FlightDetails = () => {
  const [referenceId, setReferenceId] = useState();
  const [error, setError] = useState();
  const [commission, setCommmission] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [issueTicketErr, setIssueTicketErr] = useState("");
  const [success, setSuccess] = useState();

  const fetchFlightDetailsByrecordLocator = useApi(
    getFlightDetailsByrecordLocator
  );

  const handleChange = (e) => {
    setReferenceId(e.target.value);
  };

  const handleFlightDetails = () => {
    setError("");
    setSuccess("");
    if (!referenceId) {
      setError("Recordlocator is Required");
    } else {
      fetchFlightDetailsByrecordLocator.request({
        referenceId,
      });
    }
  };

  const handleIssueTicket = async () => {
    setLoading(true);
    setIssueTicketErr("");
    if (!commission) {
      setIssueTicketErr("Commission Number is required");
    } else {
      const body = {
        flightOrderId:
          fetchFlightDetailsByrecordLocator.data?.response?.data[0]?.id,
        commission: +commission,
      };
      const response = await issueTicket({
        body,
      });

      if (!response.ok) {
        setIssueTicketErr("There is something wrong!");
        setLoading(false);
      } else {
        setSuccess("The reservation was successfully issued");
        setOpen(false);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <p className="bold main-title">Manage PNR</p>
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}
      <Box display="flex" alignItems="flex-end">
        <Input
          value={referenceId}
          label="Retrieve PNR"
          onChange={handleChange}
          error={error}
        />
        <Button
          onClick={handleFlightDetails}
          disabled={fetchFlightDetailsByrecordLocator.loading}
          loading={fetchFlightDetailsByrecordLocator.loading}
          className="primary-color mb-4 bigBtn m-2"
        >
          Query
        </Button>
      </Box>
      {fetchFlightDetailsByrecordLocator.data?.response?.data[0]?.id &&
        fetchFlightDetailsByrecordLocator.data?.response?.data[0]
          ?.flightOffers[0]?.source === "GDS" &&
        getUserFromLocalStorage()?.type === "ADMIN" && (
          <Button
            className="bigBtn mb-4 me-auto ms-0"
            onClick={() => setOpen(true)}
          >
            Issue The Ticket
          </Button>
        )}

      <hr />

      {fetchFlightDetailsByrecordLocator.data?.response?.data?.length === 0 ||
      fetchFlightDetailsByrecordLocator.error ? (
        <div className="empty-data-msg">
          <h1 className="medium-font-size title-header">
            No Active PNR found !
          </h1>
        </div>
      ) : (
        <>
          {fetchFlightDetailsByrecordLocator.data?.response?.data?.length >
            0 && (
            <ReservedFlightDetails
              loading={fetchFlightDetailsByrecordLocator.loading}
              reservation={
                fetchFlightDetailsByrecordLocator.data?.response?.data[0]
              }
              price={
                fetchFlightDetailsByrecordLocator.data?.response?.data[0]
                  ?.flightOffers[0]?.price
              }
              showRemarks
            />
          )}
        </>
      )}
      <AppDialog
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIssueTicketErr("");
        }}
      >
        <Typography variant="h6" mb={3}>
          Issue The Ticket
        </Typography>
        <Input
          mandatory
          label="Add Commission"
          error={issueTicketErr}
          onChange={(e) => setCommmission(e.target.value)}
          type="number"
          min="0"
        />

        <Button
          className="primary-color bigBtn w-100 mt-4"
          onClick={handleIssueTicket}
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </AppDialog>
    </div>
  );
};

export default FlightDetails;
