import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import Slide from "assets/images/slide4.webp";
import Slide3 from "assets/images/slide3.webp";

export function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      sx={{
        background: "#fff",
        position: "absolute",
        zIndex: 9,
        top: "50%",
        right: "1rem",
        transform: "translateY(-50%)",
      }}
      onClick={onClick}
    >
      <KeyboardArrowRightIcon />
    </IconButton>
  );
}

export function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      sx={{
        background: "#fff",
        position: "absolute",
        zIndex: 9,
        top: "50%",
        left: "1rem",
        transform: "translateY(-50%)",
      }}
      onClick={onClick}
    >
      <KeyboardArrowLeftIcon />
    </IconButton>
  );
}
const ImgSlider = () => {
  const settings = {
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Slider {...settings}>
      <img src={Slide} height="100%" />
      <img src={Slide3} height="100%" />
    </Slider>
  );
};

export default ImgSlider;
