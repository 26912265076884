import client from "./client";

const endpoint = "member";

const getMember = () => client.get(`${endpoint}`);

const updateMember = ({ body }) => client.put(`${endpoint}`, body);

const getReservedFlights = () => client.get(`${endpoint}/all-reservations`);

const getReservedFlightByRecord = ({ recordLocator }) =>
  client.get(`${endpoint}/reservation/${recordLocator}`);

export {
  getMember,
  updateMember,
  getReservedFlights,
  getReservedFlightByRecord,
};
