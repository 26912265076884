import { useState, useRef, useContext, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import ClickAwayListener from "@mui/core/ClickAwayListener";
import Slider from "react-slick";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Skeleton from "@mui/material/Skeleton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Modal from "@mui/material/Modal";

import BannerInput from "components/bannerInput";
import Button from "components/button";
import RoomCard from "components/hotels/room-card";
import ModalTravelers from "components/hotels/travelers-modal";
import RoomModal from "components/hotels/room-modal";
import DatePicker from "components/datePicker";
import SuggestedHotelCard from "components/hotels/suggested-hotel-card";
import { SampleNextArrow, SamplePrevArrow } from "components/hotels/img-slider";
import { Icon } from "components/hotels/hotelIcons";

import Slide from "assets/images/slide3.webp";

import useApi from "hooks/useApi";

import { getHotleRoomsDetails, hotelDescriptiveInfo } from "api/Hotels";

import { CurrencyContext } from "pages/useContext";

const HotelDetails = (props) => {
  const [value, setValue] = useState(0);
  const [show, setShow] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openRoomModal, setOpenRoomModal] = useState(false);
  const [open, setOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const fetchHotelRoomsDetails = useApi(getHotleRoomsDetails);
  const fetchHotelDescriptiveInfo = useApi(hotelDescriptiveInfo);

  const [currency] = useContext(CurrencyContext);

  const { state } = useLocation();
  const navigate = useNavigate();

  const myRef = useRef(null);
  const myRefLocation = useRef(null);
  const myRefAmenties = useRef(null);
  const myRefPolicies = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();
  const executeScrollLocation = () => myRefLocation.current.scrollIntoView();
  const executeScrollAmenties = () => myRefAmenties.current.scrollIntoView();
  const executeScrollPolicies = () => myRefPolicies.current.scrollIntoView();
  // run this function from an event handler or an effect to execute scroll

  const handleChangeTab = (event, newValue) => {
    if (newValue === 1) {
      executeScroll();
    } else if (newValue === 2) {
      executeScrollLocation();
    } else if (newValue === 3) {
      executeScrollAmenties();
    } else if (newValue === 4) {
      executeScrollPolicies();
    }
    setValue(newValue);
  };
  const handleFetchHotelRooms = async () => {
    const body = {
      currencyCode: currency?.currency,
      destination: searchParams.get("destination"),
      startDate: searchParams.get("startDate"),
      endDate: searchParams.get("endDate"),
      hotelCode: searchParams.get("hotelCode"),
      infoSource: searchParams.get("infoSource"),
      roomStayCandidate: searchParams.get("roomStayCandidate"),
      guestCount: searchParams.get("guestCount"),
      children: searchParams.get("children"),
    };
    fetchHotelRoomsDetails.request({
      body,
    });
  };

  const handleFetchHotelDescriptiveInfo = () => {
    const hotelCode = searchParams.get("hotelCode");
    fetchHotelDescriptiveInfo.request({
      hotelCode,
    });
  };

  useEffect(() => {
    if (currency?.currency) {
      handleFetchHotelRooms();
    }
    if (searchParams.get("hotelCode")) {
      handleFetchHotelDescriptiveInfo();
    }
  }, [currency]);

  const handelClose = () => {
    setOpenModal(false);
  };

  const handelShowRoomModal = (boolean) => {
    setOpenRoomModal(boolean);
  };

  const [tabs, setHotel] = useState([
    "Overview",
    "Prices",
    "Locations",
    "Amenities",
    "Policies",
  ]);

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const settings = {
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    rtl: true,
  };
  const settingsImageSlick = {
    ...settings,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    width: { xs: "90%", md: "80%" },
    p: 4,
    maxHeight: "90%",

    height: "80%",
    overflow: "auto",
    "&:focus": {
      outline: "none",
    },
  };
  const handleOpenImagesModal = () => {
    setOpen(true);
  };

  const handleCloseImagesModal = () => {
    setOpen(false);
  };

  const hotelDetails = JSON.parse(searchParams.get("hotel"));
  return (
    <>
      <Box bgcolor="#f8f8f8" pt={2}>
        <Box maxWidth="lg" m="auto">
          <Box>
            <Box
              bgcolor="#fff"
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <a className="bolder" onClick={() => navigate(-1)}>
                {" "}
                {" < "} See all properties
              </a>
              <p className="bolder"> Save</p>
            </Box>
            {fetchHotelRoomsDetails?.loading ? (
              <Box
                display="flex"
                overflow="hidden"
                justifyContent="space-between"
                height="300px"
                sx={{
                  "& img": {
                    border: "1px solid #fff",
                  },
                }}
              >
                <Box
                  width="100%"
                  ml={1}
                  mt={1}
                  display="flex"
                  gap={0.4}
                  border="1px solid #fff"
                  my={0.6}
                >
                  <Skeleton
                    variant="rectangular"
                    width={"60%"}
                    height={"100%"}
                    sx={{
                      marginBottom: 1,
                      bgcolor: "#F1F3F6",
                      borderRadius: "4px",
                    }}
                    animation="wave"
                  />
                  <Skeleton
                    variant="rectangular"
                    width={"40%"}
                    height={"100%"}
                    sx={{
                      marginBottom: 1,
                      bgcolor: "#F1F3F6",
                      borderRadius: "4px",
                    }}
                    animation="wave"
                  />
                </Box>
              </Box>
            ) : (
              fetchHotelDescriptiveInfo?.data?.hotelDescriptiveInfo?.images
                ?.length > 0 && (
                <Box
                  display="flex"
                  overflow="hidden"
                  justifyContent="space-between"
                  height="300px"
                  sx={{
                    "& img": {
                      border: "1px solid #fff",
                      cursor: "pointer",
                      "&:hover": { opacity: 0.9, transition: "0.3 ease" },
                    },
                    "& > img": { flex: 1 },
                  }}
                >
                  {fetchHotelDescriptiveInfo?.data?.hotelDescriptiveInfo?.images
                    ?.length > 0 && (
                    <img
                      src={
                        fetchHotelDescriptiveInfo?.data?.hotelDescriptiveInfo
                          ?.images[0]
                      }
                      alt="Slide"
                      onClick={handleOpenImagesModal}
                    />
                  )}
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    sx={{ width: { xs: "100%", md: "50%" } }}
                  >
                    {fetchHotelDescriptiveInfo?.data?.hotelDescriptiveInfo
                      ?.images?.length > 0 &&
                      fetchHotelDescriptiveInfo?.data?.hotelDescriptiveInfo?.images
                        ?.slice(1, 5)
                        ?.map((image, index) => (
                          <img
                            key={index}
                            onClick={handleOpenImagesModal}
                            src={image}
                            alt="Slide"
                            width="300px"
                            height="150px"
                          />
                        ))}
                  </Box>
                </Box>
              )
            )}
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                position: "sticky",
                top: "0px",
                "& .MuiTabs-scroller": {
                  overflowX: {
                    xs: "scroll !important",
                    md: "hidden  !important",
                  },
                  mb: { xs: "12px !important", md: 0 },
                },
              }}
              zIndex={9}
              bgcolor="#fff"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              px={2}
              py={{ xs: 1, md: 0 }}
            >
              <Tabs
                value={value}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                {tabs?.map((tab, index) => (
                  <Tab label={tab} key={index} />
                ))}
              </Tabs>
              <Button
                className="primary-color"
                onClick={() => window.scrollTo(0, 920)}
              >
                Reserve Room
              </Button>
            </Box>

            <Box
              bgcolor="#fff"
              sx={{ borderRadius: "0rem 0rem 1rem 1rem" }}
              p={3}
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Box>
                <h2 className="bolder title ">
                  {hotelDetails?.basicProperty?.hotelName}
                </h2>
                {fetchHotelDescriptiveInfo?.data?.hotelDescriptiveInfo
                  ?.highlLights?.length > 0 &&
                  fetchHotelDescriptiveInfo?.data?.hotelDescriptiveInfo?.highlLights?.map(
                    (highlLight, index) =>
                      highlLight ? (
                        <Box maxWidth="sm" key={index}>
                          {" "}
                          <p className="pb-2">{highlLight}</p> <hr />
                          <br />{" "}
                        </Box>
                      ) : null
                  )}

                {/* <h2 className="sub-main-title mb-3">8.6/10 Excellent</h2>

                <a href="£" className="app-link">
                  See all 187 reviews{"  > "}
                </a> */}
                {hotelDetails?.basicProperty?.hotelAmenities?.length > 0 && (
                  <>
                    <Box mb={3}>
                      <h2 className="sub-main-title bold mt-3">
                        Popular amenities
                      </h2>
                      <Box display="flex">
                        <Box width="220px">
                          {hotelDetails?.basicProperty?.hotelAmenities?.map(
                            (amenity, index) => (
                              <Box display="flex" mb={2} key={index}>
                                <Icon IconName="pool" />
                                <p className="mx-2">{amenity}</p>
                              </Box>
                            )
                          )}
                        </Box>
                      </Box>
                    </Box>
                    {/* <a href="£" className="app-link">
                      See all {" > "}
                    </a> */}
                  </>
                )}
              </Box>

              <Box>
                <Box sx={{ background: "#fff" }} mb={2}>
                  <Box
                    sx={{ height: "180px", width: { xs: "100%", md: "400px" } }}
                  >
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: "" }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                    >
                      <AnyReactComponent
                        lat={
                          fetchHotelDescriptiveInfo?.data?.hotelDescriptiveInfo
                            ?.position?.latitude
                        }
                        lng={
                          fetchHotelDescriptiveInfo?.data?.hotelDescriptiveInfo
                            ?.position?.longitude
                        }
                        text="My Marker"
                      />
                    </GoogleMapReact>
                  </Box>
                  <p className="my-2">
                    {hotelDetails?.address?.hotelCityName},{" "}
                    {hotelDetails?.address?.hotelAddressLine},{" "}
                    {hotelDetails?.address?.hotelCountryCode} (
                    {hotelDetails?.address?.hotelPostalCode})
                  </p>
                  <a href="£" className="app-link  py-2">
                    View in a map
                  </a>
                </Box>

                {/* <Box display="flex" mb={2}>
                  <LocationOnIcon />
                  <p className="mx-2">
                    {state?.hotelDetails?.address?.hotelCityName}
                  </p>
                </Box>
                <Box display="flex" mb={2}>
                  <LocationOnIcon />
                  <p className="mx-2">
                    {state?.hotelDetails?.address?.hotelAddressLine}
                  </p>
                </Box>
                <Box display="flex" mb={2}>
                  <LocationOnIcon />
                  <p className="mx-2">
                    {state?.hotelDetails?.address?.hotelCountryCode} (
                    {state?.hotelDetails?.address?.hotelPostalCode})
                  </p>
                </Box> */}
                {/* <Box display="flex" mb={2}>
                  <Airplate />
                  <p className="mx-2">Beirut (BEY-Rafic Hariri Intl.)</p>
                </Box> */}

                {fetchHotelDescriptiveInfo?.data?.hotelDescriptiveInfo?.areaInfo
                  ?.length > 0 && (
                  <>
                    <h2 className="sub-main-title bold mb-4">
                      Explore the area
                    </h2>

                    {fetchHotelDescriptiveInfo?.data?.hotelDescriptiveInfo?.areaInfo?.map(
                      (areaInfo, index) => (
                        <Box display="flex" mb={2} key={index}>
                          <LocationOnIcon />
                          <p className="mx-2">
                            {areaInfo?.name} ({areaInfo?.distance}{" "}
                            {areaInfo?.unit})
                          </p>
                        </Box>
                      )
                    )}
                  </>
                )}
              </Box>
            </Box>

            {fetchHotelRoomsDetails?.loading ? (
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                mt={3}
              >
                {[...Array(4)].map((_, index) => (
                  <Grid item md={3} key={index}>
                    <Box key={index} bgcolor="#fff" pb={3} mb={3}>
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={150}
                        sx={{
                          bgcolor: "#F1F3F6",
                          borderRadius: "4px",
                        }}
                        animation="wave"
                      />
                      <Box width="100%" ml={1} mt={1}>
                        <Skeleton
                          variant="text"
                          width={"60%"}
                          height={30}
                          sx={{
                            marginBottom: 1,
                            bgcolor: "#F1F3F6",
                            borderRadius: "4px",
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          variant="text"
                          width={"60%"}
                          height={30}
                          sx={{
                            marginBottom: 1,
                            bgcolor: "#F1F3F6",
                            borderRadius: "4px",
                          }}
                          animation="wave"
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : !fetchHotelRoomsDetails?.data?.hotel ? (
              <div className="empty-data-msg">
                <h1 className="medium-font-size title-header">
                  No available rooms found !
                </h1>
              </div>
            ) : (
              <Box mt={3} ref={myRef}>
                <p className="title bolder mb-2 calendar-date">
                  Choose your room
                </p>
                <ClickAwayListener
                  onClickAway={() => {
                    setShow(null);
                  }}
                >
                  <Grid
                    container
                    columnSpacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={11} md={2.5} className="calendar-date">
                      <BannerInput
                        onFocus={() => {
                          setShow("date");
                        }}
                        placeholder="Check In"
                      />
                      {show === "date" && (
                        <DatePicker
                          onHide={() => {
                            setShow(false);
                          }}
                          show={show}
                          openCalender={false}
                        />
                      )}
                    </Grid>
                    <Grid item xs={11} md={2.5}>
                      <BannerInput
                        placeholder="Check Out"
                        onFocus={() => {
                          setShow("date");
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={5}
                      xs={11}
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      <BannerInput placeholder="Travelers" />
                    </Grid>
                    <Grid item xs={11} md={2}>
                      <Button className="primary-color bigBtn mb-2">
                        Check availability
                      </Button>
                    </Grid>
                  </Grid>
                </ClickAwayListener>
                <Box mt={2} mb={3}>
                  <Grid container spacing={2} alignItems="center">
                    {fetchHotelRoomsDetails?.data?.hotel?.availableRooms?.map(
                      (room, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                          <RoomCard
                            room={room}
                            handelShowRoomModal={handelShowRoomModal}
                            session={
                              fetchHotelRoomsDetails?.data?.hotel?.session
                            }
                            hotelDetails={hotelDetails}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              </Box>
            )}

            {fetchHotelRoomsDetails?.data?.hotel && (
              <Box mt={3} ref={myRef}>
                <p className="title bolder mb-2 calendar-date">
                  You May also like
                </p>

                <Box mt={2} mb={3}>
                  <Slider {...settings}>
                    {fetchHotelRoomsDetails?.data?.hotel?.availableRooms?.map(
                      (room, index) => (
                        <SuggestedHotelCard
                          key={index}
                          room={room}
                          handelShowRoomModal={handelShowRoomModal}
                          session={fetchHotelRoomsDetails?.data?.hotel?.session}
                          hotelDetails={hotelDetails}
                        />
                      )
                    )}
                  </Slider>
                </Box>
              </Box>
            )}
            {/* <Box
              bgcolor="#fff"
              display="flex"
              mb={3}
              px={2}
              py={3}
              flexWrap={{ xs: "wrap", md: "nowrap" }}
              ref={myRefLocation}
              sx={{ borderRadius: "1rem" }}
            >
              <Box mr={{ md: 2 }} mb={1}>
                <h2 className="bolder title">About this area</h2>
                <p className="sub-main-title bold my-1">Sin El Fil</p>
                <p>
                  Located in Al Ghazālah neighborhood, Hilton Beirut Habtoor
                  Grand is connected to a shopping center. Roman Cardo Maximus
                  and Grand Serail are local landmarks, and some of the area's
                  activities can be experienced at Golf Club of Lebanon and
                  Zaitunay Bay. Traveling with kids? Consider Waves Aqua Park,
                  or check out an event or a game at Hippodrome du parc de
                  Beyrouth. Enjoy the area's slopes with downhill skiing and
                  snowboarding, and don't miss out on the snow tubing and
                  snowmobiling.
                </p>
              </Box>
              <Box sx={{ background: "#fff" }} mb={2}>
                <Box
                  sx={{ height: "280px", width: { xs: "100%", md: "400px" } }}
                >
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: "" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                    <AnyReactComponent
                      lat={59.955413}
                      lng={30.337844}
                      text="My Marker"
                    />
                  </GoogleMapReact>
                </Box>
                <p className="my-2">
                  Charles De Gaulle Street Horsh Tabet, Sin El Fil, 55555
                </p>
                <a href="£" className="app-link  py-2 d-block text-center">
                  View in a map
                </a>
              </Box>
            </Box>
            <Box
              ref={myRefAmenties}
              bgcolor="#fff"
              display="flex"
              mb={3}
              px={2}
              py={3}
              sx={{ borderRadius: "1rem" }}
            >
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Box>
                    <h2 className="bolder title ">Property amenities</h2>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8} display="flex" flexWrap="wrap">
                  <Box>
                    <Box display="flex" mb={2}>
                      <AcUnitIcon />
                      <Box mx={1}>
                        <p className="sub-main-title">Internet</p>
                        <p>Available in all rooms: Free WiFi</p>
                        <p>Available in some public areas: Free WiFi</p>
                      </Box>
                    </Box>
                    <Box display="flex" mb={2}>
                      <AcUnitIcon />
                      <Box mx={1}>
                        <p className="sub-main-title">Food and drink</p>
                        <p style={{ maxWidth: "230px" }}>
                          Buffet breakfast available for a fee daily 7:30
                          AM–11:30 AM: USD 25 for adults and USD 12.5 for
                          children
                        </p>
                        <p>4 restaurants</p>
                        <p>2 poolside bars and 5 bars/lounges</p>
                      </Box>
                    </Box>
                    <Box display="flex" mb={2}>
                      <AcUnitIcon />
                      <Box mx={1}>
                        <p className="sub-main-title">Air conditioning</p>
                        <p>Available in all rooms: Free WiFi</p>
                        <p>Available in some public areas: Free WiFi</p>
                      </Box>
                    </Box>
                  </Box>
                  <Box ml={{ md: 2 }}>
                    <Box display="flex" mb={2}>
                      <AcUnitIcon />
                      <Box mx={1}>
                        <p className="sub-main-title">Air conditioning</p>
                        <p>Valet parking on site (USD 10 per night)</p>
                        <p>Wheelchair-accessible parking available</p>
                      </Box>
                    </Box>
                    <Box display="flex" mb={2}>
                      <AcUnitIcon />
                      <Box mx={1}>
                        <p className="sub-main-title">
                          Parking and transportation
                        </p>
                        <p>Available in all rooms: Free WiFi</p>
                        <p>Available in some public areas: Free WiFi</p>
                      </Box>
                    </Box>
                    <Box display="flex" mb={2}>
                      <AcUnitIcon />
                      <Box mx={1}>
                        <p className="sub-main-title">Air conditioning</p>
                        <p>Available in all rooms: Free WiFi</p>
                        <p>Available in some public areas: Free WiFi</p>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              ref={myRefPolicies}
              bgcolor="#fff"
              display="flex"
              mb={3}
              px={2}
              py={3}
              sx={{ borderRadius: "1rem" }}
              flexWrap="wrap"
            >
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Box>
                    <h2 className="bolder title ">Policies</h2>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} display="flex" flexWrap="wrap">
                  <Box>
                    <Box mb={2}>
                      <p className="sub-main-title">Check In</p>
                      <p>Available in all rooms: Free WiFi</p>
                      <p>Available in some public areas: Free WiFi</p>
                    </Box>
                    <Box mb={2}>
                      <p className="sub-main-title">Food and drink</p>
                      <p style={{ maxWidth: "230px" }}>
                        Buffet breakfast available for a fee daily 7:30 AM–11:30
                        AM: USD 25 for adults and USD 12.5 for children
                      </p>
                      <p>4 restaurants</p>
                      <p>2 poolside bars and 5 bars/lounges</p>
                    </Box>
                    <Box mb={2}>
                      <p className="sub-main-title">Air conditioning</p>
                      <p>Available in all rooms: Free WiFi</p>
                      <p>Available in some public areas: Free WiFi</p>
                    </Box>
                  </Box>
                  <Box ml={{ md: 2 }}>
                    <Box mb={2}>
                      <p className="sub-main-title">Check Out</p>
                      <p>Valet parking on site (USD 10 per night)</p>
                      <p>Wheelchair-accessible parking available</p>
                    </Box>
                    <Box mb={2}>
                      <p className="sub-main-title">
                        Parking and transportation
                      </p>
                      <p>Available in all rooms: Free WiFi</p>
                      <p>Available in some public areas: Free WiFi</p>
                    </Box>
                    <Box mb={2}>
                      <p className="sub-main-title">Air conditioning</p>
                      <p>Available in all rooms: Free WiFi</p>
                      <p>Available in some public areas: Free WiFi</p>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}
            <Box mt={3} height={3} />
          </Box>
        </Box>
      </Box>
      <ModalTravelers open={openModal} handelClose={handelClose} />
      <RoomModal open={openRoomModal} handelClose={handelShowRoomModal} />
      <Modal open={open} onClose={handleCloseImagesModal}>
        <Box sx={style}>
          <Box>
            <Slider {...settingsImageSlick}>
              {fetchHotelDescriptiveInfo?.data?.hotelDescriptiveInfo?.images?.map(
                (image, index) => (
                  <Box height="590px" key={index}>
                    <img src={image} alt="Slide" width="100%" height="100%" />
                  </Box>
                )
              )}
            </Slider>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default HotelDetails;
