import { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import GoogleMapReact from "google-map-react";

import Button from "components/button";
import BannerInput from "components/bannerInput";

import Branch from "./components/branch";

const ContactUs = () => {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const companyBranches = [
    {
      name: "Head Office",
      address: "Doebestraat # 3 P.O.Box 5145 Kwatta Paramaribo- Suriname S.a.",
      tel: "1611 or +597 436950",
      whatsapp: "+597436950",
      openeingHours:
        "Monday thru Friday 08:30 - 16:30 \n Saturday : 09:30 - 14:00",
      whatsappIamgeQrCode:
        "https://boofcv.org/images/thumb/3/35/Example_rendered_qrcode.png/400px-Example_rendered_qrcode.png",
    },
    {
      name: "Branch Office",
      address: "Grote Combeweg # 125 Combe Markt Complex",
      tel: "1611 or +597 436950",
      whatsapp: "+597436950",
      openeingHours:
        "Monday thru Friday 08:30 - 16:30 \n Saturday : 09:30 - 14:00",
    },
    {
      name: "Branch Office",
      address: "Bridgemall unit 10-06 Meerzorg nr. 284",
      tel: "1611 or +597 436950",
      whatsapp: "+597436950",
      openeingHours:
        "Monday thru Friday 09:00 - 17:00 \n Saturday : 09:30 - 14:00",
      whatsappIamgeQrCode:
        "https://boofcv.org/images/thumb/3/35/Example_rendered_qrcode.png/400px-Example_rendered_qrcode.png",
    },
    {
      name: "Branch Office",
      address: "Indiraghandiweg 274",
      tel: "1611 or +597 436950",
      whatsapp: "+597436950",
      openeingHours:
        "Monday thru Friday 08:30 - 16:30 \n Saturday : 09:30 - 14:00",
      whatsappIamgeQrCode:
        "https://boofcv.org/images/thumb/3/35/Example_rendered_qrcode.png/400px-Example_rendered_qrcode.png",
    },
    {
      name: "Branch Office",
      address: "DoergaMall Emmastraat nr.95",
      tel: "1611 or +597 436950",
      whatsapp: "+597436950",
      openeingHours:
        "Monday thru Friday 08:30 - 16:30 \n Saturday : 09:30 - 14:00",
      whatsappIamgeQrCode:
        "https://boofcv.org/images/thumb/3/35/Example_rendered_qrcode.png/400px-Example_rendered_qrcode.png",
    },
    {
      name: "Branch Office Netherlands",
      address: "Loosduinsekade # 414, 2571 CJ Den Haag",
      tel: "070-7113625 (4 lijnen)",
      whatsapp: "+31707113625",
      openeingHours:
        "Monday thru Friday 09:30 - 16:30 \n Saturday : 10:00 - 14:00",
      whatsappIamgeQrCode:
        "https://boofcv.org/images/thumb/3/35/Example_rendered_qrcode.png/400px-Example_rendered_qrcode.png",
    },
    {
      name: "Branch Office India",
      address:
        "B – 63 / 2, Basement , Paryavaran Complex IGNOU Road ( Near Saket metro station ) New Delhi - 110030",
      tel: "+91 - 11 – 29535690 / 29535691",
      whatsapp: "+91 – 9810621718   +91 – 9810221718   +91 – 9810623898",
      openeingHours: "Monday thru Saturday 10:00 - 19:00",
      whatsappIamgeQrCode:
        "https://boofcv.org/images/thumb/3/35/Example_rendered_qrcode.png/400px-Example_rendered_qrcode.png",
    },

    {
      name: "Branch Office Curacao",
      address: "Mangusa Hypermarket Cas Cora Weg # 43 Unit # 4",
      tel: " +5999 7880134 / 7880139",
      whatsapp: " +59995141040",
      openeingHours:
        "Monday - Friday : 8:30am-12:30am , 13.30pm-16.30pm \n Saturday : 9:00am - 12:30am",
      whatsappIamgeQrCode:
        "https://boofcv.org/images/thumb/3/35/Example_rendered_qrcode.png/400px-Example_rendered_qrcode.png",
    },
  ];

  return (
    <Box sx={{ backgroundColor: "#f8f8f8" }}>
      <Typography variant="h4" fontWeight="bold" textAlign="center" py={6}>
        Contact US
      </Typography>
      <Container>
        <Typography variant="h6" mt={3} my={2} fontWeight="bold">
          Our Branches
        </Typography>
        <Grid container justifyContent="space-between" spacing={2}>
          {companyBranches.map((branch, index) => (
            <Grid item xs={12} md={4} lg={3} key={index}>
              <Branch branch={branch} />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ backgroundColor: "#fff" }} p={2} display="flex" gap={3}>
          <Box flex={1}>
            <Typography variant="h6" mb={2} fontWeight="bold">
              Get in touch
            </Typography>
            <Box
              display="flex"
              gap={3}
              sx={{ "& .input-group-container": { flex: 1 } }}
            >
              <BannerInput label="Your Name" />
              <BannerInput label="Your Email" />
            </Box>
            <div className="input-group-container">
              <label>Your Message</label>
              <div className="input-wapper textarea">
                {" "}
                <textarea rows="5" label="Your Message" />
              </div>
            </div>
            <Button className="bigBtn primary-color w-100 mt-4">Submit</Button>
          </Box>
          <Box sx={{ height: "280px", width: { xs: "100%", md: "500px" } }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              <AnyReactComponent
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
              />
            </GoogleMapReact>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ContactUs;
