import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";

import LandscapeIcon from "@mui/icons-material/Landscape";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import HotelIcon from "@mui/icons-material/Hotel";
import AcUnitIcon from "@mui/icons-material/AcUnit";

import Badge from "../badge";
import AppButton from "../button";
import ImgSlider from "./img-slider";

const RoomModal = ({ open, handelClose, room }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/hotel-payment", { room });
  };

  return (
    <Dialog
      open={open}
      sx={{ maxWidth: "850px", m: "auto" }}
      onClose={() => handelClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog-container"
    >
      <Box p={2}>
        <Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <p className="sub-main-title">Room Information</p>
            <IconButton onClick={() => handelClose(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ cursor: "pointer", "& img": { height: "300px" } }}>
            <ImgSlider />
          </Box>
        </Box>
        <Box py={2}>
          <p className="sub-main-title bolder">Room, 1 King Bed</p>

          <Box display="flex" mb={1} alignItems="center">
            <SquareFootIcon />
            <p className="mx-2">409 sq ft</p>
          </Box>
          <Box display="flex" mb={1} alignItems="center">
            <LandscapeIcon />
            <p className="mx-2">Mountain view</p>
          </Box>
          <Box display="flex" mb={1} alignItems="center">
            <HotelIcon />
            <p className="mx-2">2 Twin Beds</p>
          </Box>
        </Box>
        <Box>
          <p className="sub-main-title bolder">Property amenities</p>
          <Box display="flex" mt={2}>
            <Box>
              <Box display="flex" mb={2}>
                <AcUnitIcon />
                <Box mx={1}>
                  <p className="medium-font-size">Internet</p>
                  <p>Available in all rooms: Free WiFi</p>
                  <p>Available in some public areas: Free WiFi</p>
                </Box>
              </Box>
              <Box display="flex" mb={2}>
                <AcUnitIcon />
                <Box mx={1}>
                  <p className="medium-font-size">Food and drink</p>
                  <p style={{ maxWidth: "230px" }}>
                    Buffet breakfast available for a fee daily 7:30 AM–11:30 AM:
                    USD 25 for adults and USD 12.5 for children
                  </p>
                  <p>4 restaurants</p>
                  <p>2 poolside bars and 5 bars/lounges</p>
                </Box>
              </Box>
              <Box display="flex" mb={2}>
                <AcUnitIcon />
                <Box mx={1}>
                  <p className="medium-font-size">Air conditioning</p>
                  <p>Available in all rooms: Free WiFi</p>
                  <p>Available in some public areas: Free WiFi</p>
                </Box>
              </Box>
            </Box>
            <Box ml={{ md: 2 }}>
              <Box display="flex" mb={2}>
                <AcUnitIcon />
                <Box mx={1}>
                  <p className="medium-font-size">Air conditioning</p>
                  <p>Valet parking on site (USD 10 per night)</p>
                  <p>Wheelchair-accessible parking available</p>
                </Box>
              </Box>
              <Box display="flex" mb={2}>
                <AcUnitIcon />
                <Box mx={1}>
                  <p className="medium-font-size">Parking and transportation</p>
                  <p>Available in all rooms: Free WiFi</p>
                  <p>Available in some public areas: Free WiFi</p>
                </Box>
              </Box>
              <Box display="flex" mb={2}>
                <AcUnitIcon />
                <Box mx={1}>
                  <p className="medium-font-size">Air conditioning</p>
                  <p>Available in all rooms: Free WiFi</p>
                  <p>Available in some public areas: Free WiFi</p>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <p className="sub-main-title bolder">Room Options</p>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Radio size="small" />
              <p>Non-Refundable</p>
            </Box>

            <p>$230</p>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Radio size="small" />
              <p>Non-Refundable</p>
            </Box>

            <p>$230</p>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Radio size="small" />
              <p>Non-Refundable</p>
            </Box>

            <p>$230</p>
          </Box>
        </Box>
        <Box pt={2}>
          <p className="sub-main-title mb-2">Prices</p>

          <Badge title="12% off" className="m-0 teal" />
          <p className="sub-main-title bolder my-1"> $230</p>
          <p>$311 total</p>
          <p>includes taxes & fees</p>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <a href="£" className="app-link">
              Price Details{"  > "}
            </a>
            <AppButton className="primary-color" onClick={handleNavigate}>
              Reserve{" "}
            </AppButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RoomModal;
