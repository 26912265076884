import { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import dayjs from "dayjs";

import { getCountryCode } from "utils/Currency&Language";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

import Button from "components/button";

import AppDialog from "components/dialog";
import AddExistingTravelersModal from "./add-existing-travelers";

function AddExistingTravelers({ index, departureDate }) {
  const [open, setOpen] = useState(false);
  const { setFieldValue, setFieldError } = useFormikContext();

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleSelectExistingTraveler = (traveler) => {
    setFieldValue(`firstName${index}`, traveler?.firstName);
    setFieldValue(`lastName${index}`, traveler?.lastName);
    setFieldValue(`gender${index}`, traveler?.gender);
    setFieldValue(`emailAddress${index}`, traveler?.email);
    setFieldValue(
      `dateOfBirthYear${index}`,
      dayjs(traveler?.dateOfBirth).utc().format("YYYY")
    );
    setFieldValue(
      `dateOfBirthMonth${index}`,
      dayjs(traveler?.dateOfBirth).utc().format("MM")
    );
    setFieldValue(
      `dateOfBirthDay${index}`,
      dayjs(traveler?.dateOfBirth).utc().format("DD")
    );
    setFieldValue(`number${index}`, traveler?.contact?.phoneNumber);

    if (traveler?.documents?.length > 0) {
      const travelerDoc = traveler?.documents[0];
      const documents = [
        {
          number: travelerDoc?.number,
          expiryDate: dayjs(travelerDoc?.expiryDate).utc().format("YYYY-MM-DD"),
          issuanceCountry: travelerDoc?.issuanceCountry,
          nationality: travelerDoc?.nationality,
          documentType: "PASSPORT",
          holder: true,
        },
      ];
      setFieldValue(`documents${index}`, documents);
      setFieldValue(`docNumber${index}`, travelerDoc?.number);

      setFieldValue(
        `countryCallingCode${index}`,
        getCountryCode(traveler?.contact?.phoneNumber)?.countryCode
      );
      setFieldValue(
        `expDateDay${index}`,
        dayjs(travelerDoc?.expiryDate).utc().format("DD")
      );
      setFieldValue(
        `expDateMonth${index}`,
        dayjs(travelerDoc?.expiryDate).utc().format("MM")
      );
      setFieldValue(
        `expDateYear${index}`,
        dayjs(travelerDoc?.expiryDate).utc().format("YYYY")
      );

      let isValid = dayjs(travelerDoc?.expiryDate).diff(
        dayjs(departureDate),
        "month"
      );

      if (isValid < 6) {
        setFieldError(
          `expDateYear${index}`,
          "Expiration Passport Date is Invalid"
        );
      }
    }

    setOpen(false);
  };

  return (
    <>
      <Button
        className="primary-color"
        onClick={() => setOpen(true)}
        variant="contained"
      >
        Add Existing Traveler
      </Button>
      <AppDialog open={open} onClose={handleCloseModal} overflow="hidden">
        <AddExistingTravelersModal onSelect={handleSelectExistingTraveler} />
      </AppDialog>
    </>
  );
}

export default AddExistingTravelers;
