import { Menu, MenuItem, MenuButton, FocusableItem } from "@szhsin/react-menu";

import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import Radio from "@mui/material/Radio";

import { IoIosArrowDown } from "react-icons/io";
import { BiCheck } from "react-icons/bi";
import { ImUser } from "react-icons/im";

import { ReactComponent as Children } from "assets/images/children.svg";
import { ReactComponent as Infants } from "assets/images/infants.svg";
import { ReactComponent as Cabinbaggage } from "assets/images/cabinbaggage.svg";
import { ReactComponent as Checkedbaggage } from "assets/images/checkedbaggage.svg";
import { ReactComponent as Passenger } from "assets/images/passenger.svg";

import ListItem from "components/listItem";
import Button from "components/button";

export default function DropDown({
  activeOption,
  options,
  onClick,
  onClickMinus,
  onClickPlus,
}) {
  return (
    <Menu
      className="main-dropdown"
      menuButton={
        <MenuButton className={`dropdown-btn`}>
          {activeOption}
          <IoIosArrowDown />
        </MenuButton>
      }
      transition
    >
      <div className="passenger-container p-3">
        <h2> {activeOption}</h2>
        <div className="mb-24">
          {options.map((item, index) => (
            <ListItem
              key={index}
              index={index}
              title={item?.value}
              subTitle={item?.subTitle}
              num={item?.num}
              onClickMinus={() => onClickMinus(item.value)}
              onClickPlus={() => onClickPlus(item.value)}
              pass
            />
          ))}
        </div>
      </div>
      <MenuItem className="d-block footer-drop">
        <hr />
        <div className="d-flex justify-content-between">
          <Button>Cancel</Button>
          <Button className="primary-color">Done</Button>
        </div>
      </MenuItem>
    </Menu>
  );
}
