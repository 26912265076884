import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik } from "formik";
import * as Yup from "yup";
import { useParams, Link } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

import Input from "components/bannerInput";

import { changePassword } from "api/Auth";

const ForgotPassword = ({ setForgotPass }) => {
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [succeed, setSucceeded] = useState(false);

  const { token } = useParams();

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .matches(/^(?=.*[A-Z])/, "At least 1 upper case")
      .matches(/^(?=.*[0-9])/, "At least 1 number")
      .min(8, "At least 8 characters")
      .required()
      .label("Password"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Password do not match")
      .matches(/^(?=.*[A-Z])/, "At least 1 upper case")
      .matches(/^(?=.*[0-9])/, "At least 1 number")
      .min(8, "At least 8 characters")
      .required("Password do not match"),
  });

  const handelSubmitForgotPass = async (values) => {
    setLoading(true);
    setErr("");
    const res = await changePassword({
      body: { newPassword: values.newPassword },
      token,
    });
    if (!res.ok) {
      setErr(
        "Please try clicking again on forgot password, your session has been expired"
      );
      setLoading(false);
    } else {
      setSucceeded(true);
      setLoading(false);
    }
  };

  const validatePassword = (value, type) => {
    if (value && type === "uppercase") {
      const regex = new RegExp(/^(?=.*[A-Z])/);

      return regex.test(value);
    }

    if (value && type === "numberError") {
      const regex = new RegExp(/^(?=.*[0-9])/);

      return regex.test(value);
    }

    if (value && type === "lengthError") {
      const regex = new RegExp(/^(?=.*[0-9])/);

      return value.length >= 8;
    }
    return;
  };

  return (
    <Box>
      {succeed ? (
        <Box
          sx={{
            maxWidth: "870px",
            backgroundColor: "white",
            p: 8,
            borderRadius: "20px",
            my: 18,
            border: "1px solid #f2f2f2",
            mx: "auto",
            textAlign: "center",
            gap: 6,
          }}
        >
          <CheckCircleIcon sx={{ color: "success.main", fontSize: "70px" }} />
          <Typography my={2} variant="h5">
            Password Updated!
          </Typography>
          <Typography>Your password has been changed successfully!</Typography>
          <Typography>
            Use your new password to{" "}
            <Link
              style={{ color: "var(--primary-color)", fontWeight: "bold" }}
              to="/#auth-required"
            >
              Login
            </Link>
          </Typography>
        </Box>
      ) : (
        <Formik
          initialValues={{
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handelSubmitForgotPass}
        >
          {({
            handleSubmit,
            errors,
            setFieldTouched,
            touched,
            setFieldValue,
            values,
          }) => (
            <Box
              sx={{
                maxWidth: "870px",
                backgroundColor: "white",

                borderRadius: "20px",
                my: 10,
                mx: "auto",
                pb: 2,
                border: "1px solid #f2f2f2",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: { xs: "inherit", md: "center" },
                  gap: 6,
                  p: 4,
                  flexDirection: { xs: "column", md: "row" },
                  pr: { xs: 7, md: 4 },
                }}
              >
                <Box flex={0.9} width={"100%"} mr={{ xs: 5, md: 0 }}>
                  <Typography fontWeight="bold" variant="h5" mb={3}>
                    Change Password
                  </Typography>
                  {err && (
                    <Alert severity="error" sx={{ mb: 1 }}>
                      {err}
                    </Alert>
                  )}

                  <Box
                    position="relative"
                    sx={{
                      "& .input-wapper": {
                        boxShadow:
                          values?.newPassword &&
                          !errors?.newPassword &&
                          "#4CAF50 0px 0px 0px 1px;",
                      },
                    }}
                  >
                    <Input
                      onBlur={() => setFieldTouched("newPassword")}
                      name={"newPassword"}
                      mandatory={true}
                      label="New Password"
                      error={errors.newPassword}
                      type="password"
                      onChange={(e) => {
                        setFieldValue("newPassword", e.target.value);
                      }}
                    />
                    {values?.newPassword && !errors?.newPassword ? (
                      <CheckCircleIcon
                        sx={{
                          position: "absolute",
                          right: "-35px",
                          top: "35px",
                          color: "success.main",
                        }}
                      />
                    ) : (
                      errors?.newPassword && (
                        <ErrorIcon
                          sx={{
                            position: "absolute",
                            right: "-35px",
                            top: "35px",
                            color: "red",
                          }}
                        />
                      )
                    )}
                  </Box>
                  <Box
                    mt={3}
                    position="relative"
                    sx={{
                      "& .input-wapper": {
                        boxShadow:
                          values?.confirmPassword &&
                          !errors?.confirmPassword &&
                          "#4CAF50 0px 0px 0px 1px;",
                      },
                    }}
                  >
                    <Input
                      onChange={(e) => {
                        setFieldValue("confirmPassword", e.target.value);
                      }}
                      error={errors.confirmPassword}
                      onBlur={() => setFieldTouched("confirmPassword")}
                      name={"confirmPassword"}
                      mandatory={true}
                      label="Confirm New Password"
                      type="password"
                    />
                    {values?.confirmPassword && !errors?.confirmPassword ? (
                      <CheckCircleIcon
                        sx={{
                          position: "absolute",
                          right: "-35px",
                          top: "35px",
                          color: "success.main",
                        }}
                      />
                    ) : (
                      errors?.confirmPassword && (
                        <ErrorIcon
                          sx={{
                            position: "absolute",
                            right: "-35px",
                            top: "35px",
                            color: "red",
                          }}
                        />
                      )
                    )}
                  </Box>
                </Box>
                <Box>
                  <Typography fontWeight="bold" variant="h6" mb={1}>
                    Password must contain:{" "}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textDecoration: validatePassword(
                        values?.newPassword,
                        "uppercase"
                      )
                        ? "line-through"
                        : "none",
                    }}
                    fontWeight={400}
                    color={
                      validatePassword(values?.newPassword, "uppercase")
                        ? "success.main"
                        : "inherit"
                    }
                    mb={1}
                  >
                    At least 1 upper case
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    mb={1}
                    sx={{
                      textDecoration: validatePassword(
                        values?.newPassword,
                        "numberError"
                      )
                        ? "line-through"
                        : "none",
                    }}
                    color={
                      validatePassword(values?.newPassword, "numberError")
                        ? "success.main"
                        : "inherit"
                    }
                  >
                    At least 1 number
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    mb={1}
                    sx={{
                      textDecoration: validatePassword(
                        values?.newPassword,
                        "lengthError"
                      )
                        ? "line-through"
                        : "none",
                    }}
                    color={
                      validatePassword(values?.newPassword, "lengthError")
                        ? "success.main"
                        : "inherit"
                    }
                  >
                    At least 8 characters
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mx: { xs: "auto", md: 4 },
                  width: { xs: "90%", md: "480px" },
                  flex: 0.9,
                }}
              >
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  className="bigBtn  primary-color mt-2"
                  variant="contained"
                  disabled={
                    errors?.newPassword ||
                    errors?.confirmPassword ||
                    !values?.confirmPassword ||
                    !values.newPassword
                  }
                  size="large"
                  fullWidth
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#fff" }} size={22} />
                  ) : (
                    "Change Password"
                  )}
                </Button>
              </Box>
            </Box>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default ForgotPassword;
