import React, { useState, useCallback, useRef, useEffect } from "react";
import moment from "moment";
import LinkIcon from "@mui/icons-material/Link";
import IconButton from "@mui/material/IconButton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import DescriptionIcon from "@mui/icons-material/Description";
import ShareIcon2 from "@mui/icons-material/Share";
import SegmentIcon from "@mui/icons-material/Segment";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import Checkbox from "@mui/material/Checkbox";

import AppModalFlightDetails from "../modal";
import Button from "../button";
import Badge from "../badge";
import BannerInput from "../bannerInput";
import ItineraryComponent from "components/itinerary";

import { FareRulesModal } from "components/app-modals";

import useApi from "hooks/useApi";
import { checkISAdminOrEmployee } from "utils/Utils";

import { getUserFromLocalStorage } from "hooks/LocalStorage";

import { sharingLink, fareRules } from "api/Flights";
import { postFlightTarget } from "api/FlightTarget";

import "./style.css";
import { Typography } from "@mui/material";

const ResultCard = ({
  flight,
  destinationLocationCode,
  originLocationCode,
  dictionaries,
  handeValidatFlight,
  totalPrice,
  currency,
  cardID,
  returnDate,
  departurDate,
  handleOpenCommissionModal,

  flightType,
  officeId,
  flightSearchCriteria,
  getSelectedFLight,
  adjustedPrice,
  setAdjustedPrice,
  checked,
  setChecked,
  handlePrintComponent,
  hasSale,
  itineraries,
}) => {
  const [open, setOpen] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [isOpenRareRulesModal, setOpenRareRulesModal] = useState(false);
  const [openSellingForModal, setOpenSellingForModal] = useState(false);
  const [copy, setCopy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [showPrice, setShowPrice] = useState(false);
  const [isTargetLoading, setTargetLoading] = useState(false);
  const [targetDescription, setTargetDescription] = useState("");
  const [isTargetOpen, setTargetOpen] = useState(false);
  const fareRulesApi = useApi(fareRules);
  const [success, setSuccess] = useState("");

  const url = encodeURIComponent(
    `${window.location.href}&cardNum=${flight?.id}`
  );
  const ref = useRef(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    width: { xs: "90%", md: "400px" },
    p: 4,
    maxHeight: "90vh",
    overflow: "auto",
    "&:focus": {
      outline: "none",
    },
  };

  const handleEditTotalPrice = () => {
    getSelectedFLight(flight);
    setOpenSellingForModal(true);
    // setShowPrice(false);
    setAdjustedPrice(0);
  };

  const handelClickOpen = (boolean) => {
    setOpen(boolean);
  };

  const handleCloseCopyModal = () => {
    setOpenCopyModal(false);
  };

  const handleCloseSellingForModal = () => {
    setShowPrice(true);
    setOpenSellingForModal(false);
    handlePrintComponent();
  };

  const fetchCalendarFareData = async () => {
    setOpenCopyModal(true);
  };

  const handleOpenFareRulesModal = () => {
    setOpenRareRulesModal(true);
  };

  useEffect(() => {
    if (isOpenRareRulesModal) {
      const body = {
        schema: flight,
        isPrivateFare: false,
        ...(officeId && { officeId }),
      };
      fareRulesApi.request({ body });
    }
  }, [isOpenRareRulesModal]);

  const handleShareLink = async () => {
    const res = await sharingLink({ url });
    return res;
  };

  const sharingLinkByUrl = async () => {
    setLoading(true);

    const response = await handleShareLink();

    if (!response.ok) {
      return;
    } else {
      setValue(response?.data?.shortLink);
      setOpenCopyModal(true);

      setCopy(true);
    }
    setLoading(false);
  };

  const handelSelectFlight = () => {
    handeValidatFlight(flight);
  };

  const handleSellingForSurinamePrice = () => {
    const checkIfSellingEdited = adjustedPrice
      ? +adjustedPrice
      : +totalPrice(flight);
    const getPricePercentage = (checkIfSellingEdited * 1.5) / 100;

    const newPrice = (checkIfSellingEdited + getPricePercentage)?.toFixed(2);

    if (checked) return Math.ceil(newPrice);

    return null;
  };

  const handelGetFlightNumbers = () => {
    let numbers = [];

    for (let i = 0; i < flight?.itineraries.length; i++) {
      for (let j = 0; j < flight?.itineraries[i].segments.length; j++) {
        const segment = flight?.itineraries[i].segments[j];
        numbers.push(segment.carrierCode + segment.number);
      }
    }
    return numbers;
  };

  const handlePostTargetFlight = async ({ body }) => {
    const res = await postFlightTarget({ body });

    if (!res.ok) {
      setSuccess("error");
      setTargetLoading(false);
      setTimeout(() => {
        handleCloseTarget();
      }, 1000);
    } else {
      setSuccess("success");
      setTargetLoading(false);
      setTimeout(() => {
        handleCloseTarget();
      }, 1000);
    }
  };

  const handleFlightTarget = async (e) => {
    e.preventDefault();
    if (!targetDescription) {
      setSuccess("error");
    } else {
      setTargetLoading(true);
      setSuccess("");

      const response = await handleShareLink();
      const body = {
        flightNumbers: handelGetFlightNumbers(),
        currency: flightSearchCriteria?.currencyCode,
        initialPrice: totalPrice(flight),
        searchType: "MASTER_PRICER",
        description: targetDescription,
        flightSearchCriteria,
        source: flight.source,
      };

      if (!response.ok) {
        body.requestUrl = url;
        handlePostTargetFlight({ body });
      } else {
        body.requestUrl = response?.data?.shortLink;
        handlePostTargetFlight({ body });
      }
    }
  };

  const handleCloseTarget = () => {
    setTargetOpen(false);
    setSuccess(false);
    setTargetDescription("");
  };

  const handleCloseFareRulesModal = () => {
    setOpenRareRulesModal(false);
  };

  return (
    <>
      <div
        className={`card-wrapper ${
          cardID === flight?.id || hasSale ? "active" : "inactive"
        }`}
        ref={ref}
      >
        {hasSale && (
          <div className="box">
            <div className="ribbon-2">
              <span className="wdp-ribbon-text"> PRICE DROP</span>
            </div>
          </div>
        )}

        <div className="circle-card one"></div>
        <div className="circle-card two"></div>
        <div className="circle-card three"></div>
        <div className="circle-card four"></div>
        <div className="result-card-main">
          <Box display="flex" mt={1} mr={1} justifyContent="flex-end">
            {getUserFromLocalStorage()?.type === "ADMIN" && (
              <Tooltip title={"Target Flight"}>
                <IconButton onClick={() => setTargetOpen(true)}>
                  <AdsClickIcon />
                </IconButton>
              </Tooltip>
            )}
            {loading ? (
              <div>
                <CircularProgress sx={{ color: "rgb(46, 53, 59)" }} />
              </div>
            ) : (
              <Tooltip title={copy ? "Link Copied!" : "Copy Link"}>
                <IconButton onClick={sharingLinkByUrl}>
                  <LinkIcon />
                </IconButton>
              </Tooltip>
            )}

            {checkISAdminOrEmployee() && (
              <Tooltip title="Analyze">
                <IconButton onClick={() => handleOpenCommissionModal(flight)}>
                  <AnalyticsIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <div className="result-card">
            {flight?.itineraries?.map((itinerary, index) =>
              itinerary?.segments?.map((item, index) => (
                <ItineraryComponent
                  key={index}
                  index={index}
                  itinerary={itinerary}
                  segment={item}
                  dictionaries={dictionaries}
                  flight={flight}
                  destinationLocationCode={destinationLocationCode}
                  originLocationCode={originLocationCode}
                />
              ))
            )}

            <div className="results-card-badges d-flex justify-content-between">
              {flightType !== "One-way" && (
                <Badge
                  title={`${
                    moment(returnDate).diff(moment(departurDate), "days") + 1
                  } Days`}
                  className="teal"
                />
              )}

              <div className="badges">
                <Button
                  className="desktop-show"
                  onClick={() => handelClickOpen(true)}
                >
                  <DescriptionIcon className="normal-size" /> Show Details
                </Button>
                {getUserFromLocalStorage()?.type &&
                  getUserFromLocalStorage()?.type !== "MEMBER" && (
                    <Button onClick={handleEditTotalPrice}>
                      {" "}
                      <ShareIcon2 className="normal-size" />
                      Share Quote
                    </Button>
                  )}
                <Button onClick={handleOpenFareRulesModal}>
                  <SegmentIcon className="normal-size" />
                  View Rules
                </Button>
                {/* {checkISAdminOrEmployee() && (
                  <Tooltip
                    title={
                      searchWithNDC === "NDC"
                        ? "Searched with NDC"
                        : searchWithNDC === "GDS"
                        ? "Search With NDC to get better price"
                        : "No results found with NDC"
                    }
                    placement="top"
                  >
                    <Box ml={0.5}>
                      <Button
                        onClick={() =>
                          handleSourceSearch(source === "NDC" ? "GDS" : "NDC")
                        }
                      >
                        {searchWithNDC === "NDC" && (
                          <FileDownloadDoneIcon
                            className="normal-size"
                            sx={{ fill: "green !important" }}
                          />
                        )}
                        {!searchWithNDC && (
                          <CloseIcon
                            className="normal-size"
                            sx={{ fill: "red !important" }}
                          />
                        )}
                        NDC
                      </Button>
                    </Box>
                  </Tooltip>
                )} */}
              </div>
            </div>
          </div>
        </div>
        <div className="result-card-price">
          <div className="position-relative d-flex flex-column align-items-center">
            <Typography
              className="text-center"
              display="flex"
              alignItems="center"
              fontSize="33px"
            >
              <strong>
                {currency + " "}
                {showPrice && adjustedPrice
                  ? adjustedPrice
                  : totalPrice(flight)}
              </strong>
            </Typography>

            {flight?.numberOfBookableSeats && (
              <Badge
                className="teal"
                title={`${flight?.numberOfBookableSeats} Seats Left`}
              />
            )}
          </div>

          <div className="btn-footer-wrapper">
            <Button
              onClick={() => handelClickOpen(true)}
              className="show-mobile"
            >
              Show Details
            </Button>

            <Button className="primary-color" onClick={handelSelectFlight}>
              Select
            </Button>
          </div>
        </div>
      </div>

      {/******** Flight Details Modal ********/}
      <AppModalFlightDetails
        open={open}
        handelClickOpen={handelClickOpen}
        flight={flight}
        dictionaries={dictionaries}
        destinationLocationCode={destinationLocationCode}
        originLocationCode={originLocationCode}
        onClick={handelSelectFlight}
        currency={currency}
        priceTotal={totalPrice(flight)}
      />

      {/******** Fare Rules Modal ********/}
      <FareRulesModal
        open={isOpenRareRulesModal}
        onClose={handleCloseFareRulesModal}
        isLoading={fareRulesApi.loading}
        isError={fareRulesApi.error}
        data={fareRulesApi}
        flight={flight}
      />

      <Modal open={openCopyModal} onClose={handleCloseCopyModal}>
        <Box sx={style}>
          <p className="medium-font-size mb-3">Copy Flight Link</p>
          {value ? (
            <CopyToClipboard text={value}>
              <button
                className="MuiButton-root MuiButton-code MuiButton-codePrimary MuiButton-sizeLarge MuiButton-codeSizeLarge MuiButton-disableElevation MuiButtonBase-root  css-1id44e"
                tabindex="0"
                type="button"
              >
                {value}
                <span className="MuiButton-endIcon MuiButton-iconSizeLarge css-1ab87kf">
                  <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="ContentCopyRoundedIcon"
                  >
                    <path d="M15 20H5V7c0-.55-.45-1-1-1s-1 .45-1 1v13c0 1.1.9 2 2 2h10c.55 0 1-.45 1-1s-.45-1-1-1zm5-4V4c0-1.1-.9-2-2-2H9c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h9c1.1 0 2-.9 2-2zm-2 0H9V4h9v12z"></path>
                  </svg>
                </span>
                <span className="MuiTouchRipple-root css-w0pj6f"></span>
              </button>
            </CopyToClipboard>
          ) : (
            <p>There is something wrong! Please try again.</p>
          )}
        </Box>
      </Modal>
      <Modal open={isTargetOpen} onClose={handleCloseTarget}>
        <Box sx={style}>
          <Typography variant="h6" mb={1}>
            Target Flight
          </Typography>

          <Box component="form" onSubmit={handleFlightTarget}>
            {success && (
              <Alert severity={success} sx={{ mb: 2 }}>
                {success === "success"
                  ? "The flight was targeted successfully"
                  : "There was an error!"}
              </Alert>
            )}
            <div className="input-group-container">
              <label>Description</label>
              <div className="input-wapper textarea">
                {" "}
                <textarea
                  value={targetDescription}
                  onChange={(e) => setTargetDescription(e.target.value)}
                  rows="5"
                  label="Your Message"
                  required
                  className="p-2"
                />
              </div>
            </div>
            <Box
              display="flex"
              alignItems="center"
              gap={1.5}
              mt={3}
              justifyContent="flex-end"
            >
              <Button
                loading={isTargetLoading}
                type="submit"
                className="primary-color"
              >
                Target Flight
              </Button>
              <Button onClick={handleCloseTarget}>Cancel</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openSellingForModal}
        onClose={() => setOpenSellingForModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            onClick={() => setOpenSellingForModal(false)}
            sx={{ position: "absolute", right: "24px", top: "16px" }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>

          {itineraries?.map((flight, index) => (
            <Typography
              key={index}
              variant="h5"
              fontWeight="bold"
              display="flex"
              alignItems="center"
              my={2}
            >
              {flight?.from?.substring(3, 0)}
              <span className="mx-2">
                <svg
                  className="Icon__StyledIcon-sc-1det6wr-0 fJfkpT"
                  viewBox="0 0 24 24"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path>
                </svg>
              </span>
              {flight?.to?.substring(3, 0)}
            </Typography>
          ))}

          <hr />

          <Box display="flex" justifyContent="space-between" mt={2} mb={1}>
            <p>Total Bank Price ({currency})</p>
            <Typography fontWeight="bold">
              {currency + " "}
              {adjustedPrice || totalPrice(flight)}
            </Typography>
          </Box>
          {handleSellingForSurinamePrice() && checked && (
            <Box display="flex" justifyContent="space-between" mt={2} mb={1}>
              <p>Total Cash Price ({currency})</p>
              <Typography fontWeight="bold">
                {handleSellingForSurinamePrice()}
              </Typography>
            </Box>
          )}

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              "& .input-wapper": {
                height: "34px !important",
                width: "80px",
              },
            }}
          >
            <label>Selling For</label>
            <Box display="flex" alignItems="center">
              <label className="mx-2">{currency}</label>
              <BannerInput
                defaultValue={totalPrice(flight)}
                type="number"
                onChange={(e) => setAdjustedPrice(e.target.value)}
              />
            </Box>
          </Box>
          <Box mb={2} display="flex" alignItems="center">
            <Typography variant="subtitle2" fontWeight="500">
              Sell in Suriname
            </Typography>
            <Checkbox
              checked={checked}
              onChange={() => setChecked((prev) => !prev)}
              size="small"
              color="success"
            />
          </Box>
          <hr />
          <Box display="flex" gap={2} mt={2}>
            <Button onClick={() => setOpenSellingForModal(false)}>
              Cancel
            </Button>
            <Button
              className="primary-color w-100"
              onClick={handleCloseSellingForModal}
            >
              Share Quote
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ResultCard;
