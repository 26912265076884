import { Box, Skeleton, Typography } from "@mui/material";

import Dialog from "components/dialog";
import Button from "components/button";

function FareRules({ open, onClose, isLoading, isError, data, flight }) {
  return (
    <Dialog open={open} onClose={onClose} overflowY="hidden">
      <Typography mb={2} variant="h5">
        Fare Rules
      </Typography>

      {isLoading ? (
        <Box width="100%">
          {[...Array(3)].map((_, i) => (
            <Skeleton
              key={i}
              variant="text"
              animation="wave"
              sx={{ bgcolor: "#F1F3F6", width: "100%" }}
            />
          ))}
        </Box>
      ) : (
        <Box>
          {isError ? (
            <div className="empty-data-msg">
              <h1 className="medium-font-size title-header">
                There is something wrong! Please try again later!
              </h1>
            </div>
          ) : (
            <Box
              sx={{
                overflowX: "hidden",
                overflowY: "auto",
                maxHeight: "calc(100vh - 300px)",
              }}
            >
              {data?.data?.response &&
                Object.keys(data?.data?.response)?.map((item, index) => {
                  const fare = data?.data?.response[item * 1];
                  return (
                    <>
                      <p className="bolder  light-gray mb-1" key={index}>
                        {
                          flight?.itineraries?.map(
                            (itinerary, index) =>
                              itinerary?.segments?.find(
                                (test) => test.id === fare?.segmentId
                              )?.departure?.iataCode
                          )[0]
                        }
                        {" -> "}
                        {
                          flight?.itineraries?.map(
                            (itinerary, index) =>
                              itinerary?.segments?.find(
                                (test) => test.id === fare?.segmentId
                              )?.arrival?.iataCode
                          )[0]
                        }
                      </p>

                      {fare?.fareNotes?.descriptions?.map((des, index) => (
                        <Box className="fare-des" key={index}>
                          <p className="medium-font-size mb-2">
                            {" "}
                            {des?.descriptionType}
                          </p>
                          <p> {des?.text}</p>
                        </Box>
                      ))}
                    </>
                  );
                })}
            </Box>
          )}
        </Box>
      )}
      <Box pt={2} bgcolor="#fff">
        <Button className="w-100" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
}

export default FareRules;
