import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";

const DragAndDrop = ({ file, getFile, label }) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        getFile(file);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Box mb={3}>
      <Typography mb={1}>* {label}</Typography>
      <input
        accept="*"
        id="upload-resume"
        type="file"
        name="upload-resume"
        style={{ display: "none" }}
        {...getInputProps()}
      />
      {!file?.name && (
        <Box
          {...getRootProps()}
          sx={{
            "&:hover": { background: "#ffe2d9", cursor: "pointer" },
          }}
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          borderRadius={1}
          border="1px dashed #f15e22"
          height={"120px"}
          width="100%"
        >
          <Typography mx={3} mb={1} variant={"subtitle2"} color="#f15e22">
            Drag & drop your file here or click to select your file
          </Typography>
          <CloudUploadIcon fontSize="large" color="#f15e22" />
        </Box>
      )}
      {file?.name && (
        <Box display="flex" alignItems="center">
          <CheckIcon color="success" size="small" />
          <Typography mx={0.6} variant={"subtitle2"} color="text.secondary">
            {file?.name}
          </Typography>
          <IconButton onClick={() => getFile(null)}>
            <HighlightOffIcon size="small" color="error" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default DragAndDrop;
