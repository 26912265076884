import React from "react";

import "./style.css";
const PopularFlight = ({inbound,outbound, source}) => {
  return (
    <div className="card-container">
      <div>
        <img
          src={source}
          alt=""
        />
      </div>
      <div>
        <h1>
          {inbound}{" "}
          <svg
            className="Icon__StyledIcon-sc-1pnzn3g-0 dnIYzU"
            viewBox="0 0 24 24"
            preserveAspectRatio="xMidYMid meet"
          >
            <path d="M17.927 12.11l.086.073 2.803 2.921c.22.22.243.537.066.732l-.066.06-2.804 2.922a.56.56 0 01-.429.182c-.331 0-.563-.197-.606-.503l-.007-.106V16.4l-8.063.001a.905.905 0 01-.9-.787L8 15.5c0-.459.346-.838.793-.893l.114-.007 8.063-.001v-1.99c0-.122.06-.305.184-.426a.594.594 0 01.773-.072zM6.847 5.184a.655.655 0 01.183.426v1.99l8.063.001.114.007A.903.903 0 0116 8.5l-.007.113a.905.905 0 01-.9.787L7.03 9.399v1.992l-.007.106c-.043.306-.275.503-.606.503a.56.56 0 01-.43-.182L3.184 8.896l-.066-.06c-.177-.195-.155-.513.066-.732l2.804-2.921.086-.073a.594.594 0 01.772.073z"></path>
          </svg>{" "}
          {outbound}
        </h1>
      </div>
    </div>
  );
};

export default PopularFlight;
