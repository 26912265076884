import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import LandscapeIcon from "@mui/icons-material/Landscape";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import HotelIcon from "@mui/icons-material/Hotel";
import Radio from "@mui/material/Radio";

import Badge from "../badge";
import Button from "../button";
import ImgSlider from "./img-slider";

const HotelCard = ({
  handelShowRoomModal,
  room,
  session,
  hotelDetails,
  hasReserveBtn,
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    const hotelDetailsAndRoom = {
      ...hotelDetails,
      session,
      room,
    };
    navigate("/hotel-payment", {
      state: { hotelDetailsAndRoom },
    });
  };
  return (
    <Box borderRadius={2} bgcolor="#fff" overflow="hidden">
      <Box
        onClick={() => handelShowRoomModal(true)}
        sx={{ cursor: "pointer", "& img": { height: "200px" } }}
      >
        <ImgSlider />
      </Box>
      <Box p={"0.75rem"} border="1px solid #eee">
        <p className="bolder">Twin Room</p>

        <Box display="flex" mb={1} alignItems="center">
          <SquareFootIcon />
          <p className="mx-2">409 sq ft</p>
        </Box>
        <Box display="flex" mb={1} alignItems="center">
          <LandscapeIcon />
          <p className="mx-2">Mountain view</p>
        </Box>
        <Box display="flex" mb={1} alignItems="center">
          <HotelIcon />
          <p className="mx-2">2 Twin Beds</p>
        </Box>
        <a href="£" className="app-link">
          More Details{"  > "}
        </a>
      </Box>
      {/* 
      <Box p={"0.75rem"} border="1px solid #eee">
        <p className="bolder">Options</p>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Radio size="small" />
            <p>Non-Refundable</p>
          </Box>

          <p>$230</p>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Radio size="small" />
            <p>Fully refundable before Jan 13</p>
          </Box>

          <p>$230</p>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Radio size="small" />
            <p>Fully refundable before Jan 17</p>
          </Box>

          <p>$230</p>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Radio size="small" />
            <p>Fully refundable before Jan 17 + Half board</p>
          </Box>

          <p>$230</p>
        </Box>
        <a href="£" className="app-link">
          More Details{"  > "}
        </a>
      </Box> */}
      {!hasReserveBtn && (
        <Box pt={2} p={"0.75rem"} border="1px solid #eee">
          <Badge title="12% off" className="m-0 teal" />
          <p className="sub-main-title bolder my-1">
            {" "}
            {room?.pricing?.currency} {room?.pricing?.priceBeforeTax}
          </p>
          <p>
            {room?.pricing?.currency} {room?.pricing?.tax} taxes
          </p>
          <p>
            {room?.pricing?.currency} {room?.pricing?.priceAfterTax} total
          </p>

          <p>includes taxes & fees</p>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <a href="£" className="app-link">
              Price Details{"  > "}
            </a>
            <Button className="primary-color" onClick={handleNavigate}>
              Reserve{" "}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default HotelCard;
