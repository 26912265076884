import React from "react";
import "./style.css";
import CircularProgress from "@mui/material/CircularProgress";

const Button = ({ children, onClick, className, loading, ...otherProps }) => {
  return (
    <button
      onClick={onClick}
      className={`main-booking-btn ${className}`}
      {...otherProps}
    >
      {loading ? (
        <CircularProgress sx={{ color: "#fff" }} size={22} />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
