import { useState, useContext, useEffect } from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useNavigate, Link, NavLink, useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";

// import Logo from "assets/images/satyamTours.svg";
import Usa from "assets/images/usa.png";
import Unt from "assets/images/unt.png";
import India from "assets/images/india.png";
import Turkey from "assets/images/turkey.png";
import Netherlands from "assets/images/netherlands.png";
import Canada from "assets/images/canada.png";
import Uk from "assets/images/united-kingdom.png";

import Partner1 from "assets/images/Partner1.svg";
import Partner2 from "assets/images/anvrLogo.svg";
import Partner4 from "assets/images/iata-logo-vector.svg";
import Partner3 from "assets/images/sgrLogo.svg";

import {
  removeUserFromLocalStorage,
  getUserFromLocalStorage,
} from "hooks/LocalStorage";
import { ReactComponent as Logo } from "assets/images/satyamTours.svg";

import "./style.css";
import { CurrencyContext } from "pages/useContext";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FlightIcon from "@mui/icons-material/Flight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Button from "../button";
import Drawer from "./header-drawer";
import LangsModal from "./currency-languages-modal";
import HeaderMobileDrawer from "./navigation-drawer";

import { getFlag } from "utils/Currency&Language";
import { Typography } from "@mui/material";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  width: { xs: "90%", md: "550px" },
  p: 4,
  "&:focus": {
    outline: "none",
  },
};

const Header = ({ handelOpenSignUp, handleCloseSignUp, ...props }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [mobileOpenDrawer, setOpenMobileDrawer] = useState(false);

  const handelCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handelCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleOpenMobileDrawer = () => {
    setOpenMobileDrawer(true);
  };

  const handleCloseMobileDrawer = () => {
    setOpenMobileDrawer(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const [currency, setCurrency] = useContext(CurrencyContext);

  const handleClose = () => {
    setOpen(false);
    handleCloseSignUp();
    setSelected(null);
  };

  let navigate = useNavigate();

  const [user, setUser] = useState({});

  const logOut = () => {
    navigate(0);

    removeUserFromLocalStorage();
    setShow(false);
  };

  window.addEventListener("storage", () => {
    setUser(getUserFromLocalStorage());
  });

  useEffect(() => {
    window.dispatchEvent(new Event("storage"));
  }, []);

  return (
    <>
      <HideOnScroll {...props}>
        <nav className="navbar navbar-expand-lg navbar-light">
          <Box display="flex" width="100%" mr={{ xs: 2, md: 4 }}>
            <Link className="navbar-brand mx-0" to="/">
              <Logo className="logo-svg" />
              {/* Travel Logo */}
            </Link>
            {/* <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setShow((prev) => !prev)}
            >
              <span className="navbar-toggler-icon"></span>
            </button> */}
            <Box
              display="flex"
              alignItems="center"
              flex={1}

              // id="navbarSupportedContent"
            >
              <Box
                display={{ xs: "flex", lg: "none" }}
                alignItems="center"
                onClick={handleOpenMobileDrawer}
              >
                <FlightIcon fontSize={"small"} />
                {mobileOpenDrawer ? (
                  <KeyboardArrowUpIcon fontSize={"small"} />
                ) : (
                  <KeyboardArrowDownIcon fontSize={"small"} />
                )}
              </Box>
              <Box
                display={{ xs: "none", lg: "flex" }}
                component="ul"
                className="navbar-nav me-auto mb-2 mb-lg-0"
              >
                <li className="nav-item">
                  <NavLink
                    to="/"
                    className="nav-link"
                    aria-current="page"
                    onClick={() => setShow(false)}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/about-us"
                    className="nav-link"
                    aria-current="page"
                    onClick={() => setShow(false)}
                  >
                    About Us
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" onClick={handleOpen}>
                    Visa
                  </a>
                </li> */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/jobs"
                    onClick={() => setShow(false)}
                  >
                    Jobs
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/flight-schedules"
                    onClick={() => setShow(false)}
                  >
                    Flight Schedules
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/contact-us"
                    onClick={() => setShow(false)}
                  >
                    Contact Us{" "}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/FAQ"
                    onClick={() => setShow(false)}
                  >
                    FAQ{" "}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/entry-fee"
                    onClick={() => setShow(false)}
                  >
                    Entry Fee
                  </NavLink>
                </li>
                {user && (
                  <Box
                    sx={{
                      display: { xs: "block", md: "none" },
                      borderTop: "1px solid #eee",
                      borderBottom: "1px solid #eee",
                      py: 1,
                    }}
                  >
                    {user?.type !== "MEMBER" && (
                      <p className="bg user-type d-inline">
                        Role : {user?.type}
                      </p>
                    )}
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/my-account/profile"
                        onClick={() => setShow(false)}
                      >
                        My Account
                      </NavLink>
                    </li>
                    <Button
                      onClick={logOut}
                      py={1}
                      sx={{ cursor: "pointer" }}
                      className="nav-item"
                    >
                      <p className="bolder">Log Out</p>
                    </Button>
                  </Box>
                )}
              </Box>

              <Box
                mr={1}
                display={{ xs: "none", md: "block" }}
                width={{ xs: "100%", lg: "auto" }}
              >
                <Box display="flex" ml="auto" alignItems="center">
                  <Box ml="auto" className="partners-logo-header">
                    <img src={Partner4} />
                    <img src={Partner2} />
                    <img src={Partner1} />
                    <img src={Partner3} />
                  </Box>
                  <Box
                    className="nav-item dropdown"
                    display={{ xs: "none", lg: "flex" }}
                  >
                    <Box
                      onClick={handleOpenModal}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mx={1}
                      sx={{ cursor: "pointer" }}
                    >
                      <Box mx={1}> {getFlag(currency?.currency)}</Box>
                      <Typography variant="subtitle2" fontWeight="500">
                        {currency?.currency}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {user ? (
                <>
                  {user?.type !== "MEMBER" && (
                    <Box
                      sx={{
                        display: { xs: "none", lg: "inline-block" },
                      }}
                    >
                      <p className="bg user-type d-inline">{user?.type}</p>
                    </Box>
                  )}
                  <Box ml={{ xs: "auto", lg: "0px" }}>
                    <li className="nav-item dropdown ">
                      <Box
                        href="#"
                        component="a"
                        display="flex"
                        alignItems="center"
                        p={1}
                        ml={1}
                        gap={1.5}
                        border={"1px solid #e6e6e6"}
                        borderRadius={"20px"}
                        justifyContent="center"
                        onClick={handleOpenDrawer}
                      >
                        <Avatar
                          sx={{
                            background: "var(--primary-color)",
                            fontSize: "12px",
                            width: 28,
                            height: 28,
                          }}
                        >
                          {user?.firstName?.charAt()}
                          {user?.lastName?.charAt()}
                        </Avatar>
                        <MenuIcon sx={{ color: "#000" }} />
                      </Box>
                      <ul
                        className="dropdown-menu right-dropdown"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <Link
                            to="/my-account/profile"
                            onClick={() => setShow(false)}
                            className="dropdown-item"
                          >
                            My account
                          </Link>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li onClick={logOut}>
                          <a className="dropdown-item" href="#">
                            Log Out
                          </a>
                        </li>
                      </ul>
                    </li>
                  </Box>
                </>
              ) : (
                <Box display="flex" ml={{ xs: "auto", lg: "0px" }}>
                  <Button
                    onClick={() => {
                      window.location.hash = "auth-required";
                    }}
                    className="main-booking-btn primary-color me-3"
                    type="submit"
                  >
                    <LockOpenIcon className="me-1" /> Log In
                  </Button>

                  <Button
                    onClick={() => {
                      handelOpenSignUp(true);
                    }}
                    className="main-booking-btn"
                    type="submit"
                  >
                    <HowToRegIcon className="me-1" /> Sign up
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </nav>
      </HideOnScroll>

      <Drawer
        handleOpenModalLangs={handleOpenModal}
        open={openDrawer}
        onCloseDrawer={handelCloseDrawer}
      />

      <HeaderMobileDrawer
        open={mobileOpenDrawer}
        onCloseDrawer={handleCloseMobileDrawer}
      />

      <LangsModal
        onHandleChangeCurrentCurrency={setCurrency}
        open={openModal}
        onCloseModal={handelCloseModal}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="medium-font-size">Choose a Country</p>
          <Box py={2}>
            {[
              "USA",
              "DUBAI",
              "INDIA",
              "TURKEY",
              "NETHERLANDS",
              "CANADA",
              "UNITED KINGDOM",
            ].map((item, index) => (
              <Box
                key={index}
                borderRadius="6px"
                px={1.5}
                py={0.5}
                mb={1.5}
                border="1px solid rgb(232, 237, 241)"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={`box-country ${selected === item && "active"}`}
                onClick={() => setSelected(item)}
              >
                <Box display="flex" alignItems="center">
                  {index === 0 ? (
                    <img src={Usa} alt="flag" />
                  ) : index === 1 ? (
                    <img src={Unt} alt="flag" />
                  ) : index === 2 ? (
                    <img src={India} alt="flag" />
                  ) : index === 3 ? (
                    <img src={Turkey} alt="flag" />
                  ) : index === 4 ? (
                    <img src={Netherlands} alt="flag" />
                  ) : index === 5 ? (
                    <img src={Canada} alt="flag" />
                  ) : (
                    <img src={Uk} alt="flag" />
                  )}

                  <p className="bold mx-2">{item}</p>
                </Box>

                <Radio checked={item === selected} />
              </Box>
            ))}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                navigate(`/visa/${selected.toLowerCase()}`);
                setOpen(false);
              }}
              className="primary-color"
              disabled={!selected}
            >
              Select
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
