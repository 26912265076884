import client from "./client";

const endpoint = "util/";

const shortenUrl = ({ url }) => client.get(`${endpoint}/shorten-url?${url}`);

const scanPassport = ({ image }) => {
  const body = new FormData();

  body.append("image", image);

  return client.post(`${endpoint}scan-passports`, body);
};

export { shortenUrl, scanPassport };
