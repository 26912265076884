import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useNavigate, useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

import BannerInput from "components/bannerInput";
import Button from "components/button";
import SelectOptions from "components/selectOptions";
import CalendarField from "components/calendarField";

import { ChooseCountrySchema } from "./countriesInfo";
import { ReactComponent as ArrowDown } from "assets/images/arrowDown.svg";

import "./style.css";

const Visa = () => {
  const { countryName } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const countries = [
    "usa",
    "dubai",
    "india",
    "turkey",
    "netherlands",
    "canada",
    "united kingdom",
  ];

  const gender = ["Male", "Female"];
  const MaritalStatus = ["Single", "Married", "Divorced"];
  const YesNo = ["Yes", "No"];

  const steps = ["Personal Info", "Upload File"];

  useEffect(() => {
    if (!countries.includes(countryName)) {
      navigate("/");
    }
  }, []);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      if (event.target.id === "contained-button-file2") {
        setImage(URL.createObjectURL(img));
      } else {
        setFile(URL.createObjectURL(img));
      }
    }
  };

  return (
    <div className="visa-page">
      <Container>
        <Box pt={4}>
          <Box sx={{ width: "100%" }} className="reservation-column-stepper">
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step
                  onClick={() => {
                    if (index == 0) {
                      setActiveStep(index);
                    }
                  }}
                  key={label}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box maxWidth={{ xs: "100%", md: "930px" }}>
            <h1 className="heading-title">Visa for {countryName} people</h1>

            <Box component="form">
              {activeStep === 0 ? (
                <Formik
                  initialValues={ChooseCountrySchema(
                    countryName
                  )?.intialValues.reduce(
                    (acc, cur) => ({ ...acc, [cur.value]: "" }),
                    {}
                  )}
                  onSubmit={(values) => {
                    if (Object.keys(values).length > 0) {
                      setActiveStep(1);
                    }
                  }}
                  validationSchema={
                    ChooseCountrySchema(countryName)?.validationSchema
                  }
                >
                  {({
                    handleChange,
                    handleSubmit,
                    errors,
                    setFieldTouched,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <>
                      <Box className="card-card">
                        <p className="medium-font-size mb-24">
                          Personal Information
                        </p>
                        <Box
                          className="gird-inputs-container"
                          sx={{ gridTemplateColumns: { md: "repeat(2,1fr)" } }}
                        >
                          {ChooseCountrySchema(countryName).intialValues.map(
                            (item, index) => (
                              <>
                                {item?.title && (
                                  <p
                                    style={{ gridColumn: "1 / 3" }}
                                    className="medium-font-size"
                                  >
                                    {item?.title}
                                  </p>
                                )}

                                <Box key={index}>
                                  {item.type === "select" ? (
                                    <SelectOptions
                                      mandatory={item.mandatory}
                                      placeholder={item.placeholder}
                                      options={
                                        item.value === "gender"
                                          ? gender
                                          : item.value === "maritalName"
                                          ? MaritalStatus
                                          : YesNo
                                      }
                                      label={item.label}
                                      onChange={handleChange(item.value)}
                                      error={
                                        touched[item.value] &&
                                        errors[item.value]
                                      }
                                      onBlur={() => setFieldTouched(item.value)}
                                    />
                                  ) : item.value.includes("date") ||
                                    item.type === "date" ? (
                                    <CalendarField
                                      mandatory={item.mandatory}
                                      placeholder={item.placeholder}
                                      label={item.label}
                                      value={values[item.value]}
                                      onChange={setFieldValue}
                                      name={item.value}
                                      error={
                                        touched[item.value] &&
                                        errors[item.value]
                                      }
                                      onBlur={() => setFieldTouched(item.value)}
                                    />
                                  ) : (
                                    <BannerInput
                                      mandatory={item.mandatory}
                                      placeholder={item.placeholder}
                                      label={item.label}
                                      onChange={handleChange(item.value)}
                                      error={
                                        touched[item.value] &&
                                        errors[item.value]
                                      }
                                      onBlur={() => setFieldTouched(item.value)}
                                    />
                                  )}
                                </Box>
                              </>
                            )
                          )}
                        </Box>
                      </Box>

                      <Button
                        type="button"
                        onClick={() => {
                          handleSubmit();
                        }}
                        className="bigBtn primary-color booking  "
                      >
                        Continue <ArrowDown />
                      </Button>
                    </>
                  )}
                </Formik>
              ) : (
                <Box className="card-card">
                  <p className="medium-font-size mb-24">
                    Documents to be uploaded:
                  </p>

                  <p className="bold">
                    <Tooltip
                      arrow
                      title="A clear copy of 1 or more of the following: 
-your previous passports and/or visas (used within the last 10 years to travel outside your country)
-entry and exit stamps study and/or work permits that indicate your duration outside of your country
-expired or valid visas
"
                      placement="right-start"
                    >
                      <span>
                        <span className="mandatory-sign">*</span>Travel History
                        <InfoIcon
                          color="error.error"
                          fontSize="small"
                          sx={{ ml: 1 }}
                        />
                      </span>
                    </Tooltip>
                  </p>

                  <Box>
                    <Box width="100%" my={2}>
                      <label htmlFor="contained-button-file">
                        <input
                          style={{ display: "none" }}
                          accept="image/*"
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={onImageChange}
                        />
                        <span className="main-booking-btn">
                          Upload documents
                        </span>
                      </label>
                      {file && (
                        <Box>
                          <img src={file} width="200" height="200" />
                        </Box>
                      )}
                    </Box>
                    <hr />
                    <Box width="100%" my={2}>
                      <p className="my-3  bold">
                        <Tooltip
                          arrow
                          title="Copies of any of the following:
                      -flight details
                      -hotel reservations
                      -registration for an event"
                          placement="right-start"
                        >
                          <span>
                            <span className="mandatory-sign">*</span>Itinerary
                            <InfoIcon
                              color="error.error"
                              fontSize="small"
                              sx={{ ml: 1 }}
                            />
                          </span>
                        </Tooltip>
                      </p>
                      <label htmlFor="contained-button-file2">
                        <input
                          style={{ display: "none" }}
                          accept="image/*"
                          id="contained-button-file2"
                          multiple
                          type="file"
                          onChange={onImageChange}
                        />
                        <span className="main-booking-btn">
                          Upload document
                        </span>
                      </label>
                      {image && (
                        <Box>
                          <img src={image} width="200" height="200" />
                        </Box>
                      )}
                    </Box>
                    <hr />
                    <Box width="100%" my={2}>
                      <p className="my-3 bold">
                        <Tooltip
                          arrow
                          title="-Bank name and contact
                          -Proof it is your account"
                          placement="right-start"
                        >
                          <span>
                            <span className="mandatory-sign">*</span>Account
                            Statement
                            <InfoIcon
                              color="error.error"
                              fontSize="small"
                              sx={{ ml: 1 }}
                            />
                          </span>
                        </Tooltip>
                      </p>
                      <label htmlFor="contained-button-file2">
                        <input
                          style={{ display: "none" }}
                          accept="image/*"
                          id="contained-button-file2"
                          multiple
                          type="file"
                          onChange={onImageChange}
                        />
                        <span className="main-booking-btn">
                          Upload document
                        </span>
                      </label>
                      {image && (
                        <Box>
                          <img src={image} width="200" height="200" />
                        </Box>
                      )}
                    </Box>
                    <hr />
                    <Box width="100%" my={2}>
                      <p className="my-3 bold">
                        <Tooltip
                          arrow
                          title="At least 6 months of account details, including balances"
                          placement="right-start"
                        >
                          <span>
                            <span className="mandatory-sign">*</span>Account
                            details
                            <InfoIcon
                              color="error.error"
                              fontSize="small"
                              sx={{ ml: 1 }}
                            />
                          </span>
                        </Tooltip>
                      </p>
                      <label htmlFor="contained-button-file2">
                        <input
                          style={{ display: "none" }}
                          accept="image/*"
                          id="contained-button-file2"
                          multiple
                          type="file"
                          onChange={onImageChange}
                        />
                        <span className="main-booking-btn">
                          Upload document
                        </span>
                      </label>
                      {image && (
                        <Box>
                          <img src={image} width="200" height="200" />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
              <Box display="flex">
                {activeStep > 0 && (
                  <Button
                    type="button"
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                      window.scrollTo(0, 0);
                    }}
                    className="bigBtn booking back me-4"
                  >
                    Back <ArrowDown />
                  </Button>
                )}
                {/* <Button
                  type="button"
                  onClick={() => {
                    setActiveStep(activeStep + 1);
                    window.scrollTo(0, 0);
                  }}
                  className="bigBtn primary-color booking  "
                >
                  Continue <ArrowDown />
                </Button> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Visa;
