import React from "react";
import "./style.css";

const PopularFlight = ({ header, inbound, outbound, footerText, imageSource }) => {
  return (
    <div className="suggest-card">
      <img src={imageSource} />
      <div className="overlay-image"></div>
      <div className="overlay-image-hover"></div>
      <div className="content">
        <span className="top_heading">{header}</span>
        <div className="content-text">
          <h3>
            <span className="top_heading hide-p">
              {inbound}
              <svg
                className="Icon__StyledIcon-sc-1pnzn3g-0 kEmlNM"
                viewBox="0 0 24 24"
                preserveAspectRatio="xMidYMid meet"
              >
                <path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path>
              </svg>
            </span>
            <span className="top_heading hide-p">{outbound}</span>{" "}
          </h3>
          <div className="line"></div>
          <span> {footerText}</span>
        </div>
      </div>
    </div>
  );
};

export default PopularFlight;
