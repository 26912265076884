const paths = [
  { label: "Home", path: "/" },
  { label: "About Us", path: "/about-us" },
  { label: "Jobs", path: "/jobs" },
  { label: "Flight Schedules", path: "/flight-schedules" },
  { label: "Contact Us", path: "/contact-us" },
  { label: "FAQ", path: "/FAQ" },
  { label: "Entry Fee", path: "/entry-fee" },
];

export { paths };
