import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from "components/badge";

import { ReactComponent as Cabinbaggage } from "assets/images/cabinbaggage.svg";
import { ReactComponent as ArrowDown } from "assets/images/arrowDown.svg";
import { ReactComponent as SeatingS } from "assets/images/seating.svg";
import { ReactComponent as Checkedbaggage } from "assets/images/checkedbaggage.svg";

import "./style.css";
const MyTrips = () => {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = () => {
    setChecked(!checked);
  };
  const handleChange2 = () => {
    setChecked2(!checked2);
  };

  return (
    <Container>
      <Box py={4}>
        <h1 className="main-title">My Trips</h1>
        <Box className="card-card" maxWidth={"70%"}>
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ fontWeight: 700 }}>
              Beirut
              <span className="mx-2">
                <svg
                  className="Icon__StyledIcon-sc-1det6wr-0 fJfkpT"
                  viewBox="0 0 24 24"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path>
                </svg>
              </span>
              Istanbul and back
            </Typography>
            <Typography sx={{ fontWeight: 700 }}>$200</Typography>
          </Box>
          <Box>
            <div>
              <Box>
                <Box display="flex" py={2}>
                  <Box display="flex">
                    <Box>
                      <img
                        src="https://images.kiwi.com/airlines/32x32/TK.png?default=flight.png"
                        alt="airline"
                      />
                    </Box>
                    <Box ml={1} display={{ xs: "none", sm: "block" }}>
                      <p className="light-gray ">
                        <strong>Turkish Airlines</strong>
                      </p>
                      <Badge title="Economey" />
                    </Box>
                  </Box>

                  <Box minWidth={155} ml={2}>
                    <p>21:55 - 23:55</p>
                    <p className="small-font-size light-gray  bold mt-1">
                      Thu 31 Mar
                    </p>
                  </Box>

                  <Box>
                    <p>2h</p>
                    <p className="small-font-size light-gray  bold mt-1">
                      BEY Beirut Istanbul IST
                    </p>
                  </Box>
                </Box>
                <Box display="flex" position="relative" alignItems="center">
                  <div className="journey-nights-border"></div>
                  <p className="small-font-size">6 nights in Istanbul</p>
                </Box>
                <Box display="flex" py={2}>
                  <Box display="flex">
                    <Box>
                      <img
                        src="https://images.kiwi.com/airlines/32x32/TK.png?default=flight.png"
                        alt="airline"
                      />
                    </Box>
                    <Box ml={1} display={{ xs: "none", sm: "block" }}>
                      <p className="light-gray ">
                        <strong>Turkish Airlines</strong>
                      </p>
                      <Badge title="Economey" />
                    </Box>
                  </Box>

                  <Box minWidth={155} ml={2}>
                    <p>21:55 - 23:55</p>
                    <p className="small-font-size light-gray  bold mt-1">
                      Thu 31 Mar
                    </p>
                  </Box>

                  <Box>
                    <p>2h</p>
                    <p className="small-font-size light-gray  bold mt-1">
                      BEY Beirut Istanbul IST
                    </p>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  <Tab label="Passengers" inkBarStyle={{ background: "red" }} />
                  <Tab label="Baggage & Seating" />
                  <Tab label="Payment Info" />
                </Tabs>
              </Box>
              {value === 0 ? (
                <Box mt={2}>
                  <p className="medium-font-size">Passengers</p>
                  <Box py={2}>
                    <Box display="flex" flexWrap="wrap">
                      <Box width="40%">
                        <Box display="flex">
                          <Box mr={1} width="24px">
                            <img
                              width="100%"
                              src="https://images.kiwi.com/flags/48x0/flag-am.jpg"
                              alt="airline"
                            />
                          </Box>
                          <p>Ms AMANI Hakki</p>
                        </Box>
                      </Box>
                      <Box width="30%" minWidth={155} ml={2}>
                        <p>666666 08.05.2023</p>
                      </Box>

                      <Box>
                        <p>Travel Basic</p>
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box mr={1} width="24px" textAlign="right">
                        <svg
                          className="small-svg"
                          viewBox="0 0 24 24"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <path d="M11.262 14.127l-8.07-8.072a.42.42 0 01-.119-.235.418.418 0 01.353-.473c.08-.011.16-.017.24-.017h16.667c.08 0 .161.006.24.017a.426.426 0 01.235.119.417.417 0 010 .589l-8.073 8.072a1.044 1.044 0 01-1.473 0zM21.74 6.889a.15.15 0 01.166-.032.15.15 0 01.093.14v10c0 .92-.746 1.667-1.667 1.667H3.667c-.92 0-1.667-.747-1.667-1.667v-10a.152.152 0 01.093-.138.15.15 0 01.167.032l5.167 5.167a.21.21 0 010 .295l-3.202 3.202a.624.624 0 000 .869c.24.249.634.255.883.015l3.202-3.202a.21.21 0 01.295 0l1.776 1.772a2.293 2.293 0 003.24 0l1.775-1.775c.08-.08.212-.08.294.001l3.203 3.203.015.016c.248.239.644.231.884-.017a.625.625 0 00-.015-.883l-3.203-3.203a.208.208 0 010-.295l5.167-5.167z"></path>
                        </svg>
                      </Box>
                      <p>AssHaki@gmail.com</p>
                    </Box>

                    <Box display="flex">
                      <Box mr={1} width="24px" textAlign="right">
                        <svg
                          className="small-svg"
                          viewBox="0 0 24 24"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <path d="M17.965 13.806a2.083 2.083 0 00-2.947 0l-1.321 1.32A39.095 39.095 0 018.872 10.3l1.322-1.322a2.085 2.085 0 000-2.948l-1.77-1.768a2.13 2.13 0 00-2.945 0l-.97.97a2.926 2.926 0 00-.367 3.67 39.133 39.133 0 0010.95 10.96 2.956 2.956 0 003.667-.37l.97-.97a2.085 2.085 0 000-2.948l-1.764-1.768z"></path>
                        </svg>
                      </Box>
                      <p> +49 90057712543</p>
                    </Box>
                  </Box>
                </Box>
              ) : value === 1 ? (
                <>
                  <Box mt={1}>
                    <p className="medium-font-size">Bagges</p>

                    <Box display="flex" flexWrap="wrap" pt={2}>
                      <Box width={{ md: "40%" }}>
                        <Box display="flex" alignItems={"center"}>
                          <Box mr={1} width="24px">
                            <Checkedbaggage />
                          </Box>
                          <p>1× Personal item</p>
                          <p className="light-gray small-font-size mx-2">
                            40 × 15 × 30 cm, 4 kg
                          </p>
                        </Box>
                      </Box>
                      <Box width="30%" minWidth={155} mt={1} ml={{ sm: 2 }}>
                        <p className="gray">A. Hakki</p>
                      </Box>
                    </Box>
                    <Box display="flex" flexWrap="wrap" mt={0.5}>
                      <Box width={{ md: "40%" }}>
                        <Box display="flex" alignItems={"center"}>
                          <Box mr={1} width="24px">
                            <Cabinbaggage />
                          </Box>
                          <p>1× Personal item</p>
                          <p className="light-gray small-font-size mx-2">
                            40 × 15 × 30 cm, 4 kg
                          </p>
                        </Box>
                      </Box>
                      <Box
                        width="30%"
                        minWidth={155}
                        mt={{ xs: 1, md: 0 }}
                        ml={{ sm: 2 }}
                      >
                        <p className="gray">A. Hakki</p>
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <p className="medium-font-size">Seating</p>
                    <Box py={2}>
                      <Box display="flex" flexWrap="wrap">
                        <Box width={{ md: "40%" }}>
                          <Box display="flex" alignItems={"center"}>
                            <Box mr={1} width="24px">
                              <SeatingS />
                            </Box>
                            <p className="bold">1× Beirut — Istanbul</p>
                            <p className="light-gray small-font-size mx-2">
                              specific seat
                            </p>
                          </Box>
                        </Box>
                        <Box
                          width="30%"
                          minWidth={155}
                          mt={{ xs: 1, md: 0 }}
                          ml={{ sm: 2 }}
                        >
                          <p className="gray">A. Hakki — 6-J</p>
                        </Box>
                      </Box>
                      <Box display="flex" flexWrap="wrap" pt={0.5}>
                        <Box width={{ md: "40%" }}>
                          <Box display="flex" alignItems={"center"}>
                            <Box mr={1} width="24px">
                              <SeatingS />
                            </Box>
                            <p className="bold">1× Beirut — Istanbul</p>
                            <p className="light-gray small-font-size mx-2">
                              specific seat
                            </p>
                          </Box>
                        </Box>
                        <Box
                          width="30%"
                          minWidth={155}
                          mt={{ xs: 1, md: 0 }}
                          ml={{ sm: 2 }}
                        >
                          <p className="gray">A. Hakki — 6-J</p>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box mt={1}>
                  <p className="medium-font-size">Paid by Ideal</p>
                </Box>
              )}
            </div>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default MyTrips;
