import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import LandscapeIcon from "@mui/icons-material/Landscape";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import HotelIcon from "@mui/icons-material/Hotel";
import Radio from "@mui/material/Radio";

import Badge from "../badge";
import Button from "../button";
import ImgSlider from "./img-slider";
import Slide from "assets/images/slide4.webp";

const HotelCard = ({ handelShowRoomModal, room, session, hotelDetails }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    const hotelDetailsAndRoom = {
      ...hotelDetails,
      session,
      room,
    };
    navigate("/hotel-payment", {
      state: { hotelDetailsAndRoom },
    });
  };
  return (
    <Box borderRadius={2} bgcolor="#fff" overflow="hidden" mx={2}>
      <Box
        onClick={() => handelShowRoomModal(true)}
        sx={{ cursor: "pointer", "& img": { height: "150px" } }}
      >
        <img src={Slide} width="100%" />
      </Box>
      <Box p={"0.75rem"} border="1px solid #eee">
        <p className="bolder sub-main-title ">Luxury Suites Amsterdam</p>

        <p>Amsterdam</p>

        <Box>
          <p>Fully refundable</p>
          <p>Reserve now, pay later</p>
        </Box>
      </Box>

      <Box pt={2} display="flex" p={"0.75rem"} justifyContent="space-between">
        <Box>
          <p>9.6 out of 10</p>
          <p> Exceptional (819 reviews)</p>
        </Box>
        <Box>
          <p className="sub-main-title bolder">$232</p>

          <p>$288 total</p>
        </Box>
      </Box>
    </Box>
  );
};

export default HotelCard;
