import PoolIcon from "@mui/icons-material/Pool";
import WifiIcon from "@mui/icons-material/Wifi";
import PetsIcon from "@mui/icons-material/Pets";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import SpaIcon from "@mui/icons-material/Spa";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";

const IconsList = (icon) => {
  const allIcons = {
    wifi: <WifiIcon />,
    pet: <PetsIcon />,
    AcUnit: <AcUnitIcon />,
    spa: <SpaIcon />,
    RestaurantMenu: <RestaurantMenuIcon />,
    pool: <PoolIcon />,
  };
  return allIcons[icon];
};

export const Icon = ({ IconName }) => {
  if (!IconName) return null;
  return IconsList(IconName);
};
