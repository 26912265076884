import React, { useState, useCallback, useRef, useEffect } from "react";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { toPng } from "html-to-image";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";

import Button from "components/button";
import BannerInput from "components/bannerInput";

import CircularProgress from "@mui/material/CircularProgress";

const CommissionModal = ({ flight, open, setOpen, getNdcPrice, loading }) => {
  const [commission, setCommission] = useState(6);
  const [totalPrice, setTotalPrice] = useState();
  const [commissionValue, setCommissionValue] = useState();
  const [currency, setCurrency] = useState();
  const [costPrice, setCostPrice] = useState();
  const [ndcPrice, setNdcPrice] = useState();
  const [name, setName] = useState();

  const ref = useRef(null);

  useEffect(() => {
    setCurrency(flight?.price?.currency);
    setTotalPrice(flight?.price?.total);
    setCommissionValue(((6 / 100) * flight?.price?.base).toFixed(2));
    setCostPrice(
      Number(
        flight?.price?.total - ((6 / 100) * flight?.price?.base).toFixed(2)
      ).toFixed(2)
    );

    // setNdcPrice(Number(getNdcPrice?.price?.total));
  }, [flight, costPrice, getNdcPrice]);

  useEffect(() => {
    if (getNdcPrice?.price?.total) {
      setNdcPrice(Number(getNdcPrice?.price?.total));
    } else {
      setNdcPrice();
    }
  }, [getNdcPrice]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    width: { xs: "90%", md: "400px" },
    p: 4,
    maxHeight: "90vh",
    overflow: "auto",
    "&:focus": {
      outline: "none",
    },
  };

  const downloadScreenshot = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "analysis.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        return false;
      });
  }, [ref]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[style, { p: 0 }]} p={0}>
          <Box ref={ref} bgcolor="#fff" p={3}>
            <Typography variant="h6" fontWeight="bold" mb={2}>
              GDS-NDC Comparison Analysis
            </Typography>
            <hr />
            <Box display="flex" alignItems="center" mb={1.5}>
              <Typography fontSize="14px" minWidth={"100px"}>
                Total Price:
              </Typography>
              <Typography fontWeight="bold" fontSize="14px">
                {totalPrice} {currency}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={1.5}>
              <Typography fontSize="14px" minWidth={"100px"}>
                Commission:
              </Typography>
              <Typography fontWeight="bold" fontSize="14px">
                {((commission / 100) * flight?.price?.base).toFixed(2)}{" "}
                {currency}
              </Typography>
            </Box>
            {costPrice && (
              <>
                <Box display="flex" alignItems="center" mb={1.5}>
                  <Typography fontSize="14px" minWidth={"100px"}>
                    Cost Price:
                  </Typography>
                  <Typography fontWeight="bold" fontSize="14px">
                    {costPrice} {currency}
                  </Typography>
                </Box>
              </>
            )}

            <hr />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
              sx={{
                "& .input-wapper": {
                  height: "34px !important",
                  width: "80px",
                },
              }}
            >
              <Typography fontSize="14px" minWidth={"100px"}>
                NDC Price:
              </Typography>
              <Box display="flex" alignItems="center">
                {loading ? (
                  <CircularProgress sx={{ color: "#f15e22" }} size={"1.5rem"} />
                ) : getNdcPrice?.source === "NDC" ? (
                  <FileDownloadDoneIcon
                    className="normal-size"
                    sx={{ fill: "green !important", mb: 0.7 }}
                  />
                ) : (
                  <CloseIcon
                    className="normal-size"
                    sx={{ fill: "red !important", mb: 0.7 }}
                  />
                )}
                <label className="mx-2">{currency}</label>
                <BannerInput
                  defaultValue={getNdcPrice?.price?.total ?? ndcPrice}
                  type="number"
                  key={getNdcPrice?.price?.total}
                  onChange={(e) => setNdcPrice(e.target.value)}
                />
              </Box>
            </Box>
            {ndcPrice && (
              <>
                <Box display="flex" alignItems="center" mb={1.5}>
                  <Typography fontSize="14px" minWidth={"100px"}>
                    NDC P/L:
                  </Typography>
                  <Typography fontWeight="bold" fontSize="14px">
                    {Number((costPrice - ndcPrice).toFixed(2))} {currency}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1.5}>
                  <Typography fontSize="14px" minWidth={"100px"}>
                    Total P/L:
                  </Typography>
                  <Typography fontWeight="bold" fontSize="14px">
                    {Number((totalPrice - ndcPrice).toFixed(2))} {currency}
                  </Typography>
                </Box>
              </>
            )}
            <Box mt={1}>
              <Typography fontSize="14px" mb={1}>
                Customer Name:
              </Typography>
              <Box
                display="flex"
                sx={{ "& .input-group-container": { flex: 1 } }}
                alignItems="center"
              >
                <BannerInput
                  min="0"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {name && (
                  <Button
                    onClick={downloadScreenshot}
                    className="primary-color ms-2"
                  >
                    Capture
                  </Button>
                )}
              </Box>
            </Box>
            <Button className="w-100 mt-3" onClick={() => setOpen(false)}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CommissionModal;
