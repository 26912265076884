import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Slider from "react-slick";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

import { getCompanyInfo } from "api/Company-info";

import useApi from "../hooks/useApi";

import Button from "../components/button";

function AboutUs() {
  const [value, setValue] = useState(0);
  const [valueQ, setValueQ] = useState(0);
  const [openLogin, setOpenLogin] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const [history, setHistory] = useState([
    {
      date: "October 2007",
      description:
        "In October 2007, Aniel begins his journey in the travel industry with a vision to create a company that revolutionizes the way people travel. He launches Satyam Tours and sets out on his mission.",
    },
    {
      date: "December 2007",
      description:
        "Satyam Tours becomes IATA certified, a significant milestone in the company's growth and credibility.",
    },
    {
      date: "April 2010",
      description:
        "Satyam Tours expands its global reach by opening a branch office in the Netherlands, solidifying its position as a leading player in the travel industry.",
    },
    {
      date: "May 2011",
      description:
        "Satyam Tours opens a branch office in India, which becomes an instant success. Aniel's innovative ideas and commitment to customer satisfaction win over the hearts of travelers, and Satyam Tours becomes a household name in the Indian travel industry.",
    },
    {
      date: "August 2013",
      description:
        "Satyam Tours continues its expansion by opening four more branches in Suriname, further cementing its reputation as a major player in the travel industry.",
    },
    {
      date: "December 2021",
      description:
        "Aniel takes Satyam Tours to the next level by opening a brand new branch in Curacao, expanding the company's reach and bringing its innovative services to a new market.",
    },
    {
      date: "October 2022",
      description:
        "Satyam Tours receives ANVR, SGR, and Calamiteitenfonds certifications, a recognition of the company's commitment to quality and excellence.",
    },
    {
      date: "March 2023",
      description:
        "In March 2023, Satyam Tours launches its beta version, open to the public. For the first time, users can query flights and take advantage of Satyam Tours' cutting-edge artificial intelligence features directly from the company's website, further streamlining the booking process and providing an even better travel experience.",
    },
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const companyInfo = useApi(getCompanyInfo);

  const handleChangeQ = (event, newValue) => {
    setValueQ(newValue);
  };

  const handleClose = () => {
    setOpenLogin(false);
  };
  useEffect(() => companyInfo.request(), []);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <IconButton
        sx={{
          background: "#fff",
          position: "absolute",
          zIndex: 9,
          bottom: "-60px",
          left: "1rem",
          //   transform: "translateY(-50%)",
        }}
        onClick={onClick}
      >
        <ChevronLeftIcon />
      </IconButton>
    );
  }

  const questions = [
    "What is Virtual Interlining?",
    "I am an airline",
    "I am an airport",
    "I am a ground or sea carrier",
    "I am a travel retailer",
    "I want to be an affiliate",
    "I am a travel service provider",
  ];

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <IconButton
        sx={{
          background: "#fff",
          position: "absolute",
          zIndex: 9,
          bottom: "-60px",
          right: "1rem",
        }}
        onClick={onClick}
      >
        <ChevronRightIcon />
      </IconButton>
    );
  }

  const settings = {
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
    initialSlide,
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    width: { xs: "90%", md: "650px" },
    p: 5,
    px: { xs: 2, md: 4 },
    pb: 10,
    "&:focus": {
      outline: "none",
    },
  };

  return (
    <>
      <Container>
        <Box my={6}>
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              justifyContent: "center",
              // height: 224,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                // flexGrow: 1,
                // maxWidth: { xs: "100%", sm: 480 },
                bgcolor: "background.paper",
              }}
            >
              <Tabs
                orientation={
                  window.innerWidth > 768 ? "vertical" : "horizontal"
                }
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                  borderRight: 1,
                  borderColor: "divider",
                  position: "fixed",
                  right: { xs: 0, md: "auto" },
                  top: { xs: 0, md: "auto" },
                  pt: { xs: "65px", md: 0 },
                  left: "16px",
                  bgcolor: "white",
                  zIndex: 999,
                }}
              >
                <Tab label="About" />
                <Tab label="Features" />
                <Tab label="Team" />
                <Tab label="Company Info" />
                {/* <Tab label="Certifications" /> */}
              </Tabs>
            </Box>
            <Box
              mt={6}
              maxWidth={"1023px"}
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {value === 0 ? (
                <Box rowGap={20} display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap={{ xs: "wrap-reverse", md: "nowrap" }}
                    gap={10}
                    flexGrow={1}
                  >
                    <Box>
                      <Typography fontWeight="bold" fontSize={"40px"}>
                        Vision
                      </Typography>
                      <Typography color="text.secondary" mb={2}>
                        In 2028, SH is physically accessible in 7 countries and
                        is known worldwide as a prominent innovative and
                        technology-driven travel agency of Surinamese origin.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        "& img": {
                          xs: { width: "100%" },
                          md: { width: "450px" },
                        },
                      }}
                    >
                      <img src="https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/Vision.png" />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={10}
                    flexWrap={{ xs: "wrap", md: "nowrap" }}
                    flexGrow={1}
                  >
                    <Box
                      sx={{
                        "& img": {
                          xs: { width: "100%" },
                          md: { width: "450px" },
                        },
                      }}
                    >
                      <img src="https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/Mission.jpg" />
                    </Box>
                    <Box>
                      <Typography fontWeight="bold" fontSize={"40px"}>
                        Mission
                      </Typography>
                      <Typography color="text.secondary" mb={2}>
                        Proudly endorse Suriname's travel expertise by helping
                        global travelers realize their adventurous travel dreams
                        through high-quality innovation.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : value === 1 ? (
                <Box
                  maxWidth={"800px"}
                  rowGap={20}
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={10}
                    flexWrap={{ xs: "wrap-reverse", md: "nowrap" }}
                    flexGrow={1}
                  >
                    <Box>
                      <Typography fontWeight="bold" fontSize={"40px"}>
                        Passport scanner
                      </Typography>
                      <Typography color="text.secondary" mb={2}>
                        With our passport scanner feature, you can quickly and
                        easily scan your passport right from your phone or
                        computer. This means no more fumbling with paper
                        documents at the travel agency. Our passport scanner
                        feature allows you to breeze through the reservation
                        process and get on your way to your destination in no
                        time.
                      </Typography>
                      <Typography color="text.secondary">
                        Ready to try it out? Let's get started!
                      </Typography>
                    </Box>
                    <Box>
                      <img
                        src="https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/PassportScanner.png"
                        width="400px"
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={10}
                    flexWrap={{ xs: "wrap", md: "nowrap" }}
                    flexGrow={1}
                  >
                    <Box>
                      <img
                        src="https://suriwebwinkel-bucket.s3.us-east-2.amazonaws.com/VirtualRealty.png"
                        width="400px"
                      />
                    </Box>
                    <Box>
                      <Typography fontWeight="bold" fontSize={"40px"}>
                        Virtual reality feature
                      </Typography>
                      <Typography color="text.secondary" mb={2}>
                        Exciting news! We're currently working on a virtual
                        reality feature for our web application tool for flights
                        that will take your travel experience to the next level.
                        With virtual reality, you'll be able to explore your
                        destination like never before, right from the comfort of
                        your own home. Imagine being able to walk through the
                        streets of Paris, sit on the beaches of Hawaii, or even
                        climb Mount Everest – all without ever leaving your
                        living room. Our virtual reality feature will provide a
                        fully immersive travel experience, allowing you to see,
                        hear, and feel like you're actually there.
                      </Typography>
                      <Typography color="text.secondary">
                        While we're still in development, we can't wait to share
                        this new feature with you in the near future. Stay tuned
                        for updates and get ready to take off on the ultimate
                        virtual travel adventure!
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : value === 2 ? (
                <Box
                  mx={10}
                  display="flex"
                  flexDirection="column"
                  mb={4}
                  flex={1}
                >
                  {companyInfo?.loading ? (
                    [...Array(2)].map((_, index) => (
                      <>
                        {index === 0 && (
                          <Skeleton
                            variant="text"
                            width={180}
                            height={30}
                            className="price-cell"
                            animation="wave"
                            sx={{ bgcolor: "#F1F3F6", mb: 12, mt: 5 }}
                          />
                        )}
                        <Box gap={3} mb={5} display="flex">
                          {[...Array(4)].map((_, index) => (
                            <Box key={index}>
                              <Skeleton
                                variant="circular"
                                width={90}
                                height={90}
                                className="price-cell"
                                animation="wave"
                                sx={{ bgcolor: "#F1F3F6" }}
                              />
                              <Skeleton
                                variant="text"
                                width={80}
                                height={20}
                                className="price-cell"
                                animation="wave"
                                sx={{ bgcolor: "#F1F3F6", my: 1 }}
                              />
                              <Skeleton
                                variant="text"
                                width={140}
                                height={20}
                                className="price-cell"
                                animation="wave"
                                sx={{ bgcolor: "#F1F3F6" }}
                              />
                              <Skeleton
                                variant="text"
                                width={80}
                                height={20}
                                className="price-cell"
                                animation="wave"
                                sx={{ bgcolor: "#F1F3F6", my: 2 }}
                              />
                            </Box>
                          ))}
                        </Box>
                      </>
                    ))
                  ) : companyInfo?.data?.teams?.length === 0 ||
                    companyInfo?.data?.length === 0 ? (
                    <div className="empty-data-msg">
                      <h1 className="medium-font-size title-header">
                        No Data Found!{" "}
                      </h1>
                    </div>
                  ) : (
                    companyInfo?.data?.teams.map((team, index) => (
                      <>
                        <Typography
                          fontWeight="bold"
                          fontSize={"40px"}
                          mt={index === 0 ? 3 : 22}
                        >
                          {team?.department}
                        </Typography>
                        <Grid container alignItems="center" mt={8} spacing={4}>
                          {team?.members.map((employee, index) => (
                            <Grid item xs={12} sm={3} key={index}>
                              <Box
                                sx={{
                                  "& img": {
                                    width: "90px",
                                    height: "90px",
                                    borderRadius: "90px",
                                  },
                                }}
                              >
                                <img src={employee?.image} />
                              </Box>
                              <Typography
                                color="#f15e22"
                                fontWeight="bold"
                                mt={2}
                              >
                                {employee?.name}
                              </Typography>
                              <Typography
                                mb={3}
                                color="text.secondary"
                                variant="subtitle2"
                              >
                                {employee?.title}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  "&:hover": {
                                    cursor: "pointer",
                                    color: "var(--primary-color)",
                                  },
                                }}
                                onClick={() => {
                                  setOpenLogin(true);
                                  setInitialSlide(index);
                                }}
                                color="text.secondary"
                              >
                                Read Bio {">"}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    ))
                  )}
                </Box>
              ) : value === 3 ? (
                <Box maxWidth={"sm"}>
                  <Typography
                    color="text.secondary"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    Aniel was tired of struggling to book affordable flights for
                    his vacations. But instead of giving up, he had an idea that
                    could change the world. He developed his own flight search
                    engine, and thus began an exciting journey that would
                    revolutionize the way people search for flights. He created
                    a platform that not only finds the cheapest flights but also
                    offers a seamless booking experience for travelers
                    worldwide. From a simple idea to a global solution, his
                    passion and hard work has made booking flights more
                    accessible and affordable for everyone.
                  </Typography>
                  <Box mt={3}>
                    <Timeline position="alternate">
                      {history.map((item, index) => (
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{ bgcolor: "var(--primary-color)" }}
                            />
                            <TimelineConnector
                              sx={{
                                bgcolor: "rgb(233, 238, 242)",
                                height: "129px",
                              }}
                            />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography fontWeight="bold" variant="caption">
                              {item.date}
                            </Typography>
                            <Typography
                              color="text.secondary"
                              variant="subtitle2"
                            >
                              {item.description}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </Timeline>
                  </Box>
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  flex={1}
                  gap={8}
                  alignItems="center"
                >
                  <Box
                    bgcolor="#F6F8FA"
                    flex={1}
                    py={4}
                    px={2}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    maxWidth="320px"
                  >
                    <Typography variant="h6" fontWeight="bold" mb={2}>
                      Discover More
                    </Typography>
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={valueQ}
                      onChange={handleChangeQ}
                      aria-label="Vertical tabs example1"
                      sx={{
                        "& .MuiTabs-indicator": { display: "none" },
                        "& .MuiTab-root": {
                          alignItems: "flex-start",
                          fontWeight: "500 !important",
                          textTransform: "inherit",
                        },
                        "& .Mui-selected": {
                          bgcolor: "#fff",
                        },
                      }}
                    >
                      {questions.map((tab, i) => (
                        <Tab label={tab} />
                      ))}
                    </Tabs>
                  </Box>
                  <Box maxWidth="sm">
                    <Typography variant="h4" fontWeight="bold">
                      {questions[valueQ]}
                    </Typography>
                    <Typography my={5} color="text.secondary">
                      With lightning-fast caching and easy-to-implement APIs,
                      distributing our content is a breeze. We offer custom
                      solutions for any market
                    </Typography>

                    <Button className="bigBtn primary-color mx-0">
                      Learn More
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Container>

      <Modal
        open={openLogin}
        onClose={handleClose}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Slider {...settings}>
            {companyInfo?.data?.teams?.map((item) =>
              item.members?.map((team, index) => (
                <Box key={index}>
                  <Box
                    display="flex"
                    mb={3}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      sx={{
                        "& img": {
                          width: "90px",
                          height: "90px",
                          borderRadius: "90px",
                        },
                      }}
                    >
                      <img src={team.image} />
                    </Box>
                    <Box ml={1}>
                      <Typography color="#f15e22" fontWeight="bold" mt={2}>
                        {team.name}
                      </Typography>
                      <Typography
                        mb={3}
                        color="text.secondary"
                        variant="subtitle2"
                      >
                        {team.title}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography textAlign="center" color="rgb(124, 139, 153)">
                    {team.biography}
                  </Typography>
                </Box>
              ))
            )}
          </Slider>
        </Box>
      </Modal>
    </>
  );
}

export default AboutUs;
