import client from "./client";

const endpoint = "hotels/";

const getHotels = ({ body }) =>
  client.post(endpoint + "multi-single-availability-stateless", body);

const getHotleRoomsDetails = ({ body }) =>
  client.post(endpoint + "multi-single-availability-stateful", body);

const enhancedPricing = ({ body }) =>
  client.post(endpoint + "enhanced-pricing", body);

const multiElements = ({ body }) =>
  client.post(endpoint + "multi-elements", body);

const hotelSell = ({ body }) => client.post(endpoint + "hotel-sell", body);

const hotelDescriptiveInfo = ({ hotelCode }) =>
  client.get(endpoint + "hotel-descriptive-info/" + hotelCode);

export {
  getHotels,
  getHotleRoomsDetails,
  enhancedPricing,
  multiElements,
  hotelSell,
  hotelDescriptiveInfo,
};
