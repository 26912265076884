import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

const Feature = ({ header, body, buttonValue }) => {
  return (
    <div className="feature">
      <svg
        style={{ zIndex: -10 }}
        className="Icon__StyledIcon-sc-1det6wr-0 kbAetT"
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
      >
        <path d="M8.445 12.668a.9.9 0 00-1.303 1.242l2.573 2.745a.9.9 0 001.335-.036l5.591-7.037a.9.9 0 00-1.367-1.17l-4.655 5.913a.4.4 0 01-.607.025l-1.567-1.683zM1.999 12C2 6.5 6.5 2 12 2s10 4.5 10 10-4.5 10-10 10S2 17.5 2 12z"></path>
      </svg>
      <h3>{header}</h3>
      <p className="my-3">{body} </p>
      <Link to="/contact-us">
        <button className="btn global-btn">{buttonValue}</button>
      </Link>
    </div>
  );
};

export default Feature;
