import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import Input from "components/bannerInput";
import Button from "components/button";
import Calendar from "components/datePicker/calendar";

const TikkieTab = ({
  handleSubmit,
  validationSchema,
  error,
  success,
  fetchAllTikkieRequests,
  loading,
  info,
}) => {
  const [copy, setCopy] = useState(false);
  return (
    <>
      <Formik
        initialValues={{
          description: "",
          amountInCents: "",
          expiryDate: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnBlur={true}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          values,
          setFieldValue,
        }) => (
          <>
            <p className="bold main-title">Create Tikkie</p>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {success}
              </Alert>
            )}
            {info && (
              <Alert severity="info" sx={{ mb: 2 }}>
                {info}
              </Alert>
            )}
            <div className="form">
              <Box className="input-group-container">
                <label>
                  {" "}
                  <span className="mandatory-sign">*</span>
                  Amount
                </label>
                <div className="input-wapper">
                  <TextField
                    onChange={handleChange(`amountInCents`)}
                    error={touched.amountInCents && errors.amountInCents}
                    onBlur={() => setFieldTouched("amountInCents")}
                    name={"amountInCents"}
                    mandatory
                    type="number"
                    // label="Amount"
                    value={values?.amountInCents}
                    InputProps={{
                      endAdornment: <p position="start">€</p>,
                    }}
                  />
                </div>
              </Box>
              <Input
                onChange={handleChange(`description`)}
                error={touched.description && errors.description}
                onBlur={() => setFieldTouched("description")}
                name={"description"}
                mandatory
                type="text"
                label="Description"
                value={values?.description}
              />

              <Box
                className="input-group-container"
                sx={{ "& input": { height: "45px" } }}
              >
                <label>
                  {" "}
                  <span className="mandatory-sign">*</span>
                  Expiration Date
                </label>
                <div className="input-wapper">
                  <Calendar
                    selected={
                      values?.expiryDate
                        ? values?.expiryDate
                        : new Date(
                            new Date().getTime() + 2 * 24 * 60 * 60 * 1000
                          )
                    }
                    onChange={(e) => setFieldValue("expiryDate", e)}
                    onBlur={() => setFieldTouched("expiryDate")}
                    placeholderText="Expiration Date"
                    showPopperArrow={false}
                    minDate={
                      new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)
                    }
                  />
                </div>
                {touched.expiryDate && errors.expiryDate && (
                  <span className="error-msg">{touched.expiryDate}</span>
                )}
              </Box>

              <Button
                type="submit"
                onClick={handleSubmit}
                className="bigBtn  primary-color mb-3"
                loading={loading}
              >
                Create Tikkie
              </Button>
            </div>
          </>
        )}
      </Formik>
      <hr />
      {fetchAllTikkieRequests?.data &&
        fetchAllTikkieRequests?.data?.myPaymentRequests?.length > 0 && (
          <Box mt={3}>
            <Typography fontWeight="bold" variant="h6" mb={2}>
              All Tikkie Requests
            </Typography>
            <Box
              display={{ xs: "none", md: "flex" }}
              className="job-list-heading"
            >
              <p className="job-location" style={{ width: "10%" }}>
                Status{" "}
              </p>
              <p className="job-department"> Tikkie URL</p>
              <p className="job-location">Expiration Date </p>
              <p className="job-location">Amount </p>
              <p className="job-location">Description </p>
            </Box>

            {fetchAllTikkieRequests &&
              fetchAllTikkieRequests?.data?.myPaymentRequests?.map((tikkie) => (
                <>
                  <Box
                    display={{ xs: "none", md: "flex" }}
                    key={tikkie?.id}
                    className="featured-job"
                  >
                    <p
                      className="medium-font-size job-location"
                      style={{ width: "10%" }}
                    >
                      {tikkie?.status === "PAID" ? (
                        <CheckIcon color="success" />
                      ) : (
                        <CloseIcon color="error" />
                      )}
                    </p>
                    <p className="medium-font-size job-department">
                      <Tooltip
                        title={
                          copy === tikkie?.url ? "Link Copied!" : "Copy Link"
                        }
                      >
                        <Typography
                          sx={{
                            width: "100% !important",
                          }}
                        >
                          <CopyToClipboard text={tikkie?.url}>
                            <Typography
                              sx={{
                                maxWidth: "174px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "100% !important",
                                fontWeight: 500,
                              }}
                              pt={0.7}
                              onClick={() => setCopy(tikkie?.url)}
                            >
                              {tikkie?.url}
                            </Typography>
                          </CopyToClipboard>
                        </Typography>
                      </Tooltip>
                    </p>
                    <p className="medium-font-size job-location">
                      {tikkie?.expiryDate}
                    </p>
                    <p className="medium-font-size job-location">
                      {" € " + (tikkie?.amountInCents / 100).toFixed(2)}
                    </p>

                    <p className="medium-font-size job-location">
                      {tikkie?.description}
                    </p>
                  </Box>
                  <Box
                    display={{ xs: "block", md: "none" }}
                    borderBottom="1px solid #cdd7eb"
                    py={1}
                  >
                    <Box display="flex" mb={1}>
                      <Typography variant="body2" color="#a8a8a8" mr={1}>
                        Status:
                      </Typography>
                      <p className="job-location">
                        {tikkie?.status === "PAID" ? (
                          <CheckIcon color="success" />
                        ) : (
                          <CloseIcon color="error" />
                        )}
                      </p>
                    </Box>
                    <Box display="flex" mb={1}>
                      <Typography variant="body2" color="#a8a8a8" mr={1}>
                        Tikkie URL:
                      </Typography>
                      <p className="bold job-department">
                        <Tooltip
                          title={
                            copy === tikkie?.url ? "Link Copied!" : "Copy Link"
                          }
                        >
                          <Typography
                            sx={{
                              width: "100% !important",
                            }}
                          >
                            <CopyToClipboard text={tikkie?.url}>
                              <Typography
                                sx={{
                                  maxWidth: "200px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "100% !important",
                                  fontWeight: 500,
                                }}
                                onClick={() => setCopy(tikkie?.url)}
                                variant="body2"
                              >
                                {tikkie?.url}
                              </Typography>
                            </CopyToClipboard>
                          </Typography>
                        </Tooltip>
                      </p>
                    </Box>
                    <Box display="flex" mb={1}>
                      <Typography variant="body2" color="#a8a8a8" mr={1}>
                        Expiration Date:
                      </Typography>
                      <p className="bold job-location">{tikkie?.expiryDate}</p>
                    </Box>
                    <Box display="flex" mb={1}>
                      <Typography variant="body2" color="#a8a8a8" mr={1}>
                        Amount:
                      </Typography>
                      <p className="bold job-location">
                        {" € " + (tikkie?.amountInCents / 100).toFixed(2)}
                      </p>
                    </Box>

                    <Box display="flex" mb={1}>
                      <Typography variant="body2" color="#a8a8a8" mr={1}>
                        Description:
                      </Typography>
                      <p className="bold job-location">{tikkie?.description}</p>
                    </Box>
                  </Box>
                </>
              ))}
          </Box>
        )}
    </>
  );
};

export default TikkieTab;
