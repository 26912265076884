import React from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";

import AppButton from "../button";

const ModalTravelers = ({ open, handelClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handelClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog-container"
    >
      <Box p={2} minHeight="500px" display="flex" flexDirection="column">
        <Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <p className="bold sub-main-title">Traverls</p>
            <IconButton onClick={handelClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <p className="bold">Room 1</p>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <p>Adults</p>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <AppButton className="icon-box" disabled={true}>
                <BiMinus />
              </AppButton>
              <span className="mx-3">2</span>
              <AppButton className="icon-box">
                <BsPlus />
              </AppButton>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <p>Children</p>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <AppButton className="icon-box" disabled={true}>
                <BiMinus />
              </AppButton>
              <span className="mx-3">2</span>
              <AppButton className="icon-box">
                <BsPlus />
              </AppButton>
            </Box>
          </Box>
          <hr />
          <Button variant="text">Add Other Room</Button>
        </Box>
        <AppButton
          onClick={handelClose}
          className="primary-color w-100 bigBtn mb-0"
        >
          Done
        </AppButton>
      </Box>
    </Dialog>
  );
};

export default ModalTravelers;
