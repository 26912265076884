import * as React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Button } from "@mui/material";

import { paths } from "./paths";

export default function HeaderMobileDrawer({ open, onCloseDrawer }) {
  const navigate = useNavigate();

  const handleNavigate = (href) => {
    onCloseDrawer();
    navigate(href);
  };

  const DrawerList = (
    <Box
      sx={{
        px: 2,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        py: 3,
      }}
      role="presentation"
    >
      <List>
        {paths?.map((path) => (
          <ListItem disablePadding key={path.path}>
            <ListItemButton onClick={() => handleNavigate(path.path)}>
              <ListItemText primary={path.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />

      <Button variant="contained" onClick={onCloseDrawer} fullWidth>
        Close
      </Button>
    </Box>
  );

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "20px 20px 0px 0px",
        },
      }}
      anchor="bottom"
      open={open}
      onClose={onCloseDrawer}
    >
      {DrawerList}
    </Drawer>
  );
}
