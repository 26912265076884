import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import CopyField from "./components/copy-field";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";

import Input from "components/bannerInput";
import Button from "components/button";
import Dialog from "components/dialog";

import { createStripURL } from "api/Payment";
import { Typography } from "@mui/material";

const Stripe = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState("");
  const [stripeUrl, setStripeUrl] = useState();
  const [open, setOpen] = useState(false);

  const validationSchema = Yup.object().shape({
    paymentTypes: Yup.string().required().label("Payment Type"),
    service: Yup.string().required().label("Service"),
    amountInCents: Yup.string().required().label("Amount"),
    currency: Yup.string().required().label("Currency"),
  });

  const handleCreateStripeURL = async (values, { resetForm }) => {
    setLoading(true);
    setError("");

    const amountInCents = Number(
      (parseFloat(values.amountInCents) * 100).toFixed(0)
    );

    const body = values;
    body["paymentTypes"] = [values?.paymentTypes];
    body["amountInCents"] = amountInCents;

    const response = await createStripURL(body);
    if (!response.ok) {
      setError("There is something wrong. Please try again later!");
      resetForm();
    } else {
      const responseData = response.data;
      responseData.currency = values.currency;
      setStripeUrl(responseData);
      setOpen(true);
      resetForm();
    }
    setLoading(false);
  };

  const paymentMethods = ["bancontact", "card", "ideal", "klarna"];

  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Formik
        initialValues={{
          paymentTypes: "",
          currency: "",
          amountInCents: "",
          service: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleCreateStripeURL}
        validateOnBlur={true}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          values,
        }) => (
          <>
            <p className="bold main-title">Create Stripe URL</p>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {success}
              </Alert>
            )}

            <div className="form">
              <Box className="input-group-container">
                <label>
                  {" "}
                  <span className="mandatory-sign">*</span>
                  Amount
                </label>
                <Box
                  className="input-wapper"
                  sx={{
                    boxShadow:
                      touched.amountInCents &&
                      errors.amountInCents &&
                      "rgb(210 28 28) 0px 0px 0px 1px",
                  }}
                >
                  <TextField
                    onChange={handleChange(`amountInCents`)}
                    error={touched.amountInCents && errors.amountInCents}
                    onBlur={() => setFieldTouched("amountInCents")}
                    name={"amountInCents"}
                    mandatory
                    value={values?.amountInCents}
                    InputProps={{
                      endAdornment: <p position="start">€</p>,
                    }}
                    type="number"
                  />
                </Box>
                {touched.amountInCents && errors.amountInCents && (
                  <span className="error-msg">{errors.amountInCents}</span>
                )}
              </Box>

              <Box className="input-group-container">
                <label>
                  {" "}
                  <span className="mandatory-sign">*</span>
                  Payment Method
                </label>
                <Box
                  className="input-wapper"
                  sx={{
                    boxShadow:
                      touched?.paymentTypes &&
                      errors?.paymentTypes &&
                      "rgb(210 28 28) 0px 0px 0px 1px",
                  }}
                >
                  <TextField
                    onChange={handleChange(`paymentTypes`)}
                    error={touched.paymentTypes && errors.paymentTypes}
                    onBlur={() => setFieldTouched("paymentTypes")}
                    name={"paymentTypes"}
                    mandatory
                    select
                    value={values?.paymentTypes}
                  >
                    {paymentMethods?.map((payment) => (
                      <MenuItem key={payment} value={payment}>
                        {payment}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                {touched.paymentTypes && errors.paymentTypes && (
                  <span className="error-msg">{errors.paymentTypes}</span>
                )}
              </Box>
              <Input
                onChange={handleChange(`service`)}
                error={touched.service && errors.service}
                onBlur={() => setFieldTouched("service")}
                name={"service"}
                mandatory
                type="text"
                label="Service"
                value={values?.service}
              />

              <Box
                className="input-group-container"
                sx={{ "& input": { height: "45px" } }}
              >
                <label>
                  {" "}
                  <span className="mandatory-sign">*</span>
                  Currency
                </label>
                <Box
                  className="input-wapper"
                  sx={{
                    boxShadow:
                      touched?.currency &&
                      errors?.currency &&
                      "rgb(210 28 28) 0px 0px 0px 1px",
                  }}
                >
                  <TextField
                    onChange={handleChange(`currency`)}
                    error={touched.currency && errors.currency}
                    onBlur={() => setFieldTouched("currency")}
                    name={"currency"}
                    mandatory
                    select
                    value={values?.currency}
                  >
                    <MenuItem value={"EUR"}>EUR</MenuItem>
                    <MenuItem value={"USD"}>USD</MenuItem>
                  </TextField>
                </Box>

                {touched?.currency && errors?.currency && (
                  <span className="error-msg">{errors.currency}</span>
                )}
              </Box>

              <Button
                type="submit"
                onClick={handleSubmit}
                className="bigBtn  primary-color mb-3"
                loading={loading}
                disabled={loading}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </Formik>

      <Dialog open={open} onClose={handleCloseModal}>
        <Box>
          <Typography variant="h6" mb={2}>
            Stripe URL
          </Typography>

          <CopyField value={stripeUrl?.stripeUrl} />
          <Typography mt={1}>
            <strong> Charges: </strong>
            {stripeUrl?.currency} {stripeUrl?.charges}
          </Typography>
          <Box
            justifyContent="flex-end"
            display="flex"
            alignItems="flex-end"
            mt={2}
          >
            <Button onClick={handleCloseModal}>Close</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default Stripe;
