import React, { useState, useCallback, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";
import { ReactComponent as Calendar } from "assets/images/calendar.svg";
import { ReactComponent as Dot } from "assets/images/dot.svg";
import { ReactComponent as AirPlane } from "assets/images/airplate.svg";
import { ReactComponent as Location } from "assets/images/location.svg";
import TextForm from "../bannerInput";
import { capitalizeFirstLetter, checkISAdminOrEmployee } from "utils/Utils";

import { getUserFromLocalStorage } from "hooks/LocalStorage";
import CloseIcon from "@mui/icons-material/Close";
import logo from "assets/images/satyamTours.svg";

import Badge from "../badge";
import Button from "../button";
import "./style.css";

import { timeDiffCalc, duration } from "utils/Utils";
export default function AlertDialog({
  open,
  handelClickOpen,
  flight,
  dictionaries,
  onClick,
  destinationLocationCode,
  originLocationCode,
  currency,
  priceTotal,
}) {
  const ref = useRef(null);
  const [travelers, setTravelers] = useState([
    {
      firstname: "",
      lastname: "",
    },
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);

  const downloadScreenshot = useCallback(() => {
    setIsOpen(false);
    setShow(true);
    if (ref.current === null) {
      return;
    }
    const downloadName = `${travelers[0]?.firstname?.charAt()?.toUpperCase()}.${
      travelers[0]?.lastname.charAt(0).toUpperCase() +
      travelers[0]?.lastname.slice(1)
    }.pdf`;

    toPng(ref.current, { cacheBust: true }).then((dataUrl) => {
      const anchor = document.createElement("a");

      var doc = jsPDF({
        orientation: "p", // landscape
        unit: "mm", // points, pixels won't work properly
        format: [98, 206], // set needed dimensions for any element
      });

      document.body.appendChild(anchor);
      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();

      doc.addImage(dataUrl, "PNG", 0, 0, 98, height);

      let blob = doc.output("blob");

      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = downloadName;
      link.click();
      setShow(false);
    });
  }, [ref]);

  const handleOnChangeFirstName = (index, value) => {
    const allFields = [...travelers];
    allFields[index].firstname = value;
    setTravelers(allFields);
  };

  const handleOnChangeLastname = (index, value) => {
    const allFields = [...travelers];
    allFields[index].lastname = value;
    setTravelers(allFields);
  };

  const handleAddNewField = () => {
    const checkHasEmptyField = travelers.find(
      (field) => field.firstname === ""
    );
    if (checkHasEmptyField) {
      return;
    }
    const obj = {
      firstname: "",
      lastname: "",
    };
    return setTravelers([...travelers, obj]);
  };

  const handleRemoveField = (index) => {
    const filterFields = [...travelers];
    filterFields.splice(index, 1);
    setTravelers(filterFields);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handelClickOpen(false);
          setTravelers([{ firstname: "", lastname: "" }]);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-container"
      >
        <div className="dialog-wrapper">
          <div ref={ref}>
            <div className="dialog-header">
              <div className="close-container">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    handelClickOpen(false);
                    setTravelers([{ firstname: "", lastname: "" }]);
                  }}
                >
                  <svg
                    className="Icon__StyledIcon-sc-1det6wr-0 fhAORE"
                    viewBox="0 0 24 24"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path d="M17.655 6.333a.9.9 0 01.001 1.273l-4.103 4.108a.4.4 0 000 .566l4.103 4.109a.9.9 0 01.08 1.18l-.08.092a.9.9 0 01-1.273 0l-4.1-4.108a.4.4 0 00-.567 0l-4.1 4.107a.9.9 0 11-1.273-1.272l4.103-4.108a.4.4 0 000-.566L6.343 7.606a.9.9 0 01-.08-1.18l.081-.093a.9.9 0 011.273.001l4.099 4.106a.4.4 0 00.566 0l4.1-4.106a.9.9 0 011.273 0z"></path>
                  </svg>
                </IconButton>
              </div>
              <Box display="flex" alignItems="center">
                <h2>Itinerary details</h2>
                {show && <img src={logo} width="120px" className="logo" />}
              </Box>
            </div>
            <div className="dialog-container-fluid">
              {flight?.itineraries?.map((itinerary, index1) => (
                <section className="dialog-conetnt" key={index1}>
                  {index1 === 0 &&
                    getUserFromLocalStorage()?.type === "EMPLOYEE" &&
                    Object?.values(travelers[0]).indexOf("") === -1 && (
                      <Box sx={{ borderBottom: "1px solid #aaa" }} mb={2}>
                        <Typography mb={1} variant="h6">
                          Travelers
                        </Typography>
                        {travelers.map((field, index) => (
                          <Box key={index}>
                            <Box display="flex" gap={2} alignItems="flex-start">
                              <Typography mb={1} color="text.secondary">
                                Traveler {index + 1}:
                              </Typography>
                              <Typography>
                                {field?.firstname} {field?.lastname}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}

                  <Typography mb={1} variant="h6">
                    Flight Details
                  </Typography>
                  <div className="trip-wrapper">
                    <h2 className="medium-font-size">
                      To{" "}
                      {
                        dictionaries.locations[
                          itinerary?.segments[itinerary?.segments?.length - 1]
                            .arrival.iataCode
                        ]?.city
                      }
                    </h2>
                    <section>
                      <header className="result-details-header">
                        <div className="sector-segment"></div>
                        <div className="sector-section">
                          <Calendar />
                          <p>
                            {" "}
                            {moment(
                              itinerary?.segments[
                                itinerary?.segments?.length - 1
                              ]?.departure?.at
                            ).format("ddd D MMM")}
                          </p>
                        </div>
                      </header>
                      {itinerary?.segments?.map((item, index) => (
                        <div key={index}>
                          <div className="result-details-section">
                            <div className="result-details-section-grid">
                              <div className="sector-segment">
                                <p>
                                  {" "}
                                  {moment(item?.departure?.at).format("H:mm")}
                                </p>
                              </div>
                              <div className="result-details-flight">
                                <div className="result-details-slack">
                                  <Dot />
                                  <div className="airplane-names">
                                    <p className="small-font-size">
                                      <strong>
                                        {item?.departure?.iataCode}
                                      </strong>
                                    </p>
                                    <p className="small-font-size gray">
                                      {
                                        dictionaries.locations[
                                          item.departure.iataCode
                                        ].airport
                                      }
                                      {",  "}
                                      {
                                        dictionaries.locations[
                                          item.departure.iataCode
                                        ].country
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="sector-segment class-type">
                                <Tooltip
                                  title={capitalizeFirstLetter(
                                    dictionaries?.carriers[item?.carrierCode]
                                  )}
                                  arrow
                                  placement="top"
                                >
                                  <img
                                    className="ariline-logo"
                                    src={
                                      dictionaries?.airlineLogos?.[
                                        item?.carrierCode
                                      ]?.[0]?.squareImage
                                    }
                                  />
                                </Tooltip>
                              </div>
                              <div className="class-type class-name">
                                <Badge
                                  title={
                                    dictionaries?.carriers[item?.carrierCode]
                                  }
                                />
                              </div>
                              <div className="sector-segment flight-airline"></div>
                              <div className="flight-airline result-details-flight flight-airline-badge">
                                <div className="result-details-slack">
                                  <AirPlane />
                                  <div className="d-flex">
                                    <Badge
                                      title={
                                        flight?.travelerPricings[0]
                                          ?.fareDetailsBySegment[index1]?.cabin
                                      }
                                    />
                                    <Badge
                                      title={duration(
                                        item?.duration,
                                        itinerary?.duration
                                      )}
                                      className="teal"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="sector-segment">
                                <p>
                                  {" "}
                                  {moment(item?.arrival?.at).format("H:mm")}
                                </p>
                              </div>
                              <div className="result-details-flight">
                                <div className="result-details-slack">
                                  <Dot />
                                  <div className="airplane-names">
                                    <p className="small-font-size">
                                      <strong>{item?.arrival?.iataCode}</strong>
                                    </p>
                                    <p className="small-font-size gray">
                                      {
                                        dictionaries?.locations[
                                          item?.arrival?.iataCode
                                        ].airport
                                      }
                                      {", "}
                                      {
                                        dictionaries?.locations[
                                          item?.arrival?.iataCode
                                        ]?.country
                                      }{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {itinerary?.segments?.length > 1 &&
                            item?.arrival?.iataCode !==
                              destinationLocationCode?.split("/")[0] &&
                            item?.arrival?.iataCode !==
                              originLocationCode?.split("/")[0] && (
                              <div className="result-details-flight layover-modal">
                                <div className="layover-text-modal">
                                  <svg
                                    className="Icon__StyledIcon-sc-1det6wr-0 bSWUbe"
                                    viewBox="0 0 24 24"
                                    preserveAspectRatio="xMidYMid meet"
                                  >
                                    <path d="M12 2c5.52.006 9.994 4.48 10 10 0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 18.333A8.342 8.342 0 0020.333 12 8.333 8.333 0 1012 20.333zm4.278-5.158a.833.833 0 01.052 1.177.842.842 0 01-1.177.05l-4.133-3.787a.833.833 0 01-.27-.615V7.417a.833.833 0 011.667 0v4.217l3.861 3.541z"></path>
                                  </svg>{" "}
                                  {timeDiffCalc(
                                    new Date(
                                      moment(item?.arrival?.at).format(
                                        "yyyy/M/D HH:mm:ss"
                                      )
                                    ),
                                    new Date(
                                      moment(
                                        new Date(
                                          itinerary?.segments[1]?.departure?.at
                                        )
                                      ).format("yyyy/M/D HH:mm:ss")
                                    )
                                  )}{" "}
                                  layover in{" "}
                                  {
                                    dictionaries?.locations[
                                      item?.arrival?.iataCode
                                    ]?.country
                                  }
                                </div>
                              </div>
                            )}
                        </div>
                      ))}
                      <footer>
                        <div className="result-details-section-grid">
                          <div className="sector-segment arrival-point"></div>
                          <div className="result-details-flight arrival-point">
                            <div className="result-details-slack">
                              <Location />
                              <div className="airplane-names">
                                <p className="small-font-size gray">
                                  <strong>Arrive at destination</strong>
                                </p>
                                <p className="small-font-size">
                                  <strong>
                                    {
                                      dictionaries.locations[
                                        itinerary?.segments?.length - 1
                                      ]?.city
                                    }
                                  </strong>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </footer>
                    </section>
                  </div>
                </section>
              ))}
            </div>
            <div className="dialog-footer">
              <div className="dialog-footer-wrapper">
                <p className="medium-font-size bolder">
                  {" "}
                  {currency}
                  {priceTotal}
                </p>
                {!show && (
                  <>
                    <Button
                      className="primary-color"
                      onClick={() => onClick(flight)}
                    >
                      Select
                    </Button>
                    {checkISAdminOrEmployee() && (
                      <Button
                        className="primary-color mx-2"
                        onClick={() => setIsOpen(true)}
                      >
                        Download Itinerary
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-container"
      >
        <div className="dialog-wrapper">
          <div className="dialog-container-fluid">
            <Typography p={2} variant="h6">
              Add travelers name
            </Typography>
            <section className="dialog-conetnt">
              {travelers.map((field, index) => (
                <Box py={2} key={index}>
                  <Typography mb={1}>Traveler 1</Typography>
                  <Box display="flex" gap={2} alignItems="flex-start">
                    <TextForm
                      value={field.firstname}
                      label="Firstname"
                      onChange={(e) =>
                        handleOnChangeFirstName(index, e.target.value)
                      }
                    />
                    <TextForm
                      value={field.lastname}
                      label="Lastname"
                      onChange={(e) =>
                        handleOnChangeLastname(index, e.target.value)
                      }
                    />

                    {index !== 0 && (
                      <IconButton
                        sx={{ mx: 1, mb: 1.5 }}
                        onClick={() => handleRemoveField(index)}
                      >
                        <CloseIcon fontSize="small" color="error" />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              ))}
              <Button onClick={handleAddNewField} className="mt-2">
                Add New Traveler
              </Button>
            </section>
          </div>
          <div className="dialog-footer">
            <div className="dialog-footer-wrapper">
              <Button
                className="primary-color mx-2 w-100"
                onClick={downloadScreenshot}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
