import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ClickAwayListener from "@mui/core/ClickAwayListener";
import BannerInput from "../../bannerInput";
import ContainerSearch from "../../containerSearch";
import DatePicker from "../../datePicker";

import TimeDate from "../components/time-select";
import { getAirportByIataCode } from "api/Airport";
import useApi from "hooks/useApi";

const CarsTab = ({ isMobile }) => {
  const [show, setShow] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [keyword, setKeyword] = useState("");
  const getAirportByIataCodeApi = useApi(getAirportByIataCode);
  const [openCalender, setOpenCalendar] = useState(false);
  const [country, setCountry] = useState({});
  const navigate = useNavigate();

  const toggleDrawer = (open, type) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  useEffect(() => {
    if (keyword.length >= 2) {
      getAirportByIataCodeApi.request({ keyword });
    }
  }, [keyword]);

  const navigateToCarsList = () => {
    navigate("/cars");
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShow(null);
      }}
    >
      <div className="d-flex banner-content">
        <div className="block-group">
          <div className="block-group-item">
            <BannerInput
              placeholder="Pick Up"
              name="pickup"
              onFocus={() => {
                setShow("to");
              }}
              isMobile={isMobile}
              toggleDrawer={toggleDrawer(true, "departure")}
              onChange={(e) => setKeyword(e.target.value)}
              value={country?.cityName}
              readOnly
            />
            {show === "to" && (
              <ContainerSearch
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Pickup"
                options={getAirportByIataCodeApi?.data}
                loading={getAirportByIataCodeApi?.loading}
                name="pickup"
                onClick={(item) => {
                  setShow("to");
                  setKeyword("");
                  setCountry(item);
                }}
                readOnly
              />
            )}
          </div>
          <div className="block-group-item">
            <BannerInput
              placeholder="Pick Up"
              name="pickup"
              onFocus={() => {
                setShow("from");
              }}
              isMobile={isMobile}
              toggleDrawer={toggleDrawer(true, "departure")}
              onChange={(e) => setKeyword(e.target.value)}
              value={country?.cityName}
              readOnly
            />
            {show === "from" && (
              <ContainerSearch
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Pickup"
                options={getAirportByIataCodeApi?.data}
                loading={getAirportByIataCodeApi?.loading}
                name="pickup"
                onClick={(item) => {
                  setShow("to");
                  setKeyword("");
                  setCountry(item);
                }}
                readOnly
              />
            )}
          </div>
          <div className="block-group-item">
            <BannerInput
              placeholder={"Date"}
              onFocus={() => {
                setShow("dateFrom");
              }}
              name="dateFrom"
              onClick={() => setShow("dateFrom")}
              isMobile={isMobile}
              toggleDrawer={toggleDrawer(true, "calendar")}
              value={""}
              readOnly
              required
            />
          </div>
          <div className="block-group-item">
            <BannerInput
              placeholder={"Date"}
              onFocus={() => {
                setShow("dateFrom");
              }}
              name="dateFrom"
              onClick={() => setShow("dateFrom")}
              isMobile={isMobile}
              toggleDrawer={toggleDrawer(true, "calendar")}
              value={""}
              readOnly
              required
            />
            {(show === "dateFrom" || show == "dateTo" || openCalender) && (
              <DatePicker
                isMobile={isMobile}
                toggleDrawer={toggleDrawer(false)}
                onHide={() => {
                  setShow(false);
                  // setOpenCalendar(false);
                }}
                openCalender={openCalender}
                setOpenCalendar={setOpenCalendar}
                show={show}
                setShow={setShow}
              />
            )}
          </div>
          <TimeDate />

          <TimeDate />
          <button className="search-btn btn" onClick={navigateToCarsList}>
            Let’s go
          </button>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default CarsTab;
