import React, { useState, useEffect, useContext } from "react";

import Radio from "@mui/material/Radio";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import BankAccount from "assets/images/bankOne.jpg";
import BankAccount2 from "assets/images/bank2.png";
import BankAccount3 from "assets/images/bank3.png";
import BankAccount4 from "assets/images/bank4.png";
import BankAccount5 from "assets/images/bank5.jpg";

import AppButton from "components/button";

import { getPaymentIntent, getPaymentOptions } from "api/Payment";

import useApi from "hooks/useApi";

function payment({
  setPaymentType,
  paymentType,
  totalPrice,
  tikkieUrl,
  totalAmount,
  setCharge,
  id,
  currency,
  handleOnSuccessPaymentRequest,
  onlyTikkiePayment,
}) {
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchPaymentOptions = useApi(getPaymentOptions);

  useEffect(() => {
    const currencyFromLocalStorage = JSON.parse(
      localStorage.getItem("satyam-currency")
    )?.currency;

    fetchPaymentOptions.request({
      currency: currencyFromLocalStorage,
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = () => {
    setChecked(!checked);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    width: { xs: "90%", md: "550px" },
    p: 4,
    "&:focus": {
      outline: "none",
    },
  };

  const openInNewTab = () => {
    window.open(tikkieUrl, "_blank").focus();
  };

  const handlePayment = async () => {
    setLoading(true);
    const body = {
      currency: currency?.currency,
      totalAmount,
      flightReservationId: id,
      paymentType: fetchPaymentOptions?.data?.paymentOptions[paymentType]?.key,
    };
    const response = await getPaymentIntent({
      body,
    });

    if (!response.ok) {
      setLoading(false);
      return;
    } else {
      handleOnSuccessPaymentRequest(
        response?.data?.paymentIntent?.client_secret
      );
      setLoading(false);
    }
  };

  return (
    <>
      <div className="card-card">
        <p className="medium-font-size">Payment</p>
        {fetchPaymentOptions?.loading ? (
          <p>Loading ...</p>
        ) : (
          <>
            {fetchPaymentOptions?.data?.paymentOptions?.length > 0 && (
              <>
                <Box
                  display="flex"
                  mt={2}
                  flexDirection={{ xs: "column", md: "row" }}
                >
                  {currency?.currency === "EUR" && (
                    <Box display="flex" alignItems="center">
                      <Radio
                        checked={paymentType === 4}
                        onClick={() => {
                          setPaymentType(4);

                          openInNewTab();
                        }}
                      />
                      <p className="bold">Tikkie</p>
                    </Box>
                  )}
                  {!onlyTikkiePayment &&
                    currency?.currency !== "SRD" &&
                    fetchPaymentOptions?.data?.paymentOptions?.map(
                      (payment, index) => (
                        <Box display="flex" alignItems="center" key={index}>
                          <Radio
                            checked={paymentType === index}
                            onClick={() => {
                              setCharge(+payment?.charges);
                              setPaymentType(index);
                            }}
                          />
                          <p className="bold">{payment?.value}</p>
                        </Box>
                      )
                    )}
                  {!onlyTikkiePayment && (
                    <Box display="flex" alignItems="center">
                      <Radio
                        checked={paymentType === 3}
                        onClick={() => setPaymentType(3)}
                      />

                      <p className="bold">Bank Transfers</p>
                    </Box>
                  )}
                </Box>

                {!onlyTikkiePayment &&
                  currency?.currency !== "SRD" &&
                  fetchPaymentOptions?.data?.paymentOptions[paymentType]
                    ?.charges *
                    1 >
                    0 && (
                    <p>
                      Extra Charge :{" "}
                      {currency?.sign +
                        (
                          (fetchPaymentOptions?.data?.paymentOptions[
                            paymentType
                          ]?.charges *
                            1 *
                            totalPrice) /
                          100
                        ).toFixed(2)}
                    </p>
                  )}
              </>
            )}

            {paymentType === 3 && (
              <Box maxWidth={{ md: "65%" }} className="outlined-buttons" mt={3}>
                <AppButton onClick={() => setOpen("bankOne")}>
                  De Surinaamsche Bank
                </AppButton>
                <AppButton onClick={() => setOpen("bankTwo")}>
                  Hakrinbank
                </AppButton>
                <AppButton onClick={() => setOpen("bankThree")}>
                  Republic bank Suriname
                </AppButton>
                <AppButton onClick={() => setOpen("bankFour")}>
                  Fina bank
                </AppButton>
                <AppButton onClick={() => setOpen("bankFive")}>
                  Banco di Caribe (Curacao)
                </AppButton>
              </Box>
            )}
          </>
        )}
      </div>
      {!onlyTikkiePayment &&
        currency?.currency !== "SRD" &&
        paymentType !== undefined && (
          <AppButton
            className="primary-color w-100 bigBtn"
            onClick={handlePayment}
            loading={loading}
            disabled={paymentType === 3 || paymentType === 4}
          >
            Pay Now
          </AppButton>
        )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {open === "bankOne" && (
            <>
              <Box textAlign="center">
                <img src={BankAccount} />
              </Box>
              <Box my={2} textAlign="center">
                <p className="medium-font-size">DE SURINAAMSCHE BANK</p>
                <p className="medium-font-size">SATYAM HOLIDAYS N.V.</p>
              </Box>
              <p className="medium-font-size">SRD 3299708</p>
              <p className="medium-font-size">USD 3299716</p>
              <p className="medium-font-size">EUR 3299724</p>
              <p className="medium-font-size">Paramaribo -Suriname S.A.</p>
            </>
          )}
          {open === "bankTwo" && (
            <>
              <Box textAlign="center">
                <img src={BankAccount2} />
              </Box>
              <Box my={2} textAlign="center">
                <p className="medium-font-size">HAKRINBANK N.V.</p>
                <p className="medium-font-size">SATYAM HOLIDAYS N.V.</p>
              </Box>
              <p className="medium-font-size">SRD 20.912.88.32</p>
              <p className="medium-font-size">USD 20.818.94.39</p>
              <p className="medium-font-size">EUR 20.818.94.47</p>
              <p className="medium-font-size">Paramaribo -Suriname S.A.</p>
            </>
          )}
          {open === "bankThree" && (
            <>
              <Box textAlign="center">
                <img src={BankAccount3} />
              </Box>
              <Box my={2} textAlign="center">
                <p className="medium-font-size">REPUBLIC BANK SURINAME</p>
                <p className="medium-font-size">SATYAM HOLIDAYS N.V.</p>
              </Box>
              <p className="medium-font-size">SRD 5.00.000.9568</p>
              <p className="medium-font-size">USD 5.00.000.9576</p>
              <p className="medium-font-size">EUR 5.00.000.9584</p>
              <p className="medium-font-size">Paramaribo -Suriname S.A.</p>
            </>
          )}
          {open === "bankFour" && (
            <>
              <Box textAlign="center">
                <img src={BankAccount4} />
              </Box>
              <Box my={2} textAlign="center">
                <p className="medium-font-size">FINA BANK SURINAME</p>
                <p className="medium-font-size">SATYAM HOLIDAYS N.V.</p>
              </Box>
              <p className="medium-font-size">SRD 1.2076339</p>
              <p className="medium-font-size">USD 1.2076354</p>
              <p className="medium-font-size">EUR 1.2076347</p>
              <p className="medium-font-size">Paramaribo -Suriname S.A.</p>
            </>
          )}
          {open === "bankFive" && (
            <>
              <Box textAlign="center">
                <img src={BankAccount5} />
              </Box>
              <Box my={2} textAlign="center">
                <p className="medium-font-size">Banco di Caribe</p>
                <p className="medium-font-size">SATYAM HOLIDAYS N.V.</p>
              </Box>
              <p className="medium-font-size">Schottegatweg Oost 205 </p>
              <p className="medium-font-size">Willemstad, Curaçao</p>
              <p className="medium-font-size">Account nbr. 334736.01 (ANG)</p>
              <p className="medium-font-size">Account nbr. 334736.02 (USD) </p>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default payment;
