import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";

import BannerInput from "components/bannerInput";
import PhoneNumber from "components/phoneInput";
import Button from "components/button";
import PaymentBlock from "components/payment";

function TravelersInfo({ handleMultiElements, loading }) {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().label("First Name"),
    lastName: Yup.string().required().label("Last Name"),
    email: Yup.string().email().required().label("Email"),
  });

  return (
    <div className="pt-4">
      <p className="bolder  main-title">
        Secure booking — only takes 2 minutes!
      </p>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleMultiElements}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          values,
          setFieldValue,
        }) => (
          <>
            <div className="card-card">
              <p className="sub-main-title bold mb-2    ">Who's checking in?</p>
              <p>
                <strong>Room 1:</strong> 2 Adults 1 King Bed Non-smoking
              </p>
              <div className="form-inputs ">
                <Box
                  mt={2}
                  display="flex"
                  flexWrap={{ xs: "wrap", md: "nowrap" }}
                  gap={2}
                >
                  <BannerInput
                    placeholder="First Name"
                    label="First Name"
                    onChange={(e) => {
                      const value = e.target.value || "";
                      setFieldValue("firstName", value.toLowerCase());
                    }}
                    error={touched.firstName && errors.firstName}
                    onBlur={() => setFieldTouched("firstName")}
                    name="firstName"
                    mandatory
                  />
                  <BannerInput
                    placeholder="last Name"
                    label="Last Name"
                    onChange={handleChange(`lastName`)}
                    error={touched.lastName && errors.lastName}
                    onBlur={() => setFieldTouched("lastName")}
                    name="lastName"
                    mandatory
                  />
                </Box>
                <Box
                  display="flex"
                  flexWrap={{ xs: "wrap", md: "nowrap" }}
                  gap={2}
                >
                  <BannerInput
                    placeholder="email"
                    label="Email"
                    onChange={handleChange(`email`)}
                    error={touched.email && errors.email}
                    onBlur={() => setFieldTouched("email")}
                    name="email"
                    mandatory
                  />
                </Box>
              </div>
            </div>
            {/* <div className="card-card">
          <p className="sub-main-title bold mb-2    ">
            Important information
          </p>
          <p>
            * This property offers transfers from the airport
            (surcharges may apply). To arrange pick-up, guests must
            contact the property 24 hours prior to arrival, using the
            contact information on the booking confirmation. Front desk
            staff will greet guests on arrival.{" "}
          </p>
          <p>
            * Cancellations or changes made after 11:59pm (property
            local time) on Jan 15, 2023 or no-shows are subject to a
            property fee equal to 100% of the total amount paid for the
            reservation.{" "}
          </p>
        </div>
        <PaymentBlock /> */}
            <Button
              onClick={handleSubmit}
              type="submit"
              className="bigBtn primary-color booking"
              loading={loading}
            >
              Place reservation
            </Button>
          </>
        )}
      </Formik>
    </div>
  );
}

export default TravelersInfo;
