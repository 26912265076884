import React, { useState, useContext, useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import moment from "moment";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import IconButton from "@mui/material/IconButton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@mui/material/Tooltip";

import { ReactComponent as Airplane } from "assets/images/airplate.svg";
import { ReactComponent as AirPlane } from "assets/images/airplate.svg";
import { ReactComponent as Children } from "assets/images/children.svg";
import { ReactComponent as Infants } from "assets/images/infants.svg";
import { ReactComponent as Checkedbaggage } from "assets/images/checkedbaggage.svg";
import { ReactComponent as Calendar } from "assets/images/calendar.svg";
import { ReactComponent as SeatingS } from "assets/images/seating.svg";

import { CurrencyContext } from "../useContext";
import confetti from "canvas-confetti";

import { checkISAdminOrEmployee } from "utils/Utils";
import useApi from "hooks/useApi";

import Badge from "components/badge";
import Button from "components/button";
import Payment from "components/payment";
import AddRemark from "components/remark";
import "./style.css";

const BookingInfo = ({ setClientSecret, setOpenCheckout }) => {
  const SECONDS = 5;
  var end = Date.now() + SECONDS * 1000;

  var colors = ["#f15e22", "#124786"];
  const [checked, setChecked] = useState(false);
  const [currency, setCurrency] = useContext(CurrencyContext);
  const [expand, setExpand] = useState(false);
  const [copy, setCopy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [paymentType, setPaymentType] = useState();
  const [charge, setCharge] = useState(0);

  const { state } = useLocation();

  const handleChange = () => {
    setChecked(!checked);
  };

  const copyReferanceID = async () => {
    setCopy(false);
  };

  useEffect(() => {
    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors,
      });
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  }, []);

  const handleOnSuccessPaymentRequest = (client_secret) => {
    setClientSecret(client_secret);
    setOpenCheckout(true);
  };

  const getPaymentCharge = () => {
    const getCharge = charge * 1;
    if (getCharge > 0) {
      return ((getCharge * state?.getTotalPrice) / 100).toFixed(2);
    }
    return false;
  };

  const getPriceForTraveler = (price) => {
    let editedPrice = price;
    if (state?.fareAdjustment) {
      editedPrice = (
        price * 1 +
        state?.fareAdjustment / state?.travelerPricing?.length
      ).toFixed(2);
    }

    return editedPrice;
  };

  return (
    <div className="reservation">
      <div className="booking-info reservation-content">
        <div>
          <div className="booking-info-wrapper card-card">
            <span className="circle">
              <Airplane className="air-plane" />
              <CheckIcon className="check-icon" />
            </span>
            <div>
              <p className="medium-font-size  mb-12 gray">
                Congratulations! your flight booking is confirmed.
              </p>
              <div className="d-flex align-items-center">
                <p className="medium-font-size gray">
                  Referenced ID:{" "}
                  <strong>
                    {state?.overview?.data?.associatedRecords[0]?.reference}
                  </strong>
                </p>
                <CopyToClipboard
                  text={state?.overview?.data?.associatedRecords[0]?.reference}
                  onCopy={() => setCopy(true)}
                >
                  <Tooltip title={copy ? "Copied!" : "Copy"}>
                    <IconButton>
                      <CopyAllIcon />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </div>
          </div>

          <p className="medium-font-size mb-16">Flight Details</p>
          <div className="card-card">
            {state?.overview?.data?.flightOffers?.length > 0 && (
              <div className="journey-details">
                {state?.overview?.data?.flightOffers?.map((flighOffer) =>
                  flighOffer?.itineraries?.map((segmant, index) => (
                    <div className="itinerary-trip-wrapper">
                      {segmant?.segments?.map((flight) => (
                        <>
                          <div className="itinerary-trip">
                            <div className="itinerary-trip-title">
                              <span className="itinerary-depart">
                                {" "}
                                {index === 0 ? "departure" : "RETURN"}
                              </span>
                              {flight?.duration && (
                                <span className="itinerary-depart-time">
                                  Duration :
                                  {`
        ${flight?.duration?.substring(
          flight?.duration?.indexOf("T") + 1,
          flight?.duration?.lastIndexOf("H")
        )}h
        ${
          flight?.duration?.split("H").pop().split("M")[0]
            ? ", " + flight?.duration?.split("H").pop().split("M")[0] + "m"
            : ""
        } `}
                                </span>
                              )}
                            </div>
                            <p className="bold mb-2">
                              {flight?.departure?.iataCode}
                              <span>
                                <svg
                                  className="Icon__StyledIcon-sc-1det6wr-0 fJfkpT"
                                  viewBox="0 0 24 24"
                                  preserveAspectRatio="xMidYMid meet"
                                >
                                  <path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path>
                                </svg>
                              </span>
                              {flight?.arrival?.iataCode}
                            </p>
                            <div className="itinerary-timeline">
                              <div>
                                <div className="itinerary-date">
                                  <div>
                                    <div className="itinerary-icon-wrapper">
                                      <Calendar />
                                    </div>
                                    <p>
                                      <strong>
                                        {" "}
                                        {moment(flight?.departure?.at).format(
                                          "ddd D MMM"
                                        )}
                                      </strong>
                                    </p>
                                  </div>
                                  <div>
                                    <div className="d-flex">
                                      {state?.dictionaries?.airlineLogos[
                                        flight?.carrierCode
                                      ][0]?.squareImage ? (
                                        <img
                                          className="results-card-logo"
                                          src={
                                            state?.dictionaries?.airlineLogos[
                                              flight?.carrierCode
                                            ][0]?.tailImage
                                          }
                                        />
                                      ) : (
                                        <img
                                          className="results-card-logo"
                                          src={
                                            state?.dictionaries?.airlineLogos[
                                              flight?.carrierCode
                                            ][0]?.squareImage
                                          }
                                        />
                                      )}
                                      {flighOffer?.travelerPricings[0]?.fareDetailsBySegment?.find(
                                        (sege) => sege?.segmentId == flight?.id
                                      )?.cabin && (
                                        <Badge
                                          className="teal"
                                          title={
                                            flighOffer?.travelerPricings[0]?.fareDetailsBySegment?.find(
                                              (sege) =>
                                                sege?.segmentId == flight?.id
                                            )?.cabin
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="itinerary-flight">
                                  <div className="itinerary-milestone">
                                    <div className="itinerary-milestone-icon">
                                      <AirPlane />
                                    </div>
                                    <div className="itinerary-collapse">
                                      <div
                                        onClick={handleChange}
                                        className={`collapse-container ${
                                          checked ? "collapsed" : "incllapsed"
                                        }`}
                                      >
                                        <div>
                                          <p>
                                            {moment(
                                              flight?.departure?.at
                                            ).format("H:mm")}{" "}
                                            <span className="location">
                                              {
                                                state?.dictionaries.locations[
                                                  flight.departure.iataCode
                                                ].airport
                                              }
                                            </span>
                                          </p>
                                        </div>
                                        <p className="small-font-size light-gray">
                                          Flight no: {flight?.number}
                                        </p>
                                        <p className="small-font-size light-gray">
                                          Airline:{" "}
                                          <span className="small-font-size light-gray">
                                            {
                                              state?.dictionaries?.carriers[
                                                flight?.carrierCode
                                              ]
                                            }
                                          </span>
                                        </p>
                                        <p className="small-font-size light-gray">
                                          Aircraft:{" "}
                                          <span className="small-font-size light-gray">
                                            {flight?.aircraft?.code}
                                          </span>
                                        </p>

                                        <p>
                                          {moment(flight?.arrival?.at).format(
                                            "H:mm"
                                          )}{" "}
                                          <span className="location">
                                            {
                                              state?.dictionaries.locations[
                                                flight.arrival.iataCode
                                              ].airport
                                            }
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p className="arrival-time">
                                <strong>
                                  {" "}
                                  {moment(flight?.arrival?.at).format(
                                    "ddd D MMM"
                                  )}
                                </strong>
                              </p>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  ))
                )}
              </div>
            )}
          </div>

          <p className="medium-font-size mb-16">Travelers Details</p>
          <div className="card-card">
            {state?.overview?.data?.travelers?.map((item) => (
              <Box py={2}>
                <Box display="flex" flexWrap="wrap">
                  <Box width="40%">
                    <Box display="flex">
                      <Box ml={1} width="24px">
                        {state?.travelerPricing?.find(
                          (trav) => trav.travelerId == item?.id
                        )?.travelerType === "ADULT" ? (
                          <svg
                            className="small-svg"
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            version="1.1"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9 11.041v-0.825c1.102-0.621 2-2.168 2-3.716 0-2.485 0-4.5-3-4.5s-3 2.015-3 4.5c0 1.548 0.898 3.095 2 3.716v0.825c-3.392 0.277-6 1.944-6 3.959h14c0-2.015-2.608-3.682-6-3.959z"></path>
                          </svg>
                        ) : state?.travelerPricing?.find(
                            (trav) => trav.travelerId == item?.id
                          )?.travelerType === "CHILD" ? (
                          <Children className="small-svg" />
                        ) : (
                          <Infants className="small-svg" />
                        )}
                      </Box>

                      <p>
                        {item?.name?.firstName} {item?.name?.lastName}
                      </p>
                    </Box>
                  </Box>
                  <Box width="30%" display="flex" minWidth={155} ml={2}>
                    <p>
                      {item?.gender}/{item?.dateOfBirth}
                    </p>
                  </Box>
                </Box>
                {item?.contact?.emailAddress && (
                  <Box display="flex">
                    <Box mr={1} width="24px" textAlign="right">
                      <svg
                        className="small-svg"
                        viewBox="0 0 24 24"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path d="M11.262 14.127l-8.07-8.072a.42.42 0 01-.119-.235.418.418 0 01.353-.473c.08-.011.16-.017.24-.017h16.667c.08 0 .161.006.24.017a.426.426 0 01.235.119.417.417 0 010 .589l-8.073 8.072a1.044 1.044 0 01-1.473 0zM21.74 6.889a.15.15 0 01.166-.032.15.15 0 01.093.14v10c0 .92-.746 1.667-1.667 1.667H3.667c-.92 0-1.667-.747-1.667-1.667v-10a.152.152 0 01.093-.138.15.15 0 01.167.032l5.167 5.167a.21.21 0 010 .295l-3.202 3.202a.624.624 0 000 .869c.24.249.634.255.883.015l3.202-3.202a.21.21 0 01.295 0l1.776 1.772a2.293 2.293 0 003.24 0l1.775-1.775c.08-.08.212-.08.294.001l3.203 3.203.015.016c.248.239.644.231.884-.017a.625.625 0 00-.015-.883l-3.203-3.203a.208.208 0 010-.295l5.167-5.167z"></path>
                      </svg>
                    </Box>
                    <p>{item?.contact?.emailAddress}</p>
                  </Box>
                )}

                {item?.contact?.phones[0]?.number && (
                  <Box display="flex">
                    <Box mr={1} width="24px" textAlign="right">
                      <svg
                        className="small-svg"
                        viewBox="0 0 24 24"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path d="M17.965 13.806a2.083 2.083 0 00-2.947 0l-1.321 1.32A39.095 39.095 0 018.872 10.3l1.322-1.322a2.085 2.085 0 000-2.948l-1.77-1.768a2.13 2.13 0 00-2.945 0l-.97.97a2.926 2.926 0 00-.367 3.67 39.133 39.133 0 0010.95 10.96 2.956 2.956 0 003.667-.37l.97-.97a2.085 2.085 0 000-2.948l-1.764-1.768z"></path>
                      </svg>
                    </Box>
                    <p>{item?.contact?.phones[0]?.number}</p>
                  </Box>
                )}
              </Box>
            ))}
            <Box mt={1}>
              {state?.travelerPricing?.map((sege, index) =>
                sege?.fareDetailsBySegment[0]?.includedCheckedBags?.quantity ===
                  0 && index === 0 ? (
                  <div>
                    <p className="bold note-font">
                      <span className="orange-color"> Note:</span> this
                      reservation has no baggage included, to add a bag to your
                      reservation please call us or send an email with your
                      reference ID.{" "}
                    </p>
                  </div>
                ) : (
                  <>
                    <p className="medium-font-size">Baggage</p>

                    <Box display="flex" flexWrap="wrap" pt={2}>
                      <Box width={{ md: "40%" }}>
                        <Box display="flex" alignItems={"center"}>
                          <Box mr={1} width="24px">
                            <Checkedbaggage />
                          </Box>
                          <p>
                            {
                              sege?.fareDetailsBySegment[0]?.includedCheckedBags
                                ?.quantity
                            }
                            × Personal item
                          </p>
                          <p className="light-gray small-font-size mx-2">
                            {
                              sege?.fareDetailsBySegment[0]?.includedCheckedBags
                                ?.quantity
                            }{" "}
                            × 23 kg
                          </p>
                        </Box>
                      </Box>
                      <Box width="30%" minWidth={155} mt={1} ml={{ sm: 2 }}>
                        <p className="gray">
                          {
                            state?.overview?.data?.travelers?.find(
                              (traveler) => traveler?.id == sege?.travelerId
                            )?.name?.firstName
                          }
                          .
                          {state?.overview?.data?.travelers
                            ?.find(
                              (traveler) => traveler?.id == sege?.travelerId
                            )
                            ?.name?.lastName?.charAt(0)}
                        </p>
                      </Box>
                    </Box>
                  </>
                )
              )}

              <Box mt={3}>
                {state?.travelerPricing?.map((item, traverlindex) =>
                  item?.fareDetailsBySegment?.map((fare, index) => (
                    <>
                      {fare?.additionalServices?.chargeableSeatNumber ? (
                        <>
                          {traverlindex === 0 && index === 0 && (
                            <p className="medium-font-size mb-16">Seating</p>
                          )}
                          <Box display="flex" flexWrap="wrap">
                            <Box width={{ md: "40%" }}>
                              <Box display="flex" alignItems={"center"}>
                                <Box mr={1} width="24px">
                                  <SeatingS />
                                </Box>

                                <p className="bold">
                                  {
                                    state?.overview?.data?.flightOffers[0]?.itineraries[
                                      index
                                    ]?.segments?.find(
                                      (id) => id?.id == fare?.segmentId
                                    )?.departure?.iataCode
                                  }{" "}
                                  —{" "}
                                  {
                                    state?.overview?.data?.flightOffers[0]?.itineraries[
                                      index
                                    ]?.segments?.find(
                                      (id) => id?.id == fare?.segmentId
                                    )?.arrival?.iataCode
                                  }
                                </p>
                                <p className="light-gray small-font-size mx-2">
                                  specific seat
                                </p>
                              </Box>
                            </Box>
                            <Box
                              width="30%"
                              minWidth={155}
                              mt={{ xs: 1, md: 0 }}
                              ml={{ sm: 2 }}
                            >
                              <p className="gray">
                                {
                                  state?.overview?.data?.travelers?.find(
                                    (traveler) =>
                                      traveler.id == item?.travelerId
                                  )?.name?.firstName
                                }
                                .
                                {state?.overview?.data?.travelers
                                  ?.find(
                                    (traveler) =>
                                      traveler.id == item?.travelerId
                                  )
                                  ?.name?.lastName?.charAt(0)}
                                —
                                {fare?.additionalServices?.chargeableSeatNumber}
                              </p>
                            </Box>
                          </Box>
                        </>
                      ) : null}
                    </>
                  ))
                )}
              </Box>
            </Box>
          </div>

          {checkISAdminOrEmployee() &&
            state?.overview?.data?.flightOffers[0]?.source === "GDS" && (
              <>
                <p className="medium-font-size mb-16">Remarks</p>
                <Box className="card-card">
                  <AddRemark
                    flightOrderId={state?.overview?.data?.id}
                    officeId={state?.officeId}
                  />
                </Box>
              </>
            )}

          <Payment
            setPaymentType={setPaymentType}
            setCharge={setCharge}
            paymentType={paymentType}
            totalPrice={state?.getTotalPrice}
            totalAmount={state?.getTotalPrice * 1}
            id={state?.overview?.data?.id}
            tikkieUrl={state?.tikkieUrl}
            currency={currency}
            handleOnSuccessPaymentRequest={handleOnSuccessPaymentRequest}
          />
          {/* {currency?.currency !== "SRD" && paymentType !== undefined && (
            <Button
              className="primary-color w-100 bigBtn"
              onClick={handlePayment}
              loading={loading}
              disabled={paymentType === 3 || paymentType === 4}
            >
              Pay Now
            </Button>
          )} */}
        </div>
        <aside className="reversation-sideBar-wrapper">
          <div className="reversation-bill">
            <div className="reversation-bill-box">
              <div className="reversation-bill-box-items">
                <div className="reversation-bill-item">
                  <p>Number of Passengers</p>

                  <p>{state?.overview?.data?.travelers?.length}</p>
                </div>

                {state?.travelerPricing?.map((traveler) => (
                  <div className="reversation-bill-item">
                    <p>{traveler?.travelerType}&nbsp; Passenger</p>

                    <p>
                      {currency?.sign + " "}

                      {getPriceForTraveler(traveler?.price?.total)}
                    </p>
                  </div>
                ))}

                {state?.overview?.data?.travelers?.map(
                  (traveler, index) =>
                    state?.travelerPricing[index]?.travelerType !==
                      "HELD_INFANT" && (
                      <div className="reversation-bill-item">
                        <p>
                          {traveler?.travelerType}Seat for{" "}
                          {traveler?.name?.firstName}.
                          {traveler?.name?.lastName?.charAt(0)}
                        </p>
                        <p>
                          {currency?.sign + " "}
                          {state?.reverseSeats[index][traveler?.id]?.seats
                            ?.map((i) => (i?.price === "FREE" ? 0 : +i?.price))
                            ?.reduce((total, num) => total + num, 0)

                            .toFixed(2)
                            .toString()}
                        </p>
                      </div>
                    )
                )}
                {charge > 0 && getPaymentCharge() && (
                  <div className="d-flex justify-content-between">
                    <p>Payment Charge : </p>
                    <p>
                      {currency?.sign + " "}
                      {getPaymentCharge()}
                    </p>
                  </div>
                )}
                <hr />
              </div>

              <div className="reversation-bill-box-finel-item">
                <p>Total ({currency?.currency})</p>
                {charge > 0 && getPaymentCharge() ? (
                  <p>
                    {currency?.sign + " "}
                    {(
                      state?.getTotalPrice * 1 +
                      getPaymentCharge() * 1
                    ).toFixed(2)}
                  </p>
                ) : (
                  <p>
                    {currency?.sign + " "}
                    {state?.getTotalPrice}
                  </p>
                )}
              </div>
            </div>
          </div>
        </aside>
      </div>
      <div
        className={`box-fix ${expand ? "expand" : "no-expand"}`}
        onClick={() => setExpand(!expand)}
      >
        <div className="d-flex align-items-center justify-content-between mb-1">
          <p>Number of Passengers</p>

          <p>{state?.overview?.data?.travelers?.length}</p>
        </div>

        {state?.travelerPricing?.map((traveler) => (
          <div className="d-flex align-items-center justify-content-between mb-1">
            <p>{traveler?.travelerType}&nbsp; Passenger</p>

            <p>
              {currency?.sign + " "}
              {getPriceForTraveler(traveler?.price?.total)}
            </p>
          </div>
        ))}

        {state?.overview?.data?.travelers?.map(
          (traveler, index) =>
            state?.travelerPricing[index]?.travelerType !== "HELD_INFANT" && (
              <div className="d-flex align-items-center justify-content-between mb-1">
                <p>
                  {traveler?.travelerType}Seat for {traveler?.name?.firstName}.
                  {traveler?.name?.lastName?.charAt(0)}
                </p>
                <p>
                  {currency?.sign + " "}
                  {state?.reverseSeats[index][traveler?.id]?.seats
                    ?.map((i) => (i?.price === "FREE" ? 0 : +i?.price))
                    ?.reduce((total, num) => total + num, 0)

                    .toFixed(2)
                    .toString()}
                </p>
              </div>
            )
        )}

        <hr />
        <div className="d-flex align-items-center justify-content-between mt-2">
          <p>Total({currency?.currency}) </p>
        </div>
        {charge > 0 && getPaymentCharge() ? (
          <p className="box-price" onClick={() => setExpand(!expand)}>
            {currency?.sign + " "}
            {(state?.getTotalPrice * 1 + getPaymentCharge() * 1).toFixed(2)}
          </p>
        ) : (
          <p className="box-price" onClick={() => setExpand(!expand)}>
            {currency?.sign + " "}
            {state?.getTotalPrice}
          </p>
        )}
      </div>
    </div>
  );
};

export default BookingInfo;
