import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PaymentView from "components/payment";
const Payment = ({ id, handleOnSuccessPaymentRequest, tikkieUrl }) => {
  const [paymentType, setPaymentType] = useState();

  return (
    <Box minHeight="300px" className="card-card" width={{ md: "700px" }}>
      <Typography mb={1} fontWeight="bold">
        Please Pay with Tikkie To Finish the Process
      </Typography>
      <PaymentView
        tikkieUrl={tikkieUrl}
        handleOnSuccessPaymentRequest={handleOnSuccessPaymentRequest}
        setPaymentType={setPaymentType}
        paymentType={paymentType}
        totalPrice={233}
        totalAmount={22}
        currency={{ currency: "EUR", sign: " € " }}
        id={id}
        onlyTikkiePayment
      />
    </Box>
  );
};

export default Payment;
