import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function TimeSelect() {
  return (
    <div className="block-group-item calendar-date time-date">
      {/* <BannerInput
      placeholder={"Drop Off Time"}
      onFocus={() => {
        setShow("dateTo");
      }}
      onClick={() => setShow("dateTo")}
      isMobile={isMobile}
      toggleDrawer={toggleDrawer(true, "calendar")}
      value={""}
      readOnly
      required
    /> */}
      <div className="input-group-container">
        <div className="input-wapper">
          <span className="search-label">Drop-off time</span>
          <AccessTimeIcon className="svg-input" />
          <select aria-label="Pick-up time" className="field-select">
            <option data-time="0" value="1200AM">
              00:00
            </option>
            <option data-time="15" value="1215AM">
              00:15
            </option>
            <option data-time="30" value="1230AM">
              00:30
            </option>
            <option data-time="45" value="1245AM">
              00:45
            </option>
            <option data-time="60" value="0100AM">
              01:00
            </option>
            <option data-time="75" value="0115AM">
              01:15
            </option>
            <option data-time="90" value="0130AM">
              01:30
            </option>
            <option data-time="105" value="0145AM">
              01:45
            </option>
            <option data-time="120" value="0200AM">
              02:00
            </option>
            <option data-time="135" value="0215AM">
              02:15
            </option>
            <option data-time="150" value="0230AM">
              02:30
            </option>
            <option data-time="165" value="0245AM">
              02:45
            </option>
            <option data-time="180" value="0300AM">
              03:00
            </option>
            <option data-time="195" value="0315AM">
              03:15
            </option>
            <option data-time="210" value="0330AM">
              03:30
            </option>
            <option data-time="225" value="0345AM">
              03:45
            </option>
            <option data-time="240" value="0400AM">
              04:00
            </option>
            <option data-time="255" value="0415AM">
              04:15
            </option>
            <option data-time="270" value="0430AM">
              04:30
            </option>
            <option data-time="285" value="0445AM">
              04:45
            </option>
            <option data-time="300" value="0500AM">
              05:00
            </option>
            <option data-time="315" value="0515AM">
              05:15
            </option>
            <option data-time="330" value="0530AM">
              05:30
            </option>
            <option data-time="345" value="0545AM">
              05:45
            </option>
            <option data-time="360" value="0600AM">
              06:00
            </option>
            <option data-time="375" value="0615AM">
              06:15
            </option>
            <option data-time="390" value="0630AM">
              06:30
            </option>
            <option data-time="405" value="0645AM">
              06:45
            </option>
            <option data-time="420" value="0700AM">
              07:00
            </option>
            <option data-time="435" value="0715AM">
              07:15
            </option>
            <option data-time="450" value="0730AM">
              07:30
            </option>
            <option data-time="465" value="0745AM">
              07:45
            </option>
            <option data-time="480" value="0800AM">
              08:00
            </option>
            <option data-time="495" value="0815AM">
              08:15
            </option>
            <option data-time="510" value="0830AM">
              08:30
            </option>
            <option data-time="525" value="0845AM">
              08:45
            </option>
            <option data-time="540" value="0900AM">
              09:00
            </option>
            <option data-time="555" value="0915AM">
              09:15
            </option>
            <option data-time="570" value="0930AM">
              09:30
            </option>
            <option data-time="585" value="0945AM">
              09:45
            </option>
            <option data-time="600" value="1000AM">
              10:00
            </option>
            <option data-time="615" value="1015AM">
              10:15
            </option>
            <option selected="" data-time="630" value="1030AM">
              10:30
            </option>
            <option data-time="645" value="1045AM">
              10:45
            </option>
            <option data-time="660" value="1100AM">
              11:00
            </option>
            <option data-time="675" value="1115AM">
              11:15
            </option>
            <option data-time="690" value="1130AM">
              11:30
            </option>
            <option data-time="705" value="1145AM">
              11:45
            </option>
            <option data-time="720" value="1200PM">
              12:00
            </option>
            <option data-time="735" value="1215PM">
              12:15
            </option>
            <option data-time="750" value="1230PM">
              12:30
            </option>
            <option data-time="765" value="1245PM">
              12:45
            </option>
            <option data-time="780" value="0100PM">
              13:00
            </option>
            <option data-time="795" value="0115PM">
              13:15
            </option>
            <option data-time="810" value="0130PM">
              13:30
            </option>
            <option data-time="825" value="0145PM">
              13:45
            </option>
            <option data-time="840" value="0200PM">
              14:00
            </option>
            <option data-time="855" value="0215PM">
              14:15
            </option>
            <option data-time="870" value="0230PM">
              14:30
            </option>
            <option data-time="885" value="0245PM">
              14:45
            </option>
            <option data-time="900" value="0300PM">
              15:00
            </option>
            <option data-time="915" value="0315PM">
              15:15
            </option>
            <option data-time="930" value="0330PM">
              15:30
            </option>
            <option data-time="945" value="0345PM">
              15:45
            </option>
            <option data-time="960" value="0400PM">
              16:00
            </option>
            <option data-time="975" value="0415PM">
              16:15
            </option>
            <option data-time="990" value="0430PM">
              16:30
            </option>
            <option data-time="1005" value="0445PM">
              16:45
            </option>
            <option data-time="1020" value="0500PM">
              17:00
            </option>
            <option data-time="1035" value="0515PM">
              17:15
            </option>
            <option data-time="1050" value="0530PM">
              17:30
            </option>
            <option data-time="1065" value="0545PM">
              17:45
            </option>
            <option data-time="1080" value="0600PM">
              18:00
            </option>
            <option data-time="1095" value="0615PM">
              18:15
            </option>
            <option data-time="1110" value="0630PM">
              18:30
            </option>
            <option data-time="1125" value="0645PM">
              18:45
            </option>
            <option data-time="1140" value="0700PM">
              19:00
            </option>
            <option data-time="1155" value="0715PM">
              19:15
            </option>
            <option data-time="1170" value="0730PM">
              19:30
            </option>
            <option data-time="1185" value="0745PM">
              19:45
            </option>
            <option data-time="1200" value="0800PM">
              20:00
            </option>
            <option data-time="1215" value="0815PM">
              20:15
            </option>
            <option data-time="1230" value="0830PM">
              20:30
            </option>
            <option data-time="1245" value="0845PM">
              20:45
            </option>
            <option data-time="1260" value="0900PM">
              21:00
            </option>
            <option data-time="1275" value="0915PM">
              21:15
            </option>
            <option data-time="1290" value="0930PM">
              21:30
            </option>
            <option data-time="1305" value="0945PM">
              21:45
            </option>
            <option data-time="1320" value="1000PM">
              22:00
            </option>
            <option data-time="1335" value="1015PM">
              22:15
            </option>
            <option data-time="1350" value="1030PM">
              22:30
            </option>
            <option data-time="1365" value="1045PM">
              22:45
            </option>
            <option data-time="1380" value="1100PM">
              23:00
            </option>
            <option data-time="1395" value="1115PM">
              23:15
            </option>
            <option data-time="1410" value="1130PM">
              23:30
            </option>
            <option data-time="1425" value="1145PM">
              23:45
            </option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default TimeSelect;
