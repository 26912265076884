import React from "react";
import Drawer from "@mui/material/Drawer";

import "./style.css";

const SwipeableDrawerComponent = ({
  children,
  toggleDrawer,
  openDrawer,
  className,
  ...rest
}) => {
  return (
    <Drawer
      anchor="bottom"
      open={openDrawer}
      onClose={toggleDrawer(false)}
      className={`modal-bottom ${className} `}
      {...rest}
    >
      {children}
    </Drawer>
  );
};
export default SwipeableDrawerComponent;
