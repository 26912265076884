import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CalendarField from "components/calendarField";
import Input from "components/bannerInput";
import Button from "components/button";
import PhoneNumber from "components/phoneInput";
import Calendar from "components/datePicker/calendar";
import SelectOptions from "components/selectOptions";

const Apply = ({ setBody, handleStep }) => {
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const gender = ["Male", "Female"];
  const yerOrNo = ["Yes", "No"];
  const [code, setCode] = useState();

  const monthsLong = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  const validationSchema = Yup.object().shape({
    dayOfArrival: Yup.string().label("This Field"),
    ticketPurchasedFromUs: Yup.string().required().label("This Field"),
    isEmergency: Yup.string().required().label("This Field"),
    firstName: Yup.string().required().label("First Name"),
    lastName: Yup.string().required().label("Last Name"),
    dateOfBirthDay: Yup.string()
      .required()
      .nullable()
      .max(2)
      .label("Date Of Birth"),
    dateOfBirthMonth: Yup.string().required().nullable().label("Date Of Birth"),
    dateOfBirthYear: Yup.string()
      .required()
      .min(4)
      .max(4)
      .label("Date Of Birth"),
    phoneNumber: Yup.string().required().label("Phone Number"),
    gender: Yup.string().required().label("Gender"),
    passportNumber: Yup.string().required().label("Passport Number"),
  });

  const handelSubmit = async (values) => {
    const body = values;
    body.dayOfArrival = dayjs(body.dayOfArrival).format("YYYY-MM-DD");
    body.ticketPurchasedFromUs =
      body.ticketPurchasedFromUs === "Yes" ? true : false;
    body.isEmergency = body.isEmergency === "Yes" ? true : false;
    body.dateOfBirth =
      body.dateOfBirthYear +
      "-" +
      monthsLong[body.dateOfBirthMonth] +
      "-" +
      body.dateOfBirthDay;
    delete body["dateOfBirthYear"];
    delete body["dateOfBirthMonth"];
    delete body["dateOfBirthDay"];

    setBody(body);
    handleStep();
  };

  return (
    <Box className="account-page">
      <p className="bold main-title" mt={2}>
        Please Fill the fields to continue
      </p>
      <Box className="card-card">
        <Formik
          initialValues={{
            dayOfArrival: "",
            phoneNumber: "",
            ticketPurchasedFromUs: "",
            isEmergency: "",
            firstName: "",
            lastName: "",
            passportNumber: "",
            dateOfBirthDay: "",
            dateOfBirthMonth: "",
            dateOfBirthYear: "",
            gender: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handelSubmit}
          enableReinitialize
          validateOnBlur
        >
          {({
            handleChange,
            handleSubmit,
            errors,
            setFieldTouched,
            touched,
            values,
            setFieldValue,
          }) => (
            <>
              {/* {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}
              {success && (
                <Alert severity="success" sx={{ mb: 2 }}>
                  {success}
                </Alert>
              )} */}
              <Box className="form" columnGap={2}>
                <Box
                  className="input-group-container"
                  sx={{ "& input": { height: "45px" } }}
                >
                  <label>
                    {" "}
                    <span className="mandatory-sign">*</span>
                    Day Of Arrival
                  </label>
                  <div className="input-wapper">
                    <Calendar
                      selected={
                        values?.dayOfArrival
                          ? new Date(values?.dayOfArrival)
                          : undefined
                      }
                      onChange={(e) => setFieldValue("dayOfArrival", e)}
                      onBlur={() => setFieldTouched("dayOfArrival")}
                      showPopperArrow={false}
                      minDate={new Date()}
                    />
                  </div>
                  {touched.dayOfArrival && errors.dayOfArrival && (
                    <span className="error-msg">{touched.dayOfArrival}</span>
                  )}
                </Box>
                <PhoneNumber
                  onChange={handleChange(`phoneNumber`)}
                  error={touched.phoneNumber && errors.phoneNumber}
                  onBlur={() => setFieldTouched("phoneNumber")}
                  name="phoneNumber"
                  label="Phone number"
                  value={values?.phoneNumber}
                  mandatory
                  setCode={setCode}
                />
                <SelectOptions
                  mandatory
                  label="Ticket Purchased From Us"
                  options={yerOrNo}
                  name="ticketPurchasedFromUs"
                  onChange={handleChange("ticketPurchasedFromUs")}
                  error={
                    touched["ticketPurchasedFromUs"] &&
                    errors["ticketPurchasedFromUs"]
                  }
                  value={values["ticketPurchasedFromUs"]}
                  onBlur={() => setFieldTouched("ticketPurchasedFromUs")}
                />
                <SelectOptions
                  mandatory
                  label="Is Emergency"
                  options={yerOrNo}
                  name="isEmergency"
                  onChange={handleChange("isEmergency")}
                  error={touched["isEmergency"] && errors["isEmergency"]}
                  value={values["isEmergency"]}
                  onBlur={() => setFieldTouched("isEmergency")}
                />

                <Input
                  onChange={handleChange(`firstName`)}
                  error={touched.firstName && errors.firstName}
                  onBlur={() => setFieldTouched("firstName")}
                  name="firstName"
                  mandatory
                  type="firstName"
                  label="First name"
                  value={values?.firstName}
                />
                <Input
                  onChange={handleChange(`lastName`)}
                  error={touched.lastName && errors.lastName}
                  onBlur={() => setFieldTouched("lastName")}
                  name={"lastName"}
                  mandatory
                  label="Last name"
                  value={values?.lastName}
                />

                <CalendarField
                  valueDay={values["dateOfBirthDay"]}
                  valueMonth={values["dateOfBirthMonth"]}
                  valueYear={values["dateOfBirthYear"]}
                  error1={touched.dateOfBirthDay && errors.dateOfBirthDay}
                  error2={errors.dateOfBirthMonth && touched.dateOfBirthMonth}
                  error3={errors.dateOfBirthYear && touched.dateOfBirthYear}
                  nameDay={`dateOfBirthDay`}
                  nameMonth={`dateOfBirthMonth`}
                  nameYear={`dateOfBirthYear`}
                  onChangeDay={handleChange(`dateOfBirthDay`)}
                  onChangeMonth={handleChange("dateOfBirthMonth")}
                  onChangeYear={handleChange("dateOfBirthYear")}
                  onBlurDay={() => setFieldTouched("dateOfBirthYear")}
                  onBlurMonth={() => setFieldTouched("onBlurMonth")}
                  onBlurYear={() => setFieldTouched("onBlurYear")}
                  label="Date of birth"
                />
                <SelectOptions
                  mandatory
                  label="Gender"
                  options={gender}
                  name="gender"
                  onChange={handleChange("gender")}
                  error={touched["gender"] && errors["gender"]}
                  value={values["gender"]}
                  onBlur={() => setFieldTouched("gender")}
                />
                <Input
                  onChange={handleChange(`passportNumber`)}
                  error={touched.passportNumber && errors.passportNumber}
                  onBlur={() => setFieldTouched("passportNumber")}
                  name={"passportNumber"}
                  value={values?.passportNumber}
                  label="Passport number"
                />

                <Button
                  type="submit"
                  onClick={handleSubmit}
                  className="bigBtn  primary-color"
                  loading={loading}
                >
                  Save changes
                </Button>
              </Box>
            </>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default Apply;
