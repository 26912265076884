import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
function Calendar({
  minDate = moment().toDate(),
  selected,
  onChange,
  ...rest
}) {
  const calRef = React.useRef();

  return (
    <div className="calender-customize">
      <DatePicker
        ref={calRef}
        selected={selected}
        onChange={onChange}
        minDate={minDate}
        {...rest}
      />
    </div>
  );
}

export default Calendar;
