import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { ReactComponent as FAQSVG } from "assets/images/faq.svg";

import Button from "../components/button";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const FANDQ = () => {
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = React.useState(0);

  const handleExpandClick = (index) => {
    if (expanded === index) {
      return setExpanded(false);
    }
    return setExpanded(index);
  };

  const [faqs, setFaqs] = useState([
    {
      question: "Test Number 1",
      answer:
        "In October 2007, Aniel begins his journey in the travel industry with a vision to create a company that revolutionizes the way people travel. He launches Satyam Tours and sets out on his mission.",
    },
    {
      question: "Test Number 2",
      answer:
        "Satyam Tours becomes IATA certified, a significant milestone in the company's growth and credibility.",
    },
    {
      question: "Test Number 3",
      answer:
        "Satyam Tours expands its global reach by opening a branch office in the Netherlands, solidifying its position as a leading player in the travel industry.",
    },
    {
      question: "Test Number 4",
      answer:
        "Satyam Tours opens a branch office in India, which becomes an instant success. Aniel's innovative ideas and commitment to customer satisfaction win over the hearts of travelers, and Satyam Tours becomes a household name in the Indian travel industry.",
    },
  ]);

  return (
    <>
      <Container>
        <Box
          my={8}
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Box
            sx={{
              "& svg": { width: "100%", height: "auto", maxWidth: "450px" },
            }}
          >
            <Typography
              variant="h2"
              fontWeight="bold"
              mb={4}
              sx={{ maxWidth: { xs: "650px" } }}
            >
              We will take care of your Questions!
            </Typography>
            <FAQSVG />
          </Box>
          <Box maxWidth="500px" flex={1} mt={{ xs: 6, md: 18 }}>
            <Typography variant="h4" my={5}>
              {" "}
              Frequentely Asked Questions
            </Typography>
            {faqs?.map((faq, index) => (
              <Box key={index}>
                <Box
                  onClick={() => handleExpandClick(index)}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py={1}
                  px={2}
                  sx={{
                    cursor: "pointer",
                    bgcolor: "#f15e2214",
                  }}
                >
                  <Typography fontWeight="bold" fontSize="16px" mr={2}>
                    {faq.question}
                  </Typography>
                  <ExpandMore
                    expand={expanded === index}
                    onClick={() => handleExpandClick(index)}
                    aria-expanded={expanded === index}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </Box>
                <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography color="text.secondary">{faq.answer}</Typography>
                  </CardContent>
                </Collapse>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default FANDQ;
