import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import BannerInput from "../bannerInput";

import AppListItem from "../listItem";

import MainButton from "../button";

const AirportMobileList = ({
  airports,
  keyword,
  setKeyword,
  onItemClick,
  placeholder,
}) => {
  return (
    <List sx={{ flex: 1 }}>
      <Box sx={{ position: "relative" }}>
        <BannerInput
          placeholder={placeholder}
          onChange={(e) => setKeyword(e.target.value)}
          value={keyword}
          autoFocus
        />
        {keyword && (
          <IconButton
            sx={{ position: "absolute", right: "12px", top: "0px" }}
            onClick={() => setKeyword("")}
          >
            <CloseIcon fontSize="small" />{" "}
          </IconButton>
        )}
      </Box>
      <div className="modal-container-data">
        {airports?.loading ? (
          <Stack spacing={1} px={2}>
            {[...Array(5)].map((item, index) => (
              <Skeleton key={index} height="30px" variant="text" width="100%" />
            ))}
          </Stack>
        ) : airports?.data?.length === 0 ? (
          <Box textAlign="center" p={2}>
            <p className="bold">Example ‘Amsterdam’ </p>
          </Box>
        ) : (
          airports?.data?.map((item, index) => (
            <ListItem onClick={() => onItemClick(item)} key={index}>
              <AppListItem
                key={index}
                index={index}
                title={item?.cityName}
                subTitle={`Airport: ${item?.airportName}`}
              />
              <MainButton className="icon-box">
                <AddIcon fontSize="small" />
              </MainButton>
            </ListItem>
          ))
        )}
      </div>
    </List>
  );
};

export default AirportMobileList;
