import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { toPng, toSvg } from "html-to-image";

import Badge from "components/badge";
import ResultCard from "components/resultCard";
import Banner from "components/banner";
import SideBar from "components/sideBar";
import Button from "components/button";
import FilterCollapse from "components/filterCollapse";
import ModalCom from "components/swipeDrawer";
import Modal from "@mui/material/Modal";
import LinearProgress from "@mui/material/LinearProgress";
import { CommissionModal } from "components/app-modals";
import ShareQuatePrice from "components/share-quote-price";

import { ReactComponent as ArrowDown } from "assets/images/arrowDown.svg";
import { ReactComponent as CalPrice } from "assets/images/calPrice.svg";

import { capitalizeFirstLetter } from "utils/Utils";
import useApi from "hooks/useApi";

import moment from "moment";
import _ from "lodash";
import { flightSearch, getCalendar } from "api/Flights";
import { CurrencyContext } from "../useContext";

import { generateAmaRefId, checkISAdminOrEmployee } from "utils/Utils";
import VirtualReality from "components/virtualReality";

import useFilter from "components/filterConfiguration";

import "./style.css";

const Search = ({ setRedirectLink }) => {
  const { search, state } = useLocation();
  let searchParams = new URLSearchParams(search);

  const [currency] = useContext(CurrencyContext);
  const [closePriceModal, setCloseModalPrice] = useState(false);
  const [data, setData] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState();
  const [calendarErr, setCalendarErr] = useState(false);
  const [source, setSource] = useState("");
  const [departureDates, setdepartureDates] = useState([]);
  const [returnDates, setReturnDates] = useState([]);
  const [sortedPrices, setSortedPrices] = useState([]);

  const departureDateFromURL = searchParams.get("departure-date");

  const [nextDate, setNaxtDate] = useState(departureDateFromURL?.split("~")[0]);

  const [nextReturnDate, setNaxtReturnDate] = useState(
    searchParams.get("return-date")
      ? searchParams.get("return-date")?.split("~")[0]
      : departureDateFromURL?.split("~")[
          departureDateFromURL?.split("~").length - 1
        ]
  );
  const [calendarLoading, setCalendarLoading] = useState([]);
  const [activePrice, setActivePrice] = useState({
    return: "",
    departure: "",
  });
  const [amaRefId, setAmaRefId] = useState(generateAmaRefId());
  const [openPop, setOpenPop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openPriTable, setOpenPriTable] = useState(true);
  const [filters, setFilters] = useState([]);
  const [includedCarrierCodes, setIncludedCarrierCodes] = useState([]);
  const [searchWithNDC, setSearchWithNDC] = useState("GDS");
  const [resetSource, setResetSource] = useState(false);
  const [airlinesCodes, setAirlinesCodes] = useState([]);

  const [open2, setOpen2] = useState(false);
  const [flight, setFlight] = useState(false);
  const [ndcPrice, setNdcPrice] = useState();
  const [departureDate, setdepartureDate] = useState(
    searchParams.get("departure-date") ?? ""
  );
  const [returnDate, setReturnDate] = useState(
    searchParams.get("return-date") ?? ""
  );
  const navigate = useNavigate();

  const searchForFlights = useApi(flightSearch, source, setSearchWithNDC);
  const { allFilters, setAllFilters } = useFilter(
    JSON.parse(searchParams.get("refundableFare")),
    JSON.parse(searchParams.get("checkedbaggage")),
    searchParams.get("satyamSpecial"),
    JSON.parse(searchParams.get("stops")),
    searchParams.get("cabinType"),
    searchParams.get("officeId")
  );

  const formatDateToMoment = (date) => {
    return moment(date).format("ddd D MMM");
  };

  const checkIfdepartureDateIsTodayORTomorrow = (type) => {
    if (type === "return-date") {
      return nextReturnDate;
    } else {
      const today = new Date();
      const getdepartureDate = new Date(nextDate);

      if (formatDateToMoment(today) === formatDateToMoment(getdepartureDate)) {
        return moment(
          new Date(nextDate).setDate(new Date(nextDate).getDate() + 3)
        ).format("Y-MM-DD");
      } else if (
        getdepartureDate.getMonth() === new Date().getMonth() &&
        getdepartureDate.getFullYear() === new Date().getFullYear() &&
        getdepartureDate.getDate() === new Date().getDate() + 1
      ) {
        return moment(
          new Date(nextDate).setDate(new Date(nextDate).getDate() + 2)
        ).format("Y-MM-DD");
      } else {
        return nextDate;
      }
    }
  };

  const travelers = [];
  [
    ...Array(+searchParams.get("Senior")).fill("SENIOR"),
    ...Array(+searchParams.get("Adults")).fill("ADULT"),
    ...Array(+searchParams.get("Children") || 0).fill("CHILD"),
    ...Array(+searchParams.get("Infants") || 0).fill("HELD_INFANT"),
  ].map((item, index) => {
    const obj = {
      id: index + 1 + "",
      travelerType: item,
      fareOptions: ["STANDARD"],
      ...(item === "HELD_INFANT" && {
        associatedAdultId:
          travelers?.filter((test) => test.travelerType === "HELD_INFANT")
            ?.length +
          1 +
          "",
      }),
    };
    travelers.push(obj);
  });

  const getFlightDestinations = (type) => {
    const originDestinations = [];
    const flightOption = searchParams.get("flight");

    const origins = searchParams.get("origin").split("~");
    const destinations = searchParams.get("destination").split("~");

    const originsDates = searchParams.get("departure-date").split("~");

    if (flightOption === "Return") {
      const destinationsDates = searchParams.get("return-date").split("~");

      origins.push(destinations[0]);
      destinations.push(origins[0]);
      originsDates.push(destinationsDates[0]);
    }

    const calenderDates = originsDates.map((_, index) => {
      if (index === 0)
        return checkIfdepartureDateIsTodayORTomorrow("departure-date");
      if (index === originsDates.length - 1)
        return checkIfdepartureDateIsTodayORTomorrow("return-date");
      return originsDates[index];
    });

    origins.map((item, index) => {
      const obj = {
        id: index + 1 + "",
        originLocationCode: item.split("-")[0],

        destinationLocationCode: destinations[index].split("-")[0],
        departureDateTimeRange: {
          date:
            type === "calendar" ? calenderDates[index] : originsDates[index],
          ...(type === "calendar" && { dateWindow: "I3D" }),
        },
      };

      originDestinations.push(obj);
    });

    return originDestinations;
  };

  const commonParams = {
    travelers,
    ...(searchParams.get("cabinType") && {
      cabin: searchParams.get("cabinType"),
    }),
    ...(searchParams.get("officeId") && {
      officeId: searchParams.get("officeId"),
    }),

    currencyCode: currency?.currency,
    ...(includedCarrierCodes?.length > 0 && { includedCarrierCodes }),
    includedCheckedBagsOnly: JSON.parse(searchParams.get("checkedbaggage")),
    refundableFare: JSON.parse(searchParams.get("refundableFare")),
    maxNumberOfConnections: +searchParams.get("stops"),

    fareType: searchParams.get("satyamSpecial"),
  };

  const fetchCalendarFareData = async () => {
    // Call the calender just in case the multiCity is less than 3 destinations

    const originsDates = searchParams.get("origin").split("~");

    if (originsDates.length < 3) {
      setCalendarLoading(true);
      setCalendarErr(false);
      const calendar = await getCalendar({
        originDestinations: getFlightDestinations("calendar"),

        amaRefId,
        ...commonParams,
      });
      if (calendar.ok) {
        setData(calendar?.data);
        // if (airlinesCodes?.length === 0) {
        setAirlinesCodes(calendar?.data?.carriers);
        // }
        fetchdepartureAndReturnDate(calendar?.data?.response);
        if (calendar?.data?.response?.length === 0) {
          setCalendarErr(true);
        }
      } else {
        setCalendarErr(true);
      }

      setCalendarLoading(false);
    }
  };

  const fetchSearchFlights = async (hideSource) => {
    if (!closePriceModal) {
      setCloseModalPrice(false);
    }
    searchForFlights.request({
      originDestinations: getFlightDestinations(),
      ...(source && !hideSource && { source }),

      amaRefId,
      ...commonParams,
    });
  };

  useEffect(() => {
    if (filters.length === 0) {
      updateFilter();
    }
    if (resetSource && source) {
      if (amaRefId && currency && !disabledSearch) {
        fetchSearchFlights();
      }
    }
  }, [source]);

  useEffect(() => {
    if (!resetSource) {
      setResetSource(true);
    }
  }, []);

  useEffect(() => {
    if (resetSource) {
      setSource("");

      if (amaRefId && currency && !window.location.hash) {
        get3DatesBeforeAndAfter();
        fetchCalendarFareData();
        fetchSearchFlights("hideSource");
      }
    }
  }, [currency, window.location.hash]);

  useEffect(() => {
    if (amaRefId && currency) {
      if (!disabledSearch) {
        fetchSearchFlights();
      }
      get3DatesBeforeAndAfter();
      fetchCalendarFareData();
    }
  }, [nextDate, nextReturnDate, amaRefId, allFilters, includedCarrierCodes]);

  const fetchdepartureAndReturnDate = async (flightOptions) => {
    try {
      const departureDates = [];
      const returnDates = [];
      const prices = [];
      flightOptions.forEach((flightOption) => {
        departureDates.push(flightOption?.departure?.split("T")[0]);
        returnDates.push(flightOption?.return?.split("T")[0]);
        prices.push(flightOption.price);
      });
      const uniqueAndSorteddepartureDates = _.uniqBy(departureDates).sort(
        (a, b) => new Date(a) - new Date(b)
      );
      const uniqueAndSortedReturnDates = _.uniqBy(returnDates).sort(
        (a, b) => new Date(a) - new Date(b)
      );

      setdepartureDates(uniqueAndSorteddepartureDates);
      setReturnDates(uniqueAndSortedReturnDates);
      setSortedPrices(_.uniqBy(prices).sort((a, b) => a - b));
    } catch (err) {
      return;
    }
  };

  const getPriceBasedOndepartureAndReturnDate = (departureDate, returnDate) => {
    if (data?.response) {
      const flightOption = data?.response?.filter(
        (flightOption) =>
          flightOption?.departure?.split("T")[0] === departureDate &&
          flightOption?.return?.split("T")[0] === returnDate
      );
      if (flightOption.length === 1) {
        return flightOption[0];
      }
    }
  };

  const getPriceBasedOndepartureDate = (departureDate) => {
    if (data?.response) {
      const flightOption = data?.response?.filter(
        (flightOption) =>
          moment(flightOption.departure).format("YYYY-MM-DD") ===
          moment(departureDate).format("YYYY-MM-DD")
      );

      if (flightOption.length === 1) {
        return flightOption[0];
      }
    }
  };

  const getTravelRange = (type, dateRange) => {
    const firstDay = dateRange[0];
    const lastDay = dateRange[dateRange.length - 1];
    return `${type} ${moment(firstDay).format("MMM D")} - ${moment(
      lastDay
    ).format("MMM D")}`;
  };

  const handleFlightOptionClick = (departureDate, returnDate) => {
    setSource("");
    setDisabledSearch(false);
    if (searchParams.get("flight") === "Return") {
      searchParams.set("return-date", returnDate);
      searchParams.set("departure-date", departureDate);

      setReturnDate(searchParams.get("return-date"));
      setNaxtReturnDate(returnDate);
    }
    if (searchParams.get("flight") === "Multi-city") {
      searchParams.set("departure-date", departureDate + "~" + returnDate);
      setReturnDate(returnDate);
      setNaxtReturnDate(returnDate);
    } else {
      searchParams.set("departure-date", departureDate);
    }

    setNaxtDate(departureDate);
    navigate({
      pathname: `/search`,
      search: searchParams.toString(),
    });
    setdepartureDate(searchParams.get("departure-date"));
  };

  const getPricingColor = (price) => {
    if (price) {
      if (parseInt(price) <= parseInt(sortedPrices[2])) {
        return "green";
      } else {
        return "red";
      }
    }
  };

  const get3DatesBeforeAndAfter = () => {
    const departure = [];
    const returnDate = [];
    ["departure-date", "return-date"].map((type, index) => {
      [...Array(7)].map((_, index) => {
        if (index === 3) {
          if (type === "departure-date") {
            departure.push(
              new Date(checkIfdepartureDateIsTodayORTomorrow(type))
            );
          } else {
            returnDate.push(
              new Date(checkIfdepartureDateIsTodayORTomorrow(type))
            );
          }
        }
        if (index > 3) {
          const getAfterDate = new Date(
            checkIfdepartureDateIsTodayORTomorrow(type)
          ).setDate(
            new Date(checkIfdepartureDateIsTodayORTomorrow(type)).getDate() +
              (index - 3)
          );
          if (type === "departure-date") {
            departure.push(getAfterDate);
          } else {
            returnDate.push(getAfterDate);
          }
        } else if (index < 3) {
          const getBforeDate = new Date(
            checkIfdepartureDateIsTodayORTomorrow(type)
          ).setDate(
            new Date(checkIfdepartureDateIsTodayORTomorrow(type)).getDate() -
              (3 - index)
          );

          if (type === "departure-date") {
            departure.push(getBforeDate);
          } else {
            returnDate.push(getBforeDate);
          }
        }
      });
    });
    setdepartureDates(departure);
    setReturnDates(returnDate);
  };

  const handleNextCalendarBtn = () => {
    setSource("");
    const getdepartureDate = new Date(
      moment(
        new Date(nextDate).setDate(new Date(nextDate).getDate() + 7)
      ).format("Y-MM-DD")
    );
    if (nextReturnDate && searchParams.get("flight") !== "One-way") {
      const getReturnDate = new Date(nextReturnDate);

      if (
        getdepartureDate.getMonth() === getReturnDate.getMonth() &&
        getdepartureDate.getFullYear() === getReturnDate.getFullYear() &&
        getdepartureDate.getDate() >= getReturnDate.getDate()
      ) {
        return false;
      } else if (
        getdepartureDate.getMonth() > getReturnDate.getMonth() &&
        getdepartureDate.getFullYear() === getReturnDate.getFullYear()
      ) {
        return false;
      } else if (getdepartureDate.getFullYear() > getReturnDate.getFullYear()) {
        return false;
      } else {
        setDisabledSearch(true);
        const nextDateWithMoment = moment(
          new Date(nextDate).setDate(new Date(nextDate).getDate() + 7)
        ).format("Y-MM-DD");
        setNaxtDate(nextDateWithMoment);
      }
    } else {
      setDisabledSearch(true);
      const nextDateWithMoment = moment(
        new Date(nextDate).setDate(new Date(nextDate).getDate() + 7)
      ).format("Y-MM-DD");
      setNaxtDate(nextDateWithMoment);
    }
  };

  const handlePreviousCalendarBtn = () => {
    setSource("");
    setDisabledSearch(true);
    const previousDateWithMoment = moment(
      new Date(nextDate).setDate(new Date(nextDate).getDate() - 7)
    ).format("Y-MM-DD");

    const getPastDate = new Date(nextDate).setDate(
      new Date(nextDate).getDate() - 7
    );
    if (
      (new Date(getPastDate).getFullYear() === new Date().getFullYear() &&
        new Date(getPastDate).getMonth() === new Date().getMonth() &&
        new Date(getPastDate).getDate() < new Date().getDate()) ||
      (new Date(getPastDate).getFullYear() === new Date().getFullYear() &&
        new Date(getPastDate).getMonth() < new Date().getMonth()) ||
      new Date(getPastDate).getFullYear() < new Date().getFullYear()
    ) {
      return false;
    } else {
      setNaxtDate(previousDateWithMoment);
    }
  };

  const handleNextReturnCalendarBtn = () => {
    setSource("");
    setDisabledSearch(true);
    const nextDateWithMoment = moment(
      new Date(nextReturnDate).setDate(new Date(nextReturnDate).getDate() + 7)
    ).format("Y-MM-DD");
    setNaxtReturnDate(nextDateWithMoment);
  };

  const handlePreviousReturnCalendarBtn = () => {
    setSource("");
    const getdepartureDate = new Date(nextDate);
    const getReturnDate = new Date(
      moment(
        new Date(nextReturnDate).setDate(new Date(nextReturnDate).getDate() - 7)
      ).format("Y-MM-DD")
    );

    if (
      getdepartureDate.getMonth() === getReturnDate.getMonth() &&
      getdepartureDate.getFullYear() === getReturnDate.getFullYear() &&
      getdepartureDate.getDate() >= getReturnDate.getDate()
    ) {
      return false;
    } else {
      setDisabledSearch(true);
      const nextDateWithMoment = moment(
        new Date(nextReturnDate).setDate(new Date(nextReturnDate).getDate() - 7)
      ).format("Y-MM-DD");
      setNaxtReturnDate(nextDateWithMoment);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);

    if (!open) {
      setCloseModalPrice(true);
    }
  };

  const totalPrice = (currentFlight) => {
    const flight = currentFlight;
    const fareAdjustment = searchForFlights?.data?.response?.fareAdjustment;

    const priceWithFareAdjust =
      fareAdjustment && Object.keys(fareAdjustment)?.length > 0
        ? (
            flight?.price?.total * 1 +
            (fareAdjustment[flight?.id] ? fareAdjustment[flight?.id] : 0)
          ).toFixed(2)
        : flight?.price?.total;

    return priceWithFareAdjust;
  };

  //// handle Validate the flight
  const handeValidatFlight = (flight) => {
    const searchResults = searchForFlights?.data?.response;

    let validateNDCFlight = null;
    /// Check for NDC response to show the price of NDC

    const originFlight = searchParams.get("origin")?.split("~")[0];
    const destinationFlight = searchParams.get("destination")?.split("~")[
      searchParams.get("destination")?.split("~").length - 1
    ];

    const returnDate =
      searchParams.get("flight") === "Return"
        ? searchParams.get("return-date")
        : searchParams.get("departure-date")?.split("~")[
            searchParams.get("departure-date")?.split("~")?.length - 1
          ];

    const departureDate =
      searchParams.get("flight") === "Return"
        ? searchParams.get("departure-date")
        : searchParams.get("departure-date")?.split("~")[0];

    navigate("/booking/travelers-info", {
      state: {
        flight,
        ndcResponse: validateNDCFlight,
        source,
        prevPath: window.location.pathname + window.location.search,
        flightDetails: {
          return: searchParams.get("flight"),
          from: originFlight,
          to: destinationFlight,
          totalDays: moment(returnDate).diff(moment(departureDate), "days") + 1,

          returnDate:
            searchParams.get("flight") === "Return"
              ? searchParams.get("return-date")
              : searchParams.get("departure-date")?.split("~")[
                  searchParams.get("departure-date")?.split("~")?.length - 1
                ],
          departureDate:
            searchParams.get("flight") === "Return"
              ? searchParams.get("departure-date")
              : searchParams.get("departure-date")?.split("~")[0],
        },
        dictionaries: searchResults?.dictionaries,
        priceWithFare: totalPrice(flight),
        amaRefId,
        ...(searchParams.get("officeId") && {
          officeId: searchParams.get("officeId"),
        }),
        fareAdjustment:
          searchResults?.fareAdjustment &&
          searchResults?.fareAdjustment[flight?.id]
            ? searchResults?.fareAdjustment[flight?.id]
            : 0,
      },
    });
    window.scrollTo(0, 0);
  };

  const handleExplore = (departureDate, returnDate) => {
    setSource("");
    setIncludedCarrierCodes([]);
    setAirlinesCodes([]);
    setDisabledSearch(false);
    setNaxtDate(departureDate);
    setNaxtReturnDate(returnDate);
  };

  const clearFilter = () => {
    filters.map((item) => {
      handleRemoveBadge(item);
    });
    setFilters([]);
    setAirlinesCodes([]);
    setIncludedCarrierCodes([]);
  };

  const updateFilter = () => {
    // Change the filter state based on the current filter
    const filter = [...filters];
    allFilters.map((mainFilter) => {
      const findChecked = mainFilter.options.find((i) => i.checked);
      if (findChecked) {
        filter.push(mainFilter);

        setFilters(filter);
      }
    });
  };

  const handleFilters = (currentFilter, remove) => {
    setSource("");
    const filter = [...filters];
    const findFilters = filter.find((i) => i.label === currentFilter.label);
    if (findFilters) {
      if (remove) {
        if (findFilters.multiple) {
          const checkIfAllOptionsDisabled = findFilters?.options.find(
            (i) => i.checked === true
          );
          if (!checkIfAllOptionsDisabled) {
            const cleanFilter = filter.filter(
              (i) => i.label !== currentFilter.label
            );
            setFilters(cleanFilter);
          }
        } else {
          const checkIfAllOptionsDisabled2 = findFilters?.options.find(
            (i) => i.checked
          );
          if (!checkIfAllOptionsDisabled2) {
            const cleanFilter = filter.filter(
              (i) => i.label !== currentFilter.label
            );
            setFilters(cleanFilter);
          }
        }
      }
    } else {
      filter.push(currentFilter);
      setFilters(filter);
    }
  };

  const handleUpdateFilters = (currentFilter, selectedOption) => {
    setDisabledSearch(false);

    // Change the filter state based on the current filter
    const oldState = [...allFilters];
    const findOptionsOFFilter = allFilters.find(
      (filter) => filter.key === currentFilter.key
    ).options;
    let checkParam =
      typeof selectedOption.key === "boolean"
        ? !selectedOption.key
        : selectedOption.key;

    findOptionsOFFilter.forEach((item) => {
      /// Update the radio checkbox state
      if (item.value === selectedOption.value) {
        if (!currentFilter.key) {
          if (item.value === "Checked baggage") {
            searchParams.set("checkedbaggage", !item.checked);
          } else if (item.value === "Satyam Special") {
            searchParams.set(
              "satyamSpecial",
              item.checked ? "PUBLISHED" : "NEGOTIATED"
            );
            checkParam = item.checked ? "PUBLISHED" : "NEGOTIATED";
          }
        }
        item.checked = !item.checked;
      } else if (currentFilter.key) {
        item.checked = false;
      }
      if (typeof selectedOption.key === "boolean") {
        item.key = checkParam;
      }
    });

    /// Handle the search params
    if (currentFilter.key) {
      const checkIfExists = searchParams.get(currentFilter.key);
      if (checkIfExists === checkParam) {
        searchParams.set(currentFilter.key, currentFilter.defaultOption);
      } else {
        searchParams.set(currentFilter.key, checkParam);
      }
    }

    // Update the search url with new params
    navigate({
      pathname: `/search`,
      search: searchParams.toString(),
    });

    // Update the filter state
    setAllFilters(oldState);

    // Update the filter badges
    handleFilters(currentFilter, true);
  };

  const handleChangeCarrier = (code) => {
    setDisabledSearch(false);

    if (includedCarrierCodes.includes(code)) {
      const codesFilters = includedCarrierCodes.filter((i) => i !== code);

      setIncludedCarrierCodes(codesFilters);
      if (codesFilters.length === 0) {
        handleFilters({ label: "Carrier Code" }, true);
      }
    } else {
      const codes = [...includedCarrierCodes];
      codes.push(code);
      setIncludedCarrierCodes(codes);
    }
  };

  const handleRemoveBadge = (currentFilter) => {
    if (currentFilter.label === "Carrier Code") {
      setIncludedCarrierCodes([]);
    } else {
      const oldState = [...allFilters];
      const findOptionsOFFilter = allFilters.find(
        (filter) => filter.key === currentFilter.key
      ).options;

      findOptionsOFFilter.forEach((item) => {
        if (currentFilter.multiple && currentFilter.label === "Baggage") {
          searchParams.set("satyamSpecial", item.defaultOption);
          searchParams.set("checkedbaggage", false);
        }
        item.checked = false;
        if (typeof item.key === "boolean") {
          item.key = false;
        }
      });

      // Update the filter state
      setAllFilters(oldState);
      /// Handle the search params
      if (currentFilter.key) {
        searchParams.set(currentFilter.key, currentFilter.defaultOption);
      }

      if (currentFilter.label === "Office ID") {
        searchParams.delete("officeId");
      }

      // Update the search url with new params
      navigate({
        pathname: `/search`,
        search: searchParams.toString(),
      });
    }
    handleFilters(currentFilter, true);
  };

  //// GDS or NDC
  const handleSourceSearch = (source) => {
    setSource(source);
  };

  /// Get Carrier Logo
  const getCarrierLogo = (departureDate, returnDate) => {
    let logo;
    if (!returnDate) {
      logo =
        airlinesCodes[getPriceBasedOndepartureDate(departureDate)?.airline]
          ?.carrierLogo[0];
    } else {
      logo =
        airlinesCodes[
          getPriceBasedOndepartureAndReturnDate(departureDate, returnDate)
            ?.airline
        ]?.carrierLogo[0];
    }

    return logo?.squareImage ?? logo?.rectangleImage;
  };

  const handleOpenCommissionModal = (flight) => {
    searchForFlights.request({
      originDestinations: getFlightDestinations(),
      officeId: "AMSN222SH",

      ...{ source: "NDC" },
      ...(includedCarrierCodes?.length > 0 && { includedCarrierCodes }),
      ...commonParams,
    });
    if (flight?.source === "GDS") {
      setFlight(flight);
    }
    setOpen2(true);
  };

  useEffect(() => {
    const getNDCPriceForFlight = searchForFlights?.data?.response?.data?.find(
      (getFlight) => getFlight?.id === flight?.id
    );

    if (getNDCPriceForFlight?.source === "NDC") {
      setNdcPrice(getNDCPriceForFlight);
    } else {
      setNdcPrice();
    }
  }, [searchForFlights.data]);

  ///Adjust Prices

  const handleGetTheDestination = () => {
    const flights = [];
    const origins = searchParams.get("origin")?.split("~");
    const destinations = searchParams.get("destination")?.split("~");
    for (let i = 0; i < origins.length; i++) {
      const obj = {
        from: origins[i],
        to: destinations[i],
      };
      flights.push(obj);
    }
    return flights;
  };

  const componentRef = useRef();

  const [adjustedPrice, setAdjustedPrice] = useState(0);
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(false);

  const handleSellingForSurinamePrice = () => {
    const checkIfSellingEdited = adjustedPrice
      ? +adjustedPrice
      : +totalPrice(selectedFlight);
    const getPricePercentage = (checkIfSellingEdited * 1.5) / 100;

    const newPrice = (checkIfSellingEdited + getPricePercentage)?.toFixed(2);

    if (checked) return Math.ceil(newPrice);

    return null;
  };

  const downloadScreenshot = (flight) => {
    const returnDateFromURL = searchParams.get("return-date");
    const originDateFromURL = searchParams.get("departure-date");
    setShow(true);
    const formattedFileName = `${
      flight?.itineraries[0]?.segments[0]?.carrierCode
    }-${handleGetTheDestination()[0]?.from?.substring(
      3,
      0
    )}-${handleGetTheDestination()[
      handleGetTheDestination()?.length - 1
    ]?.to?.substring(3, 0)}-${moment(originDateFromURL?.split("~")[0]).format(
      "DMMM"
    )}${
      searchParams.get("flight") === "Multi-city"
        ? "-" +
          moment(
            originDateFromURL?.split("~")[
              originDateFromURL?.split("~")?.length - 1
            ]
          ).format("DMMM")
        : searchParams.get("flight") === "Return"
        ? "-" +
          moment(
            returnDateFromURL?.split("~")[
              returnDateFromURL?.split("~")?.length - 1
            ]
          ).format("DMMM")
        : ""
    }`;

    if (componentRef.current === null) {
      return;
    }

    setTimeout(() => {
      toPng(componentRef.current)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${formattedFileName}.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          return false;
        })
        .finally(() => {
          setShow(true);
        });
    }, 2000);
  };

  return (
    <>
      <div className="search-form-wrapper">
        <div className="search-form">
          <Banner
            activeBtn
            departureDate={departureDate}
            setdepartureDate={setdepartureDate}
            returnDate={returnDate}
            setReturnDate={setReturnDate}
            handleExplore={handleExplore}
            hideFilter
            airlinesCodes={airlinesCodes && Object.keys(airlinesCodes)}
            airlineCarrier={airlinesCodes}
            onChangeCarrier={handleChangeCarrier}
            includedCarrierCodes={includedCarrierCodes}
            handleFilters={handleFilters}
            allFilters={allFilters}
            setAllFilters={setAllFilters}
            setRedirectLink={setRedirectLink}
            loading={searchForFlights.loading}
          />
        </div>

        <div className="search-results-wrapper">
          <div className="search-results-view">
            <div className="search-results-container">
              <div className="search-form-filter">
                <div>
                  {filters?.length > 0 && (
                    <Box my={1}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mr={1}
                      >
                        <p className="bolder">
                          {filters?.length} filter active
                        </p>
                        <Button onClick={clearFilter} className="active-filter">
                          Clear Filters
                        </Button>
                      </Box>
                      <Box
                        display="flex"
                        my={1}
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        {filters?.map((filter, index) => (
                          <Badge
                            key={index}
                            className="teal"
                            title={filter?.label}
                            iconClose
                            onCloseClick={() => handleRemoveBadge(filter)}
                          />
                        ))}
                      </Box>
                    </Box>
                  )}

                  {allFilters?.map((filter, index) =>
                    !filter?.permission ? (
                      <FilterCollapse
                        key={index}
                        headerTitle={filter.label}
                        collapseChildren={filter.options}
                        onChange={(optionSelected) =>
                          handleUpdateFilters(filter, optionSelected)
                        }
                      />
                    ) : (
                      checkISAdminOrEmployee() && (
                        <FilterCollapse
                          key={index}
                          headerTitle={filter.label}
                          collapseChildren={filter.options}
                          onChange={(optionSelected) =>
                            handleUpdateFilters(filter, optionSelected)
                          }
                        />
                      )
                    )
                  )}
                  {airlinesCodes && Object.keys(airlinesCodes)?.length > 0 && (
                    <>
                      <FilterCollapse
                        headerTitle="Airline Carriers"
                        collapseChildren={Object.keys(airlinesCodes)}
                        onChange={handleChangeCarrier}
                        includedCarrierCodes={includedCarrierCodes}
                        hasImg
                        airlineCarrier={airlinesCodes}
                      />

                      <hr />
                    </>
                  )}
                </div>
              </div>
              <div className="search-results-list">
                {filters?.length > 0 && (
                  <div className="show-mobile horizontal-menu-parent">
                    <div className="horizontal-menu-child">
                      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                        {filters?.map((filter, index) => (
                          <Button key={index}>{filter?.label}</Button>
                        ))}
                        <Button
                          onClick={clearFilter}
                          className="primary-color mx-2"
                        >
                          Clear Filter
                        </Button>
                      </ScrollMenu>
                    </div>
                  </div>
                )}

                {!calendarErr &&
                  searchParams.get("origin")?.split("~").length < 3 && (
                    <div className="price-tabel">
                      <div
                        className="price-tabel-title mobile-show"
                        onClick={toggleDrawer(true)}
                      >
                        <CalPrice />
                        <p className="bolder">Pricing table</p> &nbsp;
                        <ExpandMore />
                      </div>
                      <div
                        className={
                          openPriTable
                            ? "price-tabel-title desktop-show active"
                            : "price-tabel-title desktop-show"
                        }
                        onClick={() => setOpenPriTable(!openPriTable)}
                      >
                        <CalPrice />
                        <p className="bolder">Pricing table</p>&nbsp;
                        {openPriTable ? <ExpandLess /> : <ExpandMore />}
                      </div>

                      <Collapse
                        className="desktop-show"
                        in={openPriTable}
                        timeout="auto"
                        unmountOnExit
                        sx={{ minHeight: "210px" }}
                      >
                        <div className="flex-depart">
                          <Button
                            onClick={handlePreviousCalendarBtn}
                            data-cy="d-arrow-previous"
                          >
                            <ArrowDown />
                          </Button>
                          <Button
                            data-cy="d-arrow-next"
                            onClick={handleNextCalendarBtn}
                          >
                            <ArrowDown />
                          </Button>
                          <p className="small-font-size bold">
                            {getTravelRange("Departure ", departureDates)}
                          </p>
                        </div>

                        <div className="date-calendar-table">
                          {searchParams.get("flight") !== "One-way" && (
                            <div className="return-date flex-depart">
                              <Button>
                                <ArrowDown
                                  onClick={handlePreviousReturnCalendarBtn}
                                  data-cy="r-arrow-previous"
                                />
                              </Button>
                              <Button>
                                <ArrowDown
                                  onClick={handleNextReturnCalendarBtn}
                                  data-cy="r-arrow-next"
                                />
                              </Button>

                              <p className="small-font-size bold">
                                {getTravelRange("Return ", returnDates)}
                              </p>
                            </div>
                          )}
                          <div className="date-cells-wrapper">
                            {departureDates.map((departureDate, index) => (
                              <div
                                key={index}
                                className="date-cell"
                                style={{
                                  width:
                                    searchParams.get("flight") !== "One-way"
                                      ? "12%"
                                      : "15%",
                                }}
                              >
                                {formatDateToMoment(departureDate)}
                              </div>
                            ))}
                          </div>
                          <div className="date-calendar-container">
                            {calendarLoading
                              ? [
                                  ...Array(
                                    searchParams.get("flight") !== "One-way"
                                      ? 7
                                      : 8
                                  ),
                                ].map((_, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      width:
                                        searchParams.get("flight") !== "One-way"
                                          ? "12%"
                                          : "15%",
                                    }}
                                    className="date-cells-wrapper right-side"
                                  >
                                    {[...Array(7)].map((_, index) => (
                                      <Skeleton
                                        key={index}
                                        variant="text"
                                        height="38.625px"
                                        width="98%"
                                        className="price-cell"
                                        animation="wave"
                                        sx={{ bgcolor: "#F1F3F6" }}
                                      />
                                    ))}
                                  </div>
                                ))
                              : searchParams.get("flight") !== "One-way"
                              ? departureDates?.map(
                                  (departureDate, departureIndex) => (
                                    <div
                                      className="date-cells-wrapper right-side"
                                      key={departureIndex}
                                    >
                                      {returnDates.map(
                                        (returnDate, returnIndex) => (
                                          <div
                                            key={returnIndex}
                                            onMouseOut={() => {
                                              setActivePrice({
                                                ...activePrice,
                                                return: "",
                                                departure: "",
                                              });
                                            }}
                                            onMouseOver={() => {
                                              setActivePrice({
                                                ...activePrice,
                                                return: returnIndex,
                                                departure: departureIndex,
                                              });
                                            }}
                                            onClick={() => {
                                              handleFlightOptionClick(
                                                departureDate,
                                                returnDate
                                              );
                                            }}
                                            style={{
                                              color:
                                                (departureIndex ===
                                                  activePrice.departure &&
                                                  returnIndex ===
                                                    activePrice.return) ||
                                                (returnIndex ===
                                                  returnDates?.indexOf(
                                                    nextReturnDate
                                                  ) &&
                                                  departureIndex ===
                                                    departureDates?.indexOf(
                                                      nextDate
                                                    ))
                                                  ? "#fff"
                                                  : getPricingColor(
                                                      getPriceBasedOndepartureAndReturnDate(
                                                        departureDate,
                                                        returnDate
                                                      )?.price
                                                    ),
                                              pointerEvents:
                                                getPriceBasedOndepartureAndReturnDate(
                                                  departureDate,
                                                  returnDate
                                                )?.price
                                                  ? "all"
                                                  : "none",
                                              cursor:
                                                getPriceBasedOndepartureAndReturnDate(
                                                  departureDate,
                                                  returnDate
                                                )?.price
                                                  ? "pointer"
                                                  : "default",
                                              backgroundColor:
                                                (departureIndex ===
                                                  activePrice.departure &&
                                                  returnIndex ===
                                                    activePrice.return) ||
                                                (returnIndex ===
                                                  returnDates?.indexOf(
                                                    nextReturnDate
                                                  ) &&
                                                  departureIndex ===
                                                    departureDates?.indexOf(
                                                      nextDate
                                                    ))
                                                  ? "#f15e22"
                                                  : (returnIndex <=
                                                      activePrice.return &&
                                                      departureIndex ===
                                                        activePrice.departure) ||
                                                    (returnIndex ===
                                                      activePrice.return &&
                                                      departureIndex >=
                                                        activePrice.departure) ||
                                                    (departureIndex ===
                                                      departureDates?.indexOf(
                                                        nextDate
                                                      ) &&
                                                      returnIndex <=
                                                        returnDates?.indexOf(
                                                          nextReturnDate
                                                        )) ||
                                                    (returnIndex ===
                                                      returnDates?.indexOf(
                                                        nextReturnDate
                                                      ) &&
                                                      departureIndex >=
                                                        departureDates?.indexOf(
                                                          nextDate
                                                        ))
                                                  ? // returnDates?.indexOf(
                                                    //   searchParams.get(
                                                    //     "return-date"
                                                    //   )
                                                    // )
                                                    "rgb(248 228 220)"
                                                  : "#fff",
                                            }}
                                            className="price-cell"
                                          >
                                            {getPriceBasedOndepartureAndReturnDate(
                                              departureDate,
                                              returnDate
                                            )?.price ? (
                                              <>
                                                <Tooltip
                                                  title={
                                                    airlinesCodes[
                                                      getPriceBasedOndepartureAndReturnDate(
                                                        departureDate,
                                                        returnDate
                                                      )?.airline
                                                    ]?.carrierName
                                                      ? capitalizeFirstLetter(
                                                          airlinesCodes[
                                                            getPriceBasedOndepartureAndReturnDate(
                                                              departureDate,
                                                              returnDate
                                                            )?.airline
                                                          ]?.carrierName
                                                        )
                                                      : ""
                                                  }
                                                  arrow
                                                  placement="top"
                                                >
                                                  <img
                                                    id="flight-logo-calendar"
                                                    src={getCarrierLogo(
                                                      departureDate,
                                                      returnDate
                                                    )}
                                                  />
                                                </Tooltip>

                                                {currency?.sign}
                                                {
                                                  getPriceBasedOndepartureAndReturnDate(
                                                    departureDate,
                                                    returnDate
                                                  )?.price
                                                }
                                              </>
                                            ) : null}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )
                                )
                              : departureDates?.map(
                                  (departureDate, departureIndex) => (
                                    <div
                                      className="date-cells-wrapper right-side"
                                      key={departureIndex}
                                      style={{
                                        width: "15%",
                                      }}
                                    >
                                      <div
                                        className="price-cell"
                                        style={{
                                          color: getPricingColor(
                                            getPriceBasedOndepartureDate(
                                              departureDate
                                            )?.price
                                          ),

                                          cursor: getPriceBasedOndepartureDate(
                                            departureDate
                                          )?.price
                                            ? "pointer"
                                            : "default",
                                          pointerEvents:
                                            getPriceBasedOndepartureDate(
                                              departureDate
                                            )?.price
                                              ? "all"
                                              : "none",
                                        }}
                                        onClick={() => {
                                          handleFlightOptionClick(
                                            moment(departureDate).format(
                                              "YYYY-MM-DD"
                                            )
                                          );
                                        }}
                                      >
                                        {getPriceBasedOndepartureDate(
                                          departureDate
                                        )?.price ? (
                                          <>
                                            <Tooltip
                                              title={
                                                airlinesCodes[
                                                  getPriceBasedOndepartureDate(
                                                    departureDate
                                                  )?.airline
                                                ]?.carrierName
                                                  ? capitalizeFirstLetter(
                                                      airlinesCodes[
                                                        getPriceBasedOndepartureDate(
                                                          departureDate
                                                        )?.airline
                                                      ]?.carrierName
                                                    )
                                                  : ""
                                              }
                                              arrow
                                              placement="top"
                                            >
                                              <img
                                                id="flight-logo-calendar"
                                                src={getCarrierLogo(
                                                  departureDate
                                                )}
                                              />
                                            </Tooltip>

                                            {currency?.sign}
                                            {
                                              getPriceBasedOndepartureDate(
                                                departureDate
                                              )?.price
                                            }
                                          </>
                                        ) : null}
                                      </div>
                                    </div>
                                  )
                                )}
                            {searchParams.get("flight") !== "One-way" && (
                              <div className="date-cells-wrapper right-side">
                                {returnDates.map((returnDate, index) => (
                                  <div className="date-cell" key={index}>
                                    {formatDateToMoment(returnDate)}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  )}
                <Box
                  width="580px"
                  alignItems="center"
                  justifyContent="center"
                  p={2}
                  sx={{
                    display: show ? "flex" : "none",
                    position: "fixed",
                    top: "-1000px",
                    left: "0px",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,0.8)",
                    zIndex: 1009,
                  }}
                >
                  <Box ref={componentRef} width="580px">
                    <ShareQuatePrice
                      flight={selectedFlight}
                      dictionaries={
                        searchForFlights?.data?.response?.dictionaries
                      }
                      adjustedPrice={adjustedPrice}
                      destinationLocationCode={searchParams.get("destination")}
                      originLocationCode={searchParams.get("origin")}
                      totalPrice={totalPrice}
                      currency={currency?.sign}
                      getSellingForSurinamePrice={handleSellingForSurinamePrice}
                      checked={checked}
                      daysNumber={
                        searchParams.get("flight") === "Return"
                          ? moment(searchParams.get("return-date")).diff(
                              moment(searchParams.get("departure-date")),
                              "days"
                            ) + 1
                          : null
                      }
                      fareAdjustment={
                        searchForFlights?.data?.response?.fareAdjustment
                      }
                    />
                  </Box>
                </Box>
                {searchForFlights.loading ? (
                  <div className="card-card d-flex skeleton-search card-wrapper">
                    <div className="circle-card one"></div>
                    <div className="circle-card two"></div>
                    <div className="circle-card three"></div>
                    <div className="circle-card four"></div>
                    <div className="skeleton-search-left">
                      <Skeleton
                        variant="text"
                        width={"40%"}
                        height={30}
                        animation="wave"
                        sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={100}
                        sx={{
                          marginBottom: 3,
                          bgcolor: "#F1F3F6",
                          borderRadius: "4px",
                        }}
                        animation="wave"
                      />
                      <Skeleton
                        variant="text"
                        width={"40%"}
                        height={30}
                        sx={{
                          marginBottom: 1,
                          bgcolor: "#F1F3F6",
                          borderRadius: "4px",
                        }}
                        animation="wave"
                      />
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={100}
                        sx={{
                          marginBottom: 1,
                          bgcolor: "#F1F3F6",
                          borderRadius: "4px",
                        }}
                        animation="wave"
                      />
                    </div>
                    <div className="skeleton-search-right">
                      <Skeleton
                        variant="rectangular"
                        width={"90%"}
                        height={100}
                        sx={{
                          marginBottom: 3,
                          bgcolor: "#F1F3F6",
                          borderRadius: "4px",
                        }}
                        animation="wave"
                      />
                      <Skeleton
                        variant="rectangular"
                        width={"90%"}
                        height={30}
                        sx={{
                          marginBottom: 1,
                          bgcolor: "#F1F3F6",
                          borderRadius: "4px",
                        }}
                        animation="wave"
                      />
                    </div>
                  </div>
                ) : searchForFlights?.error ||
                  searchForFlights?.data?.response?.data?.length === 0 ? (
                  <div className="empty-data-msg">
                    <h1 className="medium-font-size title-header">
                      No available flights found !
                    </h1>
                  </div>
                ) : (
                  <>
                    {searchForFlights?.data?.response?.data?.map(
                      (flight, index) => (
                        <ResultCard
                          handeValidatFlight={handeValidatFlight}
                          originLocationCode={searchParams.get("origin")}
                          destinationLocationCode={searchParams.get(
                            "destination"
                          )}
                          key={index}
                          flight={flight}
                          dictionaries={
                            searchForFlights?.data?.response?.dictionaries
                          }
                          flightSearchCriteria={{
                            originDestinations: getFlightDestinations(),
                            ...commonParams,
                          }}
                          totalPrice={totalPrice}
                          handleSourceSearch={handleSourceSearch}
                          source={source}
                          currency={currency?.sign}
                          cardID={searchParams.get("cardNum")}
                          returnDate={
                            searchParams.get("flight") === "Return"
                              ? searchParams.get("return-date")
                              : searchParams.get("departure-date")?.split("~")[
                                  searchParams.get("departure-date")?.split("~")
                                    ?.length - 1
                                ]
                          }
                          departurDate={
                            searchParams.get("flight") === "Return"
                              ? searchParams.get("departure-date")
                              : searchParams
                                  .get("departure-date")
                                  ?.split("~")[0]
                          }
                          flightType={searchParams.get("flight")}
                          officeId={searchParams.get("officeId")}
                          searchWithNDC={searchWithNDC}
                          setSearchWithNDC={setSearchWithNDC}
                          handleOpenCommissionModal={handleOpenCommissionModal}
                          getSelectedFLight={setSelectedFlight}
                          setAdjustedPrice={setAdjustedPrice}
                          adjustedPrice={adjustedPrice}
                          checked={checked}
                          setChecked={setChecked}
                          handlePrintComponent={() =>
                            downloadScreenshot(flight)
                          }
                          hasSale={flight?.source === "NDC"}
                          itineraries={handleGetTheDestination()}
                        />
                      )
                    )}
                  </>
                )}
              </div>
              {/* <VirtualReality /> */}
              <SideBar />
            </div>
          </div>
        </div>
      </div>
      <ModalCom
        className={`
        full-size-modal tabel-price
       `}
        toggleDrawer={toggleDrawer}
        sx={{ "& .MuiPaper-root": { overflow: "hidden " } }}
        openDrawer={
          openDrawer ||
          (!closePriceModal &&
            searchForFlights?.data?.response?.data?.length === 0 &&
            window.innerWidth < 768)
        }
      >
        <div className="modal-tilte">
          <p className="bolder">Pricing Table</p> &nbsp;
        </div>
        <div className="d-flex">
          <div className="modal-tabel-price">
            <Box
              className="flex-depart"
              minHeight="30px"
              position="sticky"
              left="0"
              top="0px"
              zIndex={99}
            >
              <Button
                onClick={handlePreviousCalendarBtn}
                data-cy="d-arrow-previous"
              >
                <ArrowDown />
              </Button>
              <Button data-cy="d-arrow-next" onClick={handleNextCalendarBtn}>
                <ArrowDown />
              </Button>
              <p className="small-font-size bold">
                {getTravelRange("Departure ", departureDates)}
              </p>
            </Box>

            <div className="departure-wrapper-mobile">
              <div className="date-cells-wrapper">
                {departureDates.map((departureDate, index) => (
                  <div key={index} className="date-cell">
                    {formatDateToMoment(departureDate)}
                  </div>
                ))}
              </div>
            </div>
            <div className="calendar-container-mobile">
              <div className="date-calendar-container">
                {calendarLoading
                  ? [...Array(7)].map((_, index) => (
                      <div
                        key={index}
                        className="date-cells-wrapper right-side"
                      >
                        {[...Array(7)].map((_, index) => (
                          <Skeleton
                            variant="text"
                            height="55px"
                            width="98%"
                            className="price-cell"
                            animation="wave"
                            sx={{ bgcolor: "#F1F3F6" }}
                          />
                        ))}
                      </div>
                    ))
                  : departureDates?.map((departureDate, departureIndex) => (
                      <div
                        className="date-cells-wrapper right-side"
                        key={departureIndex}
                      >
                        {returnDates.map((returnDate, returnIndex) => (
                          <div
                            key={returnIndex}
                            onMouseOut={() => {
                              setActivePrice({
                                ...activePrice,
                                return: "",
                                departure: "",
                              });
                            }}
                            onMouseOver={() => {
                              setActivePrice({
                                ...activePrice,
                                return: returnIndex,
                                departure: departureIndex,
                              });
                            }}
                            onClick={() => {
                              handleFlightOptionClick(
                                departureDate,
                                returnDate
                              );
                              setOpenDrawer(false);
                            }}
                            style={{
                              color:
                                (departureIndex === activePrice.departure &&
                                  returnIndex === activePrice.return) ||
                                (returnIndex ===
                                  returnDates?.indexOf(
                                    searchParams.get("return-date")
                                  ) &&
                                  departureIndex ===
                                    departureDates?.indexOf(nextDate))
                                  ? "#fff"
                                  : getPricingColor(
                                      getPriceBasedOndepartureAndReturnDate(
                                        departureDate,
                                        returnDate
                                      )?.price
                                    ),
                              pointerEvents:
                                getPriceBasedOndepartureAndReturnDate(
                                  departureDate,
                                  returnDate
                                )?.price
                                  ? "all"
                                  : "none",
                              cursor: getPriceBasedOndepartureAndReturnDate(
                                departureDate,
                                returnDate
                              )?.price
                                ? "pointer"
                                : "default",
                              backgroundColor:
                                (departureIndex === activePrice.departure &&
                                  returnIndex === activePrice.return) ||
                                (returnIndex ===
                                  returnDates?.indexOf(
                                    searchParams.get("return-date")
                                  ) &&
                                  departureIndex ===
                                    departureDates?.indexOf(nextDate))
                                  ? "#f15e22"
                                  : (returnIndex <= activePrice.return &&
                                      departureIndex ===
                                        activePrice.departure) ||
                                    (returnIndex === activePrice.return &&
                                      departureIndex >=
                                        activePrice.departure) ||
                                    (departureIndex ===
                                      departureDates?.indexOf(nextDate) &&
                                      returnIndex <=
                                        returnDates?.indexOf(
                                          searchParams.get("return-date")
                                        )) ||
                                    (returnIndex ===
                                      returnDates?.indexOf(
                                        searchParams.get("return-date")
                                      ) &&
                                      departureIndex >=
                                        departureDates?.indexOf(nextDate))
                                  ? "rgb(248 228 220)"
                                  : "#fff",
                            }}
                            className="price-cell"
                          >
                            {getPriceBasedOndepartureAndReturnDate(
                              departureDate,
                              returnDate
                            )?.price ? (
                              <>
                                <img
                                  id="flight-logo-calendar"
                                  src={getCarrierLogo(
                                    departureDate,
                                    returnDate
                                  )}
                                />
                                {currency?.sign}
                                {
                                  getPriceBasedOndepartureAndReturnDate(
                                    departureDate,
                                    returnDate
                                  )?.price
                                }
                              </>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    ))}
              </div>
            </div>
          </div>
          <div className="return-wrapper-mobile">
            {searchParams.get("flight") !== "One-way" && (
              <div className="return-date flex-depart">
                <Button>
                  <ArrowDown
                    onClick={handlePreviousReturnCalendarBtn}
                    data-cy="r-arrow-previous"
                  />
                </Button>
                <Button>
                  <ArrowDown
                    onClick={handleNextReturnCalendarBtn}
                    data-cy="r-arrow-next"
                  />
                </Button>

                <p className="small-font-size bold">
                  {getTravelRange("Return ", returnDates)}
                </p>
              </div>
            )}
            <div className="date-cells-wrapper right-side">
              {returnDates.map((returnDate, index) => (
                <div className="date-cell" key={index}>
                  {formatDateToMoment(returnDate)}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <Button className="w-100" onClick={toggleDrawer(false)}>
            Cancel
          </Button>
          {/* <Button className="primary-color w-100">Select</Button> */}
        </div>
      </ModalCom>
      <Modal
        open={openPop}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pop-up-modal">
          {loading ? (
            <>
              <p className="main-title">
                Hang tight while we check whether this flight is still
                available!
              </p>
              <LinearProgress sx={{ backgroundColor: "#edcfa7" }} />
            </>
          ) : (
            <>
              <p className="main-title">This flight is not available now!</p>
              <div className="footer-popup">
                <Button onClick={() => setOpenPop(false)}>Cancel</Button>
                <Button
                  onClick={() => {
                    setOpenPop(false);
                    window.location.reload();
                  }}
                  className="primary-color"
                >
                  Refresh
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>

      <CommissionModal
        getNdcPrice={ndcPrice}
        flight={flight}
        open={open2}
        setOpen={setOpen2}
        loading={searchForFlights.loading}
      />
    </>
  );
};

export default Search;

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <div
      className="horizental-btn left"
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
    >
      <svg
        className="Icon__StyledIcon-sc-1det6wr-0 fhAORE"
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
      >
        <path d="M15.254 16.089c.369.333.398.904.065 1.274a.898.898 0 01-1.27.066l-5.303-4.795a.904.904 0 01.002-1.343l5.256-4.72a.898.898 0 011.271.07c.332.372.3.942-.07 1.275l-4.18 3.753a.4.4 0 000 .594l4.229 3.826z"></path>
      </svg>
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <div
      className="horizental-btn right"
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
    >
      <svg
        className="Icon__StyledIcon-sc-1det6wr-0 fhAORE"
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
      >
        <path d="M8.746 16.089l4.23-3.825a.4.4 0 00-.001-.595l-4.18-3.753a.904.904 0 01-.07-1.275.898.898 0 011.27-.07l5.256 4.72c.399.358.4.983.003 1.342L9.952 17.43a.898.898 0 01-1.271-.066.904.904 0 01.065-1.274z"></path>
      </svg>
    </div>
  );
}
