import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import * as Yup from "yup";
import valid from "card-validator";

import BannerInput from "components/bannerInput";

import Button from "components/button";
const Payment = ({ id, handleHotelSell, loading }) => {
  const getEnv = process.env.REACT_APP_ENV === "DEV";

  const validationSchema = Yup.object().shape({
    cardNumber: Yup.string()
      .required()
      .test(
        "cardNumber",
        "Credit Card number is invalid",
        (value) => valid.number(value).isValid
      )
      .label("Credit Card number"),
    securityId: Yup.string()
      .required()
      .test(
        "securityId",
        "CVV number is invalid",
        (value) => valid.cvv(value).isValid
      )
      .label("CVV number"),
    expiryDate: Yup.string()
      .required()
      .test(
        "expiryDate",
        "Expiry Date is invalid",
        (value) => valid.expirationDate(value).isValid
      )
      .label("Expiry Date"),
  });

  return (
    <Box className="card-card" height="auto">
      <Typography mb={1} fontWeight="bold">
        Please Complete the payment to Finish the Proccess
      </Typography>
      <Formik
        initialValues={{
          cardNumber: "",
          securityId: "",
          expiryDate: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleHotelSell}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          values,
          setFieldValue,
        }) => (
          <>
            {getEnv && (
              <Button
                onClick={() => {
                  // Auto Fill for fast testing
                  setFieldValue("cardNumber", "4911830000000000");
                  setFieldValue("securityId", "555");
                  setFieldValue("expiryDate", "1024");
                }}
              >
                Auto Fill
              </Button>
            )}

            <Box mt={3} sx={{ "& > .input-group-container": { width: "50%" } }}>
              <BannerInput
                label="Credit Card Number"
                name="cardNumber"
                onChange={handleChange("cardNumber")}
                mandatory
                error={touched.cardNumber && errors.cardNumber}
                onBlur={() => setFieldTouched("cardNumber")}
                placeholderInput="Valid Card Number"
                value={values["cardNumber"]}
              />
              <Box
                display="flex"
                mt={3}
                flexWrap={{ xs: "wrap", md: "nowrap" }}
                gap={2}
              >
                <BannerInput
                  label="CVV number"
                  name="securityId"
                  onChange={handleChange("securityId")}
                  mandatory
                  error={touched.securityId && errors.securityId}
                  onBlur={() => setFieldTouched("securityId")}
                  placeholderInput="CVV"
                  value={values["securityId"]}
                />
                <BannerInput
                  name="expiryDate"
                  label="Expiry Date"
                  onChange={handleChange("expiryDate")}
                  mandatory
                  error={touched.expiryDate && errors.expiryDate}
                  placeholderInput="MM/YY"
                  onBlur={() => setFieldTouched("expiryDate")}
                  value={values["expiryDate"]}
                />
              </Box>
            </Box>
            <Button
              className="w-100 bigBtn primary-color mt-5"
              onClick={handleSubmit}
              loading={loading}
              type="submit"
            >
              Submit the payment
            </Button>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default Payment;
