import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Skeleton from "@mui/material/Skeleton";

import Badge from "components/badge";
import { TravelersList } from "api/intelligence";
import useApi from "hooks/useApi";

import { fDate } from "utils/time-format";

import CheckIcon from "@mui/icons-material/Check";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const RenderTravelerCard = ({ traveler, onSelect }) => {
  return (
    <Box
      py={1}
      px={1.5}
      mx={1}
      position="relative"
      borderRadius="5px"
      boxShadow="rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
      onClick={onSelect}
      my={1}
      sx={{
        cursor: "pointer",
        "& .hover-check": {
          display: "none",
        },
        "&:hover": {
          boxShadow:
            "rgba(241,94,34, 0.6) 0px 1px 3px 0px, rgba(241,94,34, 0.6) 0px 0px 0px 1px",
          "& .hover-check": {
            display: "block",
          },
        },
      }}
    >
      {traveler?.isImported && <Badge className="blue" title="From Module" />}
      <Box display="flex" gap={1.5}>
        <Box
          width={"24px"}
          height={"24px"}
          borderRadius="50%"
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
          sx={{
            backgroundColor: traveler?.isExpired
              ? "rgba(239,83,80,0.16)"
              : "rgba(76,175,80,0.16)",
          }}
          mt={1}
        >
          {traveler?.documents && traveler?.documents[0]?.isExpired ? (
            <LightbulbIcon color="error" sx={{ width: "16px" }} />
          ) : (
            <CheckIcon color="success" sx={{ width: "16px" }} />
          )}
        </Box>
        <Box>
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="subtitle2">
              {traveler?.firstName} {traveler?.lastName}
            </Typography>
            <Badge className="teal" title={traveler?.gender?.charAt()} />
          </Box>
          <Typography mb={1} variant="subtitle2" color="primary">
            {fDate(traveler?.dateOfBirth)}
          </Typography>
          {traveler?.documents?.length > 0 && (
            <Typography color="text.disabled" variant="body2">
              {traveler?.documents[0]?.number}
            </Typography>
          )}
          <Typography color="text.disabled" variant="body2">
            Phone Number: {traveler?.contact?.phoneNumber}
          </Typography>
        </Box>

        <Box
          position="absolute"
          right="24px"
          className="hover-check"
          sx={{ top: "50%", transform: "translateY(-50%)" }}
        >
          <CheckCircleIcon color="success" />
        </Box>
      </Box>
    </Box>
  );
};

function AddExistingTravelers({ onSelect }) {
  const [searchQuery, setSearchQuery] = useState("");
  const getTravelersList = useApi(TravelersList);

  useEffect(() => {
    handleGetAllTravelersList();
  }, [searchQuery]);

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleGetAllTravelersList = () => {
    getTravelersList.request({
      searchQuery,
    });
  };

  return (
    <Box>
      <Typography variant="h6" mb={3}>
        Existing Travelers
      </Typography>

      <TextField
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
        id="outlined-basic"
        variant="outlined"
        sx={{
          boxShadow: "rgb(186 199 213) 0px 0px 0px 1px",
          borderRadius: "5px",
          fontSize: "12px",
          "& input::placeholder": {
            fontSize: "14px",
            px: 1,
          },
        }}
        placeholder="Firstname, Lastname, Passport Number, Email Or Phone Number"
        value={searchQuery}
        onChange={handleChange}
      />

      {getTravelersList?.loading ? (
        <Box mt={2}>
          {[...Array(3)].map((_, i) => (
            <Box py={1} px={1.5} mx={1} key={i}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          ))}
        </Box>
      ) : getTravelersList.data?.travelers?.length === 0 ? (
        <Box textAlign="center" my={6}>
          <Typography fontWeight="bold">Not Found</Typography>

          <Typography mt={2}>
            No results found for <strong>"{searchQuery}"</strong> .
          </Typography>
          <Typography>
            Search only works by firstname, lastname, passport-number, email, or
            phone-number
          </Typography>
        </Box>
      ) : (
        <Box
          maxHeight="500px"
          minHeight="300px"
          mt={2}
          sx={{ overflowX: "hidden", overflowY: "auto" }}
        >
          {getTravelersList.data?.travelers?.map((traveler) => (
            <RenderTravelerCard
              traveler={traveler}
              key={traveler?._id}
              onSelect={() => onSelect(traveler)}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}

export default AddExistingTravelers;
