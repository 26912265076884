import React, { useState } from "react";
import Box from "@mui/material/Box";
import moment from "moment";

import Badge from "components/badge";
import BannerInput from "components/bannerInput";
import AppButton from "components/button";

import { ReactComponent as Calendar } from "assets/images/calendar.svg";
import { ReactComponent as AirPlane } from "assets/images/airplate.svg";
import { ReactComponent as Children } from "assets/images/children.svg";
import { ReactComponent as Infants } from "assets/images/infants.svg";
import { ReactComponent as SeatingS } from "assets/images/seating.svg";
import { ReactComponent as Checkedbaggage } from "assets/images/checkedbaggage.svg";

function Overview({
  overviewData,
  state,
  travelers,
  paymentType,
  handelReverseSubmit,
  setPaymentType,
}) {
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = () => {
    setChecked(!checked);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    width: { xs: "90%", md: "550px" },
    p: 4,
    "&:focus": {
      outline: "none",
    },
  };

  return (
    <>
      <div className="card-card overview">
        <p className="medium-font-size">Overview</p>
        <Box mt={3}>
          <p className="medium-font-size">Itinerary</p>

          {overviewData && (
            <div className="journey-details">
              {overviewData?.data?.flightOffers?.map((flighOffer) =>
                flighOffer?.itineraries?.map((segmant, index) => (
                  <div className="itinerary-trip-wrapper">
                    {segmant?.segments?.map((flight) => (
                      <>
                        <div className="itinerary-trip">
                          <div className="itinerary-trip-title">
                            <span className="itinerary-depart">
                              {index === 0 ? "departure" : "Return"}
                            </span>
                            <span className="itinerary-depart-time">
                              Duration :
                              {`
${flight?.duration?.substring(
  flight?.duration?.indexOf("T") + 1,
  flight?.duration?.lastIndexOf("H")
)}h
${
  flight?.duration?.split("H").pop().split("M")[0]
    ? ", " + flight?.duration?.split("H").pop().split("M")[0] + "m"
    : ""
} `}
                            </span>
                          </div>
                          <p className="bold mb-2">
                            {
                              state?.dictionaries?.locations[
                                flight?.departure?.iataCode
                              ]?.city
                            }

                            <span>
                              <svg
                                className="Icon__StyledIcon-sc-1det6wr-0 fJfkpT"
                                viewBox="0 0 24 24"
                                preserveAspectRatio="xMidYMid meet"
                              >
                                <path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path>
                              </svg>
                            </span>

                            {
                              state?.dictionaries?.locations[
                                flight?.arrival?.iataCode
                              ]?.city
                            }
                          </p>
                          <div className="itinerary-timeline">
                            <div>
                              <div className="itinerary-date">
                                <div>
                                  <div className="itinerary-icon-wrapper">
                                    <Calendar />
                                  </div>
                                  <p>
                                    <strong>
                                      {" "}
                                      {moment(flight?.departure?.at).format(
                                        "ddd D MMM"
                                      )}
                                    </strong>
                                  </p>
                                </div>
                                <div className="d-flex">
                                  {state?.dictionaries?.airlineLogos[
                                    flight?.carrierCode
                                  ][0]?.squareImage ? (
                                    <img
                                      className="results-card-logo"
                                      src={
                                        state?.dictionaries?.airlineLogos[
                                          flight?.carrierCode
                                        ][0]?.tailImage
                                      }
                                    />
                                  ) : (
                                    <img
                                      className="results-card-logo"
                                      src={
                                        state?.dictionaries?.airlineLogos[
                                          flight?.carrierCode
                                        ][0]?.squareImage
                                      }
                                    />
                                  )}
                                  {state?.flight?.travelerPricings[0]?.fareDetailsBySegment?.find(
                                    (sege) => sege?.segmentId == flight?.id
                                  )?.cabin && (
                                    <Badge
                                      className="teal"
                                      title={
                                        state?.flight?.travelerPricings[0]?.fareDetailsBySegment?.find(
                                          (sege) =>
                                            sege?.segmentId == flight?.id
                                        )?.cabin
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="itinerary-flight">
                                <div className="itinerary-milestone">
                                  <div className="itinerary-milestone-icon">
                                    <AirPlane />
                                  </div>
                                  <div className="itinerary-collapse">
                                    <div
                                      onClick={handleChange}
                                      className={`collapse-container ${
                                        checked ? "collapsed" : "incollapsed"
                                      }`}
                                    >
                                      <div>
                                        <p>
                                          {moment(flight?.departure?.at).format(
                                            "H:mm"
                                          )}{" "}
                                          <span className="location">
                                            {
                                              state?.dictionaries.locations[
                                                flight.departure.iataCode
                                              ].airport
                                            }
                                          </span>
                                        </p>
                                        {flight?.duration && (
                                          <p className="flight-time-duration">
                                            {`
${flight?.duration?.substring(
  flight?.duration?.indexOf("T") + 1,
  flight?.duration?.lastIndexOf("H")
)}h
${
  flight?.duration?.split("H").pop().split("M")[0]
    ? ", " + flight?.duration?.split("H").pop().split("M")[0] + "m"
    : ""
} `}
                                          </p>
                                        )}
                                      </div>
                                      <p className="small-font-size light-gray">
                                        Flight no: {flight?.number}
                                      </p>
                                      <p className="small-font-size light-gray">
                                        Airline:{" "}
                                        <span className="small-font-size light-gray">
                                          {
                                            state?.dictionaries?.carriers[
                                              flight?.carrierCode
                                            ]
                                          }
                                        </span>
                                      </p>
                                      <p className="small-font-size light-gray">
                                        Aircraft:{" "}
                                        <span className="small-font-size light-gray">
                                          {flight?.aircraft?.code}
                                        </span>
                                      </p>

                                      <p>
                                        {moment(flight?.arrival?.at).format(
                                          "H:mm"
                                        )}{" "}
                                        <span className="location">
                                          {
                                            state?.dictionaries.locations[
                                              flight?.arrival.iataCode
                                            ].airport
                                          }
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="arrival-time">
                              <strong>
                                {" "}
                                {moment(flight?.arrival?.at).format(
                                  "ddd D MMM"
                                )}
                              </strong>
                            </p>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                ))
              )}
            </div>
          )}
        </Box>
        <Box mt={2}>
          <p className="medium-font-size">Passengers</p>
          {travelers?.map((traveler) => (
            <Box py={2}>
              <Box display="flex" flexWrap="wrap">
                <Box width="40%">
                  <Box display="flex">
                    <Box ml={1} width="24px">
                      {overviewData?.data?.flightOffers[0]?.travelerPricings?.find(
                        (trav) => trav.travelerId == traveler.id
                      )?.travelerType === "ADULT" ? (
                        <svg
                          className="small-svg"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9 11.041v-0.825c1.102-0.621 2-2.168 2-3.716 0-2.485 0-4.5-3-4.5s-3 2.015-3 4.5c0 1.548 0.898 3.095 2 3.716v0.825c-3.392 0.277-6 1.944-6 3.959h14c0-2.015-2.608-3.682-6-3.959z"></path>
                        </svg>
                      ) : overviewData?.data?.flightOffers[0]?.travelerPricings?.find(
                          (trav) => trav.travelerId == traveler.id
                        )?.travelerType === "CHILD" ? (
                        <Children className="small-svg" />
                      ) : (
                        <Infants className="small-svg" />
                      )}
                    </Box>
                    <p>
                      {traveler?.name?.firstName} {traveler?.name?.lastName}
                    </p>
                  </Box>
                </Box>
                <Box width="30%" minWidth={155} display="flex" ml={2}>
                  <p>
                    {traveler?.gender}/{traveler?.dateOfBirth}
                  </p>
                </Box>
              </Box>
              {traveler?.contact?.emailAddress && (
                <Box display="flex">
                  <Box mr={1} width="24px" textAlign="right">
                    <svg
                      className="small-svg"
                      viewBox="0 0 24 24"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path d="M11.262 14.127l-8.07-8.072a.42.42 0 01-.119-.235.418.418 0 01.353-.473c.08-.011.16-.017.24-.017h16.667c.08 0 .161.006.24.017a.426.426 0 01.235.119.417.417 0 010 .589l-8.073 8.072a1.044 1.044 0 01-1.473 0zM21.74 6.889a.15.15 0 01.166-.032.15.15 0 01.093.14v10c0 .92-.746 1.667-1.667 1.667H3.667c-.92 0-1.667-.747-1.667-1.667v-10a.152.152 0 01.093-.138.15.15 0 01.167.032l5.167 5.167a.21.21 0 010 .295l-3.202 3.202a.624.624 0 000 .869c.24.249.634.255.883.015l3.202-3.202a.21.21 0 01.295 0l1.776 1.772a2.293 2.293 0 003.24 0l1.775-1.775c.08-.08.212-.08.294.001l3.203 3.203.015.016c.248.239.644.231.884-.017a.625.625 0 00-.015-.883l-3.203-3.203a.208.208 0 010-.295l5.167-5.167z"></path>
                    </svg>
                  </Box>
                  <p>{traveler?.contact?.emailAddress}</p>
                </Box>
              )}
              {traveler?.contact?.phones[0]?.number && (
                <Box display="flex">
                  <Box mr={1} width="24px" textAlign="right">
                    <svg
                      className="small-svg"
                      viewBox="0 0 24 24"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path d="M17.965 13.806a2.083 2.083 0 00-2.947 0l-1.321 1.32A39.095 39.095 0 018.872 10.3l1.322-1.322a2.085 2.085 0 000-2.948l-1.77-1.768a2.13 2.13 0 00-2.945 0l-.97.97a2.926 2.926 0 00-.367 3.67 39.133 39.133 0 0010.95 10.96 2.956 2.956 0 003.667-.37l.97-.97a2.085 2.085 0 000-2.948l-1.764-1.768z"></path>
                    </svg>
                  </Box>
                  <p> {traveler?.contact?.phones[0]?.number}</p>
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <Box mt={1}>
          {overviewData?.data?.flightOffers[0]?.travelerPricings?.map(
            (sege, index) =>
              sege?.fareDetailsBySegment[0]?.includedCheckedBags?.quantity >
                0 && (
                <>
                  {index === 0 && <p className="medium-font-size">Baggage</p>}
                  <Box display="flex" flexWrap="wrap" pt={2}>
                    <Box width={{ md: "40%" }}>
                      <Box display="flex" alignItems={"center"}>
                        <Box mr={1} width="24px">
                          <Checkedbaggage />
                        </Box>
                        <p>
                          {
                            sege?.fareDetailsBySegment[0]?.includedCheckedBags
                              ?.quantity
                          }
                          × Personal item
                        </p>
                        <p className="light-gray small-font-size mx-2">
                          {
                            sege?.fareDetailsBySegment[0]?.includedCheckedBags
                              ?.quantity
                          }{" "}
                          × 23 kg
                        </p>
                      </Box>
                    </Box>
                    <Box width="30%" minWidth={155} mt={1} ml={{ sm: 2 }}>
                      <p className="gray">
                        {
                          travelers?.find(
                            (traveler) => traveler?.id == sege?.travelerId
                          )?.name?.firstName
                        }
                        .
                        {travelers
                          ?.find((traveler) => traveler?.id == sege?.travelerId)
                          ?.name?.lastName?.charAt(0)}
                      </p>
                    </Box>
                  </Box>
                </>
              )
          )}
        </Box>
        <Box mt={3} py={2}>
          {overviewData?.data?.flightOffers?.map((flighOffer, flightIndex) =>
            flighOffer?.travelerPricings?.map((item, traverlindex) =>
              item?.fareDetailsBySegment?.map((fare, index) =>
                fare?.additionalServices?.chargeableSeatNumber ? (
                  <>
                    {flightIndex === 0 && (
                      <p className="medium-font-size mb-16">Seating</p>
                    )}
                    <Box display="flex" flexWrap="wrap">
                      <Box width={{ md: "40%" }}>
                        <Box display="flex" alignItems={"center"}>
                          <Box mr={1} width="24px">
                            <SeatingS />
                          </Box>

                          <p className="bold">
                            {
                              flighOffer?.itineraries[index]?.segments?.find(
                                (id) => id?.id == fare?.segmentId
                              )?.departure?.iataCode
                            }{" "}
                            —{" "}
                            {
                              flighOffer?.itineraries[index]?.segments?.find(
                                (id) => id?.id == fare?.segmentId
                              )?.arrival?.iataCode
                            }
                          </p>
                          <p className="light-gray small-font-size mx-2">
                            specific seat
                          </p>
                        </Box>
                      </Box>
                      <Box
                        width="30%"
                        minWidth={155}
                        mt={{ xs: 1, md: 0 }}
                        ml={{ sm: 2 }}
                      >
                        <p className="gray">
                          {
                            travelers?.find(
                              (traveler) => traveler.id == item?.travelerId
                            )?.name?.firstName
                          }
                          .
                          {travelers
                            ?.find(
                              (traveler) => traveler.id == item?.travelerId
                            )
                            ?.name?.lastName?.charAt(0)}
                          —{fare?.additionalServices?.chargeableSeatNumber}
                        </p>
                      </Box>
                    </Box>
                  </>
                ) : null
              )
            )
          )}
        </Box>
      </div>
      {/* <div className="card-card">
        <p className="medium-font-size">Redeem promo code</p>
        <Box display="flex" alignItems="end" mt={3} gap={2}>
          <BannerInput placeholder="009Xce" label="Promo code" />
          <Box mb={1}>
            <AppButton className="bigBtn">Apply</AppButton>
          </Box>
        </Box>
      </div> */}
      {/* <Payment /> */}
      <AppButton
        type="button"
        onClick={() => handelReverseSubmit()}
        className="bigBtn primary-color booking"
      >
        Place reservation
        {state?.flightDetails?.return !== "One-way" && (
          <>
            - {state?.flightDetails?.totalDays + "   "}
            Days
          </>
        )}{" "}
      </AppButton>
    </>
  );
}

export default Overview;
